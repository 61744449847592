import React, { Component } from "react";
import api from "../../http/global-vars";
import CertificateCard from "./CertificateCard";
import axios from "axios";
export default class ModalCertificat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      config: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
    };
  }

  async componentWillReceiveProps(nextProps) {
    // console.log("next props==>", nextProps?.apprenantInfos);
    let data = nextProps?.apprenantInfos;
    // await this._getCreateCertificat(data?.id)
  }

  _getCreateCertificat = async (userID) => {
    let { api, config } = this.state;
    if (userID) {
      let url = `${api}createpdfcertificate/${userID}/`;
      try {
        const req = await axios.get(url, config);
        // console.log("response certificat pdf==>", req["data"]);
        this.setState({ data: req["data"] });
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  render() {
    let { apprenantInfos,id } = this.props;
    return (
      <div>
        <p className="titre-modal-details-apprenant">
          {" "}
          {apprenantInfos.first_name + " " + apprenantInfos.last_name}{" "}
        </p>
        <ul className="nav nav-tabs" id={`myTab-${id}`} role="tablist">
          <li className="nav-item">
            <a
              name="certificat"
              className={`nav-link nav-link-modal-details-apprenant active`}
              id={`certificat-tab-${id}`}
              data-toggle="tab"
              href={`#certificat-${id}`}
              role="tab"
              aria-controls="certificat"
              aria-selected="false"
            >
              Certificat
            </a>
          </li>
        </ul>
        <div
          className="tab-content tab-content-custom"
          id={`myTabContent-${id}`}
        >
          <div
            className={`tab-pane fade show active`}
            id={`certificat-${apprenantInfos?.id}`}
            role="tabpanel"
            aria-labelledby={`certificat-tab-${apprenantInfos?.id}`}
          >
            <CertificateCard
              data={this.state?.data}
              certificats={apprenantInfos?.certificats}
              nb_certif={apprenantInfos?.certification}
            />
          </div>
        </div>
      </div>
    );
  }
}
