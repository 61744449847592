import { FETCH_LIST_APPRENANTS } from "../Type";

export const apprenantsReducer = (
  state = {
    data: [],
    isLoading: true,
    count: 0,
    femme: 0,
    homme: 0,
    moyenne_age: 0,
  },
  action
) => {
  switch (action.type) {
    case FETCH_LIST_APPRENANTS:
      return {
        data: action.payload,
        isLoading: action.isLoading,
        femme: action.femme,
        homme: action.homme,
        count: action.count,
        moyenne_age: action.moyenne_age,
      };
    default:
      return state;
  }
};
