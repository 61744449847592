/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import api from "../../http/global-vars";
import axios from "axios";
import FicheEvaluationSkeleton from "../AdminSkeletons/FicheEvaluationSkeleton";
import moment from "moment";
import { CustomPagination } from "../../modules/Pagination/CustomPagination";

function FicheEvaluation() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState("");
  let requestConfig = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [listEvaluated, setListEvaluated] = useState([]);

  useEffect(() => {
    getModules();
  }, []);
  useEffect(() => {
    getEvaluatedList(page, limit);
  }, [page, limit]);

  const getModules = async () => {
    const url = `${api}module`;
    try {
      axios.get(url, requestConfig).then((response) => {
        const data = response.data.results;
        // console.log("data module", data);
        if (data) {
          setModules(data);
        }
      });
    } catch (error) {
      console.log("e", error);
    }
  };

  const getEvaluatedList = async (page, limit) => {
    const url = api + `paginate/evaluation?page=${page}&limit=${limit}`;
    await axios
      .get(url, requestConfig)
      .then((response) => {
        console.log("response evaluation", response.data.results);
        setCount(response?.data?.count);
        const data = response.data.results;
        // console.log('eval', data)

        setListEvaluated(data);
        setIsPageLoading(false);
      })
      .catch((e) => {
        setIsPageLoading(false);
        console.log("error", e);
      });
  };

  const showFullname = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user?.first_name + " " + row.user?.last_name;
  };

  const showGender = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user?.sexe;
  };

  const showEmail = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user?.email;
  };

  const onExport = async () => {
    let url = `${api}export_evaluation`;
    if (module) {
      url = `${api}export_evaluation?module=${module}`;
    }
    await window.open(url, "_blank");
  };

  const createCustomButtonGroup = (props) => {
    return (
      // <ButtonGroup
      //   className="export-btn fs-14 fw-400 py-2 px-4"
      //   sizeClass="btn-group-md"
      // >
      //   {props.exportCSVBtn}
      // </ButtonGroup>
      <div class="d-flex" style={{ gap: "20px" }}>
        <div class="select-btn d-flex">
          <label className="select-for" htmlFor="module">
            Pour
          </label>
          <select
            name="module"
            id="module"
            className="form-control input-dashboard-backend select-input-dashboard-backend"
            value={module}
            onChange={(e) => setModule(e?.target?.value)}
          >
            <option value="">Tous les modules</option>
            {modules?.map((item) => (
              <option value={item?.id}>{item?.name}</option>
            ))}
          </select>
        </div>
        <div className="btn-group btn-group-md export-btn ">
          <button
            className="btn btn-success react-bs-table-csv-btn  hidden-print px-3"
            onClick={() => onExport()}
          >
            <FeatherIcon
              className="icon-btn-add-dashboard-orange fs-15 fw-400 "
              icon="download"
            />
            Extraction
          </button>
        </div>
      </div>
    );
  };

  const showDateNaissance = (cell, row) => {
    return row.user?.date_naissance;
  };

  const showMonth = (cell, row) => {
    let formatter = new Intl.DateTimeFormat("FR", {
      month: "long",
    });
    return formatter.format(Date.parse(row?.user?.date_joined));
  };

  const showYear = (cell, row) => {
    return moment(row?.user?.date_joined).format("YYYY");
  };

  const showAdresse = (cell, row) => {
    return row.user?.adresse;
  };

  const renderPaginationPanel = (props) => {
    return (
      <div class="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={count}
          onChange={(page, perPage) => {
            window.scrollTo(100, 100);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const options = {
    noDataText: "Aucune donnée disponible",
    btnGroup: createCustomButtonGroup,
    exportCSVText: "Extraction",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
  };
  return (
    <div className="item-card-extraction">
      <img
        className="icon-item-card-extraction"
        src="img/icons/paper.png"
        alt=""
      />
      <p className="text-item-card-extraction">Fiche d’évaluation</p>
      <button
        type="button"
        class="btn-item-card-extraction"
        data-toggle="modal"
        data-target="#ficheEvaluation"
      >
        voir
      </button>

      <div
        class="modal fade"
        id="ficheEvaluation"
        tabindex="-1"
        aria-labelledby="ficheEvaluationLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content pt-5 pb-3">
            <div className="px-md-4 px-3">
              <p className="titre-card-dashboard-sans-padding m-b-15">
                Liste des apprenants ayant rempli la fiche d’evaluation{" "}
                <span className="certified-count"> ({count}) </span>{" "}
              </p>
              <div className="row mt-5">
                {/* <div className="col-xl-4 col-lg-6 mb-3">
                    <from>
                      <button className="btn-theme fs-14 fw-400 py-2 px-4">
                        <FeatherIcon className="icon-btn-add-dashboard-orange" icon="download" />
                        Extraction
                      </button>
                    </from>
                  </div> */}
                <div className="col-xl-4 col-lg-6 mb-3">
                  {/* <div className="row">
                      <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                        <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                          <option selected>2020</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        </div>
                        <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                          <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                            <option selected>Décembre</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                    </div> */}
                </div>
                <div className="col-xl-4 mb-3"></div>
              </div>
            </div>

            <div className="table-container">
              {isPageLoading ? (
                <FicheEvaluationSkeleton />
              ) : (
                <BootstrapTable
                  // ref="table"
                  data={listEvaluated}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  pagination
                  exportCSV
                  options={options}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                    }}
                    width="80"
                    row="0"
                    rowSpan="2"
                    dataField="id"
                    isKey={true}
                  >
                    N°
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                      whiteSpace: "normal",
                    }}
                    width="230"
                    row="0"
                    rowSpan="2"
                    dataAlign="center"
                    dataField="FullName"
                    csvFormat={showFullname}
                    dataFormat={showFullname}
                  >
                    Prénom & Nom
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                      whiteSpace: "normal",
                    }}
                    width="230"
                    row="0"
                    rowSpan="2"
                    dataAlign="center"
                    csvFormat={showDateNaissance}
                    dataField="date_de_naissance"
                    dataFormat={(row, cell) => showDateNaissance(row, cell)}
                  >
                    Date de naissance
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                      whiteSpace: "normal",
                    }}
                    width="230"
                    row="0"
                    rowSpan="2"
                    dataAlign="center"
                    csvFormat={showAdresse}
                    dataField="adresse"
                    dataFormat={(row, cell) => showAdresse(row, cell)}
                  >
                    Adresse
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                    }}
                    width="250"
                    row="0"
                    rowSpan="2"
                    dataField="email"
                    csvFormat={showEmail}
                    dataAlign="center"
                    dataFormat={showEmail}
                  >
                    Adresse Mail
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                      textAlign: "center",
                    }}
                    width="140"
                    row="0"
                    rowSpan="2"
                    dataAlign="center"
                    dataField="sexe"
                    csvFormat={showGender}
                    dataFormat={showGender}
                  >
                    Genre
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                      textAlign: "center",
                    }}
                    filter={{ type: "TextFilter" }}
                    dataAlign="center"
                    filterValue={showMonth}
                    csvFormat={showMonth}
                    width="230"
                    row="0"
                    dataField="Mois"
                    rowSpan="2"
                    dataFormat={(row, cell) => showMonth(row, cell)}
                  >
                    Mois
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                      textAlign: "center",
                    }}
                    filter={{ type: "TextFilter" }}
                    dataAlign="center"
                    filterValue={showYear}
                    csvFormat={showYear}
                    width="230"
                    row="0"
                    dataField="Mois"
                    rowSpan="2"
                    dataFormat={(row, cell) => showYear(row, cell)}
                  >
                    Année
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                      whiteSpace: "normal",
                      lineHeight: 1.2,
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                    }}
                    width="300"
                    row="0"
                    rowSpan="2"
                    dataField="attente"
                  >
                    La formation a-t-elle répondu à vos attentes ?
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 15,
                      color: "#1F497D",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      lineHeight: 1.2,
                      background: "#EEEEEE",
                      padding: "10px 5px",
                      textAlign: "center",
                    }}
                    row="0"
                    colSpan="3"
                  >
                    Comment évaluez-vous la plateforme www.defaru.sn ?
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 300,
                      fontSize: 12,
                      color: "#000000",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      textAlign: "center",
                      padding: "10px 5px",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      textAlign: "center",
                    }}
                    width="200"
                    row="1"
                    dataAlign="center"
                    dataField="easy_to_use"
                  >
                    Facilité d’utilisation
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 300,
                      fontSize: 12,
                      color: "#000000",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      textAlign: "center",
                      padding: "10px 5px",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      textAlign: "center",
                    }}
                    width="230"
                    row="1"
                    dataAlign="center"
                    dataField="design_quality"
                  >
                    Qualité du design graphique{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 300,
                      fontSize: 12,
                      color: "#000000",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      textAlign: "center",
                      padding: "10px 5px",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      textAlign: "center",
                    }}
                    width="230"
                    row="1"
                    dataAlign="center"
                    dataField="platform_access"
                  >
                    Accessibilité de la plateforme
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 15,
                      color: "#1F497D",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      lineHeight: 1.2,
                      background: "#EEEEEE",
                      padding: "10px 5px",
                      textAlign: "center",
                    }}
                    row="0"
                    colSpan="4"
                  >
                    Comment évaluez-vous la formation dont vous avez bénéficié ?
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 300,
                      fontSize: 12,
                      color: "#000000",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      textAlign: "center",
                      padding: "10px 5px",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      textAlign: "center",
                    }}
                    width="200"
                    row="1"
                    dataAlign="center"
                    dataField="formation_clarity"
                  >
                    Clarté du contenu
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 300,
                      fontSize: 12,
                      color: "#000000",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      textAlign: "center",
                      padding: "10px 5px",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      textAlign: "center",
                    }}
                    width="230"
                    row="1"
                    dataAlign="center"
                    dataField="exercise_clarity"
                  >
                    Clarté du contenu pratique{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 300,
                      fontSize: 12,
                      color: "#000000",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      textAlign: "center",
                      padding: "10px 5px",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      textAlign: "center",
                    }}
                    width="230"
                    row="1"
                    dataAlign="center"
                    dataField="support_quality"
                  >
                    Qualité des supports{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 300,
                      fontSize: 12,
                      color: "#000000",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      borderBlock: "none",
                      textAlign: "center",
                      padding: "10px 5px",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      borderLeft: "1px solid #C4C4C4",
                      borderRight: "1px solid #C4C4C4",
                      textAlign: "center",
                    }}
                    width="230"
                    row="1"
                    dataAlign="center"
                    dataField="instruction_clarity"
                  >
                    Clarté des consignes
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                      whiteSpace: "normal",
                      lineHeight: 1.2,
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                    }}
                    width="800"
                    row="0"
                    rowSpan="2"
                    dataAlign="center"
                    dataField="agr"
                  >
                    4. Pensez-vous que cette formation vous a assuré des
                    possibilités d’insertion professionnelle ou de création
                    d’activité génératrice de revenu (AGR) ?{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                      whiteSpace: "normal",
                      lineHeight: 1.2,
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                    }}
                    width="300"
                    row="0"
                    rowSpan="2"
                    dataAlign="center"
                    dataField="project_future"
                  >
                    Projets concrets d’avenir
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                      border: "none",
                      whiteSpace: "normal",
                      lineHeight: 1.2,
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                      border: "none",
                    }}
                    width="300"
                    row="0"
                    rowSpan="2"
                    dataAlign="center"
                    dataField="example_project"
                  >
                    Exemples concrets
                  </TableHeaderColumn>
                </BootstrapTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FicheEvaluation;
