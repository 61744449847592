import { round } from "lodash";
import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import api from "../../http/global-vars";
// import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import { data } from "jquery";
// const data = {
// 	labels: [
// 		'Terminé',
// 		'En cours',
// 	],
// 	datasets: [{
// 		data: [60, 40],
// 		backgroundColor: [
// 			'#F39200',
// 			'#1F497D'
// 		],
// 		hoverBackgroundColor: [
// 			'#F39200',
// 			'#1F497D'
// 		]
// 	}]
// };

export default class CertificationDoughnutChart extends Component {
  state = {
  };

 

  render() {
    // let { percent_certif, percent_noCertif } = this.state;
    let { load, percent_certif, percent_noCertif } = this.props;
    // console.log('load', this.props.load)
    const DoughnutData = {
      labels: [
        `${
          percent_certif ? percent_certif : 0
        }% d'apprenants qui ont terminé le module`,
        `${
          percent_noCertif ? percent_noCertif : 0
        }% d'apprenants qui ont entamé le module`,
      ],
      datasets: [
        {
          // data: [60, 40],
          data: [percent_certif, percent_noCertif],
          backgroundColor: ["#F39200", "#1F497D"],
          hoverBackgroundColor: ["#F39200", "#1F497D"],
        },
      ],
    };
    return (
      <React.Fragment>
        {load && (
          <div
            className="d-flex justify-content-center"
            style={style.marginTop}
          >
            <DoughnutSkeleton />
          </div>
        )}
        <div className="chart-item-container">
          {!load && (
            <React.Fragment>
              <Doughnut
                data={DoughnutData}
                height={230}
                width={230}
                options={{
                  tooltips: {
                    titleFontSize: 10,
                    bodyFontSize: 10,
                  },
                  responsive: true,
                  cutoutPercentage: 85,
                  maintainAspectRatio: false,
                  legend: {
                    display: true,
                    fullWidth: true,
                  },
                  elements: {
                    arc: {
                      borderWidth: [1, 1],
                    },
                  },
                }}
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const DoughnutSkeleton = () => {
  return (
    <React.Fragment>
      <Skeleton
        className="chart-item-container"
        circle={true}
        height={270}
        width={270}
      />
    </React.Fragment>
  );
};

const style = {
  marginTop: {
    marginTop: "-1em",
  },
  chart: {
    float: "left",
    width: "70%",
  },
};
