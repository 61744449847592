import axios from "axios";
import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast, ToastContainer } from "react-toastify";
import api from "../../http/global-vars";
import ContentEditor from "./supportContentAdmin/ContentEditor";

export default class CreateTexteATrous extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      fields: { name: props.name },
      taskId: props.taskId,
      update: props.update,
      words: [{ content: "", to_delete: true }],

      created_by: parseInt(window.localStorage.getItem("userID")),
      isSuccess: false,
      isError: false,
      isLoading: false,
      description: props.content,
      is_empty_name: false,
      is_empty_text: false,
      contentId: this.props.contentId,
      errors: {},
    };
  }

  addAttributeForm = (e) => {
    e.preventDefault();
    this.setState({
      words: [...this.state.words, { content: "", to_delete: true }],
    });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields,
    });
  };

  onValidateForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
        });
      }, 5000);

      errors["name"] = "Ce champ est obligatoire";
    }

    if (!this.state.description) {
      isValidForm = false;
      this.setState({
        is_empty_text: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_text: false,
        });
      }, 5000);

      errors["texte"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  async addWords(taskId) {
    for (let item of this.state.words) {
      try {
        const text = {
          content: item.content,
          to_delete: item.to_delete,
          text: taskId,
        };
        await axios.post(
          api + "wordtextatrous/",
          text,
          this.state.requestConfig
        );
      } catch (ex) {}
    }
    this.props.notify();
    this.setState({
      isLoading: false,
      fields: {},
      words: [{ content: "", to_delete: true }],
    });
  }

  addForm = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const api = this.state.api;
    const data = {
      name: this.state.fields.name,
      complete_content: this.state.description,
      contenu: this.state.contentId,
      created_by: this.state.created_by,
    };
    if (this.state.update) {
      axios
        .put(
          api + `textatroustask/${this.state.taskId}/`,
          data,
          this.state.requestConfig
        )
        .then((response) => {
          this.props.notify();
          this.setState({
            isLoading: false,
            fields: {},
            description: "",
            words: [{ content: "", to_delete: true }],
          });
        })
        .catch((error) => {
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
          );
          this.setState({
            isLoading: false,
          });
        });
    } else if (this.state.taskId) {
      this.addWords(this.state.taskId);
    } else {
      if (this.onValidateForm()) {
        axios
          .post(api + "textatroustask/", data, this.state.requestConfig)
          .then((response) => {
            this.addWords(response.data.id);
          })
          .catch((error) => {
            toast.error(
              "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
            );
            this.setState({
              isLoading: false,
            });
          });
      } else {
        this.setState({
          isLoading: true,
        });
      }
    }
  };

  checkWordInput(index, e) {
    const words = [...this.state.words];
    if (e.target.name === "to_delete") {
      words[index] = { ...words[index], to_delete: e.target.checked };
    } else {
      words[index] = { ...words[index], content: e.target.value };
    }
    this.setState({ words });
  }
  render() {
    const inputs = this.state.words.map((word, index) => (
      <div className="col-md-12 m-b-15" key={index}>
        <div className="row">
          <div className="col-md-2 itm-center">
            <span className="fs-16 fw-600 text-dark">Mots</span>
          </div>
          <div className="col-lg-7 col-md-8 col-sm-8 col-7">
            <div className="form-group">
              <input
                type="text"
                className="form-control input-dashboard-backend border-theme"
                placeholder="Mot"
                name={"content"}
                id={`content${index}`}
                value={word.content}
                onChange={(e) => this.checkWordInput(index, e)}
              />
            </div>
          </div>
          <div className="col-md-3 itm-center m-b-15">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  id={`content${index}`}
                  checked={word.to_delete}
                  name="to_delete"
                  onChange={(e) => this.checkWordInput(index, e)}
                />
              </div>
            </div>
            <label
              style={{
                display: "inline-block",
                "margin-left": 5,
                "font-size": 14,
                marginBottom: 0,
              }}
              htmlFor={`is_right_choice${index + 100}`}
            >
              à enlever ?
            </label>
          </div>
        </div>
      </div>
    ));
    return (
      <div className="card-dashboard no-shadow">
        <form>
          <div className="row m-b-50">
            <div className="col-lg-9">
              <p className="titre-card-create-content">Texte A Trous</p>
            </div>
          </div>
          {(!this.state.taskId || this.state.update) && (
            <>
              <div className="form-group m-b-40">
                <input
                  type="text"
                  className="form-control input-dashboard-backend"
                  placeholder="Consigne"
                  name="name"
                  value={this.state.fields.name}
                  onChange={(e) => this.handleChange(e)}
                />
                {this.state.is_empty_name && (
                  <div className="alert alert-danger keysjob-alert-danger">
                    {" "}
                    {this.state.errors.name}{" "}
                  </div>
                )}
              </div>
              <ContentEditor
                name={`description_text_trous`}
                placeholder="Résumé"
                value={this.state.description}
                onChange={(val) => this.setState({ description: val })}
              />

              {this.state.is_empty_text && (
                <div className="alert alert-danger keysjob-alert-danger">
                  {" "}
                  {this.state.errors.texte}{" "}
                </div>
              )}
            </>
          )}
          {!this.state.update && (
            <>
              <div className="row m-b-30">{inputs}</div>
              <div className="row m-b-60">
                <div className="col-md-7 dis-flex justify-content-center">
                  <button
                    className="link-btn-add-orange"
                    onClick={(e) => this.addAttributeForm(e)}
                  >
                    <FeatherIcon
                      className="icon-btn-add-dashboard"
                      icon="plus"
                    />
                    Ajouter un mot
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 m-b-20">
              {this.state.isLoading === false ? (
                <button
                  type="submit"
                  onClick={(e) => this.addForm(e)}
                  className="btn-theme text-up w-full"
                >
                  Terminer
                </button>
              ) : (
                <button className="btn-theme text-up w-full in-progress-btn">
                  Ajout en cours... &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
      </div>
    );
  }
}
