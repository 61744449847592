import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import api from "../http/global-vars";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import NavbarVerticalAdmin from "./components/NavbarVertical";
import MessageAllPage from "./components/MessageAllPage";
import baseUrl from "../http/backend-base-url";
import AllCourseSkeleton from "../frontend/components/AllCourseSkeleton";
import { EmptyDataAlert } from "./Thematiques";

const ModuleByThematique = ({ theme }) => {
  const [modules, setModules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const getModules = async (id) => {
    let url = `${api}modulebythematique/${id}/`;
    await axios
      .get(url, config)
      .then((res) => {
        console.log("res", res);
        setModules(res?.data?.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (theme?.id) {
      getModules(theme?.id);
    }
  }, [theme]);
  return (
    <div className="row">
      {!!isLoading && <AllCourseSkeleton />}
      {!isLoading &&
        (modules?.length ? (
          modules?.map((item) => (
            <div className="col-md-4 px-md-2 d-flex" key={item.id}>
              <div className="card card-all-modules">
                <img
                  className="img-course-on-card-cours"
                  src={baseUrl + item?.logo}
                  alt=""
                />
                <div
                  className="content-course-on-card-cours"
                  title={item?.name}
                >
                  <NavLink
                    className="titre-course-on-card-cours"
                    to={{
                      pathname: `/module-details/${item?.id}`,
                      state: item,
                    }}
                  >
                    {item?.name}
                  </NavLink>
                  <div className="row m-t-15">
                    <div className="col-lg-12 col-md-12 col-sm-3 col-xs-6 col-12 flex-row info-content-course-on-card-cours itm-center">
                      {/* <FeatherIcon
                        className="icon-content-course-on-card-cours"
                        icon="youtube"
                      />
                      {item?.number_session} sessions */}
                    </div>
                    <div className="col">
                      <NavLink
                        to={{
                          pathname: `/module-details/${item?.id}`,
                          state: item,
                        }}
                      >
                        <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyDataAlert text={"Aucune donnée trouvée"} />
        ))}
      {/* <div className="col-md-4 px-md-2">
        <div className="card">
          <img
            className="img-course-on-card-cours"
            src="/img/communication.png"
            alt=""
          />
          <div className="content-course-on-card-cours">
            <NavLink
              className="titre-course-on-card-cours"
              to={`/presentation-module`}
            >
              La communication numérique
            </NavLink>
            <div className="row m-t-15">
              <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                <FeatherIcon
                  className="icon-content-course-on-card-cours"
                  icon="youtube"
                />
                3 sessions
              </div>
              <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                <FeatherIcon
                  className="icon-content-course-on-card-cours"
                  icon="clock"
                />
                1h 30min
              </div>
              <div className="col">
                <NavLink to={`/presentation-module`}>
                  <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 px-md-2">
        <div className="card">
          <img
            className="img-course-on-card-cours"
            src="/img/numerique"
            alt=""
          />
          <div className="content-course-on-card-cours">
            <NavLink
              className="titre-course-on-card-cours"
              to={`/presentation-module`}
            >
              Les bases du numérique
            </NavLink>
            <div className="row m-t-15">
              <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                <FeatherIcon
                  className="icon-content-course-on-card-cours"
                  icon="youtube"
                />
                3 sessions
              </div>
              <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                <FeatherIcon
                  className="icon-content-course-on-card-cours"
                  icon="clock"
                />
                1h 30min
              </div>
              <div className="col">
                <NavLink to={`/presentation-module`}>
                  <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

function ThematiqueDetails() {
  const [theme, setTheme] = useState({});
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const getModule = async (id) => {
    await axios
      .get(`${api}module/${id}`, config)
      .then((res) => {
        setTheme(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
        history.goBack();
      });
  };

  useEffect(() => {
    if (id) {
      getModule(id);
    }
  }, [id]);

  useEffect(() => {
    if (location?.state) {
      setTheme(location?.state);
    }
  }, [location]);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobileAdmin />
        </div>
      </div>

      <HeaderDashboardAdmin />

      <div className="row">
        {/* Navbar Desktop  */}
        {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
        <div className="nav-container">
          <div
            className="navbar-vertical-customisation no-view-mobile"
            style={{ top: "40px" }}
          >
            <NavbarVerticalAdmin />
          </div>
        </div>

        <div className="col-xl-2 col-lg-3 col-md-12"></div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <p className="m-b-30">
              <NavLink className="routing-view-url" to="/thematiques">
                Thématique
              </NavLink>
              <FeatherIcon
                className="icon-routing-view-url"
                icon="chevron-right"
              />
              <NavLink className="routing-view-url" to="#">
                {theme?.name}
              </NavLink>
            </p>
            <div>
              <div className="card">
                <div className="banner-image-cours-details">
                  <div className="row">
                    <div className="col-md-7">
                      {/* <p className="f-f-work text-dark ">Module 1 :</p> */}
                      <p className="text-banner-image-cours-details">
                        {theme?.name}
                      </p>

                      <div className="d-flex">
                        <FeatherIcon
                          className="icon-details-infos-cours"
                          icon="align-justify"
                        />
                        <p className="text-details-infos-cours">
                          <span className="fw-600">
                            {theme?.modules_count} Modules
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 offset-md-1 itm-center">
                      <img
                        className="w-full"
                        src={
                          theme?.logo
                            ? baseUrl + theme?.logo
                            : "/img/intersect.png"
                        }
                        alt={theme?.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="content-cours-details">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="text-black m-b-100 fs-14 fw-300">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: theme?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-modules">
                <h4 className="titre-page-dashboard m-b-35">Modules</h4>
                <ModuleByThematique theme={theme} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
}

export default ThematiqueDetails;
