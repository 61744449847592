import axios from "axios";
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import api from "./../../http/global-vars";

export default class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      statData: [],
      moduleName: [],
      apprenantCounting: [],
      certificatCount: [],
      finishedModuleCount: [],
      is_load: true,
    };
  }

  componentDidMount() {
    this.onGetStatistiques();
  }

  onGetStatistiques = () => {
    var api = this.state.api;
    var config = this.state.requestConfig;
    let { statData } = this.state;

    axios
      .get(api + "certificat_info/", config)
      .then((response) => {
        // console.log(response.data.data.list_modules);
        statData = response.data.data.list_modules.sort(
          (a, b) => a.module.module_number > b.module.module_number
        );
        this.setState({
          statData: response.data.data.list_modules,
          isPageLoading: false,
        });
        // console.log("infos test=>", this.state.statData)

        var moduleName = this.state.moduleName;
        var apprenantCounting = this.state.apprenantCounting;
        var certificatCount = this.state.certificatCount;
        var finishedModuleCount = this.state.finishedModuleCount;
        for (let i of this.state.statData) {
          moduleName.push(i.module.name);
          apprenantCounting.push(i.nbr_apprenant);
          certificatCount.push(i.nbr_certificat);
          finishedModuleCount.push(i.nbr_user_finished);
        }
        this.setState({
          moduleName: moduleName,
          apprenantCounting: apprenantCounting,
          finishedModuleCount: finishedModuleCount,
          is_load: false,
        });
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({ is_load: false });
      });
  };

  render() {
    let { load } = this.props;
    // console.log("module name=>", this.state.moduleName);
    let data = {
      labels: this.state.moduleName,
      datasets: [
        {
          type: "bar",
          label: "Nombre d'apprenants qui ont entamé le module",
          data: this.state.apprenantCounting,
          fill: false,
          backgroundColor: "#1F497D",
          borderColor: "#1F497D",
        },
        {
          type: "bar",
          label: "Nombre d'apprenants qui ont terminé le module",
          data: this.state.finishedModuleCount,
          fill: false,
          backgroundColor: "#F39200",
          borderColor: "#F39200",
        },
      ],
    };

    let options = {
      responsive: true,
      labels: this.state.moduleName,
      tooltips: {
        mode: "label",
      },
      elements: {
        line: {
          fill: false,
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false,
            },
            labels: this.state.moduleName,
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
          },
          {
            type: "linear",
            display: false,
            position: "right",
            id: "y-axis-2",
            gridLines: {
              display: false,
            },
            labels: {
              show: true,
            },
          },
        ],
      },
    };

    let plugins = [
      {
        afterDraw: (chartInstance, easing) => {
          //const ctx = chartInstance.chart.ctx;
          //ctx.fillText("This text drawn by a plugin", 100, 100);
        },
      },
    ];

    return (
      <div>
        {load && <BarSkeleton />}
        {!load && <Bar data={data} options={options} plugins={plugins} />}
        {/* <Bar
                    data={data}
                    options={options}
                    plugins={plugins}
                /> */}
      </div>
    );
  }
}

const BarSkeleton = () => {
  return (
    <React.Fragment>
      <Skeleton
        className="card-dashboard m-b-15 p-b-20"
        height={255}
        width={"100%"}
      />
    </React.Fragment>
  );
};
