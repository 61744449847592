import ReactPaginate from "react-paginate";
import React from "react"

function Pagination({
  page,
  onPageChange,
  total,
}) {
  return (
    /* @ts-ignore */
    <ReactPaginate
      breakLabel="..."
      initialPage={page - 1}
      nextLabel=">"
      onPageChange={(page) => onPageChange(page?.selected + 1)}
      pageRangeDisplayed={5}
      pageCount={total}
      previousLabel="<"
      pageClassName="page-item"
      pageLinkClassName="page-link custom-page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link custom-break-link"
      containerClassName="pagination"
      activeClassName="active"
    />
  );
}

export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}) {
  if (parseInt(nbPages) <= 0) return null;
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="footer-form d-flex mt-4">
        {nbPages > 0 && (
          <select
            className="form-control select-perpage"
            name="limit"
            id="pageDropDown"
            value={perPage}
            onChange={(e) => {
              onChange(1, parseInt(e.target.value, 10));
            }}
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        )}
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={Math.ceil((nbPages || 0) / perPage) || 0}
          perPage={perPage}
          onPageChange={(page) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  );
}