/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import FeatherIcon from "feather-icons-react";
import MessageAllPage from "../../backend/components/MessageAllPage";
import { NavLink } from "react-router-dom";
import api from "./../../http/global-vars";
import axios from "axios";
import baseUrl from "../../http/backend-base-url";
// import { Document, Page } from 'react-pdf';
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

export default class MyCertificatesV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      userType: window.localStorage.getItem("userType"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      isPageLoading: true,
      certicats: [],
    };
  }

  componentDidMount() {
    var userID = this.state.userID;
    window.scroll(0,0)
    this._getCertificat(userID);
  }

  _getCertificat = async (userID) => {
    let { api, requestConfig } = this.state;
    let url = `${api}certificatbyuser/${userID}/`;
    try {
      const req = await axios.get(url, requestConfig);
      // console.log("response certificat pdf==>", req);
      this.setState({ certicats: req["data"], isPageLoading: false });
      // console.log(this.state.data)
    } catch (error) {
      console.log(error.message);
      this.setState({ isPageLoading: false });
    }
  };

  render() {
    var { certicats, isPageLoading } = this.state;
    // console.log("data", data);
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobile />
          </div>
        </div>

        <HeaderDashboard />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="col-xl-2 col-lg-3 col-md-12">
            <div className="navbar-vertical-customisation no-view-mobile">
              <NavbarVertical />
            </div>
          </div>

          <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 content-position-after-header pdf-viewer-container">
            <div className="row content-routing-url m-b-20">
              <div className="lh-0">
                <NavLink className="routing-view-url" to="/tous-les-modules">
                  Tous les modules
                </NavLink>
                <FeatherIcon
                  className="icon-routing-view-url"
                  icon="chevron-right"
                />
                <NavLink className="routing-view-url" to="#">
                  Mes Certificats
                </NavLink>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="container-fluid">
                  <div className="item-profile-badge-container">
                    <p className="text-header-quiz-card p-l-40 m-b-50">
                      Mes certificats
                    </p>
                    <div className="row p-l-40">
                      {!isPageLoading && !certicats?.length ? (
                        <div className="col-md-12 dis-flex badges text-center">
                          <div className="alert alert-info gfa-alert-info empty-badge-alert">
                            <div className="row empty-alert-row">
                              <div className="col-md-2 empty-icon-col">
                                <FeatherIcon
                                  icon="alert-circle"
                                  className="m-b-3 empty-alert-icon rotateIn"
                                />
                              </div>
                              <div className="col-md-10 empty-textual-col">
                                <p>
                                  Vous n'avez acquis aucun certificat pour le
                                  moment.
                                </p>
                                <p className="info-label">
                                  Pour rappel chaque module de formation terminé
                                  vous donne droit à un certificat.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {(!isPageLoading && certicats?.length) && (
                        certicats?.map((certificat, index) => {
                          return (
                            <div className="col-md-12 ceritificat-col">
                              <div className="ceritificat-item-container">
                                <a className="certificat-downlodable-link" href={ `${baseUrl}${certificat?.url}` } target="_blank" download> { certificat?.name } </a>
                              </div>
                            </div>
                          )
                        })
                      )}

                      {/* {!isPageLoading && certicats?.length
                        ? certicats?.map((item) => (
                            <div className="col-md-12 dis-flex badges text-center">
                              <div
                                className="row pdf-created-row w-100"
                                key={item?.id}
                              >
                                {!item?.url ? null : (
                                  <>
                                    <div className="col-md-6 offset-md-3 pdf-created-link-container">
                                      <a
                                        className="pdf-created-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${baseUrl}${item?.url}`}
                                      >
                                        Télécharger votre attestation
                                      </a>
                                    </div>
                                    <div className="col-md-12 pdf-created-viewr-container">
                                      <iframe
                                        src={`${baseUrl}${item?.url}`}
                                        style={style.ifram_}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ))
                        : null} */}
                      {!!isPageLoading && (
                        <div className="col-md-12 dis-flex badges text-center">
                          <div className="row pdf-created-row w-100">
                            {/* <div className="col-md-6 offset-md-3 pdf-created-link-container">
                              <Skeleton className="pdf-created-link" />
                            </div> */}
                            <div className="col-md-12 pdf-created-viewr-container">
                              <Skeleton style={style.ifram_} height={`100vh`} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>
      </div>
    );
  }
}
const style = {
  ifram_: {
    width: "100%",
  },
};
