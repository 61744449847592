import axios from "axios";
import FeatherIcon from "feather-icons-react";
import $ from "jquery";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../http/global-vars";
toast.configure();
const score = [5, 10, 15, 20, 25];

export default class CreateImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contenu: props.contentId,
      fields: { score: 0 },
      responses: [],
      scores: {},
      is_pretest: false,
      is_testfinal: false,
      content_type: "",
      api: api,
      title: "",
      quiz: props.quiz || 0,
      question: props.question || 0,
      load: false,
      load2: false,
      end: false,
    };
  }
  componentDidMount() {
    const item = { content: "", is_right_choice: false, image: "" };
    if (this.props.question) {
      this.setState({ responses: [{ ...item }] });
    } else {
      this.setState({
        responses: [{ ...item }, { ...item }, { ...item }, { ...item }],
      });
    }
  }

  addQuestion = async (quiz) => {
    if (this.state.question === 0) {
      let url = this.state.api + "question/";
      let form_data = new FormData();
      let data = {
        content: this.state.fields.content,
        score: this.state.fields.score,
        quiz: quiz,
      };
      form_data.append("content", data.content);
      form_data.append("score", data.score);
      form_data.append("quiz", data.quiz);

      try {
        let res = await axios.post(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userToken}`,
          },
        });
        if (res.status === 201) {
          let question = res.data.id;
          await this.addResponse(question);
        }
      } catch (error) {
        this.setState({ load: false });
        if (this.state.load2) {
          this.setState({ load2: false });
        }
      }
    } else {
      await this.addResponse(this.state.question);
    }
  };

  addResponse = async (question) => {
    let url = this.state.api + "response/";
    for (let i = 0; i < this.state.responses.length; i++) {
      const element = this.state.responses[i];
      let form_data = new FormData();
      form_data.append("content", element.content);
      form_data.append("is_right_choice", element.is_right_choice);
      form_data.append("image", element.image);
      form_data.append("question", question);

      try {
        let res = await axios.post(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userToken}`,
          },
        });
        console.log(res);
        if (res.status === 201) {
        }
      } catch (error) {
        this.setState({ load: false });
        if (this.state.load2) {
          this.setState({ load2: false });
        }
        toast.error("Ouups une erreur lors l'envoie de la requete", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    }
    $("#fieldreset")[0].reset();
    let responses = [
      { content: "", is_right_choice: false, image: "" },
      // { content: "", is_right_choice: false, image: "" },
      // { content: "", is_right_choice: false, image: "" },
      // { content: "", is_right_choice: false, image: "" },
    ];

    let fields = { score: 0, content: "" };
    this.state.fields = fields;
    this.state.responses = responses;

    this.setState({
      responses,
      is_pretest: false,
      is_testfinal: false,
    });
    this.notify();
  };

  addQuizz = async (e) => {
    e.preventDefault();
    this.setState({ load: true });
    if (this.state.quiz === 0) {
      let url = this.state.api + "quiz/";
      let form_data = new FormData();
      if (this.state.image1 || this.state.image2 || this.state.images3) {
        this.setState({ content_type: "image" });
      }

      form_data.append("created_by", JSON.parse(localStorage.userID));
      form_data.append("title", this.state.title);
      form_data.append("content_type", this.state.content_type);
      form_data.append("contenu", this.props.contentId);
      form_data.append("is_pretest", this.state.is_pretest);
      form_data.append("is_testfinal", this.state.is_testfinal);
      try {
        let res = await axios.post(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.userToken}`,
          },
        });
        let quiz = res.data.id;
        this.setState({ quiz });
        await this.addQuestion(quiz);
        $("#fieldreset")[0].reset();
      } catch (error) {
        this.setState({ load: false });
        toast.error("Ouups une erreur lors l'envoie de la requete", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    } else {
      await this.addQuestion(this.state.quiz);
    }
    this.setState({ load: false });
  };

  notify = () => {
    this.props.notify();
  };

  onChangeQues = (e) => {
    e.preventDefault();
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields: fields });
  };

  handleChange(index, e) {
    const inputs = [...this.state.responses];
    const input = inputs.find((_, i) => i === index);
    if (e.currentTarget.name === `image${index + 1}`) {
      input.image = e.currentTarget.files[0];
      input.content = e.currentTarget.files[0].name;
    }
    if (e.currentTarget.name === `is_right_choice${index + 100}`) {
      input.is_right_choice = e.currentTarget.checked;
    }
    inputs[index] = { ...input };
    console.log("inputs", inputs);
    this.setState({ responses: inputs, content_type: "image" });
  }

  addResponseForm = (e) => {
    e.preventDefault();
    this.setState({
      responses: [
        ...this.state.responses,
        { content: "", is_right_choice: false, image: "" },
      ],
    });
  };
  renderBooldField(name, label) {
    return (
      <div className="col-sm-6 col-md-6 m-b-15">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            name={name}
            id={name}
            value={!!this.state[name]}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.checked })
            }
            className=""
          />{" "}
          <label className="" htmlFor={name}>
            {label}
          </label>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="card-dashboard no-shadow">
        <div className="row m-b-50">
          <div className="col-lg-5">
            <p className="titre-card-create-content">Quiz image</p>
          </div>
          <div className="col-lg-4"></div>
        </div>
        <form id="fieldreset">
          {this.state?.quiz === 0 && (
            <div className="row m-b-25">
              <div className="col-xl-2 col-lg-2 itm-center p-r-0">
                <label className="label-quiz-backend" htmlFor="reponse1">
                  Titre Quizz
                </label>
              </div>
              <div className="col-xl-7 col-lg-7 itm-center m-b-30">
                <textarea
                  rows="2"
                  name="title"
                  className="form-control form-control-quiz-backend"
                  placeholder=""
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  id="title"
                />
                <button className="btn-placeholder-textarea">
                  <FeatherIcon
                    className="icon-btn-placeholder-textarea"
                    icon="link-2"
                  />
                </button>
              </div>
              <div className="col-md-12 m-b-30">
                <div className="row">
                  {this.renderBooldField("is_pretest", "Prétest ?")}
                  {this.renderBooldField("is_testfinal", "Test final ?")}
                </div>
              </div>
            </div>
          )}
          {this.state?.question === 0 && (
            <div className="row m-b-25">
              <div className="col-xl-2 col-lg-2 m-b-25 itm-center p-r-0">
                <label className="label-quiz-backend" htmlFor="question">
                  Question
                </label>
              </div>
              <div className="col-xl-7 col-lg-7 itm-center m-b-30">
                <textarea
                  rows="2"
                  name="content"
                  className="form-control form-control-quiz-backend"
                  placeholder=""
                  onChange={(e) => this.onChangeQues(e)}
                />
                <button className="btn-placeholder-textarea">
                  <FeatherIcon
                    className="icon-btn-placeholder-textarea"
                    icon="link-2"
                  />
                </button>
              </div>
              <div className="col-xl-3 col-lg-3 m-b-45 flex-col justify-content-end containt-mobile-left">
                <label
                  className="label-quiz-backend m-b-0 m-r-20 score-label-qcm"
                  htmlFor="score"
                >
                  Score
                </label>
                <select
                  className="custom-select score-input-dashboard"
                  id="score"
                  name="score"
                  onChange={(e) => this.onChangeQues(e)}
                >
                  <option>{0}</option>
                  {score.map((item, index) => (
                    <option value={item !== undefined ? item : 0} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div>
            {this.state.responses.map((text, index) => {
              return (
                <div className="row m-b-25" key={index}>
                  <div className="col-xl-2 col-lg-2 itm-center p-r-0">
                    <label className="label-quiz-backend" htmlFor="reponse">
                      Réponse {index + 1}
                    </label>
                  </div>
                  <div className="col-xl-7 col-lg-7 itm-center">
                    <input
                      type="file"
                      name={`image${index + 1}`}
                      id={`image${index + 1}`}
                      className="form-control"
                      onChange={(e) => this.handleChange(index, e)}
                    />
                    <div className="btn-placeholder-textarea">
                      <FeatherIcon
                        className="icon-btn-placeholder-textarea"
                        icon="image"
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 itm-center m-b-15">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          type="checkbox"
                          id={`is_right_choice${index + 100}`}
                          name={`is_right_choice${index + 100}`}
                          onChange={(e) => this.handleChange(index, e)}
                        />
                      </div>
                    </div>
                    <label
                      style={{
                        display: "inline-block",
                        "margin-left": 5,
                        "font-size": 14,
                        marginBottom: 0,
                      }}
                      htmlFor={`is_right_choice${index + 100}`}
                    >
                      Bonne réponse {index + 1}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row m-b-25">
            <div className="col-xl-2 col-lg-2 itm-center p-r-0"></div>
            <div className="col-xl-4 col-lg-4 m-t-10">
              <button
                type="button"
                className="btn-light-add-content"
                onClick={(e) => this.addResponseForm(e)}
              >
                <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
                Ajouter une réponse
              </button>
            </div>
          </div>
          <div className="row m-b-25">
            <div className="col-xl-2 col-lg-2 itm-center p-r-0"></div>
          </div>

          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-4 col-lg-4 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>

            <div className="col-xl-4 col-lg-4 m-b-20 itm-center">
              {!this.state.load2 ? (
                <button
                  onClick={(e) => this.addQuizz(e)}
                  className="btn-theme text-up w-full"
                >
                  Ajouter
                </button>
              ) : (
                <button className="btn-theme text-up w-full">
                  Chargement...
                  <i className="fa fa-spin fa-spinner" aria-hidden="true"></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
