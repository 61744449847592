import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import api from "../../http/global-vars";
import axios from "axios";

export default class ItemSessionDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleId: this.props.moduleId,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      sessions: [],
      userDoSessions: [],
      contenuByModule: []
    };
  }

  componentDidMount() {
    if(this.props.moduleId){
      this.getSessionByModule();
      this.getContenuByModule()
    }    
  }

  getContenuByModule = async () => {
    const url = `${api}contenubymodule/${this.props.moduleId}`;
   try {
     const res=await axios.get(url,this.state.requestConfig);
     if(res['status']===201||res['status']===200){
       //console.log('res', res.data)
       this.setState({contenuByModule: res.data})
     }
   } catch (error) {
     console.log(error.message);
     console.log(error.response);
   }
  }

  getSessionByModule =async () => {
    const url = `${api}sessionByModule/${this.props.moduleId}`;
   try {
     const res=await axios.get(url,this.state.requestConfig);
     if(res['status']===201||res['status']===200){
       this.setState({sessions: res.data.sort((a,b) => parseInt(a?.id) - parseInt(b?.id))})
     }
   } catch (error) {
     console.log(error.message);
     console.log(error.response);
   }
  };

  render() {
    const { sessions, loading } = this.state;
    if (!loading) {
      return sessions.map((item) => {
          return (
            <div className="item-session-details-page" key={item.id}>
              
                {this.props.isDoing === true ? 
                (
                  <>
                <h6 className="text-up text-dark f-f-work fw-600">
                  <NavLink
                  className="link-details-sessions-on-title"
                  to={`/session-start/${"session"}/${item.id}`}
                  
                >
                  {item.name}
                </NavLink>
              </h6>
              <NavLink
                className="link-show-on-item-session-details-page"
                to={`/session-start/${"session"}/${item.id}`}
              >
                <FeatherIcon
                  className="icon-show-on-item-session-details-page"
                  icon="chevron-right"
                />
              </NavLink>
              </>
                ) : 
                (
                  <>
                  <h6 className="text-up text-dark f-f-work fw-600">
                  <NavLink
                  className="link-details-sessions-on-title"
                  to="#"
                  
                >
                  {item.name}
                </NavLink>
              </h6>
              <NavLink
                className="link-show-on-item-session-details-page"
                to="#"
              >
                <FeatherIcon
                  className="icon-show-on-item-session-details-page"
                  icon="chevron-right"
                />
              </NavLink>
              </>
                )}
            </div>
          );
      });
    }
  }
}
