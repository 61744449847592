/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import MessageAllPage from "../../backend/components/MessageAllPage";
import HeaderAccueilV2 from "./HeaderAccueilV2";
import { Redirect } from "react-router-dom";

function WelcomeV4() {
  let token = window.localStorage.getItem("userToken");
  const userType = window.localStorage.getItem("userType");

  if (token) {
    if (userType === "student") {
      return <Redirect to="/tous-les-modules" />;
    } else if (userType === "admin") {
      return <Redirect to="/tableau-de-bord" />;
    }
  }

  return (
    <div>
      {/* <HeaderAccueil /> */}
      <HeaderAccueilV2 />
      <div className="bg-white  p-t-200 p-b-40">
        <div className="container">
          <div className="m-b-20">
            <div className="learning-theme-col">
              <div className="learning-theme-description">
                <p className="content-text-welcome mb-0">
                  Tu souhaites améliorer ton{" "}
                  <span className="text-color fw-700">employabilité</span> ?{" "}
                  <br />
                </p>
                <p className="content-text-welcome mb-0">
                  Tu souhaites donner un nouveau souffle à{" "}
                  <span className="text-color fw-700">
                    ton projet professionnel
                  </span>{" "}
                  et à <span className="text-color fw-700">ta carrière</span> ?{" "}
                  <br />
                </p>
                <p className="content-text-welcome">
                  Tu souhaites te{" "}
                  <span className="text-color fw-700">former gratuitement</span>{" "}
                  et à{" "}
                  <span className="text-color fw-700">ton propre rythme </span>?
                </p>
              </div>
            </div>
          </div>

          <div className="home-illustration-container row">
            <div className="col-md-12 dis-flex justify-content-center itm-center home-illustration-container-col">
              <img
                className="w-full home-illustration"
                src="img/unnamed.webp"
                alt="Couverture"
                style={{ height: 400, objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="learning-theme-col">
            <div className="learning-theme-description">
              <p className="content-text-welcome">
                La plateforme{" "}
                <a className="h-theme fw-700 text-underline" href="#">
                  www.defaru.sn
                </a>{" "}
                te donne la possibilité de bénéficier de{" "}
                <span className="text-color fw-700">
                  modules de formation gratuits en ligne
                </span>{" "}
                sur des compétences Soft Skills et des compétences
                entrepreneuriales mais aussi des compétences sur le numérique !
              </p>
              <p className="content-text-welcome">
                Les <span className="text-color fw-700">Soft Skills</span> sont
                essentiels pour l’orientation professionnelle, la recherche
                d’emploi et l’intégration durable dans le milieu professionnel.
              </p>
              <p className="content-text-welcome">
                Les{" "}
                <span className="text-color fw-700">
                  compétences entrepreneuriales
                </span>{" "}
                t’aident à penser et à développer un modèle d’affaires et à
                gérer les finances d’une entreprise.
              </p>

              <p className="content-text-welcome">
                La
                <span className="text-color fw-700 pl-2">
                  maitrise d’outils numériques
                </span>{" "}
                te permet d’avoir facilement accès aux informations, de
                communiquer, de travailler et d’interagir de manière efficace.
              </p>
              <p className="content-text-welcome">
                La plateforme de formation te propose{" "}
                <span className="text-color fw-700">
                  cinq (07) modules indépendants
                </span>{" "}
                en auto-apprentissage, avec à la clé de chaque module un
                certificat de participation.
              </p>
            </div>
          </div>
          {/* <div className="row row-img-modules py-3">
            {ImagesModules.map((item) => (
              <div className="col-lg-4 col-md-6 col-images-modules d-flex mb-4">
                <div className="container-images-modules-v4">
                  <img
                    src={item?.img}
                    alt={`img${item?.id}`}
                    className="img-modules-v4"
                  />
                </div>
              </div>
            ))}
          </div> */}

          <div className="learning-theme-col">
            <div className="learning-theme-description">
              <p className="content-text-welcome text-center">
                Saisis l’occasion d’être parmi les bénéficiaires en t’inscrivant
                ici !
              </p>
              <p className="content-text-welcome text-center">
                Pour cela, clique sur l’onglet{" "}
                <NavLink to="/inscription" className="h-theme fw-700">
                  {" "}
                  « Inscription »
                </NavLink>{" "}
                en haut à droite.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <MessageAllPage fullWidth={true} />
      </div>
    </div>
  );
}

export default WelcomeV4;
export const ImagesModules = [
  {
    id: 1,
    img: "img/La communication.png",
  },
  {
    id: 2,
    img: "img/L'ethique de travail.png",
  },
  {
    id: 3,
    img: "img/La gestion de carriere.png",
  },
  {
    id: 4,
    img: "img/Mon modele d'affaires.png",
  },
  {
    id: 5,
    img: "img/Gestion des finances.png",
  },
];
