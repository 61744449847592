import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ImageUploader from "quill-image-uploader";
import { VideoHandler } from "quill-upload";
import Divider from "quill-divider";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);
//Quill.register("modules/videoHandler", VideoHandler);
Quill.register("modules/Divider", Divider);
var Image = Quill.import('formats/image');
//Image.className = 'custom-class-to-image';
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];

class ImageFormat extends Image {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}
Quill.register(ImageFormat, true);


function Editor({
    value = "",
    onChange,
    placeholder = "Content ",
    className = "",
    id = "ql-editor",
}) {
    return (
        <ReactQuill
            theme={"snow"}
            onChange={onChange}
            value={value}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={"#root"}
            placeholder={placeholder}
            className={className}
            id={id}
        />
    );
}

const sizesLimit = 100;
const sizes = [];
for(var i = 1; i <= sizesLimit; i++){
    sizes.push(i + 'px');
}

Editor.modules = {
    toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        [
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            { align: [] },
            { color: [] },
            { background: [] },
        ],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { script: "sub" },
            { script: "super" },
        ],
        ["link", "image", "video"],
        ["divider"],
        ["clean"],
    ],
    divider: {
        cssText: 'border: none;border-bottom: 1px inset;',
        icon: '<svg class="icon" style="vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path class="ql-fill" d="M64 464h896v96H64v-96zM224 96v160h576V96h96v256H128V96h96z m576 832v-160H224v160H128v-256h768v256h-96z"></path></svg>',
    },
    clipboard: {
        matchVisual: false,
    },
    imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
    },
    imageUploader: {
        upload: (file) => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("media", file);
                formData.append("project_name", " cres");
                formData.append("media_type", "image");

                fetch(
                    "https://hub-services.volkeno-engineering.click/api/v1/app/upload-file",
                    {
                        method: "POST",
                        body: formData,
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                        console.log(result);
                        if (result?.success) {
                            resolve(result.file_url);
                        } else {
                            reject("Upload failed");
                        }
                    })
                    .catch((error) => {
                        reject("Upload failed");
                        console.error("Error:", error);
                    });
            });
        },
    },
    // videoHandler: {
    //     upload: (file) => {
    //         console.log("file", file);
    //         return new Promise((resolve, reject) => {
    //             const formData = new FormData();
    //             formData.append("media", file);
    //             formData.append("project_name", "cres");
    //             formData.append("media_type", "video");

    //             fetch(
    //                 "https://hub-services.volkeno-engineering.click/api/v1/app/upload-file",
    //                 {
    //                     method: "POST",
    //                     body: formData,
    //                 }
    //             )
    //                 .then((response) => response.json())
    //                 .then((result) => {
    //                     console.log(result);
    //                     if (result?.success) {
    //                         resolve(result.file_url);
    //                     } else {
    //                         reject("Upload failed");
    //                     }
    //                 })
    //                 .catch((error) => {
    //                     reject("Upload failed");
    //                     console.error("Error:", error);
    //                 });
    //         });
    //     },
    // },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "divider",
    "color",
    "background",

];

export default Editor;