import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import FeatherIcon from "feather-icons-react";
import SessionStartInfos from "../components/SessionStartInfos";
// import LayoutCours from '../components/LayoutCours';
import { NavLink } from "react-router-dom";
import api from "../../http/global-vars";
import MessageAllPage from '../../backend/components/MessageAllPage'

class QuizCours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
    };
  }

  render() {
    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      window.location = "connexion";
    } else {
      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobile />
            </div>
          </div>

          <HeaderDashboard />

          <div className="row">
            {/* Navbar Desktop  */}
            <div className="col-xl-2 col-lg-3 col-md-12">
              <div className="navbar-vertical-customisation no-view-mobile">
                <NavbarVertical />
              </div>
            </div>

            <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 p-t-25 content-position-after-header">
              <div className="container-fluid padding-right-web">
                <div className="row">
                  <div className="col-lg-9 col-md-12">
                    <div className="card-quiz m-b-30">
                      <NavLink
                        className="icon-return-text-header-quiz-card"
                        to="#"
                      >
                        <FeatherIcon icon="arrow-left" />
                      </NavLink>
                      <div className="position-text-header-quiz-card">
                        <p className="text-header-quiz-card">
                          Testez vos connaissances
                        </p>
                        <p className="f-f-work fs-14 text-black m-b-50">
                          Réponds aux huit questions suivantes relatives au
                          professionnalisme, afin de tester tes connaissances
                          préalables.
                        </p>
                      </div>

                      <div className="content-choices-response-quiz">
                        <div className="row m-b-50">
                          <div className="col-xl-8 col-lg-7 col-md-8 col-sm-7 dis-flex justify-content-center itm-center">
                            <p className="fs-14 fw-400 text-black f-f-work m-b-15">
                              Questions 1/8
                            </p>
                          </div>
                          <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
                            <p className="score-content">
                              Score:{" "}
                              <span className="note-score-content">0/20</span>
                            </p>
                          </div>
                        </div>
                        <h5 className="h-theme-light m-b-40">
                          Quelle est la particularité de l'attribut class par
                          rapport à l'attribut id ?
                        </h5>

                        <form action="">
                          <div className="custom-control custom-radio custom-control-inline input-radio-quiz">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="repQuiz1"
                              name="example"
                              value=""
                            />
                            <label className="custom-control-label" htmlFor="repQuiz1">
                              Il ne peut y avoir qu'un seul et même nom d'id par
                              page, et plusieurs fois une même classe
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline input-radio-quiz">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="repQuiz2"
                              name="example"
                              value=""
                            />
                            <label className="custom-control-label" htmlFor="repQuiz2">
                              class est utilisable dans les vieilles versions de
                              HTML mais pas id
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline input-radio-quiz">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="repQuiz3"
                              name="example"
                              value=""
                            />
                            <label className="custom-control-label" htmlFor="repQuiz3">
                              Il n'y a strictement aucune différence entre les
                              deux
                            </label>
                          </div>
                        </form>
                        <div className="dis-flex justify-content-end m-t-20">
                          <NavLink className="btn-theme" to="/quiz-cours">
                            Suivant
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 padding-right-web">
                    <SessionStartInfos />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
              <MessageAllPage />
          </div>
        </div>
      );
    }
  }
}

export default QuizCours;
