export const formatDate = (date) => {
    var a = new Date(date);
    var months = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date_r = a.getDate();
    // var hour = a.getHours();
    // var min = a.getMinutes();
    // var sec = a.getSeconds();
    var format = date_r + ' ' + month + ' ' + year;
    return format;
}

export const getMonth = (date) => {
    var a = new Date(date);
    var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var format = month;
    return format;
}

export const formatDateHistoriq = (date) => {
    var a = new Date(date);
    var months = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date_r = a.getDate();
    var hour = a.getHours();
    var min = (a.getMinutes() < 10 ? '0' : '') + a.getMinutes()
    var format = date_r + '/' + month + '/' + year + ' ' + hour + ':' + min;
    return format;
}

export const calcTime = (time) => {

    if (time >= 0) {
        const hours = Math.floor(time / 60);
        const mins = time % 60;
        return `${hours}h ${mins}m`;
    } else {
        const hours = Math.floor(-time / 60);
        const mins = -time % 60;
        return `${hours}h ${mins}m`;
    }


};