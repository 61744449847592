import React, { Component } from "react";
import baseUrl from '../../http/backend-base-url'
import api from "../../http/global-vars";
import axios from 'axios'
import FeatherIcon from "feather-icons-react";

// const BaseUrl = "https://www.andu-gfa-backend.withvolkeno.com";

export default class TacheClickAndReveal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: baseUrl,
      userID: window.localStorage.getItem("userID"),
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      endSession: false,
      selectedId: null,
      module_id: 0,
      contenuBySession: []
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getSessionByModule();
    }, 3000);
  }

  handleClick(event) {
    // console.log(event.currentTarget.firstChild);
    var element = event.currentTarget.firstChild;
    var element2 = element.nextSibling;
    if (element.style.transform === "rotateY(180deg)") {
      element.style.transform = "rotateY(0deg)";
      element2.style.transform = "rotateY(180deg)";
      if (window.innerWidth < 768) {
        element2.style.display = "none";
        element.style.display = "block";
      }
    } else {
      element.style.transform = "rotateY(180deg)";
      element2.style.transform = "rotateY(0deg)";
      if (window.innerWidth < 768) {
        element2.style.display = "block";
        element.style.display = "none";
      }
    }
  }

  _redirect = () => {
    //console.log("last", this.props.last[0]?.id, this.props.click_reveal[0].id);
    let lastcontenu = []
    // console.log("teste", this.state.contenuBySession, this.state.session);
     let end = false
     if (this.state.contenuBySession !== undefined && this.state.contenuBySession.length) {
       lastcontenu = this.state.contenuBySession.filter((d) => d?.id === this.state.contenuBySession[this.state.contenuBySession.length - 1]?.id)[0]?.list_task
       if (lastcontenu !== undefined) {
         if(lastcontenu[0]?.id === this.props.click_reveal[0]?.id) {
           end = true
         }
       }
     }
    if (this.props.last[0]?.id === this.props.click_reveal[0].id || end === true) {
      console.log("END");
      var elementPos = this.state.session
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.props.sessionId);
      var objectFound = this.state.session[elementPos + 1];
      this.setState({ endSession: true, selectedId: objectFound?.id });

      window.location = `/session-start/session/${objectFound?.id}`;
    }
    //return (window.location = `/session-start/session/${this.state.selectedId}`);
  };

  updateProgres = async () => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter((v) => v?.module?.id === this.state.module_id)
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;

    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };

    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  UserDoContenu = async () => {
    let checker = (arr) =>
      arr.every(
        (v) => parseInt(v?.contenu?.id) !== this.props.click_reveal[0]?.contenu
      );
    console.log("check contenu", checker(this.props.usercontenus));
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.props.click_reveal[0]?.contenu,
    };
    if (checker(this.props.usercontenus) !== false) {
      const url = api + "userdocontenu/";
      await axios
        .post(url, sendData, this.state.requestConfig)
        .then((res) => {
          console.log("data post", res);
          this.updateProgres();
        })
        .catch((e) => {
          console.log("e post", e.response.data);
        });
   }
   this.props.onGetUserDoModules();
    window.scrollTo(0, 0);
  };

  onScroll = () => {
    window.scrollTo(0, 0);
  }

  /* getSessionByModule = () => {
    if (this.props.sessionId) {
      const url = api + "session/" + this.props.sessionId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) => d.module_id === response.data.module_id
          );
          let data = response["data"];
          this.setState({ session: last, module_id: data.module_id });
          //console.log("session", last);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
    this.contenuBySession()
  };
 */
  getSessionByModule = () => {
    if (this.props.sessionId) {
      const url = api + "session/" + this.props.sessionId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) => d.module_id?.id === response.data?.module_id || d?.module_id === response.data?.module_id
          );
          let data = response["data"];
          this.setState({ session: last, module_id: data.module_id });
          //console.log("session", last);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }

    if (this.props.moduleId) {
      // console.log("url module");
       const url = api + "sessionByModule/" + this.props.moduleId;
       axios
       .get(url, this.state.requestConfig)
       .then(async(response) => {
     
         let data = await response["data"];
        
         this.setState({ session: data, module_id: this.props.moduleId });
         // console.log("session by module", this.state.session);
       })
       .catch((e) => {
         console.log("e", e);
       });
       // this.setState({ module_id: this.props.moduleId });
     }
     this.contenuBySession()
  };

  contenuBySession = async () => {
    const url = api + "contenubysession/" + this.props.sessionId + '/';
    //console.log("url session", url);
    if (this.props.sessionId !== undefined && this.props.moduleId === undefined) {
     await axios.get(url, this.state.requestConfig).then((response) => {
        //console.log("contenu By session", response.data);
        if (response) {
          // console.log("contenu By session", response.data);

          this.setState({ contenuBySession: response.data });

        }
        // console.log("contenu By session", this.state.contenuBySession);
      });
    }else if (this.props.moduleId !== undefined) {
     await axios.get(api + "contenubymodule/" + this.props.moduleId + '/', this.state.requestConfig).then((response) => {
        
        if (response) {
          // console.log("contenu By module", response.data);

          this.setState({ contenuBySession: response.data });

        }
        //console.log("contenu By module", this.state.contenuBySession);
      });
    }

  }

  render() {
   // console.log("c&r", this.props.sessions);
    let lastcontenu = []
   // console.log("teste",this.state.session);
    let end = false
    if (this.state.contenuBySession !== undefined && this.state.contenuBySession.length) {
      lastcontenu = this.state.contenuBySession.filter((d) => d?.id === this.state.contenuBySession[this.state.contenuBySession.length - 1]?.id)[0]?.list_task
      if (lastcontenu !== undefined) {
        if(lastcontenu[0]?.id === this.props.click_reveal[0]?.id) {
          end = true
        }
      }
    }
    console.log("click_reveal", this.props.click_reveal)
  //  console.log(lastcontenu, end)
    return (
      <>
        {this.props.click_reveal.map((item, inx) => {
          return (
            <div key={`candrvItem${inx}`}>
              <div className="m-b-30">
                <p className="fs-13 text-dark">
                  - Regarde le terme qui t’est présenté.{" "}
                </p>
                <p className="fs-13 text-dark">
                  - Réfléchis bien à ce qu’il pourrait signifier.{" "}
                </p>
                <p className="fs-13 text-dark">- {item.title}</p>
              </div>
              <div className="row cr-row">
                {item.list_question.map((question, indx) => (
                  <div
                    key={`list-candr${indx}`}
                    className="col-md-12 col-xs-12 col-sm-12 m-b-2 cr-col"
                  >
                    <div
                      className="card card-flip"
                      onClick={(e) => this.handleClick(e)}
                    >
                      <div
                        className={`front`}
                        id={`front${question.id}`}
                        style={{ transform: "rotateY(0deg)" }}
                      >
                        {question.image === null ? (
                          <h1 className="titre-card-front">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: question.content,
                              }}
                            />
                          </h1>
                        ) : (
                          <img
                            src={`${baseUrl}${question.image}`}
                            alt={`ClickAndReveal${question.id}`}
                            width="100%"
                            className="car-img-question"
                          />
                        )}
                        <p className="indication-bottom-card-flip">
                          Clique sur la carte
                        </p>
                      </div>

                      <div
                        className={`back`}
                        id={`back${question.id}`}
                        style={{ transform: "rotateY(180deg)" }}
                      >
                        <div className="candr-list-response">
                          {question.list_responses.map((reponse, idx) =>
                            reponse.image === null ? (
                              <div
                                key={`responseKey${idx}`}
                                className="response-content-item"
                              >
                                {" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: reponse.content,
                                  }}
                                />
                              </div>
                            ) : (
                              <img
                              key={`responseKey${idx}`}
                                src={`${baseUrl}${reponse.image}`}
                                alt={`ClickAndRevealReponse${reponse.id}`}
                                width="100%"
                                className="car-img-response"
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              data-slide="prev"
              onClick={this.onScroll}
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            {!this.props.last && !end ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.UserDoContenu}
              >
                Suivant 
              </button>
            ) : this.props?.last[0]?.id !== this.props.click_reveal[0]?.id && end === false ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.UserDoContenu}
              >
                Suivant 
              </button>
            ) : (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                data-toggle="modal"
                data-target={`#endSessionSucces${this.props.click_reveal[0]?.id}`}
                onClick={this.UserDoContenu}
              >
                Suivant 
              </button>
            )}
          </div>
        </div>
         {/* Session End Modal */}
         <div
            className="modal fade"
            id={`endSessionSucces${this.props.click_reveal[0]?.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-notify modal-success"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                    <FeatherIcon
                      color="green"
                      icon="check-circle"
                      className="mb-3 animat rotateIn"
                    />
                    <h4 className="pop-title fw-600">FIN DE {this.props.moduleId === undefined ?
                      (
                        'LA SESSION'
                      ) : (
                        'L\'INTRODUCTION'
                      )} !!!</h4>
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    data-dismiss="modal"
                    className="btn btn-theme fw-400 fs-15 justify-content-start"
                    onClick={this._redirect}
                  >
                    CONTINUER{" "}
                  </button>
                  {/* <a
                      type="button"
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      role="button"
                      data-slide="next"
                    >
                      CONTINUER
                    </a> */}
                </div>
              </div>
            </div>
          </div>
          {/* END Modal */}
      </>
    );
  }
}
