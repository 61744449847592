// import api from "../../http/global-vars";
import api from "../../http/global-vars";
import { FETCH_LIST_APPRENANTS } from "../Type";

export const fetchListApprenants = (
  page = 1,
  limit = 10,
  q = "",
  pm1_equal = "",
  pm2_equal = "",
  pm3_equal = "",
  pm4_equal = "",
  pm5_equal = ""
) => {
  return async (dispatch) => {
    // dispatch({
    //   type: FETCH_LIST_APPRENANTS,
    //   isLoading: true,
    // });
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url =
      api +
      `paginate/apprenants_info?page=${page}&limit=${limit}&q=${q}&pm1_equal=${pm1_equal}&pm2_equal=${pm2_equal}&pm3_equal=${pm3_equal}&pm4_equal=${pm4_equal}&pm5_equal=${pm5_equal}`;
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        console.log("data ListApprenants", data);

        dispatch({
          type: FETCH_LIST_APPRENANTS,
          payload: data.results,
          isLoading: false,
          count: data.count,
          femme: data.femme,
          homme: data.homme,
          moyenne_age: data?.moyenne_age?.age__avg,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: FETCH_LIST_APPRENANTS,
          payload: [],
          isLoading: false,
        });
      });
  };
};
