import React, { useState, useEffect } from "react";
import NavbarVertical from "../../components/NavbarVertical";
import HeaderDashboard from "../../components/HeaderDashboard";
import NavbarDashboardMobile from "../../components/NavbarDashboardMobile";
import MessageAllPage from "../../../backend/components/MessageAllPage";
import FeatherIcon from "feather-icons-react";
import { NavLink, useParams, useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import api from "../../../http/global-vars";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import AllCourseSkeleton from "../../components/AllCourseSkeleton";
import baseUrl from "../../../http/backend-base-url";
import { EmptyDataAlert } from "../../../backend/Thematiques";

const ModuleItem = ({ item }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const [progression, setProgression] = useState(0);
  const id = window.localStorage.getItem("userID");
  useEffect(() => {
    if (id && item?.id) {
      userGetUserDomodule();
    }
  }, [id, item]);
  const userGetUserDomodule = () => {
    Axios.get(
      `${api}usergetlastuserdomodule?user=${id}&module=${item?.id}`,
      config
    )
      .then((res) => {
        // console.log("userdomodule", res);
        setProgression(res?.data?.progression);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div className="card card-all-modules">
      <img
        className="img-course-on-card-cours"
        src={baseUrl + item?.logo}
        alt={item?.name}
      />
      <div className="content-course-on-card-cours" title={item?.name}>
        <NavLink
          className="titre-course-on-card-cours"
          to={{
            pathname: `/cours-details/${item?.id}`,
            state: item,
          }}
        >
          {item?.name}
        </NavLink>
        <div className="row m-t-15">
          <div className="col-lg-12 col-md-12 col-sm-3 col-xs-6 col-12 flex-row info-content-course-on-card-cours itm-center">
            {progression === 100 && (
              <span className="header-badge-item badge-blue">
                <FeatherIcon className="icon-user-badge-header" icon="award" />
              </span>
            )}
          </div>
          <div className="col">
            <NavLink
              to={{
                pathname: `/cours-details/${item?.id}`,
                state: item,
              }}
            >
              <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModuleByThematique = ({ theme }) => {
  const [modules, setModules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const getModules = async (id) => {
    let url = `${api}modulebythematique/${id}/`;
    await Axios.get(url, config)
      .then((res) => {
        console.log("res", res);
        setModules(res?.data?.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (theme?.id) {
      getModules(theme?.id);
    }
  }, [theme]);
  return (
    <div className="row">
      {!!isLoading && <AllCourseSkeleton />}
      {!isLoading &&
        (modules?.length ? (
          modules?.map((item) => (
            <div className="col-md-4 px-md-2 d-flex" key={item.id}>
              <ModuleItem item={item} />
            </div>
          ))
        ) : (
          <EmptyDataAlert text={"Aucune donnée trouvée"} />
        ))}
    </div>
  );
};
const ListSousModules = () => {
  const { state } = useLocation();
  const [item, setItem] = useState(state);
  const history = useHistory();
  const { id } = useParams();
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const getModule = async (id) => {
    await Axios.get(`${api}module/${id}`, config)
      .then((res) => {
        setItem(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
        history.goBack();
      });
  };

  useEffect(() => {
    if (id) {
      getModule(id);
    } else {
      history.goBack();
    }
    window.scrollTo(0,0)
  }, [id]);

  useEffect(() => {
    if (state) {
      setItem(state);
    }
  }, [state]);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>
        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="row content-routing-url m-b-20">
            <div className="lh-0">
              <NavLink className="routing-view-url" to="/tous-les-modules">
                Tous les modules
              </NavLink>
              <FeatherIcon
                className="icon-routing-view-url"
                icon="chevron-right"
              />
              <NavLink className="routing-view-url" to="#">
                {item?.name}
              </NavLink>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-title-sous-modules m-b-20">
              <div className="container-header-sous-module">
                <h4 className="titre-page-dashboard m-b-35">{item?.name}</h4>
                <div className="content-btn-redirect-to-personnage">
                  <NavLink
                    to={{
                      pathname: `/presention-personnages/${id}`,
                      state: {item : item , path:`/sous-modules/${id}`, parcours: false},
                    }}
                    className="btn btn-theme"
                  >
                    Revoir les personnages
                  </NavLink>
                </div>
              </div>
              <div>
                <span>
                  <FeatherIcon
                    className="icon-content-course-on-card-cours"
                    icon="menu"
                  />
                </span>
                <span className="sub-title-sous-modules">
                  Contenu du module
                </span>
              </div>
            </div>
            <ModuleByThematique theme={item} />
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};

export default ListSousModules;

const Datas = [
  {
    id: 1,
    logo: "/img/logo1.png",
    name: "Ce qu'il faut savoir avant d'acheter un ordinateur",
    number_session: 16,
  },
  {
    id: 2,
    logo: "/img/logo2.png",
    name: "Pourquoi faut-il organiser son ordinateur ?",
    number_session: 16,
  },
  {
    id: 3,
    logo: "/img/logo3.png",
    name: "Des textes bien présentés, tout un art !",
    number_session: 16,
  },
  {
    id: 4,
    logo: "/img/logo4.png",
    name: "Utiliser Excel pour être efficace",
    number_session: 16,
  },
  {
    id: 5,
    logo: "/img/logo5.png",
    name: "Une présentation convaincante, ça se prépare !",
    number_session: 16,
  },
  {
    id: 6,
    logo: "/img/logo6.png",
    name: "L'Internet, comment ça fonctionne ?",
    number_session: 16,
  },
  {
    id: 7,
    logo: "/img/logo7.png",
    name: "Allons faire une recherche en ligne !",
    number_session: 16,
  },
  {
    id: 8,
    logo: "/img/logo8.png",
    name: "Peux-tu utiliser l'intelligence artificielle ?",
    number_session: 16,
  },
];
