    import React, { Component } from 'react';
    import FeatherIcon from 'feather-icons-react';
    import { NavLink } from 'react-router-dom';
    import api from './../../http/global-vars';
    import axios from 'axios';

    export default class NavbarDashboardMobileAdmin extends Component {
        constructor(props){
            super(props)
            this.state = {
                token: window.localStorage.getItem('userToken'),
                requestConfig: {
                    headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
                },
                api: api,
                userID: window.localStorage.getItem('userID'),
                userInfos: [],
            }
        }

        componentDidMount(){
            var config = this.state.requestConfig
            this.onGetConnectedUserInfos(config)
        }
    
    
    
        onGetConnectedUserInfos = (config) => {
            var api = this.state.api
            axios.get(api + 'me/', config)
                .then(response => {
                    //console.log(response)
                    this.setState({
                        isLoginInProgress: false
                    })
                    if(response.status === 200){
                        this.setState({
                            userInfos: response.data.data
                        })
                        //console.log(response.data.data)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    
        onLogoutUser = () => {
            window.localStorage.removeItem('userToken');
            localStorage.removeItem('resp_gfa')
            setTimeout(() => {
                window.location = '/connexion'
            }, 1000);
        }
  
    render(){
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-white p-t-20">
                <button className="navbar-toggler ml-sm-3" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <NavLink className="dis-flex" to="/accueil">
                    <img className="logo-dashboard-mobile" src={`/img/logo.png`} alt="Logo GFA" />
                </NavLink>
                
                <div className="dropdown dis-flex mr-3">
                    <button className="user-acount-dashboard-header" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.state.userInfos.avatar === null ?
                            <img className="image-user-acount-dashboard-header" src="#" alt="Avatar" /> :
                            <img className="image-user-acount-dashboard-header" src={ 'https://www.andu-gfa-backend.withvolkeno.com' + this.state.userInfos.avatar} alt="Avatar" />
                        }
                        
                    <div className="user-acount-dashboard-header-display">
                        {this.state.userInfos.first_name + ' ' + this.state.userInfos.last_name}
                        <FeatherIcon className="icon-user-acount-dashboard" icon="chevron-down" />
                    </div>
                    </button>
                    <div className="dropdown-menu dropdown-menu-custom" aria-labelledby="dropdownMenuButton">
                        <span className="dropdown-item item-dropdown-custom" onClick={ (e) => this.onLogoutUser(e) }> Deconnexion</span>
                    </div>
                </div>
        
                <div className="collapse navbar-collapse bg-theme" id="navbarNavDropdown">
                    <ul className="navbar-nav m-t-30">
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom" to="/tableau-de-bord" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="grid" />
                                Tableau de bord
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom" to="/module" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="book" />
                                Module
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom" to="/apprenants" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="user" />
                                Apprenant
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom" to="/certificats" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="award" />
                                Certificats
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom" to="/performances" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="activity" />
                                Performances
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom " to="/forum" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical" icon="users" />
                                Forum
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom" to="/extraction" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="download" />
                                Extraction
                            </NavLink>
                        </li>
                        <li className="nav-item nav-item-vertical-custom">
                            <NavLink className="nav-link nav-link-vertival-custom" to="/gestion-messages-contact" activeClassName="actived">
                                <FeatherIcon className="icon-navbar-vertical m-l-15" icon="message-square" />
                                Messages
                            </NavLink>
                        </li>
                    </ul>
            
                    <div className="container flex-col m-b-30 m-t-50">
                        <p className="text-white f-f-inter fw-600 fs-14 m-b-20">Besoin d’aide ?</p>
                        <NavLink className="btn-icon-transparent" to="#"><FeatherIcon className="icon-contact-support" icon="mail" />Contacter le support</NavLink>
                    </div>
                </div>
            </nav>
        );
    }
}
