/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import Axios from "axios";
import $ from "jquery";
import api from "../../http/global-vars";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

function ScormContentAdmin(props) {
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const history = useHistory();
  const { module } = props;
  const iframeRef = useRef(null);
  const [userDoModule, setUserDoModule] = useState(null);
  // const [title, setTitle] = useState("Copier");
  let id = window.localStorage.getItem("userID");
  let userType = window.localStorage.getItem("userType");

  useEffect(() => {
    const handleMessage = (event) => {
      if (!module?.scorm_link?.includes(event.origin)) {
        return;
      }

      // Vérifiez le type de message reçu

      const { type, data } =
        typeof event?.data === "string" ? JSON.parse(event.data) : event?.data;
      if (type === "scormData") {
        // Traiter les données ici : userName, userId, userProgress
        if (
          data?.progress &&
          userDoModule &&
          data?.progress > userDoModule?.progression
        ) {
          getProgress(data?.progress);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [iframeRef, userDoModule]);

  useEffect(() => {
    // window.API = new ScormAPI();

    const iframe = document.getElementById("targetIframe");

    iframe.onload = () => {
      if (userType !== "admin") {
        Swal.showLoading();
        // console.log("To SCORM:", iframe.contentWindow);
        if (iframe && iframe.contentWindow) {
          const message = {
            type: "userProgress",
            data: { progression: userDoModule?.progression || 0 }, // Contient userName, userId, etc.
          };
          setTimeout(() => {
            iframe.contentWindow.postMessage(JSON.stringify(message), "*");
            Swal.close();
          }, 3000);
        }
      }
    };
  }, [userDoModule]);

  useEffect(() => {
    if (id && module?.id && userType !== "admin") {
      userGetUserDomodule();
    }
  }, [id, module]);

  const sendProgressBackToStoryline = (progress) => {
    const iframe = iframeRef.current;
    const message = {
      type: "userProgress",
      data: { progression: progress || 0 },
    };
    console.log("userProgress react",message)
    // Envoi du message à l'iframe (Storyline)
    iframe.contentWindow.postMessage(JSON.stringify(message), "*");
  };

  const userGetUserDomodule = () => {
    Axios.get(
      `${api}usergetlastuserdomodule?user=${id}&module=${module?.id}`,
      config
    )
      .then((res) => {
        // console.log("userdomodule", res);
        sendProgressBackToStoryline(res?.data?.progression);
        setUserDoModule(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getProgress = async (progression) => {
    if (progression === 100) {
      Swal.showLoading();
    }
    await Axios.put(
      `${api}userdomodule/${userDoModule?.id}/`,
      { progression },
      config
    )
      .then(async (res) => {
        const resp = res?.data
        console.log("res edit progress", resp);
        await Swal.close();
        if (resp?.data?.progression === 100) {
          await Swal.fire({
            icon: "success",
            title: resp?.message, // "Félicitations! Tu viens de terminer ce module et de gagner un badge.", 
            showConfirmButton: false,
            timer: 5000,
            iconColor: "#1f497d",
          }).then(() => {
            history.push(`/sous-modules/${module?.thematique}`);
          });
        }
        await userGetUserDomodule();
      })
      .catch((e) => {
        Swal.close();
        console.log("err", e);
      });
  };

  // const copyLink = (value) => {
  //   const linkInput = document.createElement("textarea");
  //   linkInput.value = value;
  //   document.body.appendChild(linkInput);
  //   linkInput.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(linkInput);
  //   setTitle("Copié!");
  //   setTimeout(() => {
  //     setTitle("Copier");
  //   }, 2000);
  // };

  // document.getElementById('targetIframe').requestFullscreen().then(()=>{
  //   console.log("fullscreen")
  // },()=>{console.warn("no fullscreen")});

  return (
    <div className="card-item-sessions-module contenu-shadow">
      <div className="d-flex" style={{ gap: "10px" }}>
        <FeatherIcon
          icon="arrow-left"
          className="linkable"
          onClick={() => history.goBack()}
        />
        <span className="link-collapse-session-item ">{module?.name}</span>
      </div>

      <div className="card card-body p-t-50 m-b-0">
        <div className="row dis-flex justify-content-center">
          {/* <div className="col-md-12 m-b-20 scorm-btn-copy">
            <div className="d-flex justify-content-start scorm">
              <input type="text" value={id} readOnly className="d-none" />
              <button
                title={title}
                onClick={() => copyLink(id)}
                className="social_link__item d-flex align-items-center gap-1"
                style={{
                  width: "auto",
                  minWidth: 90,
                }}
              >
                <FeatherIcon icon="copy" />
                <span>ID utilisateur</span>
              </button>
              <button
                title={title}
                onClick={() => copyLink(module?.id)}
                className="social_link__item d-flex align-items-center gap-1"
                style={{
                  width: "auto",
                  minWidth: 90,
                }}
              >
                <FeatherIcon icon="copy" />
                <span>ID module</span>
              </button>
            </div>
          </div> */}
          <div className="col-md-12 offset-md-0">
            <div class="scorm-container">
              <iframe
                src={module?.scorm_link}
                frameBorder="0"
                ref={iframeRef}
                title={module?.name}
                className="scorm-content"
                loading="lazy"
                id="targetIframe"
                // allow="fullscreen"
                allow="fullscreen"
                // onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default withScorm()(ScormContentAdmin);
export default ScormContentAdmin;
