import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import NavbarVerticalAdmin from "./components/NavbarVertical";
import ModalDetailsApprenant from "./components/ModalDetailsApprenant";
// import { NavLink } from 'react-router-dom';
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import $ from "jquery";
import api from "./../http/global-vars";
import axios from "axios";
import MessageAllPage from "./components/MessageAllPage";
import ImportApprenant from "./components/ImportApprenant";
import { toast } from "react-toastify";
import ApprenantSkeleton from "./AdminSkeletons/ApprenantSkeleton";
import Skeleton from "react-loading-skeleton";
import SendPasswordModal from "./components/SendPasswordModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchListApprenants } from "../redux/apprenantList/apprenantListAction";
import { fetchListModules } from "../redux/modules/moduleAction";
import { CustomPagination } from "../modules/Pagination/CustomPagination";

const CardSkeleton = () => {
  const renderModules = Array(3)
    .fill()
    .map((item, index) => (
      <div className="col-md-4" key={index}>
        <div className="card">
          <Skeleton variant="rect" width={332} height={160} />
        </div>
      </div>
    ));
  return (
    <React.Fragment>
      {/* <Skeleton className="row mb-3 px-2" height={255} width={590} /> */}
      {renderModules}
    </React.Fragment>
  );
};

function Apprenants() {
  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const dispatch = useDispatch();
  const modules = useSelector((s) => s?.list_modules);
  const { count, femme, homme, data, isLoading, moyenne_age } = useSelector(
    (s) => s?.list_apprenants
  );
  const [itemToActive, setItemToActive] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [progress1, setProgress1] = useState("");
  const [progress2, setProgress2] = useState("");
  const [progress3, setProgress3] = useState("");
  const [progress4, setProgress4] = useState("");
  const [progress5, setProgress5] = useState("");

  useEffect(() => {
    dispatch(fetchListModules());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      fetchListApprenants(
        page,
        limit,
        query,
        progress1,
        progress2,
        progress3,
        progress4,
        progress5
      )
    );
  }, [
    page,
    limit,
    dispatch,
    query,
    progress1,
    progress2,
    progress3,
    progress4,
    progress5,
  ]);

  const handleChangeQuery = (e) => {
    setQuery(e?.target?.value);
  };

  const renderPaginationPanel = (props) => {
    return (
      <div class="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={count}
          onChange={(page, perPage) => {
            window.scrollTo(100, 100);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  const createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const fullNameFilter = (cell, row) => {
    return row.first_name + " " + row.last_name;
  };
  const fullNameFormatter = (cell, row) => {
    //console.table(row)
    return (
      <>
        <span
          data-toggle="modal"
          data-target={".modalDetailsApprenant" + row.id}
          className="apprenant_fullname linkable"
        >
          {" "}
          {row.first_name + " " + row.last_name}{" "}
        </span>
        <div
          className={"modal fade modalDetailsApprenant" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className={"modal-dialog modal-xl"}>
            <div className={"modal-content card-dashboard p-b-100"}>
              <ModalDetailsApprenant id={row?.id} apprenantInfos={row} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const onActionFormatter = (cell, row) => {
    return (
      <div className="actions-buttons-container d-flex justify-content-between">
        <button
          className={
            "btn btn-primary mr-1 gfa-btn-action-primary spinnable " + row.id
          }
          data-toggle="modal"
          title="Génèrer un mot de passe"
          onClick={() => onRegenerateApprenantPassword(row)}
        >
          <FeatherIcon
            icon="hash"
            className="regenerate-apprenant-password-icon"
          />
        </button>
        <button
          className={
            "btn btn-primary mr-1 gfa-btn-action-primary spinnable " + row.id
          }
          data-toggle="modal"
          title="Créer un mot de passe"
          data-target={`#SendPasswordModal${row?.id}`}
          // onClick={() => this.onRegenerateApprenantPassword(row)}
        >
          <FeatherIcon
            icon="lock"
            className="regenerate-apprenant-password-icon"
          />
        </button>
        {!row?.active && !itemToActive?.includes(row?.id) && (
          <button
            className={
              "btn btn-primary gfa-btn-action-primary spinnable" + row.id
            }
            title="Activer ce compte"
            onClick={() => onActiveApprenant(row)}
          >
            <FeatherIcon
              icon="check-circle"
              className="regenerate-apprenant-password-icon"
            />
          </button>
        )}

        <div
          className="modal fade sendPasswordModal"
          id={`SendPasswordModal${row?.id}`}
          tabindex="-1"
          role="dialog"
          data-backdrop="static"
          aria-labelledby="SendPasswordModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content card-dashboard">
              <SendPasswordModal apprenant={row} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onActiveApprenant = (apprenant) => {
    if (window.confirm("Confirmez l'action")) {
      // console.log(apprenant);
      let array = [];
      array.push(apprenant?.id);
      setItemToActive((prevState) => [...itemToActive, ...array]);
      // this.setState({ itemToActive: [...this.state.itemToActive, ...array] });
      // console.log("array", [...this.state.itemToActive, ...array]);

      var apprenantID = apprenant.id;
      $(".spinnable" + apprenant?.id).html(
        '<span className="fas fa-spin fa-spinner"></span>'
      );

      axios
        .get(`${api}active_user/${apprenantID}/`, config)
        .then((response) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          if (response.status === 200 || response.status === 201) {
            toast.success("Le compte de l'apprenant a été bien activé.");
            console.log("response", response);
            dispatch(
              fetchListApprenants(
                page,
                limit,
                progress1,
                progress2,
                progress3,
                progress4,
                progress5
              )
            );
          }
        })
        .catch((error) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          console.log(error);
          toast.error("Une erreur est survnue. Veuillez réessayer plus tard.");
        });
    }
  };

  const onRegenerateApprenantPassword = (apprenant) => {
    if (window.confirm("Confirmez l'action")) {
      console.log(apprenant);
      var apprenantID = apprenant?.id;
      $(".spinnable" + apprenant.id).html(
        '<span className="fas fa-spin fa-spinner"></span>'
      );

      axios
        .get(`${api}regeneratepassword/${apprenantID}/`, config)
        .then((response) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          if (response.status === 200 || response.status === 201) {
            toast.success(
              "Le mot de passe de l'apprenant a été bien regénéré."
            );
          }
        })
        .catch((error) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          console.log(error);
          toast.error("Une erreur est survnue. Veuillez réessayer plus tard.");
        });
    }
  };

  const onProgressionModuleFormatter1 = (cell, row) => {
    var progression = 0;
    let module1 = row.modules?.filter(
      (item) => item.module.name === "Module 1 : La communication"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module1?.length && module1?.length === 1) {
      return (progression = module1[0]?.progression + "%");
    }
    if (module1?.length && module1?.length > 1) {
      return (progression =
        Math.max.apply(
          Math,
          module1.map(function (o) {
            return o.progression;
          })
        ) + "%");
    }
    // row.modules.forEach((item, index) => {

    //   if (item.module.name === "Module 1 : La communication") {
    //     progression = item.progression
    //   }
    // })
    return progression + "%";
  };
  const onProgressionModuleFilter1 = (cell, row) => {
    var progression = 0;
    let module1 = row.modules?.filter(
      (item) => item.module.name === "Module 1 : La communication"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module1?.length && module1?.length === 1) {
      return (progression = module1[0]?.progression);
    }
    if (module1?.length && module1?.length > 1) {
      return (progression = Math.max.apply(
        Math,
        module1.map(function (o) {
          return o.progression;
        })
      ));
    }
    // row.modules.forEach((item, index) => {

    //   if (item.module.name === "Module 1 : La communication") {
    //     progression = item.progression
    //   }
    // })
    return progression;
  };
  const onProgressionModuleFormatter2 = (cell, row) => {
    var progression = 0;
    let module2 = row.modules?.filter(
      (item) => item.module.name === "Module 2  : L'éthique de travail"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module2?.length && module2?.length === 1) {
      return (progression = module2[0]?.progression + "%");
    }
    if (module2?.length && module2?.length > 1) {
      return (progression =
        Math.max.apply(
          Math,
          module2.map(function (o) {
            return o.progression;
          })
        ) + "%");
    }
    // row.modules.forEach((item, index) => {
    //   if (index === row.modules?.length - 2) {
    //     progression = item.progression
    //   }
    // })
    return progression + "%";
  };
  const onProgressionModuleFormatter3 = (cell, row) => {
    var progression = 0;
    let module3 = row.modules?.filter(
      (item) => item.module.name === "Module 3 : La gestion de carrière"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module3?.length && module3?.length === 1) {
      return (progression = module3[0]?.progression + "%");
    }
    if (module3?.length && module3?.length > 1) {
      return (progression =
        Math.max.apply(
          Math,
          module3.map(function (o) {
            return o.progression;
          })
        ) + "%");
    }
    return progression + "%";
  };

  const onProgressionModuleFormatter4 = (cell, row) => {
    var progression = 0;
    let id = modules?.data?.find((el, i) => i === 3)?.id;
    let module4 = row.modules?.find(
      (item) => parseInt(item.module.id) === parseInt(id)
    );

    // console.log("module4", module4, id);
    if (module4) {
      return (progression = module4?.progression + "%");
    }
    // if (module4) {
    //   return (progression + "%");
    // }
    return progression + "%";
  };

  const onProgressionModuleFormatter5 = (cell, row) => {
    var progression = 0;
    let id = modules?.data?.find((el, i) => i === 4)?.id;
    let module5 = row.modules?.find(
      (item) => parseInt(item.module.id) === parseInt(id)
    );

    // console.log("module5", module5, id);
    if (module5) {
      return (progression = module5?.progression + "%");
    }
    // if (module5) {
    //   return (progression + "%");
    // }
    return progression + "%";
  };

  const onProgressionModuleFilter2 = (cell, row) => {
    var progression = 0;
    let module2 = row.modules?.filter(
      (item) => item.module.name === "Module 2  : L'éthique de travail"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module2?.length && module2?.length === 1) {
      return (progression = module2[0]?.progression);
    }
    if (module2?.length && module2?.length > 1) {
      return (progression = Math.max.apply(
        Math,
        module2.map(function (o) {
          return o.progression;
        })
      ));
    }
    // row.modules.forEach((item, index) => {
    //   if (index === row.modules?.length - 2) {
    //     progression = item.progression
    //   }
    // })
    return progression;
  };
  const onProgressionModuleFilter3 = (cell, row) => {
    var progression = 0;
    let module3 = row.modules?.filter(
      (item) => item.module.name === "Module 3 : La gestion de carrière"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module3?.length && module3?.length === 1) {
      return (progression = module3[0]?.progression);
    }
    if (module3?.length && module3?.length > 1) {
      return (progression = Math.max.apply(
        Math,
        module3.map(function (o) {
          return o.progression;
        })
      ));
    }
    return progression;
  };

  const onExportApprenants = async () => {
    await window.open(`${api}export_apprenants_info`, "_blank");
  };

  const createCustomButtonGroup = (props) => {
    return (
      <div className="btn-group btn-group-md export-btn ">
        <button
          className="btn btn-success react-bs-table-csv-btn  hidden-print px-1"
          onClick={() => onExportApprenants()}
        >
          <FeatherIcon
            className="icon-btn-add-dashboard-orange fs-15 fw-400 "
            icon="download"
          />
          Extraction
        </button>
      </div>
    );
  };

  const renderCustomSearchPanel = (props) => {
    return (
      <div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
        <input
          type="text"
          className="form-control"
          placeholder="Recherche"
          onChange={(e) => handleChangeQuery(e)}
        />
        <span className="input-group-btn">
          <button
            className="btn btn-warning my-custom-class react-bs-table-search-clear-btn"
            type="button"
            onClick={(e) => setQuery("")}
          >
            <span>Effacer</span>
          </button>
        </span>
      </div>
    );
  };

  const onChangeData = (key, value) => {
    if (key === "p1") {
      setProgress1(value);
    } else if (key === "p2") {
      setProgress2(value);
    } else if (key === "p3") {
      setProgress3(value);
    } else if (key === "p4") {
      setProgress4(value);
    } else if (key === "p5") {
      setProgress5(value);
    }
    setPage(1);
  };

  const getCustomTextFilter = (filterHandler, nom) => {
    console.log("nom", nom);
    return (
      <CustomFilterText
        onChange={filterHandler}
        name={nom}
        setValue={(value) =>
          nom === "p1"
            ? onChangeData("p1", value)
            : nom === "p2"
            ? onChangeData("p2", value)
            : nom === "p3"
            ? onChangeData("p3", value)
            : nom === "p4"
            ? onChangeData("p4", value)
            : onChangeData("p5", value)
        }
        value={
          nom === "p1"
            ? progress1
            : nom === "p2"
            ? progress2
            : nom === "p3"
            ? progress3
            : nom === "p4"
            ? progress4
            : nom === "p5"
            ? progress5
            : ""
        }
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
    btnGroup: createCustomButtonGroup,
    exportCSVText: "Extraction",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
    searchPanel: renderCustomSearchPanel,
  };
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobileAdmin />
        </div>
      </div>

      <HeaderDashboardAdmin />

      <div className="row">
        {/* Navbar Desktop  */}
        {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
        <div className="nav-container">
          <div
            className="navbar-vertical-customisation no-view-mobile"
            style={{ top: "40px" }}
          >
            <NavbarVerticalAdmin />
          </div>
        </div>

        <div className="col-xl-2 col-lg-3 col-md-12"></div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="row m-b-10">
              <div className="col-xl-6">
                <h5 className="text-black f-f-work fw-500 m-b-15">Apprenant</h5>
              </div>
              <div className="col-xl-6 dis-flex justify-content-end justify-left-tablette">
                <button
                  className="btn-add-dashboard"
                  data-toggle="modal"
                  data-target=".import-apprenant-cv"
                >
                  <FeatherIcon
                    className="icon-btn-add-dashboard"
                    icon="plus-circle"
                  />
                  Ajouter des apprenants
                </button>
              </div>
            </div>
            {isLoading === true ? (
              <div className="row m-b-10">
                <CardSkeleton />
              </div>
            ) : (
              <div className="row m-b-10">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                  <div className="card-dashboard w-full m-b-15">
                    <p className="chiffre-statistics-apprenant-page">
                      {homme || 0}
                    </p>
                    <p className="legend-statistics-apprenant-page">
                      Total des apprenants homme{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                  <div className="card-dashboard w-full m-b-15">
                    <p className="chiffre-statistics-apprenant-page">
                      {femme || 0}
                    </p>
                    <p className="legend-statistics-apprenant-page">
                      Total des apprenants femme{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                  <div className="card-dashboard w-full m-b-15">
                    <p className="chiffre-statistics-apprenant-page">
                      {moyenne_age ? parseInt(moyenne_age) : 0}
                    </p>
                    <p className="legend-statistics-apprenant-page">
                      Âge moyen{" "}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="card-dashboard p-l-0 p-r-0">
              <div className="col">
                <div className="row m-b-50">
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                    <p className="titre-card-dashboard-sans-padding m-b-15">
                      Liste des apprenants{" "}
                      <span className="certified-count">
                        {" "}
                        {`( ${count || 0} )`}{" "}
                      </span>{" "}
                    </p>
                  </div>
                </div>

                {/* <form>
                                            <button className="btn-theme py-2 px-4">
                                                <FeatherIcon className="icon-btn-add-dashboard-orange fs-15 fw-400" icon="download" />
                                                Extraction
                                            </button>
                                        </form> */}
              </div>
              <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                {isLoading && <ApprenantSkeleton />}
                {!isLoading && (
                  <div className="table-container">
                    <BootstrapTable
                      data={data}
                      key={"id"}
                      striped={true}
                      hover={true}
                      condensed={true}
                      multiColumnSort={2}
                      exportCSV={true}
                      options={options}
                      pagination={true}
                      search={true}
                      version="4"
                      bordered={false}
                    >
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        dataField="id"
                        isKey={true}
                        hidden={true}
                      >
                        Numero
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                          padding: 15,
                        }}
                        width="100"
                        dataField="id"
                        dataFormat={onActionFormatter}
                      >
                        Action
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="300"
                        dataField="first_name"
                        filterValue={fullNameFilter}
                        dataFormat={fullNameFormatter}
                      >
                        Prénom & Nom
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        // filter={{
                        //   type: "NumberFilter",
                        //   defaultValue: { comparator: "=" },
                        // }}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "p1"),
                        }}
                        filterValue={onProgressionModuleFilter1}
                        width="350"
                        dataField="Module 1"
                        csvFormat={onProgressionModuleFormatter1}
                        dataFormat={onProgressionModuleFormatter1}
                      >
                        Module 1 : La Communication
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="350"
                        dataField="Module 2"
                        csvFormat={onProgressionModuleFormatter2}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "p2"),
                        }}
                        filterValue={onProgressionModuleFilter2}
                        dataFormat={onProgressionModuleFormatter2}
                      >
                        Module 2 : L'hétique au travail
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="350"
                        dataField="Module 3"
                        csvFormat={onProgressionModuleFormatter3}
                        dataFormat={onProgressionModuleFormatter3}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "p3"),
                        }}
                        filterValue={onProgressionModuleFilter3}
                      >
                        Module 3 : La gestion de carriére
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="350"
                        dataField="Module 4"
                        csvFormat={onProgressionModuleFormatter4}
                        dataFormat={onProgressionModuleFormatter4}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "p4"),
                        }}
                        filterValue={onProgressionModuleFormatter4}
                        hidden={modules?.data?.length < 4}
                      >
                        {modules?.data?.find((el, i) => i === 3)?.name}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="350"
                        dataField="Module 5"
                        csvFormat={onProgressionModuleFormatter5}
                        dataFormat={onProgressionModuleFormatter5}
                        filter={{
                          type: "CustomFilter",
                          getElement: (filterHandler) =>
                            getCustomTextFilter(filterHandler, "p5"),
                        }}
                        filterValue={onProgressionModuleFormatter5}
                        hidden={modules?.data?.length < 5}
                      >
                        {modules?.data?.find((el, i) => i === 4)?.name}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="300"
                        dataField="civilite"
                      >
                        Civilité
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="300"
                        dataField="phone"
                      >
                        Téléphone
                      </TableHeaderColumn>
                      {/* <TableHeaderColumn 
                                                        thStyle={{fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none'}}
                                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }} 
                                                        width="300" dataField='certification'>Certification</TableHeaderColumn> */}
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#1F497D",
                          border: "none",
                        }}
                        tdStyle={{
                          fontWeight: 500,
                          fontSize: 14,
                          color: "rgba(0, 0, 0, 0.7)",
                          border: "none",
                        }}
                        width="300"
                        dataField="email"
                      >
                        Email
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
      <ImportApprenant
        onGetApprenantsInfos={() =>
          dispatch(
            fetchListApprenants(
              page,
              limit,
              progress1,
              progress2,
              progress3,
              progress4,
              progress5
            )
          )
        }
      />

      {/* {this.state.isSuccessRegenerate || this.state.isErrorRegenerate ? (
            <ToastContainer />
          ) : (
            ""
          )} */}
    </div>
  );
}

export default Apprenants;
const CustomFilterText = React.forwardRef((props, ref) => {
  const { onChange, name, setValue, value } = props;
  const dataSelected = (filterHandler, e) => {
    var value = e.target.value;
    console.log(value);
    setValue(value);
    // filterHandler(value)
  };
  return (
    <input
      ref={ref}
      type={"number"}
      name={name}
      min={0}
      onChange={dataSelected.bind(this, onChange)}
      // value={value}
      placeholder={"Progression"}
      className="filter text-filter form-control"
    />
  );
});
