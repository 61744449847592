import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import CardSession from "../components/CardSession";
import MessageAllPage from "../../backend/components/MessageAllPage";
import { Message } from "rsuite";
// import 'rsuite/dist/styles/rsuite-default.css'
import { Link } from "react-router-dom";
import api from "../../http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";

export default class AllCoursesV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      userModules: [],
    };
  }

  componentDidMount() {
    window.scroll(0,0)
    this.getUser();
    this.getUserModules();
  }

  getUser = async () => {
    let config = this.state.requestConfig;
    let url = this.state.api + "me/";

    await axios
      .get(url, config)
      .then((user) => {
        console.log(user.data.data);
        this.setState({ user: user.data.data });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  getUserModules = async () => {
    let config = this.state.requestConfig;
    let url = this.state.api + "usermodules/" + this.state.userID + "/";
    let data = [];
    await axios
      .get(url, config)
      .then((response) => {
        //console.log('user', this.state.user)
        if (response.data.data.length) {
          response.data.data.forEach((module) => {
            if (module.progression >= 50) {
              data.push(module);
            }
          });
        }
        console.log("response", data);
        this.setState({ userModules: data });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  handleSubmit = async () => {
    console.log("teste");
    const url = this.state.api + "prolongement/" + this.state.userID + "/";
    const config = this.state.requestConfig;

    await axios
      .get(url, config)
      .then((response) => {
        //console.log('response', response)
        if (response.data) {
          //Alert.success(response.data.message)
          toast.success(response.data.message);
        }
        this.getUser();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  render() {
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobile />
          </div>
        </div>

        <HeaderDashboard />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="col-xl-2 col-lg-3 col-md-12">
            <div className="navbar-vertical-customisation no-view-mobile">
              <NavbarVertical />
            </div>
          </div>

          <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
            <div className="container-fluid">
              <h4 className="titre-page-dashboard m-b-35">Tous les modules</h4>
              <div className="row">
                <CardSession />
                {/* <div className="col-md-4 px-md-2">
                  <div className="card">
                    <img
                      className="img-course-on-card-cours"
                      src="/img/communication.png"
                      alt=""
                    />
                    <div className="content-course-on-card-cours">
                      <NavLink
                        className="titre-course-on-card-cours"
                        to={`/presentation-module`}
                      >
                        La communication numérique
                      </NavLink>
                      <div className="row m-t-15">
                        <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                          <FeatherIcon
                            className="icon-content-course-on-card-cours"
                            icon="youtube"
                          />
                          3 sessions
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                          <FeatherIcon
                            className="icon-content-course-on-card-cours"
                            icon="clock"
                          />
                          1h 30min
                        </div>
                        <div className="col">
                          <NavLink to={`/presentation-module`}>
                            <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 px-md-2">
                  <div className="card">
                    <img
                      className="img-course-on-card-cours"
                      src="/img/numerique"
                      alt=""
                    />
                    <div className="content-course-on-card-cours">
                      <NavLink
                        className="titre-course-on-card-cours"
                        to={`/presentation-module`}
                      >
                        Les bases du numérique
                      </NavLink>
                      <div className="row m-t-15">
                        <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                          <FeatherIcon
                            className="icon-content-course-on-card-cours"
                            icon="youtube"
                          />
                          3 sessions
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-3 col-xs-6 col-5 flex-row info-content-course-on-card-cours itm-center">
                          <FeatherIcon
                            className="icon-content-course-on-card-cours"
                            icon="clock"
                          />
                          1h 30min
                        </div>
                        <div className="col">
                          <NavLink to={`/presentation-module`}>
                            <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {(this.state.userModules.length >= 2 ||
                  this.state.user.badge >= 1) &&
                  this.state.user.certificate === false &&
                  this.state.user.eligible === true &&
                  this.state.user.prolonge === false && (
                    <Message
                      closable
                      type="warning"
                      title="Alerte!"
                      description={
                        <span className="fs-15">
                          Attention, tu arrives à la fin du délais de formation.
                          Tu as besoin de plus de temps pour terminer la
                          formation ?
                          <Link to="#" onClick={this.handleSubmit}>
                            {" "}
                            Clique ici pour demander une prolongation de 3
                            semaines afin de pouvoir terminer les trois modules
                            et tenter d’obtenir ton certificat
                          </Link>
                          .
                        </span>
                      }
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>
      </div>
    );
  }
}
