import React, { Component } from "react";
import VraiFauxCorrection from "./VraiFauxCorrection";
import api from "../../http/global-vars";
import axios from "axios";
import FeatherIcon from "feather-icons-react";

export default class TacheVraiFaux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: this.props.yes_no,
      selectedQstIndex: 0,
      selectedIndex: 0,
      checked: null,
      responses: [],
      arrIndex: {},
      module_id: "",
      isEnded: false,
      isEndedQuiz: false,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      score: 0,
      scoreTotal: 0,
      endSession: false,
      selectedId: null,
      isDoing: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this._getModuleBySession();

      this.checkContenu();
    }, 3000);

    //console.log(this.props)
  }

  getModuleItem = async (id) => {
    // console.log('module id', id)
    const url = api + "module/" + id;
    await axios
      .get(url, this.state.requestConfig)
      .then(async (response) => {
        // console.log('module item', response)
        this.setState({ module: response?.data });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  checkContenu = () => {
    const contenususer = this.props?.usercontenus.filter(
      (v) => v.contenu.id === this.state.fields[0]?.contenu
    );
    // console.log('isDoing', this.state.fields[0]?.contenu)
    let checker = this.props?.usercontenus.every(
      (v) => parseInt(v?.contenu?.id) !== contenususer[0]?.contenu?.id
    );
    if (checker === false) {
      return this.setState({ isDoing: true });
    }
  };

  _getModuleBySession = async () => {
    if (this.props.sessionId) {
      const url = api + "session/" + this.props.sessionId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) => d.module_id === response.data.module_id
          );
          let data = response["data"];
          this.setState({ session: last, module_id: data.module_id });
          //console.log("session", last);
          this.getModuleItem(data?.module_id);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }

    if (this.props.moduleId) {
      // console.log(this.props.moduleId)
      this.setState({ module_id: this.props.moduleId });
      this.getModuleItem(this.props.moduleId);
      const url = api + "sessionByModule/" + this.props.moduleId;
      axios
        .get(url, this.state.requestConfig)
        .then(async (response) => {
          let data = await response["data"];
          // console.log("session by module", data);
          this.setState({ session: data, module_id: this.props.moduleId });
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  };
  updateProgres = async (id) => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id
    );
    console.log("mod", mod, this.state.module_id);
    const url = api + `userdomodule/${mod[0]?.id}/`;
    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log("continu");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  sendData = async () => {
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.state.fields[this.state.selectedQstIndex]?.contenu,
    };
    const url = api + "userdocontenu/";
    await axios
      .post(url, sendData, this.state.requestConfig)
      .then((res) => {
        //console.log("data post", data);
        //let data = res['data'];
        this.updateProgres();
      })
      .catch((e) => {
        console.log("e post", e.response);
      });
    this.props.onGetUserDoModules();
    // if (this.props?.last[0].id === this.props.yes_no[0].id) {
    //   // console.log("END");
    //   const Data = {
    //     user: parseInt(this.state.userID),
    //     session: this.props.sessionId,
    //   };
    //   var elementPos = this.props.sessions
    //     .map(function (x) {
    //       return x.id;
    //     })
    //     .indexOf(this.props.sessionId);
    //   var objectFound = this.props.sessions[elementPos + 1];
    //   //console.log("qst", this.state.selectedId);
    //   axios
    //     .post(api + "userdosession/", Data, this.state.requestConfig)
    //     .then((data) => {
    //       console.log("data", data.data);
    //     })
    //     .catch((e) => {
    //       console.log("e", e.response);
    //     });
    //   this.setState({ endSession: true, selectedId: objectFound?.id });
    // }

    //this.checkScore()
  };

  result(e) {
    e.preventDefault();
    this.setState({ isEnded: true });
  }

  NextQuiz = () => {
    this.setState({
      responses: [],
      isEnded: false,
    });
    if (this.state.selectedQstIndex === this.props.yes_no.length - 1) {
      this.setState({
        isEndedQuiz: true,
      });
      return;
    } else {
      this.setState((prevState) => ({
        selectedIndex: 0,
        selectedQstIndex: prevState.selectedQstIndex + 1,
      }));
    }
    //console.log("quizEnd", this.state.isEndedQuiz);
  };

  // _redirect = () => {
  //   return (window.location = `/session-start/session/${this.state.selectedId}`)
  // }
  _redirect = async () => {
    console.log("last", this.props.last[0]?.id, this.state.slide?.id);
    let lastcontenu = [];
    lastcontenu = this.state.contenuBySession.filter(
      (d) =>
        d?.id ===
        this.state.contenuBySession[this.state.contenuBySession.length - 1]?.id
    )[0]?.list_supports;

    if (
      this.props.last[0]?.id === this.state.slide?.id ||
      lastcontenu[0]?.id === this.state.slide?.id
    ) {
      // console.log('END Session', this.props.sessionsModule)
      var objectFound = null;
      if (this.props.sessionId) {
        const Data = {
          user: parseInt(this.state.userID),
          session: this.props.sessionId,
        };
        console.log("data", Data);
        var last = this.props.sessionsModule.filter(
          (d) =>
            d?.id ===
            this.props.sessionsModule[this.props.sessionsModule.length - 1]?.id
        )[0];

        var elementPos = this.props.sessionsModule
          .map(function (x) {
            return x?.id;
          })
          .indexOf(this.props.sessionId);

        let checkerSession = (arr) =>
          arr.every((v) => parseInt(v?.session?.id) !== this.props.sessionId);
        if (this.props.sessionsModule.length && last !== undefined) {
          console.log("tested");
          if (this.props.sessionsModule[elementPos]?.id === last?.id) {
            /* let checker = (arr) =>
              arr.every((v) => parseInt(v?.module?.id) !== last?.module_id);
            console.log(this.props.usermodules);
            console.log("check", checkerSession(this.props.usersessions)); */
            this.setState({ endModule: true, load: true });
            console.log("test");

            if (checkerSession(this.props.usersessions) !== false) {
              axios
                .post(api + "userdosession/", Data, this.state.requestConfig)
                .then((data) => {
                  console.log("data", data.data);
                })
                .catch((e) => {
                  console.log("e", e.response);
                });
            }

            this.updateUser();
          } else {
            if (checkerSession(this.props.usersessions) === false) {
              axios
                .post(api + "userdosession/", Data, this.state.requestConfig)
                .then((data) => {
                  console.log("data", data.data);
                })
                .catch((e) => {
                  console.log("e", e.response);
                });
            }
            if (elementPos >= 0) {
              objectFound = this.props.sessionsModule.sort(
                (a, b) => a?.id - b?.id
              )[elementPos + 1];
              console.log("end", elementPos, objectFound);
            }

            console.log("elementPos", objectFound);
            this.setState({ endSession: true, selectedId: objectFound?.id });
            if (objectFound?.id && objectFound?.id !== undefined) {
              window.location = `/session-start/session/${objectFound?.id}`;
            } else {
              window.location = `/cours-details/${this.props.module_id}`;
            }
          }
        } else {
          if (checkerSession(this.props.usersessions) === false) {
            axios
              .post(api + "userdosession/", Data, this.state.requestConfig)
              .then((data) => {
                console.log("data", data.data);
              })
              .catch((e) => {
                console.log("e", e.response);
              });
          }
          // objectFound = this.props.sessions[elementPos + 1];
          if (elementPos >= 0) {
            objectFound = this.props.sessionsModule.sort(
              (a, b) => a?.id - b?.id
            )[elementPos + 1];
            console.log("end", elementPos, objectFound);
          }
          console.log("end", elementPos, objectFound);
          this.setState({ endSession: true, selectedId: objectFound?.id });
          if (objectFound?.id && objectFound?.id !== undefined) {
            window.location = `/session-start/session/${objectFound?.id}`;
          } else {
            window.location = `/cours-details/${this.props.module_id}`;
          }
        }
      } else {
        console.log(
          "test",
          this.props.sessionsModule.sort((a, b) => a?.id - b?.id)
        );
        objectFound = this.props.sessionsModule.sort(
          (a, b) => a?.id - b?.id
        )[0];
        if (objectFound !== undefined) {
          window.location = `/session-start/session/${objectFound?.id}`;
        }
      }
    }
    //return (window.location = `/session-start/session/${this.state.selectedId}`);
  };

  updateUser = async () => {
    if (this.props.progression === 100) {
      //  if (this.props.doEvaluation === false) {
      //    // return (window.location = '/fiche-evaluation')
      //    return (window.location = `/fiche-evaluation/${this?.state.module?.id}`);
      //  } else {
      //    // console.log("evaluation fait");
      //    return (window.location = `/tous-les-modules/`);
      //  }
      return (window.location = `/tous-les-modules/`);
    }
  };

  checkInput(index, e, data, score) {
    if (e.target.checked === true) {
      const checked = e.target.value === "true" ? true : false;
      this.addOrReplace({
        id: parseInt(e.target.id),
        response: checked,
        content: data.content,
        is_right_choice: data.is_right_choice,
        question: parseInt(e.target.name),
      });
      if (data.is_right_choice === true) {
        this.setState({
          score: this.state.score + score,
        });
      }
      this.setState({
        scoreTotal: this.state.scoreTotal + score,
      });
    }
    //console.log(this.state.scoreTotal)
    this.setState({
      responses: this.state.responses,
    });
  }

  addOrReplace = (object) => {
    const { responses, arrIndex } = this.state;
    var index = arrIndex[object.question];
    if (index === undefined) {
      index = responses.length;
    }
    arrIndex[object.question] = index;
    responses[index] = object;
    // console.log("responses", responses);
  };

  render() {
    const { fields, selectedQstIndex } = this.state;
    //console.log("yes_no", this.state.isDoing);
    return (
      <div>
        <div className="content-choices-response-quiz p-t-15">
          <h5 className="fw-300 f-f-work text-dark m-b-10">
            {fields[selectedQstIndex]?.title}
          </h5>

          <div>
            <p className="fs-14 fw-500 text-dark f-f-work m-b-50">
              {/* Tâche {i + 1}: {q?.content}  */}
            </p>
            {this.state.isEnded === false ? (
              <>
                {fields[selectedQstIndex]?.list_question.map((q, index) => {
                  return (
                    <div className="row m-b-20" key={q.id}>
                      <div className="col-xl-10 col-lg-10 col-md-10 m-b-10">
                        <p className="item-choice-vrai-faux">{q?.content}. </p>
                      </div>

                      <div className="col-xl-2 col-lg-2 col-md-2 itm-center m-b-10">
                        {q.list_responses.map((r, index) => {
                          return (
                            <div className="col-md-6" key={r.id}>
                              <label className="label-vrai-faux">
                                {r?.content}
                              </label>

                              <label className="label-vrai-faux-mobile">
                                {r?.content}
                              </label>

                              <div
                                className="custom-control custom-radio"
                                key={r.id}
                              >
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id={`${r.id}`}
                                  value={r.is_right_choice}
                                  name={q.id}
                                  defaultChecked={false}
                                  onChange={(e) =>
                                    this.checkInput(index, e, r, q?.score)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`${r.id}`}
                                ></label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <VraiFauxCorrection
                corrections={
                  this.state.fields[this.state.selectedQstIndex]?.list_question
                }
                responses={this.state.responses}
              />
            )}
          </div>

          <div className="row dis-flex justify-content-end m-t-55">
            {this.state.responses.length ===
              this.state.fields[this.state.selectedQstIndex]?.list_question
                .length && this.state.isEnded === false ? (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 m-b-10">
                <button
                  className="btn-theme-light dis-flex justify-content-center fw-600"
                  onClick={this.sendData}
                  data-toggle="modal"
                  data-target={`#centralSuccess${fields[selectedQstIndex]?.id}`}
                >
                  Envoyer
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="modal fade"
          id={`centralSuccess${fields[selectedQstIndex]?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="mb-3 animat rotateIn"
                  />
                  <h4 className="pop-title fw-600">
                    {(this.state.score * 100) / this.state.scoreTotal <= 49 ? (
                      "Oups ! Peut-être que tu dois répéter certains contenus ? Mais ne t’en fais pas, tu as encore le temps de t’améliorer !"
                    ) : (this.state.score * 100) / this.state.scoreTotal >=
                        50 &&
                      (this.state.score * 100) / this.state.scoreTotal <= 59 ? (
                      "Bravo ! Tu peux encore t’améliorer, mais c’est un bon début ! "
                    ) : (this.state.score * 100) / this.state.scoreTotal >=
                        60 &&
                      (this.state.score * 100) / this.state.scoreTotal <= 90 ? (
                      "Félicitations ! Tu as déjà beaucoup appris. Continue comme ça !"
                    ) : this.props.contenu?.message ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.contenu?.message,
                        }}
                      />
                    ) : (
                      "Wow, génial ! Tu as atteint le meilleur score. Félicitations !"
                    )}
                  </h4>
                  <p className="parag fs-20 fw-600">
                    Tu as obtenu{" "}
                    <span className="scores">
                      {this.state.score} / {this.state.scoreTotal} points
                    </span>
                  </p>
                </div>
              </div>
              <div className="modal-footer justify-content-center slider-actions-btn">
                <button
                  data-dismiss="modal"
                  className="btn btn-theme fw-400 fs-15 justify-content-start"
                  onClick={(e) => this.result(e)}
                >
                  VOIR LE RESULTAT{" "}
                </button>

                {!this.state.endSession ? (
                  <a
                    type="button"
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    data-dismiss="modal"
                    onClick={this.props.onGetUserDoModules}
                    href="#carouselSliderContentDetailsSession"
                    role="button"
                    data-slide="next"
                  >
                    CONTINUER
                  </a>
                ) : (
                  <button
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    onClick={this._redirect}
                    data-dismiss="modal"
                  >
                    CONTINUER
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              role="button"
              data-slide="prev"
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            {this.state.isDoing === true || this.state.isEnded === true ? (
              !this.state.endSession ? (
                <button
                  type="button"
                  className="btn-theme dis-flex justify-content-center fw-600"
                  data-dismiss="modal"
                  onClick={this.props.onGetUserDoModules}
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  onClick={this._redirect}
                  data-dismiss="modal"
                >
                  Suivant
                </button>
              )
            ) : this.props.yes_no.length ? (
              <button
                className="btn-theme disabled dis-flex justify-content-center fw-600"
                data-dismiss="modal"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                disabled
              >
                Suivant
              </button>
            ) : (
              <button
                type="button"
                className="btn-theme dis-flex justify-content-center fw-600"
                data-dismiss="modal"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.props.onGetUserDoModules}
              >
                Suivant
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
