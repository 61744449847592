import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "../../http/global-vars";

const SendPasswordModal = ({ apprenant }) => {
  const { register, handleSubmit, formState, setValue, reset, clearErrors } =
    useForm();

  const [apprenantId, setApprenantId] = useState(apprenant?.id);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (apprenantId) {
      register("user");
      setValue("user", apprenantId);
    }
  }, [apprenantId, apprenant]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleReset = () => {
    reset();
    setApprenantId(null);
  };

  const handleToggle = (e) => {
    e.preventDefault();
    setIsRevealPwd((prevState) => !prevState);
  };

  const onSubmit = (data) => {
    console.log(data);
    // data["user"] = window.localStorage.getItem("userID")
    setIsLoading(true);
    const url = `${api}sendpasswordadmin/`;
    Axios.post(url, data, config)
      .then((response) => {
        console.log("response", response);
        setIsLoading(false);
        window.$(`#SendPasswordModal${apprenant?.id}`).modal("hide");
        toast.success("Mot de passe ajouté avec succés.");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error?.response);
        toast.error(
          "Une erreur est survenue lors de l'ajout, veuillez réessayer plut tard."
        );
      });
  };
  return (
    <div>
      <h5 className="text-black m-b-50" style={{ whiteSpace: "normal" }}>
        Créer un mot de passe pour{" "}
        {apprenant.first_name + " " + apprenant.last_name}
      </h5>
      <form id="fieldreset" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <input
                type={isRevealPwd ? "text" : "password"}
                name="password"
                {...register("password", {
                  required: "Ce champ est obligatore.",
                })}
                className={`form-control input-dashboard-backend password-generate text-black mb-3 w-100`}
                placeholder="Mot de passe"
              />
              <span
                toggle="#password-field"
                className={
                  isRevealPwd
                    ? "fa fa-eye fa-eye-slash field-icon toggle-password"
                    : "fa fa-fw fa-eye field-icon toggle-password"
                }
                title={
                  isRevealPwd
                    ? "Cacher le mot de passe"
                    : "Voir le mot de passe"
                }
                onClick={(e) => handleToggle(e)}
              ></span>
              {formState?.errors && formState?.errors?.password && (
                <div className="alert alert-danger gfa-alert-danger">
                  {" "}
                  {formState?.errors?.password?.message}{" "}
                </div>
              )}
            </div>
            <input
              hidden
              {...register("user")}
              value={apprenant?.id || apprenantId}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <button
              className="btn-cancel-form"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleReset()}
            >
              Annuler
            </button>
          </div>
          <div className="col-lg-6 col-md-6">
            {!isLoading ? (
              <button className="btn-submit-form" type="submit">
                Envoyer
              </button>
            ) : (
              <button className="btn-submit-form">
                En cours... &nbsp;
                <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SendPasswordModal;
