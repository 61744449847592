import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { EditorState } from "draft-js";
import { toast, ToastContainer } from "react-toastify";
import api from "../../http/global-vars";

export default class CreateScale extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: window.localStorage.getItem("userToken"),
			requestConfig: {
				headers: {
					Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
				},
			},
			api: api,
			userID: window.localStorage.getItem("userID"),
			fields: {},
			questions: [""],
			editorState: EditorState.createEmpty(),
			created_by: parseInt(window.localStorage.getItem("userID")),
			isSuccess: false,
			isError: false,
			isLoading: false,
			is_empty_text: false,
			is_empty_texte: false,
			content_type: "scale",
			contentId: this.props.contentId,
			errors: {},
			global: [""],
		};
	}

	handleRemoveClick = (e, index) => {
		e.preventDefault();
		const list = [...this.state.questions];
		list.splice(index, 1);
		this.setState({ questions: list });
	};

	addQuestionForm = (e) => {
		//this method adds label and input field to the state array
		e.preventDefault();
		this.setState({
			questions: {
				length: this.state.questions.length + 1,
			},
		});

		/**A ne pas commenter */
		// eslint-disable-next-line
		this.state.questions.length = this.state.questions.length + 1;
		const updatedArray = [...this.state.questions];
		this.setState({ questions: updatedArray });
	};

	onChange = (editorState) => {
		this.setState({ editorState });
	};

	handleChange = (e) => {
		let fields = this.state.fields;

		fields[e.target.name] = e.target.value;

		console.log(fields);
		this.setState({
			fields: fields,
		});
	};

	onValidateForm() {
		let errors = this.state.errors;
		var isValidForm = true;

		if (!this.state.questions[0]) {
			isValidForm = false;
			this.setState({
				is_empty_text: true,
			});
			setTimeout(() => {
				this.setState({
					is_empty_text: false,
				});
			}, 5000);

			errors["texte"] = "Ce champ est obligatoire";
		}

		this.setState({
			errors: errors,
		});

		return isValidForm;
	}

	addForm = (e) => {
		e.preventDefault();
		console.log("valid", this.state);
		if (this.onValidateForm()) {
			this.setState({
				isLoading: true,
			});

			const api = this.state.api;
			for (let i = 0; i < this.state.questions.length; i++) {
				const question = this.state.questions[i];
				const response = "___";
				const colonnes = {
					question: question,
					response: response,
					contenu: this.state.contentId,
					content_type: this.state.content_type,
					created_by: this.state.created_by,
				};
				console.log("data", i , colonnes);
				axios.post(api + "scale/", colonnes, this.state.requestConfig)
					.then((resp) => {
						console.log("colonnes", resp.data);
						if (resp.data && i===this.state.questions.length-1) {
							this.setState({
								isSuccess: true,
								isLoading: false,
								fields: {},
								questions: [""],
							});
							// call this function after adding items
							this.props.notify();
						}
					})
					.catch((e) => {
						console.log("error question", e.response);
						toast.error(
							`Oupsss! Une erreur est survenue lors d'ajout de la question ${
								i + 1
							}. Veuillez réessayer plus tard.`
						);
						this.setState({
							isError: true,
							isLoading: false,
						});

						setTimeout(() => {
							this.setState({
								isError: false,
							});
						}, 5000);
						setTimeout(() => {
							this.setState({
								isSuccess: false,
								message: {},
							});
						}, 5000);
					});
			}
			this.setState({
				isError: true,
				isLoading: false,
			});
		}
	};

	checkQuestionInput(index, e) {
		console.log("ind", index);
		this.setState({
			questions: this.state.questions.map((item, i) =>
				i === index ? e.target.value : item
			),
		});
		console.log("questions", this.state.questions);
	}

	render() {
		const inputQ = this.state.questions.map((item, index) => (
			<div className="row m-b-5" key={index}>
				<div className="col-lg-3 col-md-4 col-sm-4 m-b-15 itm-center">
					<span className="fs-15 fw-400">Question {index + 1}</span>
				</div>
				<div className="col-lg-8 col-md-8 col-sm-8">
					<div className="form-group">
						<input
							type="text"
							className="form-control input-dashboard-backend border-theme text-theme"
							placeholder="Ajouter une question "
							value={item}
							onChange={(e) => this.checkQuestionInput(index, e)}
						/>
						{this.state.questions.length !== 1 && (
							<button
								className="btn-placeholder-textarea"
								onClick={(e) => this.handleRemoveClick(e, index)}
							>
								<FeatherIcon className="close" icon="x" />
							</button>
						)}
						{this.state.is_empty_text && (
							<div className="alert alert-danger keysjob-alert-danger">
								{" "}
								{this.state.errors.texte}{" "}
							</div>
						)}
					</div>
				</div>
			</div>
		));

		const inputsG = this.state.global.map((item, index) => (
			<div className="ddd" key={index}>
				{inputQ}
			</div>
		));
		return (
			<div className="card-dashboard no-shadow">
				<form>
					<div className="row m-b-50">
						<div className="col-lg-9">
							<p className="titre-card-create-content">Scale</p>
						</div>

						<div className="col-lg-3">
							<span className="text-inside-select-bg-theme">Ordre</span>
							<select className="custom-select select-bg-theme" id="">
								<option value="">0</option>
							</select>
						</div>
					</div>

					{inputsG}

					<div className="row m-b-100">
						<div className="col-lg-3 col-md-12"></div>
						<div className="col-lg-8 col-md-12">
							<div className="row">
								<div className="col-lg-6">
									<button
										type="button"
										className="btn-light-add-content fs-12"
										data-toggle="modal"
										data-target=".modalCreateContent"
										onClick={(e) => this.addQuestionForm(e)}
									>
										<FeatherIcon
											className="icon-btn-add-dashboard"
											icon="plus"
										/>
										Ajouter une question
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="row dis-flex justify-content-end m-t-60">
						<div className="col-xl-3 col-lg-3 m-b-20 itm-center">
							<button
								type="button"
								className="btn-cancel-theme"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">Annuler</span>
							</button>
						</div>
						<div className="col-xl-4 col-lg-4 m-b-20">
							{/* <button className="btn-theme text-up w-full">Terminer</button> */}
							{this.state.isLoading === false ? (
								<button
									type="submit"
									onClick={(e) => this.addForm(e)}
									className="btn-theme text-up w-full"
								>
									Terminer
								</button>
							) : (
								<button className="btn-theme text-up w-full in-progress-btn">
									Ajout en cours... &nbsp;
									<i className="fas fa-spin fa-spinner"></i>
								</button>
							)}
						</div>
					</div>
				</form>
				{this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
			</div>
		);
	}
}
