import React, { Component } from 'react';
// import FeatherIcon from 'feather-icons-react';
import baseUrl from '../../http/backend-base-url'

export default class CardReponseForum extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    //console.log('props', this.props)
    return (
      <div>
        <div className="card-reponse">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 auteur-reponse-position-mobile">
              <p className="auteur-reponse-card-forum-details">
                {this.props.creator_id.first_name} {" "} {this.props.creator_id.last_name}
              </p>
              <img
                className="avatar-reponse-card-forum-details"
                src={baseUrl + this.props.creator_id.avatar}
                alt="Avatar"
              />
            </div>
            <div className="col-xl-7 col-lg-9 col-md-9 col-sm-8">
              <p className="date-reponse-card-forum-details">
                Posté le {this.props.created_at}

              </p>
              <p className="text-content-reponse-card-forum-details">
                {this.props.name}, <br />
                <br />
                {this.props.description}
              </p>
            </div>
            {/* <div className=" col-xl-3 col-lg-4 col-md-4 col-sm-6 "> 
                <strong className="forum-module">Texte</strong>
            </div> */}
          </div>
         
        </div>
      </div>
    );
  }
}
