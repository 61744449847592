import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
export default class ItemProgressionModalDetailsApprenant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps?.apprenant_Infos !== this.props?.apprenant_Infos) {
            // console.log("next props==>", nextProps?.apprenant_Infos);
            // console.log("props==>", this.props?.apprenant_Infos);
            let data = nextProps?.apprenant_Infos
            this.setState({ data: data })
        }
    }

    render() {
        //console.log("apprenant progression", this.props?.apprenant_Infos);
        // let { apprenant_Infos } = this?.props
        {/* <p className="content-quiz-item m-b-15 h-content-quiz-item">Session 1 - Les différents types de communication</p> */ }
        {/* <p className="content-quiz-item h-theme-light">57 %</p> */ }
        // console.log("infos apprenant", this.props?.list_apprenants)
        // console.log("data==>", this.state?.data)

        return (
            <div className="item-progression-modal-details-apprenant">
                {this.state?.data?.modules?.length!==0 ?
                    this.state.data?.modules?.map((m) => (
                        <div className="row item-progression-modal-details-apprenant-row" key={m?.id}>
                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 itm-center containt-mobile-center">
                                <p className="content-quiz-item m-b-15 h-content-quiz-item">{m?.module?.name}</p>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 text-center m-b-15">
                                <p className="content-quiz-item fw-300">Progression</p>
                                <p className="content-quiz-item h-theme-light">{m?.progression}%</p>
                            </div>
                        </div>
                    )) :
                    <div className="alert alert-info gfa-alert-info empty-badge-alert col-12">
                    <div className="row empty-alert-row">
                      <div className="col-md-2 empty-icon-col">
                        <FeatherIcon
                          icon="alert-circle"
                          className="m-b-3 empty-alert-icon rotateIn"
                        />
                      </div>
                      <div className="empty-textual-col">
                      <p>Pas de progression pour le moment</p>
                      </div>
                    </div>
                  </div>
                    // <div className="col-md-10 empty-textual-col">
                    //     <p>Pas de progression pour le moment</p>
                    // </div>
                }
            </div>
        );
    }
}

