import React from "react";
import FeatherIcon from "feather-icons-react";
import baseUrl from "../../http/backend-base-url";

function ShowPersonnageModal({ modalId, item }) {
  return (
    <div
      className="modal fade sendPasswordModal"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      //   data-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content card-dashboard">
          <div className="row m-b-35">
            <div className="col-10 itm-center">
              <p className="title-modal">{item?.name}</p>
            </div>
            <div className="col-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <FeatherIcon icon="x" />
                </span>
              </button>
            </div>
          </div>
          <div>
            <div className="container-presentation-page mb-4">
              <div className="container-presentaion-personnage">
                <div className="container-img-personnage">
                  <img
                    src={baseUrl + item?.image}
                    alt="Personnage 1"
                    className="img-personnage"
                  />
                </div>
                <div className="container-text-presentation-personnage">
                  <h3 className="nom-personnage mb-4">IBRAHIMA</h3>
                  <p
                    className="text-description-presentation-personnage"
                    dangerouslySetInnerHTML={{
                      __html: item?.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowPersonnageModal;
