import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { NavLink } from 'react-router-dom'

export default class CardSessionDownload extends Component {
    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    render(){
        return (
            <div>
                <div className="card">
                    <img className="img-course-on-card-cours" src="img/courses/cours2.png" alt=""/>
                    <div className="content-course-on-card-cours">
                        <NavLink to="#" className="titre-course-on-card-cours">Développement Web</NavLink>
                        <div className="row m-t-15">
                            <div className="col-4 flex-row info-content-course-on-card-cours itm-center">
                                <FeatherIcon className="icon-content-course-on-card-cours" icon="youtube" />
                                12 leçons
                            </div>
                            <div className="col-4 flex-row info-content-course-on-card-cours itm-center">
                                <FeatherIcon className="icon-content-course-on-card-cours" icon="clock" />
                                1h 30min
                            </div>
                            <div className="col-4">
                                <NavLink to="#" className="d-flex">
                                    <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}