import axios from "axios";
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import api from "./../../http/global-vars";
// import { Redirect } from 'react-router-dom'
import MessageAllPage from "./../../backend/components/MessageAllPage";

export default class FicheIdentificationV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      userFirstName: window.localStorage.getItem("userFirstName"),
      userLastName: window.localStorage.getItem("userLastName"),
      fields: {},
      errors: {},
      sendInformationInProgress: false,
      isSuccessSendingInfos: false,
      isErrorSendingInfos: false,
      isShowYearAndMonth: false,
      yearsCount: 0,
      monthsCount: 0,
      openEcoInputOther: false,
      is_empty_migrant: false,
      is_empty_diplome: false,
      is_empty_employes: false,
      is_empty_niveau_etude: false,
      is_empty_pays_provenance: false,
      is_empty_profession: false,
      is_empty_situation_eco: false,
      openMetierInputOther: false,
      openProvenanceInputOther: false,
      is_empty_revenu_jour: false,
      is_empty_date_debut: false,
      is_empty_secteur: false,
      is_empty_nbre_employes: false,
      is_empty_type_energie: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmitInformation = this.onSubmitInformation.bind(this);
  }

  componentDidMount() {
    this.monthsDiff("2020-04-23");
    window.scroll(0,0)
  }

  yearsDiff(startDate) {
    let date1 = new Date(startDate);
    let date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
  }

  monthsDiff(startDate) {
    let date1 = new Date(startDate);
    let date2 = new Date();
    let years = this.yearsDiff(startDate);
    let months = years * 12 + (date2.getMonth() - date1.getMonth());
    return months;
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields["type_energie"] = "Aucune forme d’énergie";
    fields["user"] = this.state.userID;
    if (e.target.name === "date_debut") {
      var dateDebut = e.target.value;
      var years = this.yearsDiff(dateDebut);
      var months = this.monthsDiff(dateDebut);
      fields["nbr_annee"] = years;
      fields["nbr_mois"] = months;
      this.setState({
        isShowYearAndMonth: true,
        yearsCount: years,
        monthsCount: months,
      });
      var m = new Date(e.target.value);
      var dateString =
        m.getUTCFullYear() +
        "-" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + m.getUTCDate()).slice(-2) +
        " " +
        ("0" + m.getUTCHours()).slice(-2) +
        ":" +
        ("0" + m.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + m.getUTCSeconds()).slice(-2);
      fields[e.target.name] = dateString;
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields,
    });
  };

  onValidateForm = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["migrant"]) {
      isValidForm = false;
      this.setState({
        is_empty_migrant: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_migrant: false,
        });
      }, 5000);
      errors["migrant"] = "Ce champ est obligatoire";
    }
    if (
      typeof fields["migrant"] !== "undefined" &&
      fields["migrant"] === "oui" &&
      !fields["pays_provenance"]
    ) {
      isValidForm = false;
      this.setState({
        is_empty_pays_provenance: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_pays_provenance: false,
        });
      }, 5000);
      errors["pays_provenance"] = "Ce champ est obligatoire";
    }
    if (!fields["profession"]) {
      isValidForm = false;
      this.setState({
        is_empty_profession: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_profession: false,
        });
      }, 5000);
      errors["profession"] = "Ce champ est obligatoire";
    }
    if (!fields["situation_eco"]) {
      isValidForm = false;
      this.setState({
        is_empty_situation_eco: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_situation_eco: false,
        });
      }, 5000);
      errors["situation_eco"] = "Ce champ est obligatoire";
    }
    const is_agr = ["AGR", "Entrepreneur / Entrepreneuse"].includes(
      fields.situation_eco
    );
    // if (
    //   typeof fields["situation_eco"] !== "undefined" &&
    //   (fields["situation_eco"] === "Salarié(e)" || is_agr)
    // ) {
    //   if (!fields["revenu_jour"]) {
    //     isValidForm = false;
    //     this.setState({
    //       is_empty_revenu_jour: true,
    //     });
    //     setTimeout(() => {
    //       this.setState({
    //         is_empty_revenu_jour: false,
    //       });
    //     }, 5000);
    //     errors["revenu_jour"] = "Ce champ est obligatoire";
    //   }
    // }
    if (typeof fields["situation_eco"] !== "undefined" && is_agr) {
      if (!fields["date_debut"]) {
        isValidForm = false;
        this.setState({
          is_empty_date_debut: true,
        });
        setTimeout(() => {
          this.setState({
            is_empty_date_debut: false,
          });
        }, 5000);
        errors["date_debut"] = "Ce champ est obligatoire";
      }
      if (!fields["secteur"]) {
        isValidForm = false;
        this.setState({
          is_empty_secteur: true,
        });
        setTimeout(() => {
          this.setState({
            is_empty_secteur: false,
          });
        }, 5000);
        errors["secteur"] = "Ce champ est obligatoire";
      }
      if (!fields["employes"]) {
        isValidForm = false;
        this.setState({
          is_empty_employes: true,
        });
        setTimeout(() => {
          this.setState({
            is_empty_employes: false,
          });
        }, 5000);
        errors["employes"] = "Ce champ est obligatoire";
      }
      if (
        typeof fields["employes"] !== "undefined" &&
        fields["employes"] === "oui"
      ) {
        if (!fields["nbr_employe_temp"] && !fields["nbr_employe_perm"]) {
          isValidForm = false;
          this.setState({
            is_empty_nbre_employes: true,
          });
          setTimeout(() => {
            this.setState({
              is_empty_nbre_employes: false,
            });
          }, 5000);
          errors["nbre_employes"] = "Ce champ est obligatoire";
        }
      }
      //   if (!fields["type_energie"]) {
      //     isValidForm = false;
      //     this.setState({
      //       is_empty_type_energie: true,
      //     });
      //     setTimeout(() => {
      //       this.setState({
      //         is_empty_type_energie: false,
      //       });
      //     }, 5000);
      //     errors["type_energie"] = "Ce champ est obligatoire";
      //   }
    }

    if (!fields["niveau_etude"]) {
      isValidForm = false;
      this.setState({
        is_empty_niveau_etude: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_niveau_etude: false,
        });
      }, 5000);
      errors["niveau_etude"] = "Ce champ est obligatoire";
    }
    if (!fields["diplome"]) {
      isValidForm = false;
      this.setState({
        is_empty_diplome: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_diplome: false,
        });
      }, 5000);
      errors["diplome"] = "Ce champ est obligatoire";
    }
    if (
      typeof fields["diplome"] !== "undefined" &&
      fields["diplome"] === "oui" &&
      !fields["nom_diplome"]
    ) {
      isValidForm = false;
      this.setState({
        is_empty_nom_diplome: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_nom_diplome: false,
        });
      }, 5000);
      errors["nom_diplome"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });
    return isValidForm;
  };

  onSubmitInformation = (e) => {
    e.preventDefault();

    if (this.onValidateForm()) {
      this.setState({
        sendInformationInProgress: true,
      });
      var api = this.state.api;
      var config = this.state.requestConfig;
      var data = this.state.fields;

      axios
        .post(`${api}feddback/`, data, config)
        .then((response) => {
          this.setState({
            sendInformationInProgress: false,
          });
          console.log(response)
          if (response.status === 201) {
            window.localStorage.setItem("userFirstConnexion", "false");
            toast.success(
              "Votre fiche d'information a été bien pris en compte! Vous allez être redirigé dans 5 secondes."
            );
            this.setState({
              isSuccessSendingInfos: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessSendingInfos: false,
              });
              window.location = "/tous-les-modules";
            }, 6000);
          } else {
            console.log(response);
            toast.error(
              "Oupsss! Une erreur est survenue lors de la soumission de votre fiche d'information. Veuillez réessayer plus tard."
            );
            this.setState({
              isErrorSendingInfos: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorSendingInfos: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Oupsss! Une erreur est survenue lors de la soumission de votre fiche d'information. Veuillez réessayer plus tard."
          );
          this.setState({
            sendInformationInProgress: false,
            isErrorSendingInfos: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorSendingInfos: false,
            });
          }, 5000);
        });
    }
  };

  onOpenEcoInputOther = () => {
    if (this.state.openEcoInputOther === false) {
      this.setState({
        openEcoInputOther: true,
      });
    } else {
      this.setState({
        openEcoInputOther: false,
      });
    }
    // console.log(this.state.openEcoInputOther)
  };

  onCloseAndClearEcoInputOther = () => {
    var status = this.state.openEcoInputOther;
    if (status === true) {
      this.setState({
        openEcoInputOther: false,
      });
    }
  };

  onOpenMetierInputOther = () => {
    if (this.state.openMetierInputOther === false) {
      this.setState({
        openMetierInputOther: true,
      });
    } else {
      this.setState({
        openMetierInputOther: false,
      });
    }
    console.log(this.state.openMetierInputOther);
  };

  onCloseAndClearMetierInputOther = () => {
    var status = this.state.openMetierInputOther;
    if (status === true) {
      this.setState({
        openMetierInputOther: false,
      });
    }
  };

  onOpenProvenanceInputOther = () => {
    if (this.state.openProvenanceInputOther === false) {
      this.setState({
        openProvenanceInputOther: true,
      });
    } else {
      this.setState({
        openProvenanceInputOther: false,
      });
    }
    console.log(this.state.openProvenanceInputOther);
  };

  onCloseAndClearProvenanceInputOther = () => {
    var status = this.state.openProvenanceInputOther;
    if (status === true) {
      this.setState({
        openProvenanceInputOther: false,
      });
    }
  };

  render() {
    let { userFirstName, userLastName } = this.state;
    const is_agr = ["AGR", "Entrepreneur / Entrepreneuse"].includes(
      this.state.fields.situation_eco
    );
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobile />
          </div>
        </div>

        <HeaderDashboard />

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 p-b-200 p-t-25 content-position-after-header">
            <div className="container-fluid">
              <div className="card-dashboard b-r-0">
                <form onSubmit={(e) => this.onSubmitInformation(e)}>
                  <div className="row m-b-50">
                    <div className="col-sm-6 m-b-20 dis-flex containt-mobile-center">
                      <img
                        className="logo-fiche-identification"
                        src="/img/Logo_Coop_GIZ 2.png"
                        alt=""
                      />
                    </div>
                    <div className="col-sm-6 m-b-20 dis-flex containt-mobile-center justify-content-end">
                      <img
                        className="logo-fiche-identification"
                        src="/img/gfa.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <h4 className="h-theme-light fw-500 f-f-inter m-b-15">
                    Remplissez la fiche d’identification pour continuer
                  </h4>
                  <p className="fs-16 f-f-work text-dark fw-400">
                    Avant de commencer la formation, nous t’invitons à remplir
                    la fiche d’identification ci-après en répondant à toutes les
                    questions.
                  </p>
                  <p className="fs-16 f-f-work text-dark fw-400 m-b-15">
                    Les données de cette fiche seront utilisées exclusivement
                    pour des fins de suivi et évaluation du projet « Réussir au
                    Sénégal », et seront traitées dans le plus strict respect
                    des règles de confidentialité.
                  </p>
                  <p className="fs-16 f-f-work text-dark fw-400 m-b-70">
                    Bonne formation !
                  </p>

                  <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 dis-flex itm-center">
                      <div className="form-group">
                        <p className="fw-600 fs-20 f-f-work text-dark">
                          Prénom & Nom
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="form-group">
                        <input
                          value={userFirstName}
                          onChange={this.handleChange}
                          type="text"
                          className="form-control input-dashboard-backend border-theme"
                          placeholder="Prénom"
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="form-group">
                        <input
                          value={userLastName}
                          onChange={this.handleChange}
                          type="text"
                          className="form-control input-dashboard-backend border-theme"
                          placeholder="Nom"
                          name="name"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="ligne m-t-20 m-b-40"></div>

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      1. Quelle est votre profession ou métier ?
                    </h5>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={this.handleChange}
                          className="form-control input-fiche-identification"
                          placeholder="................................................................................................................"
                          name="profession"
                        />
                      </div>
                    </div>
                    {this.state.is_empty_profession && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.profession}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      2. Quel est votre niveau d’étude (niveau le plus élevé) ?{" "}
                    </h5>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={this.handleChange}
                          className="form-control input-fiche-identification"
                          placeholder="................................................................................................................"
                          name="niveau_etude"
                        />
                      </div>
                    </div>
                    {this.state.is_empty_niveau_etude && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.niveau_etude}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      3. Avez-vous obtenu un certificat/diplôme officiel :
                    </h5>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioCertificatDiplomeOui"
                        name="diplome"
                        value={"oui"}
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioCertificatDiplomeOui"
                      >
                        Oui
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioCertificatDiplomeNon"
                        name="diplome"
                        value={"non"}
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioCertificatDiplomeNon"
                      >
                        Non
                      </label>
                    </div>
                    {this.state.is_empty_diplome && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.diplome}
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.fields.diplome === "oui" && (
                      <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-5">
                          <p className="fw-400 f-f-poppins text-dark dis-flex">
                            {" "}
                            Si oui, lequel :
                          </p>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-5 p-l-0">
                          <input
                            type="text"
                            onChange={this.handleChange}
                            className="form-control input-fiche-identification "
                            placeholder="......................................................................................................."
                            name="nom_diplome"
                          />
                        </div>
                      </div>
                    )}
                    {this.state.is_empty_nom_diplome && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.nom_diplome}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      4. Quelle est votre situation économique actuelle ?
                    </h5>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation1"
                        name="situation_eco"
                        value="Salarié(e)"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation1"
                      >
                        Salarié(e)
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation12"
                        name="situation_eco"
                        value="Porteur de projet"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation12"
                      >
                        Porteur de projet
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation13"
                        name="situation_eco"
                        value="Entrepreneur / Entrepreneuse"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation13"
                      >
                        Entrepreneur / Entrepreneuse
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation2"
                        name="situation_eco"
                        value="AGR"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation2"
                      >
                        Responsable d’une Activité Génératrice de Revenu (AGR)
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation3"
                        name="situation_eco"
                        value="Stagiaire"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation3"
                      >
                        Stagiaire
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation4"
                        name="situation_eco"
                        value="Étudiant(e)"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation4"
                      >
                        Étudiant(e)
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation14"
                        name="situation_eco"
                        value="Elève"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation14"
                      >
                        Elève
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation15"
                        name="situation_eco"
                        value="Apprenant"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation15"
                      >
                        Apprenant
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onCloseAndClearEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation5"
                        name="situation_eco"
                        value="Sans emploi"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation5"
                      >
                        Sans emploi
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onClick={() => this.onOpenEcoInputOther()}
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioQ3Sutuation6"
                        name="situation_eco"
                        value="Autre"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ3Sutuation6"
                      >
                        Autre (à préciser)
                      </label>
                    </div>
                    {this.state.openEcoInputOther && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control input-dashboard-backend"
                              placeholder="Précisez votre métier ici"
                              name="situation_eco"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.is_empty_situation_eco && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.situation_eco}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {is_agr && (
                    <div className="m-b-45">
                      <h5 className="fw-700 f-f-poppins text-dark">
                        Si vous êtes responsable d’AGR et/ou entrepreneur /
                        entrepreneuse :
                      </h5>
                      <h6 className="fw-700 f-f-poppins text-black">
                        4.1 Depuis combien de temps avez-vous démarré votre AGR
                        / entreprise ?
                      </h6>
                      <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-12">
                          <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6">
                              <div className="form-group">
                                <input
                                  type="date"
                                  onChange={this.handleChange}
                                  className="form-control input-fiche-identification"
                                  placeholder="..............................."
                                  name="date_debut"
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-7 col-md-7 col-sm-6 col-6 p-l-0">
                              <p className="text-dark f-f-work fs-16 fw-400 m-b-16 start-date">
                                TT.mm.jjjj date de démarrage
                              </p>
                            </div>
                          </div>
                        </div>
                        {this.state.isShowYearAndMonth && (
                          <div className="row error-row">
                            <div className="col-md-12 date-counter-container">
                              <span className="counter-item">
                                Celà veut dire que votre activité dure depuis{" "}
                                {`${this.state.monthsCount} mois`} soit{" "}
                                {`${this.state.yearsCount} année(s)`}
                              </span>
                            </div>
                          </div>
                        )}

                        {this.state.is_empty_date_debut && (
                          <div className="row error-row">
                            <div className="col-md-6">
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.date_debut}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <h6 className="fw-700 f-f-poppins text-black">
                        4.2 Dans quel secteur exercez-vous ?
                      </h6>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control input-fiche-identification"
                              placeholder="................................................................................................................"
                              name="secteur"
                            />
                          </div>
                        </div>
                        {this.state.is_empty_secteur && (
                          <div className="row error-row">
                            <div className="col-md-6">
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.secteur}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <h6 className="fw-700 f-f-poppins text-black">
                        4.3 Avez-vous des employés ?
                      </h6>
                      <div className="custom-control custom-radio m-b-10">
                        <input
                          type="radio"
                          onChange={this.handleChange}
                          className="custom-control-input"
                          id="radioEmployeOui"
                          name="employes"
                          value={"oui"}
                        />
                        <label
                          className="custom-control-label custom-radio-fiche-identification"
                          htmlFor="radioEmployeOui"
                        >
                          Oui
                        </label>
                      </div>
                      <div className="custom-control custom-radio m-b-10">
                        <input
                          type="radio"
                          onChange={this.handleChange}
                          className="custom-control-input"
                          id="radioEmployeNon"
                          name="employes"
                          value={"non"}
                        />
                        <label
                          className="custom-control-label custom-radio-fiche-identification"
                          htmlFor="radioEmployeNon"
                        >
                          Non
                        </label>
                      </div>

                      {this.state.is_empty_employes && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="alert alert-danger gfa-danger">
                              {this.state.errors.employes}
                            </div>
                          </div>
                        </div>
                      )}

                      <h6 className="fw-700 f-f-poppins text-black">
                        4.4 Si oui, combien d’employés avez-vous ?
                      </h6>
                      <div>
                        <p className="fw-400 f-f-poppins text-dark dis-flex m-b-15">
                          Nombre exactes d’employés temporaires ou saisonniers :
                          <span className="col-md-2">
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control input-fiche-identification "
                              placeholder="............................"
                              name="nbr_employe_temp"
                            />
                          </span>
                        </p>
                        <p className="fw-400 f-f-poppins text-dark dis-flex">
                          Employés permanents :
                          <span className="col-md-2">
                            <input
                              type="text"
                              onChange={this.handleChange}
                              className="form-control input-fiche-identification "
                              placeholder="............................"
                              name="nbr_employe_perm"
                            />
                          </span>
                        </p>
                      </div>
                      {this.state.is_empty_nbre_employes && (
                        <div className="row error-row">
                          <div className="col-md-6">
                            <div className="alert alert-danger gfa-danger">
                              {this.state.errors.nbre_employes}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {/* <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      3) Si vous êtes responsable d’AGR, quel type d’énergie
                      utilisez-vous ?
                    </h5>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioEnergie1"
                        name="type_energie"
                        value="Energie fossile"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioEnergie1"
                      >
                        Energie fossile
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioEnergie2"
                        name="type_energie"
                        value="Energie renouvelable"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioEnergie2"
                      >
                        Energie renouvelable
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="radioEnergie3"
                        name="type_energie"
                        value="Aucune forme d’énergie"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioEnergie3"
                      >
                        Aucune forme d’énergie
                      </label>
                    </div>
                    {this.state.is_empty_type_energie && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.type_energie}
                          </div>
                        </div>
                      </div>
                    )}
                  </div> */}

                  

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      5. Etes-vous un Migrant de retour :
                    </h5>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="checkboxOuiQ1"
                        name="migrant"
                        value={"oui"}
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="checkboxOuiQ1"
                      >
                        Oui
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        type="radio"
                        onChange={this.handleChange}
                        className="custom-control-input"
                        id="checkboxNonQ1"
                        name="migrant"
                        value={"non"}
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="checkboxNonQ1"
                      >
                        Non
                      </label>
                    </div>
                    {this.state.is_empty_migrant && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.migrant}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.fields.migrant === "oui" && (
                      <>
                        <h6 className="fw-700 f-f-poppins text-black">
                          Si oui, pays de provenance :
                        </h6>
                        <div className="custom-control custom-radio m-b-10">
                          <input
                            type="radio"
                            onClick={() =>
                              this.onCloseAndClearProvenanceInputOther()
                            }
                            onChange={this.handleChange}
                            className="custom-control-input"
                            id="checkboxAllemagneQ1"
                            name="pays_provenance"
                            value="Allemagne"
                          />
                          <label
                            className="custom-control-label custom-radio-fiche-identification"
                            htmlFor="checkboxAllemagneQ1"
                          >
                            Allemagne
                          </label>
                        </div>
                        <div className="custom-control custom-radio m-b-10">
                          <input
                            type="radio"
                            onClick={() => this.onOpenProvenanceInputOther()}
                            onChange={this.handleChange}
                            className="custom-control-input"
                            id="checkboxAutreQ1"
                            name="pays_provenance"
                            value="Autre"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#autrePays"
                          />
                          <label
                            className="custom-control-label custom-radio-fiche-identification"
                            htmlFor="checkboxAutreQ1"
                          >
                            Autre pays, (à préciser)
                          </label>
                        </div>
                        {this.state.openProvenanceInputOther && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  onChange={this.handleChange}
                                  className="form-control input-dashboard-backend"
                                  placeholder="Autres pays ..."
                                  name="pays_provenance"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.is_empty_pays_provenance && (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.pays_provenance}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="row dis-flex justify-content-end m-t-60">
                    <div className="col-xl-3 col-lg-3 col-md-4 m-b-20">
                      {!this.state.sendInformationInProgress ? (
                        <button
                          type="submit"
                          className="btn-theme text-up w-full"
                        >
                          Envoyer
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn-theme text-up w-full in-progress"
                          disabled
                        >
                          Envoi en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <MessageAllPage fullWidth={true} />
        </div>

        {this.state.isSuccessSendingInfos || this.state.isErrorSendingInfos ? (
          <ToastContainer />
        ) : (
          ""
        )}
      </div>
    );
  }
}
