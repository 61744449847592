import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';

export default class ImageZoomableModal extends Component{


    render(){
        return(
            <div className="image-zoomable-modal-component">
                <div className="row m-b-35">
                    <div className="col-10 itm-center">
                        <p className="title-modal">Image Zoomable</p>
                    </div>
                    <div className="col-2">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><FeatherIcon icon="x" /></span>
                        </button>
                    </div>
                </div>
                <div className="row image-zoomable-row">
                    <div className="col-md-12 image-zoomable-col">
                        <div className="image-zoomable-container"></div>
                    </div>
                </div>
            </div>
        )
    }
}