import React, { Component } from 'react'

export default class Copyright extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="copyright">
        © 2020 Copyright:{' '}
        <a
          href="https://www.gfa-group.de"
          target="blank"
          className="fw-600 text-white"
        >
          GFA Consulting Group GmbH  {" "}
          <img
            src="/img/gfa_bwhite.png"
            alt="Logos Sponsors"
            style={{width: "80px", marginBottom: "10px"}}
          />
        </a> 
        {" "}
        {/* <div className="logo-gfa-container"> */}
          
        {/* </div> */}
      </div>
    )
  }
}
