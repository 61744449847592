import React from "react";
import Skeleton from "react-loading-skeleton";

const ForumSkeleton = () => {
  return (
    <section>
      <div className="card-dashboard m-b-30">
        {Array(9)
          .fill()
          .map((item, index) => (
            <div className="row" key={index}>
              <div className=" col-xl-5 col-lg-5 col-md-6">
                <div className="titre-forum-card" to="/details-forum">
                  <Skeleton height={36} width={`90%`} />
                </div>
                <p className="publish-date-forum-card m-b-20">
                  <Skeleton width={`80%`} />
                </p>
              </div>
              <div className=" col-xl-4 col-lg-3 col-md-2 col-sm-6">
                <p className="nbre-message-forum-card">
                  <Skeleton width={`90%`} />
                </p>
                <p className="text-forum-card fs-12 fw-300 m-b-20">
                  <Skeleton width={`80%`} />
                </p>
              </div>
              <div className=" col-xl-3 col-lg-4 col-md-4 col-sm-6 dis-flex m-b-10">
                <Skeleton circle={true} height={50} width={50} />
                &nbsp;&nbsp;
                <p className="text-forum-card fs-12 fw-300">
                  <Skeleton width={`80%`} />
                  <strong>
                    <Skeleton duration={1} height={30} width={90} />
                  </strong>
                </p>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default ForumSkeleton;
