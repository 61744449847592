import React, { useEffect, useState } from "react";
import NavbarVertical from "../../components/NavbarVertical";
import HeaderDashboard from "../../components/HeaderDashboard";
import NavbarDashboardMobile from "../../components/NavbarDashboardMobile";
import MessageAllPage from "../../../backend/components/MessageAllPage";
import { NavLink, useParams, useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import api from "../../../http/global-vars";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import baseUrl from "../../../http/backend-base-url";

const ChoisirPersonnages = () => {
  const schema = yup.object().shape({
    personnage_id: yup
      .string()
      .required(
        "Veuillez selectionner le personnage qui te correspond le plus."
      ),
  });
  const [personnages, setPersonnages] = useState([]);
  const [personnage, setPersonnage] = useState();
  const [userPersonnage, setUserPersonnage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState, clearErrors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  let userID = window.localStorage.getItem("userID");
  const { state } = useLocation();
  const [item, setItem] = useState(state);
  const history = useHistory();
  const { id } = useParams();
  const getPersonnages = async () => {
    let url = `${api}personnage/`;
    await Axios.get(url, config)
      .then((res) => {
        console.log("res", res);
        setPersonnages(res.data?.results);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getUserPersonnage = async () => {
    let url = api + `user/${parseInt(userID)}/personnage/`;
    try {
      let res = await Axios.get(url, config);
      console.log("ress", res);
      if (res?.data?.id) {
        setUserPersonnage(res?.data);
        setPersonnage(res?.data?.personnage_id?.id);
        setValue("personnage_id", res?.data?.personnage_id?.id);
      }
    } catch (error) {
      setPersonnage(undefined);
    }
  };
  useEffect(() => {
    getPersonnages();
    getUserPersonnage();
  }, []);

  const getModule = async (id) => {
    await Axios.get(`${api}module/${id}`, config)
      .then((res) => {
        setItem(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
        history.goBack();
      });
  };
  useEffect(() => {
    if (id) {
      getModule(id);
    } else {
      history.goBack();
    }
  }, [id]);

  useEffect(() => {
    if (state) {
      setItem(state);
    }
  }, [state]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    data["user_id"] = userID;
    if (userPersonnage) {
      let url = `${api}userchoosepersonnage/${userPersonnage?.id}/`;
      let req = Axios.put(url, data, config);
      await req
        .then((res) => {
          console.log("res", res);
          setIsLoading(false);
          history.push({
            pathname: `/sous-modules/${item?.id}`,
            state: item,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("error", err?.response);
          toast.error(
            err?.response?.data?.message || "Une erreur est survenue."
          );
        });
    } else {
      let url = `${api}userchoosepersonnage/`;
      let req = Axios.post(url, data, config);
      await req
        .then((res) => {
          console.log("res", res);
          setIsLoading(false);
          history.push({
            pathname: `/terminer-presentation/${item?.id}`,
            state: item,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("error", err?.response);
          toast.error(
            err?.response?.data?.message || "Une erreur est survenue."
          );
        });
    }
  };
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-presentation-page mb-4">
                <div className="presenation-page-check-personnage-content">
                  <h3 className="title-choisir-personnage mt-4 pb-3">
                    Quel est le personnage qui te correspond le plus ?
                  </h3>
                  <p className="text-title-choisir-personnage">
                    Coche le personnage avec lequel tu as le plus de points en
                    commun :
                  </p>
                  <div>
                    <div className="row mt-5 py-4 form">
                      {personnages?.map((p) => (
                        <div
                          className="col-lg-3 col-choisir-personnage m-b-30"
                          key={p?.id}
                        >
                          <label
                            className="label-choisir-personnage"
                            htmlFor={p?.name}
                          >
                            <input
                              type="radio"
                              onChange={(e) => {
                                setPersonnage(parseInt(e?.target?.value));
                                setValue("personnage_id", e?.target?.value);
                              }}
                              value={p?.id}
                              id={p?.name}
                              checked={personnage === p?.id}
                              className="input-check-personnage"
                            />
                            <div className="span-check-personnage">
                              <div className="content-img-check-personnage">
                                <img
                                  src={baseUrl + p?.image}
                                  alt={p?.name}
                                  className="img-check-personnage"
                                />
                              </div>
                              <p className="name-personnage-check py-3">
                                {p?.name}
                              </p>
                              <p className="poste-personnage-check">
                                {p?.fonction}
                              </p>
                            </div>
                          </label>
                        </div>
                      ))}
                      <div className="col-md-12">
                        {!!formState?.errors?.personnage_id && (
                          <div className="alert-danger p-3">
                            {formState?.errors?.personnage_id?.message}
                          </div>
                        )}
                      </div>
                      {/* <div className="col-lg-3 col-choisir-personnage">
                        <label className="label-choisir-personnage">
                          <input
                            type="radio"
                            name="CloverCode"
                            className="input-check-personnage"
                          />
                          <div className="span-check-personnage">
                            <div className="content-img-check-personnage">
                              <img
                                src="/img/personnages/moussa.svg"
                                alt="Personnage 2"
                                className="img-check-personnage"
                              />
                            </div>
                            <p className="name-personnage-check py-3">MOUSSA</p>
                            <p className="poste-personnage-check">
                              Étudiant en droit à I'UNCHK
                            </p>
                          </div>
                        </label>
                      </div>
                      <div className="col-lg-3 col-choisir-personnage">
                        <label className="label-choisir-personnage">
                          <input
                            type="radio"
                            name="CloverCode"
                            className="input-check-personnage"
                          />
                          <div className="span-check-personnage">
                            <div className="content-img-check-personnage">
                              <img
                                src="/img/personnages/fatou.svg"
                                alt="Personnage 3"
                                className="img-check-personnage"
                              />
                            </div>
                            <p className="name-personnage-check py-3">FATOU</p>
                            <p className="poste-personnage-check">
                              Étudiante en couture et entrepreneuse
                            </p>
                          </div>
                        </label>
                      </div>
                      <div className="col-lg-3 col-choisir-personnage">
                        <label className="label-choisir-personnage">
                          <input
                            type="radio"
                            name="CloverCode"
                            className="input-check-personnage"
                          />
                          <div className="span-check-personnage">
                            <div className="content-img-check-personnage">
                              <img
                                src="/img/personnages/aicha.svg"
                                alt="Personnage 4"
                                className="img-check-personnage"
                              />
                            </div>
                            <p className="name-personnage-check py-3">AICHA</p>
                            <p className="poste-personnage-check">
                              Diplômée à la recherche d'opportunités
                            </p>
                          </div>
                        </label>
                      </div> */}
                      <div class="select"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="container-btn-theme-presenttion-page"
                style={{ display: "flex", justifyContent: "flex-end", gap: 15 }}
              >
                <NavLink
                  to={{
                    pathname: `/presention-personnages/${item?.id}`,
                    state: item,
                  }}
                  className="btn btn-theme-outline"
                >
                  Précédent
                </NavLink>
                <button
                  type="submit"
                  className="btn btn-theme"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      Chargement... &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </>
                  ) : (
                    "Suivant"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};

export default ChoisirPersonnages;
