import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { toast } from "react-toastify";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import MessageAllPage from "./components/MessageAllPage";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import NavbarVerticalAdmin from "./components/NavbarVertical";
// import ModalDetailsApprenant from "./components/ModalDetailsApprenant";
import axios from "axios";
import $ from "jquery";
import {
  BootstrapTable,
  ButtonGroup,
  ClearSearchButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import baseUrl from "../http/backend-base-url";
import api from "./../http/global-vars";
import CertificatSkeleton from "./AdminSkeletons/CertificatSkeleton";
import BarChart from "./components/BarChart";
import CertificationDoughnutChart from "./components/CertificationDoughnutChart";
import ModalCertificat from "./components/ModalCertificat";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
// import { fr } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CustomPagination } from "../modules/Pagination/CustomPagination";

registerLocale("fr", fr);
export default class Certificats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      apprenants: [],
      nbreHommes: 0,
      nbreFemmes: 0,
      ageMoyenne: 0,
      isPageLoading: true,
      apprenantInfos: {},
      apprenantsCertif: [],
      data: {},
      list_student: [],
      students: [],
      load: true,
      isErrorRegenerate: false,
      isSuccessRegenerate: false,
      startDate: "",
      endDate: "",
      dateDebut: "",
      dateFin: "",
      count: 0,
      percent_certif: 0,
      percent_noCertif: 0,
      page: 1,
      limit: 10,
      q: "",
    };
  }

  componentDidMount() {
    let { page, limit, q } = this.state;
    $(".react-bs-table-search-form input.form-control").attr(
      "placeholder",
      "Chercher"
    );
    // this.onGetApprenantsInfos();
    this._getInfoPerf(page, limit, q);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   prevState?.page !== this.state.page ||
    //   prevState?.limit !== this.state.limit
    // ) {
    //   this.setState({ load: true });
    // }
    if (
      prevState?.page !== this.state.page ||
      prevState?.limit !== this.state.limit ||
      prevState?.q !== this.state.q
    ) {
      this._getInfoPerf(this.state.page, this.state.limit, this.state.q);
    }
  }

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  _getInfoPerf = async (page, limit, q) => {
    let { api, requestConfig } = this.state;
    let url =
      api + `apprenants_info_performance?page=${page}&limit=${limit}&q=${q}`;
    // this.setState({ isPageLoading: true, load: true });
    try {
      const req = await axios.get(url, requestConfig);
      // console.log("new resp=>", req["data"]?.data?.list_student);
      this.setState({
        students: req["data"]?.results,
        count: req["data"]?.count,
        isPageLoading: false,
        load: false,
        percent_certif: parseFloat(req["data"]?.percentage_end_module).toFixed(
          2
        ),
        percent_noCertif: parseFloat(
          req["data"]?.percentage_start_module
        ).toFixed(2),
      });
    } catch (error) {
      console.log("err=>", error.message);
      this.setState({ isPageLoading: false, load: false });
    }
  };

  fullNameFormatter = (cell, row) => {
    return (
      <>
        <span
          data-toggle="modal"
          data-target={".modalDetailsApprenant" + row.id}
          onClick={(e) => this.getRowApprenantInfos(row)}
          className="apprenant_fullname linkable"
        >
          {" "}
          {row.first_name + " " + row.last_name}{" "}
        </span>
        <div
          className={"modal fade modalDetailsApprenant" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content card-dashboard p-b-100">
              <ModalCertificat
                apprenantInfos={this.state.apprenantInfos}
                id={this.state?.apprenantInfos?.id}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  getRowApprenantInfos = (row) => {
    this.setState({
      apprenantInfos: row,
    });
  };

  onExportCertificate = async () => {
    await window.open(`${api}export_certifies`, "_blank");
  };

  onExportByModuleCertificate = async (startDate, endDate) => {
    if (startDate && endDate) {
      // console.log(startDate, endDate);
      await window.open(
        `${api}exportcertificatmoduledaterange/?date_debut=${moment(
          startDate
        ).format("DD-MM-YYYY")}&date_fin=${moment(endDate).format(
          "DD-MM-YYYY"
        )}`,
        "_blank"
      );

      setTimeout(() => {
        this.setState({ dateDebut: "", dateFin: "" });
      }, 2000);
    }
  };

  createCustomButtonGroup = (props) => {
    return (
      <div className="btn-group btn-group-md export-btn ">
        <button
          className="btn btn-success react-bs-table-csv-btn  hidden-print px-1"
          onClick={() => this.onExportCertificate()}
        >
          <FeatherIcon
            className="icon-btn-add-dashboard-orange fs-15 fw-400 "
            icon="download"
          />
          Export excel
        </button>
      </div>
    );
  };

  onSendCertificat = (apprenant) => {
    if (window.confirm("Confirmez l'action")) {
      // console.log(apprenant);

      // console.log("array", [...this.state.itemToActive, ...array]);
      var api = this.state.api;
      var config = this.state.requestConfig;
      var apprenantID = apprenant.id;
      $(".spinnable" + apprenant.id).html(
        '<span className="fas fa-spin fa-spinner"></span>'
      );

      axios
        .get(`${api}sendcertificat/${apprenantID}/`, config)
        .then((response) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          if (response.status === 200 || response.status === 201) {
            toast.success("Le certificat de l'apprenant a été bien envoyé.");
            console.log("response", response);
            this.setState({
              isSuccessRegenerate: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessRegenerate: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          console.log(error);
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error(
              "Une erreur est survnue. Veuillez réessayer plus tard."
            );
          }

          this.setState({
            isErrorRegenerate: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorRegenerate: false,
            });
          }, 5000);
        });
    }
  };

  onActionFormatter = (cell, row) => {
    return (
      <div className="actions-buttons-container d-flex justify-content-between">
        <button
          className={
            "btn btn-primary mr-1 gfa-btn-action-primary spinnable " + row.id
          }
          data-toggle="modal"
          title="Envoyer le certificat"
          onClick={() => this.onSendCertificat(row)}
        >
          <FeatherIcon
            icon="send"
            className="regenerate-apprenant-password-icon"
          />
        </button>
      </div>
    );
  };

  certificatFormatter = (cell, row) => {
    return baseUrl + "/uploads/" + cell;
  };

  renderPaginationPanel = (props) => {
    const { page, limit, count } = this.state;
    return (
      <div class="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={count}
          onChange={(page, perPage) => {
            window.scrollTo(200, 200);
            this.setState({ page: page, limit: perPage });
          }}
        />
      </div>
    );
  };

  renderCustomSearchPanel = (props) => {
    return (
      <div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
        <input
          type="text"
          className="form-control"
          placeholder="Recherche"
          onChange={(e) => this.setState({ q: e.target.value })}
        />
        <span className="input-group-btn">
          <button
            className="btn btn-warning my-custom-class react-bs-table-search-clear-btn"
            type="button"
            onClick={(e) => this.setState({ q: "" })}
          >
            <span>Effacer</span>
          </button>
        </span>
      </div>
    );
  };

  render() {
    let { count, students, load, percent_certif, percent_noCertif, limit } =
      this.state;

    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: "Aucune donnée disponible",
      btnGroup: this.createCustomButtonGroup,
      paginationPanel: this.renderPaginationPanel,
      sizePerPage: limit,
      searchPanel: this.renderCustomSearchPanel,
    };

    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobileAdmin />
          </div>
        </div>

        <HeaderDashboardAdmin />

        <div className="row">
          {/* Navbar Desktop  */}
          {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
          <div className="nav-container">
            <div
              className="navbar-vertical-customisation no-view-mobile"
              style={{ top: "40px" }}
            >
              <NavbarVerticalAdmin />
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-12"></div>

          <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
            <div className="container-fluid">
              <div className="row m-b-30">
                <div className="col-xl-6">
                  <h5 className="text-black f-f-work fw-500 m-b-15">
                    Certificat
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-5 d-flex">
                  <div className="card-dashboard w-full m-b-15 p-b-20 p-t-50">
                    <CertificationDoughnutChart
                      load={load}
                      percent_certif={percent_certif}
                      percent_noCertif={percent_noCertif}
                    />
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-7">
                  <div className="card-dashboard w-full m-b-15 p-b-20 p-t-50">
                    <BarChart load={load} />
                  </div>
                </div>
              </div>

              <div className="card-dashboard p-l-0 p-r-0">
                <div className="col">
                  <div className="row m-b-50">
                    <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                      <p className="titre-card-dashboard-sans-padding m-b-15">
                        Liste des apprenants certifiés{" "}
                        <span className="certified-count">
                          {" "}
                          {`( ${count} )`}{" "}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                  {this.state.load && <CertificatSkeleton />}
                  {!this.state.load && (
                    <div className="table-container">
                      <BootstrapTable
                        data={students}
                        striped={true}
                        hover={true}
                        condensed={true}
                        multiColumnSort={2}
                        options={options}
                        exportCSV
                        pagination
                        search={true}
                        version="4"
                      >
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          dataField="id"
                          isKey={true}
                          hidden={true}
                        >
                          Numero
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          // filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          dataField="first_name"
                          dataFormat={this.fullNameFormatter.bind(this)}
                        >
                          Premon & Nom
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          // filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          dataField="sexe"
                        >
                          Civilité
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          // filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          dataField="phone"
                        >
                          Téléphone
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          // filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          width="200"
                          dataField="certification"
                        >
                          Certification
                        </TableHeaderColumn> */}
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          // filter={{ type: "TextFilter", delay: 1000 }}
                          dataSort={true}
                          width="300"
                          dataField="email"
                        >
                          Adresse e-mail
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          dataSort={true}
                          width="300"
                          dataField="certificat_url"
                          dataFormat={this.certificatFormatter}
                          csvFormat={this.certificatFormatter}
                          hidden
                          export={true}
                        >
                          Certificat
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{
                            fontWeight: 600,
                            fontSize: 16,
                            color: "#1F497D",
                            border: "none",
                          }}
                          tdStyle={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: "rgba(0, 0, 0, 0.7)",
                            border: "none",
                          }}
                          width="100"
                          dataField="id"
                          dataFormat={this.onActionFormatter}
                          export={false}
                        >
                          Action
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>
      </div>
    );
  }
}

const style = {
  card: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "20px 25px 35px 20px",
    maxheight: "100%",
  },
};
