import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import api from './../../http/global-vars'
import axios from 'axios'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default class LineChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            userID: window.localStorage.getItem('userID'),
            dayData: [],
            certificatCount: [],
            apprenantCounting: [],
            choiceDate: new Date(),
            testDate: new Date(),
            statData: [],
            myData: [],
            optionChoice: this.props?.optionChoice
        }
    }

    async componentDidMount() {
        // this.onGetStatistiques()
    }

    // componentWillUnmount() {
    //     console.log("je suis la line chart")
    //     this.onGetStatistiques()
    // }

    componentWillReceiveProps(nextProps) {
        if (this.props?.data !== nextProps?.data) {
            // console.log("OKKK")
            console.log("next props choice=>", nextProps?.optionChoice
            );
            let data = nextProps?.data?.stat;
            this.setState({
                statData: data,
                optionChoice: nextProps?.optionChoice
            }, () => {
                this._getDataFromProps(this.state.statData)
            });
        }

    }


    _getDataFromProps = (data) => {
        var testTab = [];
        var apprenantCounting = []
        var certificatCount = []
        var dayData = []
        if (Array.isArray(data) && data.length) {
            data.forEach(item => {
                let index = dayData.findIndex((f) => f === item?.day);
                let index2 = testTab.findIndex((f) => f === item?.nbr_apprenant);
                if (index === -1) {
                    dayData.push(item.day)
                    apprenantCounting.push(item.nbr_apprenant)
                    certificatCount.push(item.nbr_certification)
                } else return

                if (index2 === -1) {
                    testTab.push(item.nbr_apprenant)
                } else return

            })
        }
        // console.log("my method=>", apprenantCounting)
        this.setState({
            dayData: dayData,
            apprenantCounting: apprenantCounting,
            certificatCount: certificatCount
        })
        // console.log("my method=>", apprenantCounting)

    }


    _getGrapheByDate = async (date) => {
        const { api, requestConfig } = this.state;
        const url = `${api}graphbydate/`;
        try {
            const request = await axios.post(url, {
                date: moment(date).format('DD/MM/YYYY')
            }, requestConfig);
            console.log("request=>", request['data'].data);
            if (this.state.optionChoice === this.props?.optionChoice) {
                let data = request['data'].data[this.state.optionChoice];
                this._getDataFromProps(data);
            }
            // this._getDataFromProps();
        } catch (error) {
            console.log(error.message)
        }
    }


    _onDateChange = (date) => {
        // console.log("on change test", moment(event).format('DD/MM/YYYY'))
        // const { name, value } = event.target;
        // console.log("stat data=>", this.state.optionChoice)
        this.setState({
            choiceDate: date,
            testDate: date
        }, () => {
            this._getGrapheByDate(this.state.choiceDate)
        })
    }

    onGetStatistiques = async () => {
        var api = this.state.api
        var config = this.state.requestConfig

        axios.get(api + 'dashboard_info/', config)
            .then(response => {
                // console.log("response=>", response)
                this.setState({
                    statData: response.data.data.stat,
                    isPageLoading: false
                })
                var dayData = this.state.dayData
                var apprenantCounting = this.state.apprenantCounting
                var certificatCount = this.state.certificatCount
                this.state.statData.forEach(item => {
                    // console.log("item=>", item)
                    dayData.push(item.day)
                    apprenantCounting.push(item.nbr_apprenant)
                    certificatCount.push(item.nbr_certification)
                })

                // console.log("day data==>", dayData)

                this.setState({
                    dayData: dayData,
                    apprenantCounting: apprenantCounting,
                    certificatCount: certificatCount
                })
            })
            .catch(error => {
                console.log(error.message)
            })
    }



    render() {
        // console.log("day", this.state?.dayData.map((f, index) => moment(f).format('DD-MM-YYYY')))
        // console.log("apprenants et certififant", this.state.apprenantCounting, this.state.certificatCount)
        let data = {
            labels: this.state.dayData.map((f, index) => moment(f).format('DD-MM-YYYY')),
            datasets: [{
                type: 'line',
                label: 'Apprenants',
                data: this.state.apprenantCounting,
                fill: false,
                backgroundColor: '#1F497D',
                borderColor: '#1F497D',
                hoverBackgroundColor: '#71B37C',
                hoverBorderColor: '#71B37C',
                yAxisID: 'y-axis-1'
            }
                // , {
                //     type: 'line',
                //     label: 'Certification',
                //     data: this.state.certificatCount,
                //     fill: false,
                //     backgroundColor: '#FFB946',
                //     borderColor: '#FFB946',
                //     hoverBackgroundColor: '#71B37C',
                //     hoverBorderColor: '#71B37C',
                //     yAxisID: 'y-axis-1'
                // }
            ]
        };

        let options = {
            responsive: true,
            labels: this.state.dayData.map((f, index) => moment(f).format('DD-MM-YYYY')),
            tooltips: {
                mode: 'label'
            },
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    gridLines: {
                        display: false
                    },
                    labels: this.state.dayData.map((f, index) => moment(f).format('DD-MM-YYYY')),
                }],
                yAxes: [{
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        display: false
                    },
                    labels: {
                        show: true
                    }
                },
                {
                    type: 'linear',
                    display: false,
                    position: 'right',
                    id: 'y-axis-2',
                    gridLines: {
                        display: false
                    },
                    labels: {
                        show: true
                    }
                }
                ]
            }
        };

        let plugins = [{
            afterDraw: (chartInstance, easing) => {
                //const ctx = chartInstance.chart.ctx;
                //ctx.fillText("This text drawn by a plugin", 100, 100);
            }
        }];
        // const renderChoiceDate = () => this.state.dayData.map((d, index) => {
        //     return (
        //         <option key={index} value={d?.day} id={index}>{moment(d).format('DD/MM/YYYY')}</option>               
        //     )
        // })
        const renderCalendar = () => {
            const ExampleCustomInput = ({ value, onClick }) => (
                <button style={style_Css.inputPicker} onClick={onClick}>
                    {value}
                </button>
            );
            return (
                <div style={style_Css.datePicker} className="form-select-line-chart ml-md-auto" >
                    <DatePicker selected={this.state.choiceDate}
                        onChange={(e) => this._onDateChange(e)}
                        customInput={<ExampleCustomInput />} />
                </div>
            )
        }
        return (
            <div className="chart-item-container">
                <div className="row mb-2">
                    <div className="col-md-6 mb-2">
                        <h2 className="chart-item-title">Chiffre du jour - <span className="fw-300"> Nombre d'apprenants par jour </span> </h2>
                    </div>
                    <div className="col-xl-4 offset-xl-1 col-md-5 offset-md-1 col-sm-6 mb-2 dis-flex">
                        {renderCalendar()}
                        {/* <select className="form-select form-select-line-chart ml-md-auto" aria-label="Default select example" onChange={(e) => this._onDateChange(e)} name='choiceDate'>
                            <option selected>Semaine du 30/11/2020</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                            {renderChoiceDate()}                            
                        </select> */}
                    </div>
                </div>
                <Bar
                    data={data}
                    options={options}
                    plugins={plugins}
                />
            </div>
        );
    }
}



const style_Css = {
    datePicker: {
        backgroundColor: '#FFFFFF',
        boxShadow: ' 0px 5px 25px rgba(0, 0, 0, 0.1)',
        borderRadius: '100px',
        fontFamily: 'Poppins sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: ' 0.4px',
        color: '#252733',
        padding: ' 0.5rem 3rem 0.5rem 1rem',
        border: '1px solid transparent',
        width: 'auto',
        appearance: 'none',
    },
    inputPicker: {
        cursor: 'pointer',
        padding: '5px 15px',
        border: 0,
        borderRadius: ' 4px',
        backgroundColor: '#fff',
        font: 'inherit',
        color: 'black',
    }
}
