import React, { Component } from 'react';
import HeaderDashboard from './HeaderDashboard';
import NavbarDashboardMobile from './NavbarDashboardMobile';
import api from './../../http/global-vars'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery'
import FeatherIcon from 'feather-icons-react';
import MessageAllPage from '../../backend/components/MessageAllPage';
import { BsEmojiFrown, BsEmojiSmile } from "react-icons/bs"
import { withRouter } from 'react-router-dom';


class FicheEvaluationV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      moduleId: this.props.match.params.id,
      userID: window.localStorage.getItem("userID"),
      userFirstName: window.localStorage.getItem("userFirstName"),
      userLastName: window.localStorage.getItem("userLastName"),
      fields: {},
      errors: {},
      sendInformationInProgress: false,
      isSuccessSendingInfos: false,
      isErrorSendingInfos: false,
      openAutreMetier: false,
      is_empty_agr: false,
      is_empty_attente: false,
      is_empty_design_quality: false,
      is_empty_easy_to_use: false,
      is_empty_example_project: false,
      is_empty_exercise_clarity: false,
      is_empty_formation_clarity: false,
      is_empty_instruction_clarity: false,
      is_empty_platform_access: false,
      is_empty_project_future: false,
      is_empty_support_quality: false,
      module: {},
      userModules:[]
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmitEvaluation = this.onSubmitEvaluation.bind(this);
  }

  componentDidMount() {
    console.log("moduleId", this.state.moduleId);
    this.userDoModule()
    this.getModuleById();
  }

  userDoModule = () => {
    const {userID,requestConfig,moduleId} = this.state
    const url = api + "usermodules/" + parseInt(userID) + "/";
    axios
      .get(url, requestConfig)
      .then((data) => {
        // setUserModules(data.data.data);
        // this.setState({ usermodules: data.data.data });
        let modules = data?.data?.data;
        let item = modules?.find((el) => el?.module?.id === parseInt(moduleId))
        if (!modules?.length || !item || item?.progression < 100) {
            console.log("non accès!")
            window.location = "/tous-les-modules";
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  };

  getModuleById = async () => {
    const url = `${api}module/${this.state.moduleId}`;
    await axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        //console.log("response", response.data);
        this.setState({
          module: response.data,
        });
      })
      .catch((e) => {
        if (e?.response?.status === 404) {
          window.location = "/tous-les-modules";
        }
        console.log("e", e?.response, e?.status);
      });
  };

  onCloseAndClearAutreMetierInputOther = () => {
    var status = this.state.openAutreMetier;
    if (status === true) {
      this.setState({
        openAutreMetier: false,
      });
    }
  };
  onOpenAutreMetierInput = () => {
    if (this.state.openAutreMetier === false) {
      this.setState({
        openAutreMetier: true,
      });
    } else {
      this.setState({
        openAutreMetier: false,
      });
    }
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields["user"] = this.state.userID;
    fields["module"] = this.state.moduleId;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields,
    });
    // console.log(fields)
  };

  onValidateForm = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["attente"]) {
      isValidForm = false;
      this.setState({
        is_empty_attente: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_attente: false,
        });
      }, 5000);
      errors["attente"] = "Ce champ est obligatoire";
    }
    if (!fields["easy_to_use"]) {
      isValidForm = false;
      this.setState({
        is_empty_easy_to_use: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_easy_to_use: false,
        });
      }, 5000);
      errors["easy_to_use"] = "Ce champ est obligatoire";
    }
    if (!fields["design_quality"]) {
      isValidForm = false;
      this.setState({
        is_empty_design_quality: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_design_quality: false,
        });
      }, 5000);
      errors["design_quality"] = "Ce champ est obligatoire";
    }
    if (!fields["platform_access"]) {
      isValidForm = false;
      this.setState({
        is_empty_platform_access: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_platform_access: false,
        });
      }, 5000);
      errors["platform_access"] = "Ce champ est obligatoire";
    }
    if (!fields["formation_clarity"]) {
      isValidForm = false;
      this.setState({
        is_empty_formation_clarity: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_formation_clarity: false,
        });
      }, 5000);
      errors["formation_clarity"] = "Ce champ est obligatoire";
    }
    if (!fields["exercise_clarity"]) {
      isValidForm = false;
      this.setState({
        is_empty_exercise_clarity: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_exercise_clarity: false,
        });
      }, 5000);
      errors["exercise_clarity"] = "Ce champ est obligatoire";
    }
    // if (!fields["support_quality"]) {
    //   isValidForm = false;
    //   this.setState({
    //     is_empty_support_quality: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_support_quality: false,
    //     });
    //   }, 5000);
    //   errors["support_quality"] = "Ce champ est obligatoire";
    // }
    // if (!fields["instruction_clarity"]) {
    //   isValidForm = false;
    //   this.setState({
    //     is_empty_instruction_clarity: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_instruction_clarity: false,
    //     });
    //   }, 5000);
    //   errors["instruction_clarity"] = "Ce champ est obligatoire";
    // }
    if (!fields["agr"]) {
      isValidForm = false;
      this.setState({
        is_empty_agr: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_agr: false,
        });
      }, 5000);
      errors["agr"] = "Ce champ est obligatoire";
    }
    if (!fields["project_future"]) {
      isValidForm = false;
      this.setState({
        is_empty_project_future: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_project_future: false,
        });
      }, 5000);
      errors["project_future"] = "Ce champ est obligatoire";
    }
    if (!fields["example_project"]) {
      isValidForm = false;
      this.setState({
        is_empty_example_project: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_example_project: false,
        });
      }, 5000);
      errors["example_project"] = "Ce champ est obligatoire";
    }
    console.log(errors, fields);
    this.setState({
      errors,
    });

    return isValidForm;
  };

  onSubmitEvaluation = (e) => {
    e.preventDefault();
    if (this.onValidateForm()) {
      var api = this.state.api;
      var config = this.state.requestConfig;
      var data = this.state.fields;
      this.setState({
        sendInformationInProgress: true,
      });

      axios
        .post(`${api}evaluation/`, data, config)
        .then((response) => {
          this.setState({
            sendInformationInProgress: false,
          });
          if (response.status === 200 || response.status === 201) {
            $("#click-me-btn").click();
          } else {
            console.log(response);
            toast.error(
              "Vos réponses n'ont été envoyées, une erreur est survenue lors de l'envoi. Veillez réessayer plus tard."
            );
            this.setState({
              isErrorSendingInfos: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorSendingInfos: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Vos réponses n'ont été envoyées, une erreur est survenue lors de l'envoi. Veillez réessayer plus tard."
          );
          this.setState({
            sendInformationInProgress: false,
            isErrorSendingInfos: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorSendingInfos: false,
            });
          }, 5000);
        });
    }
  };

  render() {
    let { module } = this.state;
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobile />
          </div>
        </div>

        <HeaderDashboard />

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 p-b-200 p-t-25 content-position-after-header">
            <div className="container-fluid">
              <div className="card-dashboard b-r-0">
                <form
                  id="evaluation-form"
                  onSubmit={(e) => this.onSubmitEvaluation(e)}
                >
                  <div className="row m-b-50">
                    <div className="col-sm-6 m-b-20 dis-flex containt-mobile-center">
                      <img
                        className="logo-fiche-identification"
                        src="/img/Logo_Coop_GIZ 2.png"
                        alt=""
                      />
                    </div>
                    <div className="col-sm-6 m-b-20 dis-flex containt-mobile-center justify-content-end">
                      <img
                        className="logo-fiche-identification"
                        src="/img/gfa.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <h4 className="h-theme-light fw-500 f-f-inter m-b-15">
                    Fiche d’évaluation des formations – GFA/Réussir au Sénégal
                  </h4>
                  <p className="fs-16 f-f-work text-dark fw-400">
                    Toutes nos félicitations !
                  </p>
                  <p className="fs-16 f-f-work text-dark fw-400 m-b-15">
                    Tu as maintenant terminé avec succès le module{" "}
                    <b>{module?.name}</b> sur les Soft Skills après avoir
                    complété les <b>{module?.number_session}</b> sessions
                    proposées. Nous te souhaitons beaucoup de réussite pour la
                    suite !
                  </p>
                  <p className="fs-16 f-f-work text-dark fw-400 m-b-70">
                    Afin de recevoir l’attestation de participation, nous
                    t’invitons à prendre le temps de remplir cette dernière
                    fiche d’évaluation. Ton avis est indispensable à
                    l’amélioration continue de notre formation.
                  </p>

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      1. La formation a-t-elle répondu à vos attentes ?
                    </h5>
                    <div className="container-form-attente-rep mt-4">
                      <div className="custom-control custom-radio m-b-10">
                        <input
                          onChange={this.handleChange}
                          type="radio"
                          className="custom-control-input"
                          id="checkbox1Q1"
                          name="attente"
                          value="Oui, en partie"
                        />
                        <label
                          className="custom-control-label custom-radio-fiche-identification"
                          htmlFor="checkbox1Q1"
                        >
                          Oui, en partie
                        </label>
                      </div>
                      <div className="custom-control custom-radio m-b-10">
                        <input
                          onChange={this.handleChange}
                          type="radio"
                          className="custom-control-input"
                          id="checkbox2Q1"
                          name="attente"
                          value="Non, pas vraiment"
                        />
                        <label
                          className="custom-control-label custom-radio-fiche-identification"
                          htmlFor="checkbox2Q1"
                        >
                          Non, pas vraiment{" "}
                        </label>
                      </div>
                      <div className="custom-control custom-radio m-b-10">
                        <input
                          onChange={this.handleChange}
                          type="radio"
                          className="custom-control-input"
                          id="checkbox3Q1"
                          name="attente"
                          value="Non, pas du tout"
                        />
                        <label
                          className="custom-control-label custom-radio-fiche-identification"
                          htmlFor="checkbox3Q1"
                        >
                          Non, pas du tout
                        </label>
                      </div>
                      <div className="custom-control custom-radio m-b-10">
                        <input
                          onChange={this.handleChange}
                          type="radio"
                          className="custom-control-input"
                          id="checkbox4Q1"
                          name="attente"
                          value="Oui, tout à fait"
                        />
                        <label
                          className="custom-control-label custom-radio-fiche-identification"
                          htmlFor="checkbox4Q1"
                        >
                          Oui, tout à fait
                        </label>
                      </div>
                    </div>
                    {this.state.is_empty_attente && (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.attente}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="m-b-70">
                    <h5 className="fw-700 f-f-poppins text-dark m-b-20">
                      2. Comment évalues-tu la plateforme www.defaru.sn ?
                    </h5>
                    <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                      <table className="table table-bordered b-t-0">
                        <caption className="caption-table">
                          1 = insatisfaisant, 2 = peu satisfaisant, 3 =
                          satisfaisant, 4 = très satisfaisant
                        </caption>
                        <thead className="head-table-evaluation">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">
                              1
                              <div className="content-emoji-table d-flex">
                                <BsEmojiFrown />
                                <BsEmojiFrown />
                              </div>
                            </th>
                            <th scope="col">
                              2
                              <div className="content-emoji-table">
                                <BsEmojiFrown />
                              </div>
                            </th>
                            <th scope="col">
                              3
                              <div className="content-emoji-table">
                                <BsEmojiSmile />
                              </div>
                            </th>
                            <th scope="col">
                              4
                              <div className="content-emoji-table d-flex">
                                <BsEmojiSmile />
                                <BsEmojiSmile />
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="body-table-evaluation">
                          <tr>
                            <td>
                              <div className="form-label-title">
                                Facilité d’utilisation de la plateforme et
                                navigation du module
                              </div>
                              {this.state.is_empty_easy_to_use && (
                                <div className="row">
                                  <div className="col">
                                    <div className="alert alert-danger gfa-danger">
                                      {this.state.errors.easy_to_use}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox1X1"
                                  name="easy_to_use"
                                  value="1"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox1X1"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox1X2"
                                  name="easy_to_use"
                                  value="2"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox1X2"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox1X3"
                                  name="easy_to_use"
                                  value="2"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox1X3"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox1X4"
                                  name="easy_to_use"
                                  value="2"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox1X4"
                                ></label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-label-title">
                                Qualité du design graphique du module
                              </div>
                              {this.state.is_empty_design_quality && (
                                <div className="row">
                                  <div className="col">
                                    <div className="alert alert-danger gfa-danger">
                                      {this.state.errors.design_quality}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox2X1"
                                  name="design_quality"
                                  value="1"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox2X1"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox2X2"
                                  name="design_quality"
                                  value="2"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox2X2"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox2X3"
                                  name="design_quality"
                                  value="3"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox2X3"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox2X4"
                                  name="design_quality"
                                  value="4"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox2X4"
                                ></label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-label-title">
                                Accessibilité de la plateforme et du module
                              </div>
                              {this.state.is_empty_platform_access && (
                                <div className="row">
                                  <div className="col">
                                    <div className="alert alert-danger gfa-danger">
                                      {this.state.errors.platform_access}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox3X1"
                                  name="platform_access"
                                  value="1"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox3X1"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox3X2"
                                  name="platform_access"
                                  value="2"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox3X2"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox3X3"
                                  name="platform_access"
                                  value="3"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox3X3"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox3X4"
                                  name="platform_access"
                                  value="4"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox3X4"
                                ></label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="m-b-70">
                    <h5 className="fw-700 f-f-poppins text-dark m-b-20">
                      3. Comment évalues-tu le module dont tu as bénéficié ?
                    </h5>
                    <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                      <table className="table table-bordered b-t-0">
                        <caption className="caption-table">
                          1 = insatisfaisant, 2 = peu satisfaisant, 3 =
                          satisfaisant, 4 = très satisfaisant
                        </caption>
                        <thead className="head-table-evaluation">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">
                              1
                              <div className="content-emoji-table d-flex">
                                <BsEmojiFrown />
                                <BsEmojiFrown />
                              </div>
                            </th>
                            <th scope="col">
                              2
                              <div className="content-emoji-table">
                                <BsEmojiFrown />
                              </div>
                            </th>
                            <th scope="col">
                              3
                              <div className="content-emoji-table">
                                <BsEmojiSmile />
                              </div>
                            </th>
                            <th scope="col">
                              4
                              <div className="content-emoji-table d-flex">
                                <BsEmojiSmile />
                                <BsEmojiSmile />
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="body-table-evaluation">
                          <tr>
                            <td>
                              <div className="form-label-title">
                                La clarté du contenu du module
                              </div>
                              {this.state.is_empty_formation_clarity && (
                                <div className="row">
                                  <div className="col">
                                    <div className="alert alert-danger gfa-danger">
                                      {this.state.errors.formation_clarity}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox4X1"
                                  name="formation_clarity"
                                  value="1"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox4X1"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox4X2"
                                  name="formation_clarity"
                                  value="2"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox4X2"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox4X3"
                                  name="formation_clarity"
                                  value="3"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox4X3"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox4X4"
                                  name="formation_clarity"
                                  value="4"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox4X4"
                                ></label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="form-label-title">
                                La clarté du contenu pratique du module
                                (exercices, études de cas, tests et quiz)
                              </div>
                              {this.state.is_empty_exercise_clarity && (
                                <div className="row">
                                  <div className="col">
                                    <div className="alert alert-danger gfa-danger">
                                      {this.state.errors.exercise_clarity}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox5X1"
                                  name="exercise_clarity"
                                  value="1"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox5X1"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox5X2"
                                  name="exercise_clarity"
                                  value="2"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox5X2"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox5X3"
                                  name="exercise_clarity"
                                  value="3"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox5X3"
                                ></label>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="custom-control custom-radio p-l-35">
                                <input
                                  onChange={this.handleChange}
                                  type="radio"
                                  className="custom-control-input"
                                  id="checkbox5X4"
                                  name="exercise_clarity"
                                  value="4"
                                />
                                <label
                                  className="custom-control-label custom-radio-fiche-identification"
                                  htmlFor="checkbox5X4"
                                ></label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      4. Penses-tu que ce module de formation t’ait assuré des
                      possibilités d’insertion professionnelle ou de création
                      d’activité génératrice de revenu (AGR) ?{" "}
                    </h5>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        onChange={this.handleChange}
                        type="radio"
                        className="custom-control-input"
                        id="checkbox1Q4"
                        name="agr"
                        value="oui"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="checkbox1Q4"
                      >
                        Oui
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        onChange={this.handleChange}
                        type="radio"
                        className="custom-control-input"
                        id="checkbox2Q4"
                        name="agr"
                        value="non"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="checkbox2Q4"
                      >
                        Non
                      </label>
                    </div>
                    {this.state.is_empty_agr && (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.agr}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="m-b-45">
                    <h5 className="fw-700 f-f-poppins text-dark">
                      5. Quels sont tes projets concrets d’avenir au Sénégal ?
                    </h5>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        onChange={this.handleChange}
                        onClick={() =>
                          this.onCloseAndClearAutreMetierInputOther()
                        }
                        type="radio"
                        className="custom-control-input"
                        id="radioQ2Metier1"
                        name="project_future"
                        value="création d’entreprise dans votre domaine de formation"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ2Metier1"
                      >
                        création d’entreprise dans votre domaine de formation
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        onChange={this.handleChange}
                        onClick={() =>
                          this.onCloseAndClearAutreMetierInputOther()
                        }
                        type="radio"
                        className="custom-control-input"
                        id="radioQ2Metier2"
                        name="project_future"
                        value="avoir un emploi salarié"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ2Metier2"
                      >
                        avoir un emploi salarié
                      </label>
                    </div>
                    <div className="custom-control custom-radio m-b-10">
                      <input
                        onChange={this.handleChange}
                        onClick={() => this.onOpenAutreMetierInput()}
                        type="radio"
                        className="custom-control-input"
                        id="radioQ2Metier5"
                        name="project_future"
                        value="Autre"
                      />
                      <label
                        className="custom-control-label custom-radio-fiche-identification"
                        htmlFor="radioQ2Metier5"
                      >
                        Autres
                      </label>
                    </div>

                    {this.state.openAutreMetier && (
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-dashboard-backend"
                              placeholder="Autre métier ..."
                              name="project_future"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.is_empty_project_future && (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.project_future}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="m-b-10">
                    <p className="f-f-poppins fs-16 text-dark fw-400">
                      Donne quelques exemples concrets :
                    </p>
                    <textarea
                      onChange={this.handleChange}
                      className="form-control text-area-redaction-evaluation"
                      placeholder="......................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................"
                      rows="5"
                      name="example_project"
                    />
                    {this.state.is_empty_example_project && (
                      <div className="row">
                        <div className="col">
                          <div className="alert alert-danger gfa-danger">
                            {this.state.errors.example_project}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row dis-flex justify-content-end m-t-60">
                    <div className="col-xl-3 col-lg-3 col-md-4 m-b-20">
                      {!this.state.sendInformationInProgress ? (
                        <button
                          type="submit"
                          className="btn-theme text-up w-full"
                        >
                          Envoyer
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn-theme text-up w-full in-progress"
                        >
                          Envoi en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <MessageAllPage fullWidth={true} />
        </div>

        <button
          style={{ display: "none" }}
          className="btn btn-success"
          id="click-me-btn"
          data-toggle="modal"
          data-target="#success-evaluation-modal"
        >
          Ouvrir le modal
        </button>

        <div
          className="modal fade"
          id="success-evaluation-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="mb-3 animat rotateIn"
                  />
                  <h4 className="pop-title fw-600">Félicitation !!!</h4>
                </div>
                <div className="text-center felicitation-textuel">
                  <p>
                    Vos réponses ont été bien envoyées et vous avez brillament
                    terminé votre module.
                  </p>
                  <p className="last_ending_words">
                    Votre certificat de participation est maintenant disponible.
                  </p>
                </div>
              </div>
              <div className="modal-footer justify-content-center slider-actions-btn">
                <a
                  className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15 go-to-attestion-link"
                  href="/mon-certificat"
                >
                  VOIR VOTRE ATTESTATION
                </a>
              </div>
            </div>
          </div>
        </div>

        {this.state.isSuccessSendingInfos || this.state.isErrorSendingInfos ? (
          <ToastContainer />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(FicheEvaluationV2)