import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import api from '../../http/global-vars'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import $ from 'jquery'


export default class ContactSupport extends Component {
    constructor(props){
        super(props)
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            userID: window.localStorage.getItem('userID'),
            fields: {},
            errors: {},
            sendMessageInProgress: false,
            isSuccessSendingMessage: false,
            isErrorSendingMessage: false,
            is_empty_objet: false,
            is_empty_email: false,
            is_empty_message: false
        }

        this.handleChangeSendMessage = this.handleChangeSendMessage.bind(this)
        this.onSubmitContactForm = this.onSubmitContactForm.bind(this)
    }

    componentDidMount(){

    }

    handleChangeSendMessage = (e) => {
        let fields = this.state.fields
        fields['user'] = this.state.userID
        fields[e.target.name] = e.target.value

        this.setState({
            fields: fields
        })

        console.log(fields)
    }

    onValidateSendingMessageForm = () => {
        let fields = this.state.fields
        let errors = this.state.errors
        var isValidForm = true

        if(!fields['objet']){
            isValidForm = false
            this.setState({
                is_empty_objet: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_objet: false
                })
            }, 5000);
            errors['objet'] = 'Ce champ est obligatoire'
        }
        if(!fields['message']){
            isValidForm = false
            this.setState({
                is_empty_message: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_message: false
                })
            }, 5000);
            errors['message'] = 'Ce champ est obligatoire'
        }

        this.setState({
            errors: errors
        })

        return isValidForm;
    }

    onSubmitContactForm = (e) => {
        e.preventDefault()

        if(this.onValidateSendingMessageForm()){
            this.setState({
                sendMessageInProgress: true
            })

            var api = this.state.api
            var config = this.state.requestConfig
            var data = this.state.fields

            axios.post(api + 'contact/', data, config)
                .then(response => {
                    this.setState({
                        sendMessageInProgress: false
                    })

                    if(response.status === 201){
                        toast.success('Votre message a été bien envoyé!')
                        $('#objet').val('')
                        $('#message').val('')
                        $('.close').click()
                        this.setState({
                            isSuccessSendingMessage: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isSuccessSendingMessage: false
                            })
                        }, 5000);
                    }else{

                    }
                })
                .catch(error => {
                    console.log(error)
                    this.setState({
                        sendMessageInProgress: false,
                        isErrorSendingMessage: true
                    })
                    setTimeout(() => {
                        this.setState({
                            isErrorSendingMessage: false
                        })
                    }, 5000);
                })
        }
    }
  
    render(){
        return (
            <div>
                <div className="row m-b-35">
                    <div className="col-10 itm-center">
                        <p className="title-modal">Contacter le support</p>
                    </div>
                    <div className="col-2">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><FeatherIcon icon="x" /></span>
                        </button>
                    </div>
                </div>

                <form id="contact-support-form">
                    <div className="form-group">
                        <input id="objet" onChange={ (e) => this.handleChangeSendMessage(e) } name="objet" type="objet" className="form-control input-modal" placeholder="Sujet de votre message" />
                        {this.state.is_empty_objet &&
                            <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.objet } </div>
                        }
                    </div>
                    <div className="form-group">
                        <textarea id="message" onChange={ (e) => this.handleChangeSendMessage(e) } name="message" className="input-modal p-t-15" rows="7" placeholder="Votre message ..." />
                        {this.state.is_empty_message &&
                            <div className="alert alert-danger gfa-alert-danger"> { this.state.errors.message } </div>
                        }
                    </div>
                </form>

                <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                    {this.state.sendMessageInProgress === false ?
                        <button type="submit" onClick={ (e) => this.onSubmitContactForm(e) } className="btn-theme p-l-90 p-r-90" >ENVOYER</button>
                        :
                        <button className="btn-theme p-l-90 p-r-90" >
                            Envoi en cours &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                        </button>
                    }
                </div>

                {this.state.isSuccessSendingMessage || this.state.isErrorSendingMessage ?
                    <ToastContainer /> : ''
                }
            </div>
        );
    }
}
