import { FETCH_LIST_MODULES } from "../Type";

export const moduleReducer = (
  state = {
    data: [],
    isLoading: true,
  },
  action
) => {
  switch (action.type) {
    case FETCH_LIST_MODULES:
      return {
        data: action.payload,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};
