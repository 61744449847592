import axios from "axios";
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import api from "../../http/global-vars";
import { ScoreInput } from "./CreateQcm";
export default class CreateVraiFaux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          "Content-Type": "multipart/form-data",
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      isSuccess: false,
      isError: false,
      isLoading: false,
      is_pretest: false,
      is_testfinal: false,

      quiz: props.quiz || 0,
      question: props.question || 0,
      is_empty_name: false,
      is_empty_url: false,
      contentId: this.props.contentId,
      errors: {},
      responses: [],
      fields: {
        name: "",
        content_type: "yes_no",
        contentId: this.props.contentId,
        created_by: parseInt(window.localStorage.getItem("userID")),
        score: 0,
        question: "",
      },
    };
  }

  componentDidMount() {
    this.initResponses();
   
  }
  initResponses() {
    this.setState({
      responses: [
        { content: "Vrai", checked: false },
        { content: "Faux", checked: false },
      ],
    });
  }
  

  handleInputChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  handleChange = (index, e) => {
    const checked = e.target.value === "true" ? true : false;
    this.setState({
      inputs: this.state.inputs.map((title, i) =>
        i === index ? checked : title
      ),
    });
  };

  checkColonneInput(index, e) {
    let inputs = [...this.state.responses];
    for (let i = 0; i < inputs.length; i++) {
      if (i === index) {
        if (e.target.name === "content") {
          inputs[i].content = e.target.value;
          break;
        }
        if (e.target.name === "checked") {
          console.log('check',e.target.checked)
          inputs[i].checked = e.target.checked;
          break;
        }
      }
    }
    this.setState({ inputs });
  }

  thisFileUpload = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  onValidateForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
        });
      }, 5000);

      errors["name"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  async addResponse(question) {
    for (let i = 0; i < this.state.responses.length; i++) {
      const element = this.state.responses[i];
      const formData = new FormData();
      formData.append("content", element.content);

      formData.append("question", question);
      formData.append("is_right_choice", element.checked);
      try {
        await axios.post(api + "response/", formData, this.state.requestConfig);
      } catch (ex) {}
    }
    this.setState({
      isLoading: false,
      fields: { score: 0 },
    });
    this.initResponses();
    this.props.notify();
  }

  async addQuestion(quiz) {
    if (this.state.question) {
      this.addResponse(this.state.question);
    } else {
      var fdata = new FormData();
      fdata.append("content", this.state.fields.question);
      fdata.append("score", this.state.fields.score || 0);
      fdata.append("quiz", quiz);
      try {
        const resp = await axios.post(
          api + "question/",
          fdata,
          this.state.requestConfig
        );
        this.addResponse(resp.data.id);
      } catch (e) {
        toast.error(
          "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
        );
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  addForm = async (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    if (this.state.quiz) {
      this.addQuestion(this.state.quiz);
    } else {
      if (this.onValidateForm()) {
        var api = this.state.api;
        var fd = new FormData();
        fd.append("title", this.state.fields.name);

        fd.append("content_type", this.state.fields.content_type);
        fd.append("created_by", this.state.fields.created_by);
        fd.append("contenu", this.state.fields.contentId);
        fd.append("is_pretest", this.state.is_pretest);
        fd.append("is_testfinal", this.state.is_testfinal);
        try {
          const response = await axios.post(
            api + "quiz/",
            fd,
            this.state.requestConfig
          );
          this.addQuestion(response.data.id);
        } catch (error) {
          console.log(error.response);
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
          );
          this.setState({
            isLoading: false,
          });
        }
      }
    }
  };

  renderBooldField(name, label) {
    return (
      <div className="col-sm-6 col-md-6 m-b-15">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            name={name}
            id={name}
            checked={!!this.state[name]}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.checked })
            }
            className=""
          />{" "}
          <label className="" htmlFor={name}>
            {label}
          </label>
        </div>
      </div>
    );
  }

  render() {
    const inputs = (
      <div className="row m-b-25">
        <div className="col-md-2 itm-center p-r-0">
          <label className="label-quiz-backend" htmlFor="reponse1">
            Réponse
          </label>
        </div>
        <div className="col-md-10 itm-center">
          <div className="row">
            {this.state.responses.map((text, index) => (
              <div className="col-md-6">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <input
                      type="checkbox"
                      id={`reponse1CheckVrai${index + 1}`}
                      value="true"
                      name="checked"
                      onChange={(e) => this.checkColonneInput(index, e)}
                      checked={text.checked}
                    />
                  </div>
                </div>
                <label
                  style={{
                    display: "inline-block",
                    "margin-left": 5,
                    "font-size": 14,
                    marginBottom: 0,
                  }}
                  htmlFor={`reponse1CheckVrai${index + 1}`}
                >
                  {text.content}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );

    return (
      <div className="card-dashboard no-shadow">
        <form>
          <div className="row m-b-50">
            <div className="col-lg-9">
              <p className="titre-card-create-content">Vrai/Faux</p>
            </div>
          </div>

          {this.state.quiz === 0 && (
            <>
              <div className="form-group m-b-60">
                <input
                  type="text"
                  className="form-control input-dashboard-backend"
                  placeholder="Consigne"
                  name="name"
                  onChange={(e) => this.handleInputChange(e)}
                />
                {this.state.is_empty_name && (
                  <div className="alert alert-danger keysjob-alert-danger">
                    {" "}
                    {this.state.errors.name}{" "}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-12 m-b-30">
                  <div className="row">
                    {this.renderBooldField("is_pretest", "Prétest ?")}
                    {this.renderBooldField("is_testfinal", "Test final ?")}
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.question === 0 && (
            <div className="row m-b-20">
              <div className="col-md-2 itm-center p-r-0">
                <label className="label-quiz-backend" htmlFor="question">
                  Question
                </label>
              </div>
              <div className="col-md-7 itm-center">
                <textarea
                  rows="2"
                  className="form-control form-control-quiz-backend border-theme"
                  placeholder=""
                  id="question"
                  name="question"
                  value={this.state.fields.question}
                  onChange={(e) => this.handleInputChange(e)}
                ></textarea>
              </div>
              <ScoreInput onChange={(e) => this.handleInputChange(e)} />
            </div>
          )}
          {inputs}
          <div className="row m-b-25">
            <div className="col-xl-2 col-lg-2 itm-center p-r-0"></div>
            <div className="col-xl-4 col-lg-4 m-t-10"></div>
          </div>
          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 m-b-20">
              {this.state.isLoading === false ? (
                <button
                  type="submit"
                  onClick={(e) => this.addForm(e)}
                  className="btn-theme text-up w-full"
                >
                  Terminer
                </button>
              ) : (
                <button className="btn-theme text-up w-full in-progress-btn">
                  Ajout en cours... &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
      </div>
    );
  }
}
