import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import api from '../../http/global-vars';
import ReponseSkeleton from '../AdminSkeletons/ReponseSkeleton';
import axios from 'axios';

let order = 'desc';

export default class ReponseQuestionOuvertes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      requestConfig: {
        headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
      },
      isloading: false,
      modules: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      next: '',
      sessions: []
    }
  }

  handleBtnClick = () => {
    if (order === 'desc') {
      this.refs.table.handleSort('asc', 'name');
      order = 'asc';
    } else {
      this.refs.table.handleSort('desc', 'name');
      order = 'desc';
    }
  }

  componentDidMount() {
    this.getModules()
    this.getSessions()
    this.fetchData()
  }

  getModules = () => {
    const url = api + 'module/'
    const { requestConfig } = this.state;
    axios.get(url, requestConfig).then((resp) => {
      //console.log('modules', resp.data.results)
      this.setState({ modules: resp.data.results });
    }).catch((e) => {
      console.log('err', e)
    })
  }

  getSessions = () => {
    const url = api + 'session/'
    const { requestConfig } = this.state;
    axios.get(url, requestConfig).then((resp) => {
      //console.log('sessions', resp.data.results)
      this.setState({ sessions: resp.data.results });
    }).catch((e) => {
      console.log('err', e)
    })
  }

  fetchData = async (page = this.state.page,) => {

    const { requestConfig } = this.state;
    const url = api + 'textfieldresponses/?page=' + page;

    this.setState({ isloading: true });
    axios.get(url, requestConfig).then((response) => {
      //console.log('data', response.data)
      // const res = fetch(url, requestConfig);
      //1,18,22,33,39,160,168,169,170,171,172,173,174,1315,1319
      const data = response.data;
      //console.log('data', data)
      if (data) {
        this.setState({
          allData: data,
          isloading: false,
          totalSize: response.data.count,
          next: response.data.next
        });
      }
    }).catch((e) => {
      console.log('error', e)
    })


  }

  showFullname = (cell, row) => {
    //console.log('row cell', row, cell)
    let fullName = row.first_name + ' ' + row.last_name
    //console.log('fullName', fullName)
    return fullName;
  }

  showGenre = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.genre;
  }

  showSession = (cell, row) => {
    //console.log('row cell', row, cell)
    return this.state.sessions.filter(s => s.id === row.session_id)[0]?.name;
  }

  showModule = (cell, row) => {
    // console.log('row cell', this.state.sessions.filter(s => s.id === row.reponse.question.quiz.contenu.session))
    let moduleId = this.state.sessions.filter(s => s.id === row.session_id)[0]?.module_id;
    return this.state.modules.filter(m => m.id === moduleId)[0]?.name;

  }

  showquestion = (cell, row) => {
    //console.log('row cell', row, cell)
    let question = row.question;

    return question;
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
      </ButtonGroup>
    );
  }



  render() {
    const { isloading, allData } = this.state;
    // console.log("all data=>", allData)
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: 'Aucune donnée disponible',
      btnGroup: this.createCustomButtonGroup,
      exportCSVText: 'Extraction',
    };
    return (
      <div className="item-card-extraction">
        <img className="icon-item-card-extraction" src="img/icons/paper.png" alt="" />
        <p className="text-item-card-extraction">Réponses aux questions ouvertes</p>
        <button type="button" class="btn-item-card-extraction" data-toggle="modal" data-target="#reponseQuestionOuverte">
          voir
        </button>
        <div class="modal fade" id="reponseQuestionOuverte" tabindex="-1" aria-labelledby="reponseQuestionOuverteLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content pt-5 pb-3">
              <div className="px-md-4 px-3">
                <p className="titre-card-dashboard-sans-padding m-b-15">Liste des apprenants ayant répondu aux questions ouvertes <span className="certified-count"> ({allData?.length}) </span> </p>
                <div className="row mt-5">
                  {/*  <div className="col-xl-4 col-lg-6 mb-3">
                    <from>
                      <button className="btn-theme fs-14 fw-400 py-2 px-4">
                        <FeatherIcon className="icon-btn-add-dashboard-orange" icon="download"/>
                        Extraction
                      </button>
                    </from>
                  </div> */}
                  <div className="col-xl-4 col-lg-6 mb-3">
                    {/* <div className="row">
                      <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                        <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                          <option selected>2020</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        </div>
                        <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                          <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                            <option selected>Décembre</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                    </div> */}
                  </div>
                  <div className="col-xl-4 mb-3">

                  </div>
                </div>
              </div>
              {isloading ?
                (
                  <ReponseSkeleton />
                ) :
                (
                  <div className="table-container">
                    <BootstrapTable ref='table'
                      data={allData}
                      striped={true}
                      hover={true}
                      exportCSV
                      options={options}
                      condensed={true}
                      multiColumnSort={2}
                      pagination
                      version='4'
                      bordered={false}>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                        width="80" dataField='N°' isKey={true}>N°</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        width="300" dataField='Fullname' csvFormat={this.showFullname} dataFormat={this.showFullname} dataSort={true}>Prénom & Nom</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        width="130" dataField='Gender' dataFormat={this.showGenre} csvFormat={this.showGenre} dataSort={true}>Genre</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        width="300" dataField='Module' dataFormat={this.showModule} csvFormat={this.showModule} >Module</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        width="300" dataField='Session' dataFormat={this.showSession} csvFormat={this.showSession} >Session</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        width="350" dataField='content' >Questions</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                        width="350" dataField='text' csvHeader='Réponses' >Reponses</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                )}

            </div>
          </div>
        </div>
      </div>
    );
  }

}