import axios from "axios";
import $ from "jquery";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../http/global-vars";

toast.configure();

export default class ModalAddSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      fields: {},
      load: false,
      errors: [],
      module_id: this.props.module_id,
    };
  }

  // componentDidMount() {
  //     console.log(this.props.module_id)
  // }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields: fields });
  };

  hasErrorFor = (field) => {
    return !!this.state.errors[field];
  };

  renderErrorFor = (field) => {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  };

  notify = () => {
    toast.success("Session ajoutée avec succès", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ load: true, errors: {} });
    let { api, fields } = this.state;
    let url = api + "session/";
    let data = fields;
    data.creator_by = localStorage.userID;
    data.module_id = this.state.module_id;
    console.log("data sended ==>", data);

    if (
      this.state.fields.description === undefined ||
      this.state.fields.name === undefined
    ) {
      let msg = "This field is required";
      let errors = {
        description: [msg],
        name: [msg],
      };
      this.setState({ errors: errors, load: false });
      toast.error(
        "Veuillez remplir tous les champs",
        { position: toast.POSITION.TOP_CENTER, autoClose: 5000 },
        () => this.setState({ errors: {} })
      );
    } else {
      try {
        let res = await axios.post(url, data, {
          headers: { Authorization: `Bearer ${localStorage.userToken}` },
        });
        console.log(res);
        if (res.status === 201) {
          $("#fieldreset")[0].reset();
          this.setState({ load: !this.state.load, fields: {} });
          this.notify();
          this.props.getSessions();
          window.$(".modalAddSession").modal("hide");
          // window.location.reload();
          // window.location = `/module-details/${this.props.module_id}`;
        }
      } catch (error) {
        console.log(error.message);
        this.setState({ load: !this.state.load });
        console.log(error.response);
        let err = error.response;
        if (err) {
          // console.log(error.response.data)
          let status = error.response.status;
          let data = error.response.data;
          if (status === 400) {
            let error = {};
            for (let [key, value] of Object.entries(data)) {
              error = {
                [key]: [value],
              };
              this.setState({ errors: error });
            }
            console.log(this.state.errors);
            toast.error("Oupps un probleme de connection!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            });
          }
        }
      }
    }
  };

  render() {
    let { load } = this.state;
    console.log(this.props.module_id);
    return (
      <div>
        <h5 className="text-black m-b-50">Ajouter une session</h5>
        <form onSubmit={this.onSubmit} id="fieldreset">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  className={`form-control input-dashboard-backend text-black ${
                    this.hasErrorFor("name") ? "is-invalid" : ""
                  }`}
                  placeholder="Titre de la session"
                />
                {this.renderErrorFor("name")}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group m-b-40">
                <textarea
                  type="text"
                  name="description"
                  onChange={this.handleChange}
                  className={`form-control input-dashboard-backend text-black b-r-15 p-t-15 p-b-15 ${
                    this.hasErrorFor("description") ? "is-invalid" : ""
                  }`}
                  placeholder="Description"
                  rows="4"
                />
                {this.renderErrorFor("description")}
              </div>
              <div className="row dis-flex justify-content-end">
                <div className="col-lg-6 col-md-12">
                  {!load ? (
                    <button className="btn-submit-form">Ajouter</button>
                  ) : (
                    <button className="btn-submit-form">
                      Chargement... &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
