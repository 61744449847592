import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Container, Draggable } from 'react-smooth-dnd';
import { applyDrag } from "./util";
import api from "../../http/global-vars";
import axios from "axios";

export default class TacheCarteHeuristiqueV2 extends Component {
  constructor(props) {
    super(props);
    const list_words = this.props.heuristique;
    const parts = [];
    const pushRecoursive = (items, item) => {
      const childs = item.list_child.filter((c) => {
        return c?.id !== item.id;
      });
      items.push({
        id: item.id,
        text: item.content,
        parent: item.parent?.id,
        list_child: [],
        childs: childs
          .map((c) => {
            return {
              id: c.id,
              text: c.text,
              child: [],
            };
          })
          .filter((c) => c.id !== item.parent?.id),
      });
    };
    for (let w of list_words) {
      pushRecoursive(parts, w);
    }
    const words = parts.map((p) => {
      const { childs, ...rest } = p;
      return rest;
    });
    const normalize = (items) => {
      let normalized = [];
      let taked = [];
      const getItemsByParent = (id) => {
        return items.filter((f) => f.parent === id);
      };
      const pushTaked = (taked, item) => {
        taked.push(item.id);
        item.childs.map((item) => pushTaked(taked, item));
      };
      for (let item of items) {
        if (!taked.includes(item.id)) {
          let childs = getItemsByParent(item.id).map((c) => {
            const childs = getItemsByParent(c.id);
            return {
              ...c,
              childs,
            };
          });

          let normal = { ...item, childs: childs };
          pushTaked(taked, normal);
          normalized.push(normal);
        }
      }
      return normalized;
    };
    const shuffleArray = (array) => {
      let i = array.length - 1;
      for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    };
    this.state = {
      scene: {
        type: "container",
        props: {
          orientation: "horizontal",
        },
        child: normalize(parts),
        colonne: shuffleArray(words).filter((f) => f.parent !== 0),
        parent: this.props.heuristique.map((item) => {
          return {
            id: item.id,
            text: item.content,
            parent: item.parent,
            child: shuffleArray(item.list_child)
              .map((c) => {
                return {
                  id: c.id,
                  text: c.content,
                  parent: c.parent,
                };
              })
              .filter((f, i) => f.parent !== null && f.id !== item.parent?.id),
          };
        }),
      },
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      module_id: "",
      isDone: false,
      isEmpty: false,
      isActiveSuivant: false
    };
  }

  getCardPayload = (e, columnId, index) => {
    // console.log('teste',this.state.scene.colonne.filter((p) => p.id === columnId), index)
    return this.state.scene.child.filter((p) => p.id === columnId)[0].childs[
      index
    ];
  };

  getWordPayload = (e, columnId, index) => {
    //console.log('test',this.state.scene.parent.filter((p) => p.id === columnId), index)
    return this.state.scene.parent.filter((p) => p.id === columnId)[0].child[
      index
    ];
  };

  getChildPayload = (e, columnId, index, ChildId) => {
    //console.log('test',this.state.scene.parent.filter((p) => p.id === columnId), ChildId)
    return this.state.scene.parent.filter((p) => p.id === columnId)[0].child[
      index
    ];
  };

  onCardDrop = (columnId, dropResult, index) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const scene = Object.assign({}, this.state.scene);
      const column = scene.child.filter((p) => p.id === columnId)[0];
      //console.log("new", column);
      const columnIndex = scene.child.indexOf(column);
      const newColumn = Object.assign({}, column);
      //console.log('index', columnIndex, newColumn)
      newColumn.list_child = applyDrag(newColumn.list_child, dropResult, index);
      scene.child.splice(columnIndex, 1, newColumn);

      this.setState({
        scene,
      });
    }
  };

  onWordDrop = (columnId, dropResult, index) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const scene = Object.assign({}, this.state.scene);
      const column = scene.parent.filter((p) => p.id === columnId)[0];
      //console.log('column', column)
      const columnIndex = scene.parent.indexOf(column);
      const newColumn = Object.assign({}, column);
      newColumn.child = applyDrag(newColumn.child, dropResult, index);
      scene.parent.splice(columnIndex, 1, newColumn);
      //console.log("new word", newColumn, dropResult);

      this.setState({
        scene,
      });
      this.checker();
    }
  };

  onChildDrop = (columnId, dropResult, ChildId, index) => {
    //console.log('child', ChildId, columnId)
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const scene = Object.assign({}, this.state.scene);
      const column = scene.child
        .filter((p) => p.id === ChildId)[0]
        ?.childs.filter((c) => c.id === columnId)[0];
      //console.log("new", column);
      const columnIndex = scene.child
        .filter((p) => p.id === ChildId)[0]
        ?.childs.indexOf(column);
      const newColumn = Object.assign({}, column);
      //console.log("index", columnIndex, newColumn);
      newColumn.list_child = applyDrag(newColumn.list_child, dropResult, index);
      scene.child
        .filter((p) => p.id === ChildId)[0]
        .childs.splice(columnIndex, 1, newColumn);

      this.setState({
        scene,
      });
    }
  };

  sendData = async () => {
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.props.contenu,
    };
    console.log(sendData);
    const url = api + "userdocontenu/";
    await axios
      .post(url, sendData, this.state.requestConfig)
      .then((response) => {
        this.updateProgres();
      })
      .catch((e) => {
        console.log("e post", e.response);
      });
      this.props.onGetUserDoModules();
  };

  ScrollTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    if (this.props.sessionId) {
      this._getModuleBySession();
    }
    console.log('done', this.state.isDone)
    console.log('active', this.state.isActiveSuivant)
  }

  _getModuleBySession = async () => {
    const { api } = this.state;
    if (this.props.sessionId) {
      const url = api + `session/${this.props.sessionId}/`;
      try {
        const res = await axios.get(url, this.state.requestConfig);
        if (res.status === 201 || 200) {
          let data = res["data"];
          this.setState({ module_id: data.module_id });
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  };

  updateProgres = async () => {
    const { api } = this.state;
    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };
    const mod = this.props.usermodules.filter((v) => v?.module?.id === this.state.module_id)
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  loadResult = () => {
    this.setState({ isDone: true, isActiveSuivant: true });
    window.scrollTo(0, 0);
  };

  checker = () => {
    for (let i = 0; i < this.state.scene.parent.length; i++) {
      const element = this.state.scene.parent[i];
      if (element.child.length > 0) {
        this.setState({
          isEmpty: false,
        });
        break;
      }
      this.setState({
        isEmpty: true,
      });
    }
  };

  render() {
    const contenususer = this.props?.usercontenus.filter(
      (v) => v.contenu.id === this.props.contenu
    );

    return (
      <>
        {!this.state.isDone ? (
          <>
            <p className="m-b-35 fw-400 f-f-work text-dark fs-14">
              Glisse les termes dans la bonne colonne.{" "}
            </p>
            <p className="m-b-25 fw-600 f-f-work text-dark fs-16">
              Mots proposés :{" "}
            </p>
            <div className="row gfa-dnd-container">
              {this.state.scene.parent ? (
                <>
                  {this.state.scene?.parent.map((c, i) => {
                    return (
                      <Fragment key={c?.id}>
                        {c.child.map((p, i) => {
                          return (
                            <Container
                              key={p?.id}
                              groupName="1"
                              onDrop={(e) => this.onWordDrop(c.id, e, i)}
                              getChildPayload={(e) =>
                                this.getWordPayload(e, c?.id, i)
                              }
                            >
                              <Draggable >
                                <div className="dnd-custom animated">
                                  {p?.text}
                                </div>
                              </Draggable>
                            </Container>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                </>
              ) : null}
            </div>
            <div className="row m-t-50">
              {this.state.scene.child ? (
                <>
                  {this.state.scene?.child.map((column, i) => {
                    return (
                      <Fragment key={column.id}>
                        <div className="col-md-12 m-b-30" >
                          <p className="dnd-response-left-head">
                            {column?.text}
                          </p>
                          <div>
                            <Container
                              groupName="1"
                              onDrop={(e) => this.onCardDrop(column.id, e, i)}
                              getChildPayload={(e) =>
                                this.getCardPayload(e, column.id, i)
                              }
                            >
                              {column.list_child.map((card, i) => {
                                return (
                                  <Draggable
                                    className="dnd-custom-response"
                                    key={card?.id}
                                  >
                                    <div className="draggable-item" key={card?.id}>
                                      <p>{card?.text}</p>
                                    </div>
                                  </Draggable>
                                );
                              })}
                            </Container>
                          </div>
                        </div>
                        <div className="row m-t-25 col-md-12">
                          {column.childs.map((ch, i) => {
                            return (
                              <div className="col-md-6 m-b-30" key={ch.id}>
                                <p className="dnd-response-left-head">
                                  {ch?.text}
                                </p>
                                <div>
                                  <Container
                                    groupName="1"
                                    onDrop={(e) =>
                                      this.onChildDrop(ch.id, e, column?.id, i)
                                    }
                                    getChildPayload={(e) =>
                                      this.getChildPayload(
                                        e,
                                        ch.id,
                                        i,
                                        column?.id
                                      )
                                    }
                                  >
                                    {ch.list_child.map((card, i) => {
                                      return (
                                        <Draggable
                                          className="dnd-custom-response"
                                          key={card?.id}
                                        >
                                          <div
                                            className="draggable-item"
                                            key={card?.id}
                                          >
                                            <p>{card?.text}</p>
                                          </div>
                                        </Draggable>
                                      );
                                    })}
                                  </Container>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Fragment>
                    );
                  })}
                </>
              ) : null}
            </div>
            <div className="row dis-flex justify-content-end m-b-20 m-t-20">
              {this.state.isEmpty === true ? (
                <div className="col-md-3 col-sm-6 m-b-10">
                  <button
                    className="btn-theme dis-flex justify-content-center fw-600"
                    onClick={this.sendData}
                    data-toggle="modal"
                    data-target={`#hrtSuccess${this.props.heuristique[0]?.id}`}
                  >
                    Valider
                  </button>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div className="row m-t-50">
            {this.state.scene.child ? (
              <>
                {this.state.scene?.child.map((column, i) => {
                  return (
                    <Fragment key={column.id}>
                      <div className="col-md-12 m-b-30" >
                        <p className="dnd-response-left-head">{column?.text}</p>
                        <div>
                          <Container
                            groupName="1"
                            onDrop={(e) => this.onCardDrop(column.id, e, i)}
                            getChildPayload={(e) =>
                              this.getCardPayload(e, column.id, i)
                            }
                          >
                            {column.childs.map((card, i) => {
                              return (
                                <Draggable
                                  className="dnd-custom-response"
                                  key={card?.id}
                                >
                                  <div className="draggable-item" key={card?.id}>
                                    <p>{card?.text}</p>
                                  </div>
                                </Draggable>
                              );
                            })}
                          </Container>
                        </div>
                      </div>
                      <div className="row m-t-25 col-md-12">
                        {column.childs.map((ch, i) => {
                          return (
                            <div className="col-md-6 m-b-30" key={ch.id}>
                              <p className="dnd-response-left-head">
                                {ch?.text}
                              </p>
                              <div>
                                <Container
                                  groupName="1"
                                  onDrop={(e) =>
                                    this.onChildDrop(ch.id, e, column?.id, i)
                                  }
                                  getChildPayload={(e) =>
                                    this.getChildPayload(
                                      e,
                                      ch.id,
                                      i,
                                      column?.id
                                    )
                                  }
                                >
                                  {ch.childs.map((card, i) => {
                                    return (
                                      <Draggable
                                        className="dnd-custom-response"
                                        key={card?.id}
                                      >
                                        <div className="draggable-item" key={card?.id}>
                                          <p>{card?.text}</p>
                                        </div>
                                      </Draggable>
                                    );
                                  })}
                                </Container>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Fragment>
                  );
                })}
              </>
            ) : null}
          </div>
        )}
        <div
          className="modal fade"
          id={`hrtSuccess${this.props.heuristique[0]?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="mb-3 animat rotateIn"
                  />
                  <h4 className="pop-title fw-600"> Tes réponses ont été soumises ! Bravo pour avoir terminé cet exercice ! </h4>
                  {/* <p className="parag fs-20 fw-600">
                    Vous avez obtenu{" "}
                    <span className="scores">{this.state.score} points</span>
                  </p> */}
                </div>
              </div>
              <div className="modal-footer justify-content-center slider-actions-btn">
                <button
                  data-dismiss="modal"
                  className="btn btn-theme fw-400 fs-15 justify-content-start"
                  onClick={this.loadResult}
                >
                  VOIR LE RESULTAT{" "}
                </button>

                <a
                  type="button"
                  className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                  data-dismiss="modal"
                  href="#carouselSliderContentDetailsSession"
                  role="button"
                  data-slide="next"
                  onClick={this.ScrollTop}
                >
                  CONTINUER
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              role="button"
              data-slide="prev"
              onClick={this.ScrollTop}
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            {this.props?.usercontenus.every(
              (v) => v?.contenu?.id !== contenususer[0]?.contenu?.id
            ) === false ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.ScrollTop}
              >
                Suivant
              </button>
            ) : (
              <button
                className="btn-theme disabled dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                disabled
              >
                Suivant
              </button>
            )}
            {/* {this.state.isActiveSuivant === true ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.ScrollTop}
              >
                Suivant
              </button>
            ) : (
              <button
                className="btn-theme disabled dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                disabled
              >
                Suivant
              </button>
            )} */}
          </div>
        </div>
      </>
    );
  }
}
