import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

export default class SlideSkeleton extends Component {

    render() {

        return (
            <div className="doc-viewer-container slide-content-viewer">
                <div
                    className="fs-14 f-f-work fw-400 text-dark content_html"
                >
                    <p className="text-banner-image-cours-details">
                        <Skeleton variant="text" height={350} width={`100%`} />
                    </p>
                   
                </div>

               
            </div>
        )
    }
}
