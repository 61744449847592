export const applyDrag = (arr, dragResult, index) => {
    var { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
  
    const result = [...arr];
    let itemToAdd = payload;
  
    if (removedIndex !== null) {
      if(index !== undefined) {
       removedIndex = index 
      }
      
      //console.log("remove", index, removedIndex);
      
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
  
    if (addedIndex !== null) {
      if(index !== undefined) {
        addedIndex = index;
       }
      
      //console.log("added", index, itemToAdd);
      result.splice(addedIndex, 0, itemToAdd);
    }
  
    return result;
};


export const generateItems = (count, creator) => {
    const result = [];
    for (let i = 0; i < count; i++) {
        result.push(creator(i));
    }
    return result;
};