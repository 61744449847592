import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery'
import api from '../../http/global-vars'


export default class ModalAddAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            userID: window.localStorage.getItem('userID'),
            fields: {},
            errors: {},
            isSuccessAddAdmin: false,
            isErrorAddAdmin: false,
            addAdminInProgress: false,
            is_empty_email: false,
            is_empty_phone: false,
            is_empty_first_name: false,
            is_empty_last_name: false,
            is_empty_adresse: false,
            adminInfos: this.props?.updateAdminData,
            isSuccessUpdateAdmin: false,
            isErrorUpdateAdmin: false,
            updateAdminInProgress: false,


        }

    }

    componentDidMount() {
        // this.setState({
        //     adminInfos: this.props?.updateAdminData
        // })
        console.log(this.state.adminInfos)
    }


    addAdminHandleChange = (e) => {
        // let fields = this.state.fields
        // fields[e.target.name] = e.target.value
        // this.setState({
        //     fields
        // })
        let { adminInfos, fields } = this.state
        let { name, value } = e.target
        fields = adminInfos
        if(name === 'email'){
            fields[name] = value
        }else{
            fields[name] = value.toLowerCase()
        }
        this.setState({ fields })
    }

    onValidateAddFormData = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        var isValidForm = true;

        if (!fields["phone"]) {
            isValidForm = false;
            this.setState({
                is_empty_phone: true,
            });
            setTimeout(() => {
                this.setState({
                    is_empty_phone: false,
                });
            }, 5000);
            errors["phone"] = "Ce champ est obligatoire";
        }
        if (!fields["email"]) {
            isValidForm = false;
            this.setState({
                is_empty_email: true,
            });
            setTimeout(() => {
                this.setState({
                    is_empty_email: false,
                });
            }, 5000);

            errors["email"] = "Ce champ est obligatoire";
        }
        if (typeof fields["email"] !== "undefined") {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(fields["email"])) {
                isValidForm = false;
                this.setState({
                    is_invalid_email: true,
                });
                setTimeout(() => {
                    this.setState({
                        is_invalid_email: false,
                    });
                }, 5000);

                errors["invalid_email"] = "Votre adresse email n'est pas valide";
            }
        }
        if (!fields["first_name"]) {
            isValidForm = false;
            this.setState({
                is_empty_first_name: true,
            });
            setTimeout(() => {
                this.setState({
                    is_empty_first_name: false,
                });
            }, 5000);
            errors["first_name"] = "Ce champ est obligatoire";
        }
        if (!fields["last_name"]) {
            isValidForm = false;
            this.setState({
                is_empty_last_name: true,
            });
            setTimeout(() => {
                this.setState({
                    is_empty_last_name: false,
                });
            }, 5000);
            errors["last_name"] = "Ce champ est obligatoire";
        }
        if (!fields["adresse"]) {
            isValidForm = false;
            this.setState({
                is_empty_adresse: true,
            });
            setTimeout(() => {
                this.setState({
                    is_empty_adresse: false,
                });
            }, 5000);
            errors["adresse"] = "Ce champ est obligatoire";
        }

        // if (!this.state.isVerified) {
        //     isValidForm = false;
        //     this.setState({
        //         isVerifiedEnd: true,
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             isVerifiedEnd: false,
        //         });
        //     }, 5000);
        //     errors["isVerified"] = "Vous devez confirmer via le Captcha que vous n'êtes pas un robot.";
        // }


        this.setState({
            errors: errors,
        });

        return isValidForm;
    }

    onSubmitAddAdminForm = (e) => {
        e.preventDefault()
        if (this.onValidateAddFormData()) {
            this.setState({
                addAdminInProgress: true
            })

            var api = this.state.api
            var config = this.state.requestConfig
            var data = this.state.fields

            axios.post(api + 'addadmin/', data, config)
                .then(response => {
                    this.setState({
                        addAdminInProgress: false
                    })
                    if (response.status === 201 || response.status === 200) {
                        this.props.onRefreshAdminList()
                        toast.success("Le compte Admin a été créé avec succès !");
                        $('.close').click();
                        this.setState({
                            isSuccessAddAdmin: true,
                        })
                        setTimeout(() => {
                            this.setState({
                                isSuccessAddAdmin: false,
                            })
                        }, 5000);
                    } else {
                        console.log(response)
                        toast.error("Oupsss! Une erreur est survenue lors de la création du compte. Veuillez réessayer plus tard.");
                        this.setState({
                            isErrorAddAdmin: true,
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorAddAdmin: false,
                            })
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Oupsss! Une erreur est survenue lors de la création du compte. Veuillez réessayer plus tard.");
                    this.setState({
                        isErrorAddAdmin: true,
                        addAdminInProgress: false
                    })
                    setTimeout(() => {
                        this.setState({
                            isErrorAddAdmin: false,
                        })
                    }, 5000);
                })
        }
    }

    handleChangeUpdate = (e) => {
        e.persist();

        this.setState(prevState => ({
            adminInfos: { ...prevState.adminInfos, [e.target.name]: e.target.value }
        }))
    }

    onSubmitUpdateAdminForm = (e) => {
        e.preventDefault()
        if (this.onValidateAddFormData()) {
            this.setState({
                updateAdminInProgress: true
            })

            var api = this.state.api
            var data = this.state.adminInfos
            console.log('data send=>', data)
            let formData = new FormData();
            formData.append('first_name', data?.first_name)
            formData.append('last_name', data?.last_name)
            formData.append('phone', data?.phone)
            formData.append('adresse', data?.adresse)

            axios.put(api + 'user/' + data.id + '/', formData, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
                    "Content-Type": "multipart/form-data",
                }
            })
                .then(response => {
                    this.setState({
                        updateAdminInProgress: false
                    })
                    if (response.status === 201 || response.status === 200) {
                        this.props.onRefreshAdminList()
                        toast.success("Le compte Admin a été modifié avec succès !");
                        $('.close').click();
                        this.setState({
                            isSuccessUpdateAdmin: true,
                        })
                        setTimeout(() => {
                            this.setState({
                                isSuccessUpdateAdmin: false,
                            })
                        }, 5000);
                    } else {
                        console.log(response)
                        toast.error("Oupsss! Une erreur est survenue lors de la modification du compte. Veuillez réessayer plus tard.");
                        this.setState({
                            isErrorAddAdmin: true,
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorAddAdmin: false,
                            })
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error("Oupsss! Une erreur est survenue lors de la modification du compte. Veuillez réessayer plus tard.");
                    this.setState({
                        isErrorUpdateAdmin: true,
                        updateAdminInProgress: false
                    })
                    setTimeout(() => {
                        this.setState({
                            isErrorUpdateAdmin: false,
                        })
                    }, 5000);
                })
        }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        if (nextProps.updateAdminData !== prevProps.updateAdminData) {
            this.setState({ adminInfos: nextProps?.updateAdminData, fields: nextProps?.updateAdminData },
                () => console.log("infos=>", this.state.fields)
            )

        }
    }


    render() {
        console.log("props=>", this.props?.updateAdminData)
        let { updateAdminData } = this.props
        let { adminInfos } = this.state
        return (
            <div className="modal fade" id="modalAddAdmin" tabIndex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal-card">
                        <div className="row m-b-35">
                            <div className="col-10 itm-center">
                                <p className="title-modal">
                                    {this.props.actionType === 'create' &&
                                        'Ajouter un administrateur'
                                    }
                                    {this.props.actionType === 'update' &&
                                        'Modifier les infos de ' + updateAdminData?.first_name + ' ' + updateAdminData.last_name
                                    }
                                </p>
                            </div>
                            <div className="col-2">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><FeatherIcon icon="x" /></span>
                                </button>
                            </div>
                        </div>

                        {this.props.actionType === 'create' &&
                            <form onSubmit={(e) => this.onSubmitAddAdminForm(e)}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="first_name"
                                                onChange={this.addAdminHandleChange}
                                                className={`form-control input-dashboard-backend text-black  ? 'is-invalid' : ''}`}
                                                placeholder="Prénom" />
                                            {this.state.is_empty_first_name === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.first_name}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="last_name"
                                                onChange={this.addAdminHandleChange}
                                                className={`form-control input-dashboard-backend text-black  ? 'is-invalid' : ''}`}
                                                placeholder="Nom" />
                                            {this.state.is_empty_last_name === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.last_name}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                onChange={this.addAdminHandleChange}
                                                className={`form-control input-dashboard-backend text-black  ? 'is-invalid' : ''}`}
                                                placeholder="Adresse mail" />
                                            {this.state.is_empty_email === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.email}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="phone"
                                                onChange={this.addAdminHandleChange}
                                                className={`form-control input-dashboard-backend text-black number-input-custom  ? 'is-invalid' : ''}`}
                                                placeholder="Téléphone" />
                                            {this.state.is_empty_phone === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.phone}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="adresse"
                                                onChange={this.addAdminHandleChange}
                                                className={`form-control input-dashboard-backend text-black number-input-custom  ? 'is-invalid' : ''}`}
                                                placeholder="Adresse" />
                                            {this.state.is_empty_adresse === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.adresse}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                                    {this.state.addAdminInProgress ?
                                        <button type="submit" className="btn-theme p-l-90 p-r-90">
                                            Ajout en cours &nbsp;
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </button> :
                                        <button type="submit" className="btn-theme p-l-90 p-r-90">Ajouter</button>
                                    }
                                </div>
                            </form>
                        }


                        {this.props.actionType === 'update' &&
                            <form onSubmit={(e) => this.onSubmitUpdateAdminForm(e)} encType='multipart/form-data'>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="first_name"
                                                value={adminInfos?.first_name}
                                                onChange={this.addAdminHandleChange}
                                                className={`form-control input-dashboard-backend text-black  ? 'is-invalid' : ''}`}
                                                placeholder="Prénom" />
                                            {this.state.is_empty_first_name === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.first_name}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="last_name"
                                                onChange={this.addAdminHandleChange}
                                                value={adminInfos?.last_name}
                                                className={`form-control input-dashboard-backend text-black  ? 'is-invalid' : ''}`}
                                                placeholder="Nom" />
                                            {this.state.is_empty_last_name === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.last_name}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                name="email"
                                                onChange={this.addAdminHandleChange}
                                                value={adminInfos?.email}
                                                className={`form-control input-dashboard-backend text-black  ? 'is-invalid' : ''}`}
                                                placeholder="Adresse mail" />
                                            {this.state.is_empty_email === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.email}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="phone"
                                                onChange={this.addAdminHandleChange}
                                                value={adminInfos?.phone}
                                                className={`form-control input-dashboard-backend text-black number-input-custom  ? 'is-invalid' : ''}`}
                                                placeholder="Téléphone" />
                                            {this.state.is_empty_phone === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.phone}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="adresse"
                                                onChange={this.addAdminHandleChange}
                                                value={adminInfos.adresse}
                                                className={`form-control input-dashboard-backend text-black number-input-custom  ? 'is-invalid' : ''}`}
                                                placeholder="Adresse" />
                                            {this.state.is_empty_adresse === true && (
                                                <div className="alert alert-danger gfa-alert-danger">
                                                    {" "}
                                                    {this.state.errors.adresse}{" "}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                                    {this.state.updateAdminInProgress ?
                                        <button type="submit" className="btn-theme p-l-90 p-r-90">
                                            Modification en cours &nbsp;
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </button> :
                                        <button type="submit" className="btn-theme p-l-90 p-r-90">Modifier</button>
                                    }
                                </div>
                            </form>
                        }


                    </div>
                </div>

                {this.state.isSuccessAddAdmin || this.state.isErrorAddAdmin || this.state.isSuccessUpdateAdmin || this.state.isErrorUpdateAdmin ?
                    <ToastContainer /> : ''
                }

            </div>
        )
    }
}