import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import api from "./../../http/global-vars";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import ContactSupport from "../components/ContactSupport";

export default class NavbarVertical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      fields: {},
      errors: {},
      sendMessageInProgress: false,
      isSuccessSendingMessage: false,
      isErrorSendingMessage: false,
      is_empty_objet: false,
      is_empty_email: false,
      is_empty_message: false,
    };

    this.handleChangeSendMessage = this.handleChangeSendMessage.bind(this);
    this.onSubmitContactForm = this.onSubmitContactForm.bind(this);
  }

  componentDidMount() {}

  handleChangeSendMessage = (e) => {
    let fields = this.state.fields;
    fields["user_id"] = this.state.userID;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });

    console.log(fields);
  };

  onValidateSendingMessageForm = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["objet"]) {
      isValidForm = false;
      this.setState({
        is_empty_objet: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_objet: false,
        });
      }, 5000);
      errors["objet"] = "Ce champ est obligatoire";
    }
    if (!fields["message"]) {
      isValidForm = false;
      this.setState({
        is_empty_message: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_message: false,
        });
      }, 5000);
      errors["message"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onSubmitContactForm = (e) => {
    e.preventDefault();

    if (this.onValidateSendingMessageForm()) {
      this.setState({
        sendMessageInProgress: true,
      });

      var api = this.state.api;
      var config = this.state.requestConfig;
      var data = this.state.fields;

      axios
        .post(api + "contact/", data, config)
        .then((response) => {
          this.setState({
            sendMessageInProgress: false,
          });

          console.log(response);

          if (response.status === 201) {
            toast.success("Votre message a été bien envoyé!");
            $(".close").click();
            this.setState({
              isSuccessSendingMessage: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessSendingMessage: false,
              });
            }, 5000);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            sendMessageInProgress: false,
            isErrorSendingMessage: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorSendingMessage: false,
            });
          }, 5000);
        });
    }
  };

  onClickable = () => {
    $(".contactSupportModal").appendTo($("body"));
  };

  render() {
    return (
      <div>
        <ul className="nav nav-items-custom flex-column">
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom active"
              to="/tous-les-modules"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="grid" />
              Tous les Modules
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom "
              to="/mon-parcours"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="layout" />
              Mon parcours
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom "
              to="/forum"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="users" />
              Forum
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom "
              to="/mon-certificat"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="award" />
              Mes certificats
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom "
              to="/a-propos"
              activeClassName="actived"
            >
              <FeatherIcon
                className="icon-navbar-vertical"
                icon="alert-circle"
              />
              A propos
            </NavLink>
          </li>
        </ul>

        {this.state.isSuccessSendingMessage ||
        this.state.isErrorSendingMessage ? (
          <ToastContainer />
        ) : (
          ""
        )}

        <div className="container-fluid">
          <p className="text-white f-f-inter fw-600 fs-14 m-b-15">
            Besoin d’aide ?
          </p>
          <button
            onClick={() => this.onClickable()}
            className="btn-icon-transparent"
            data-toggle="modal"
            data-target=".contactSupportModal"
          >
            <FeatherIcon className="icon-contact-support" icon="mail" />{" "}
            Contacter le support
          </button>
          <div
            className="modal fade contactSupportModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content modal-card">
                <ContactSupport />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
