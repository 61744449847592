import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
// import axios from 'axios'

export default class QuizCorrection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedResponses: this.props.userAnswer,
      quiz: this.props.quiz,
      answers: [],
      userID: window.localStorage.getItem("userID"),
      score: this.props.score,
      rightAnswerCount: this.props.rightAnswerCount,
      totalScore: this.props.totalScore,
      totalQuestions: this.props.totalQuestions,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
    };
  }

  // componentDidMount() {
  //   //console.log(this.state.selectedResponses)
  //   const sendData = {
  //     score:this.state.score,
  //     user: parseInt(this.state.userID),
  //     contenu: this.state.quiz.contenu,
  //   };
  //   const url = api + "userdocontenu/";
  //   axios.post(url, sendData, this.state.requestConfig).then((data) => {
  //     // console.log('data post', data)
  //   }).catch(e => {
  //     // console.log('e post', e.response)
  //   })
  //   // this.getAnswers();
  //   //console.log("sendData", sendData);
  // }

  render() {
    // console.log("total reponse", this.state.rightAnswerCount);
    // console.log("score", this.state.score);
    // console.log("score total", this.state.totalScore);
    return (
      <div>
        <div className="content-choices-response-quiz">
          <div className="row m-b-50">
            <div className="col-xl-8 col-lg-7 col-md-8 col-sm-7 dis-flex justify-content-center itm-center">
              <p className="fs-14 fw-400 text-black f-f-work m-b-15">
                Questions réussies {this.state.rightAnswerCount}/
                {this.state.totalQuestions}
              </p>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
              <p className="score-content">
                Score:{" "}
                <span className="note-score-content">
                  {" "}
                  {this.state.score}/{this.state.totalScore}
                </span>
              </p>
            </div>
          </div>

          {this.state.quiz.list_question.map((item, index) => {
            return (
              <div key={item.id}>
                <h5 className="h-theme-light m-b-10">{item.content}</h5>
                {item.list_responses.map((resp) => {
                  return (
                    <div key={resp.id}>
                      <form action="" key={resp.id}>
                        {resp.is_right_choice ? (
                          <div className="custom-control custom-radio custom-control-inline input-radio-quiz">
                            <div>
                              <FeatherIcon
                                color="green"
                                icon="check-circle"
                                className="check"
                              />
                              <label className="custom-answer-label" htmlFor={resp.id}>
                                {resp.content}
                              </label>
                            </div>
                            <br />
                            <div className="custom-control custom-radio custom-control-inline input-radio-quiz">
                              <p className="h-themes">
                                <b>Ta Réponse</b>
                              </p>
                              <div>
                                {this.state.selectedResponses[
                                  index
                                ].responses.map((choice) => {
                                  return (
                                    <div key={choice.id}>
                                      {/* {console.log("repe", choice, index)}
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      id={`repoQuiz${choice.id}`}
                                      name="example"
                                      value={choice.content}
                                      checked={
                                        this.state.selectedResponses[index]
                                          .is_right_choice === true
                                      }
                                    /> */}
                                      {choice.is_right_choice === true ? (
                                        <FeatherIcon
                                          color="green"
                                          icon="check-circle"
                                          className="check"
                                        />
                                      ) : (
                                        <FeatherIcon
                                          color="red"
                                          icon="x-circle"
                                          className="check"
                                        />
                                      )}

                                      <label
                                        className="custom-answer-label"
                                        htmlFor={`repoQuiz${choice.id}`}
                                      >
                                        {choice.content}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </form>
                    </div>
                  );
                })}
              </div>
            );
          })}

          {/* <div className="dis-flex justify-content-end m-t-20 m-b-20">
            <a
              className="btn-theme"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              Question suivante
            </a>
          </div> */}
          {/* <div className="row dis-flex justify-content-end m-b-10">
            <div className="col-md-3 col-sm-6 m-b-10">
              <a
                className="btn-theme-light dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                role="button"
                data-slide="prev"
              >
                Précédent
              </a>
            </div>
            <div className="col-md-3 col-sm-6 m-b-10">
              <a
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                role="button"
                data-slide="next"
              >
                Suivant
              </a>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
