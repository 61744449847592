/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import NavbarVerticalAdmin from "./components/NavbarVertical";
import MessageAllPage from "./components/MessageAllPage";
import api from "../http/global-vars";
import Swal from "sweetalert2";
import { EmptyDataAlert } from "./Thematiques";
import baseUrl from "../http/backend-base-url";
import AddOrEditPersonnage from "./modal/AddOrEditPersonnage";
import ShowPersonnageModal from "./modal/ShowPersonnageModal";

const PersonnageItem = ({ personne, refetch }) => {
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce personnage ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "red",
      confirmButtonColor: "red",
      preConfirm: async () => {
        // return deleteData(slug);
        try {
          const url = `${api}personnage/${personne?.id}/`;
          const response = await fetch(url, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
          });

          console.log("response", response);
          return response;
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          refetch();
          Swal.fire({
            icon: "success",
            title: `Personnage supprimé avec succès!`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return (
    <div className="col-md-4 col-choisir-personnage m-b-30">
      <div className="label-choisir-personnage">
        <div
          className="span-check-personnage"
          data-toggle="modal"
          data-target={`#showPersonnageModal${personne?.id}`}
        >
          <div className="content-img-check-personnage">
            <img
              src={baseUrl + personne?.image}
              alt={personne?.name}
              className="img-admin-personnage"
            />
          </div>
          <p className="name-personnage-check py-3">{personne?.name}</p>
          <p className="poste-personnage-check">{personne?.fonction}</p>
        </div>
        <div className="d-flex justify-content-center btn-action-personnage m-t-20">
          <button
            className="btn-edit-table-admin"
            data-toggle="modal"
            data-target={`#editPersonnageModal${personne?.id}`}
          >
            <FeatherIcon icon="edit" />
          </button>
          <button
            className="btn-delete-table-admin"
            title="supprimer"
            type="button"
            onClick={() => onDelete()}
          >
            <FeatherIcon icon="trash-2" />
          </button>
        </div>
      </div>
      <AddOrEditPersonnage
        modalId={`editPersonnageModal${personne?.id}`}
        refetch={refetch}
        item={personne}
      />
      <ShowPersonnageModal
        modalId={`showPersonnageModal${personne?.id}`}
        item={personne}
      />
    </div>
  );
};

function Personnage() {
  const [personnages, setPersonnages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const getPersonnages = async () => {
    let url = `${api}personnage/`;
    await axios
      .get(url, config)
      .then((res) => {
        console.log("res", res);
        setPersonnages(res.data?.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getPersonnages();
  }, []);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobileAdmin />
        </div>
      </div>

      <HeaderDashboardAdmin />

      <div className="row">
        {/* Navbar Desktop  */}
        {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
        <div className="nav-container">
          <div
            className="navbar-vertical-customisation no-view-mobile"
            style={{ top: "40px" }}
          >
            <NavbarVerticalAdmin />
          </div>
        </div>

        <div className="col-xl-2 col-lg-3 col-md-12"></div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="row m-b-10">
              <div className="col-xl-6">
                <h5 className="text-black f-f-work fw-500 m-b-15">
                  Personnages
                </h5>
              </div>
              <div className="col-xl-6 dis-flex justify-content-end justify-left-tablette">
                <button
                  className="btn-add-dashboard"
                  data-toggle="modal"
                  data-target="#addPersonnageModal"
                >
                  <FeatherIcon
                    className="icon-btn-add-dashboard"
                    icon="plus-circle"
                  />
                  Ajouter un personnage
                </button>
              </div>
            </div>

            <div className="card-dashboard p-l-0 p-r-0">
              {/* <div className="col">
                  <div className="row m-b-50">
                    <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                      <p className="titre-card-dashboard-sans-padding m-b-15">
                        Liste des thématiques
                      </p>
                    </div>
                  </div>
                </div> */}
              <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm row px-2">
                {/* {!!isLoading && <CardModuleSkeleton />} */}
                {!isLoading &&
                  (personnages?.length ? (
                    personnages?.map((personne) => (
                      <PersonnageItem
                        key={personne?.id}
                        personne={personne}
                        refetch={getPersonnages}
                      />
                    ))
                  ) : (
                    <EmptyDataAlert text={"Aucune donnée trouvé"} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddOrEditPersonnage
        modalId={"addPersonnageModal"}
        refetch={getPersonnages}
      />
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
}

export default Personnage;
