import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { toast, ToastContainer } from "react-toastify";
import api from "../../http/global-vars";
import ContentEditor from "./supportContentAdmin/ContentEditor";
export default class CreateLessonContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          "Content-Type": "multipart/form-data",
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      fields: {
        name: "",
        url: null,
        content: "",
      },

      content_type: "slide",
      created_by: parseInt(window.localStorage.getItem("userID")),
      isSuccess: false,
      isError: false,
      isLoading: false,
      is_empty_name: false,
      is_empty_session: false,
      is_empty_module: false,
      contentId: this.props.contentId,
      errors: {},
    };
  }

  handleChange = (e) => {
    let fields = this.state.fields;

    if (e.target.name === "session" || e.target.name === "module") {
      fields[e.target.name] = parseInt(e.target.value);
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
  };

  urlChange = (e) => {
    this.setState({ fields: { ...this.state.fields, url: e.target.files[0] } });
  };

  onValidateForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;
    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
        });
      }, 5000);

      errors["name"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  addForm = (e) => {
    e.preventDefault();
    console.log("valid", this.state.contentId);
    if (this.onValidateForm()) {
      this.setState({
        isLoading: true,
      });

      var api = this.state.api;
      var formData = new FormData();
      formData.append("name", this.state.fields.name);
      formData.append("description", this.state.fields.content);
      if (this.state.fields.url) {
        formData.append("url", this.state.fields.url);
      }
      formData.append("content_type", this.state.content_type);
      formData.append("created_by", this.state.created_by);
      formData.append("contenu", this.state.contentId);
      axios
        .post(api + "support/", formData, this.state.requestConfig)
        .then((response) => {
          // console.log(response)
          if (response.status === 201) {
            this.props.notify();
            this.setState({
              isLoading: false,
              fields: {
                name: "",
                url: null,
                content: "",
              },
            });
          } else {
            toast.error("Vos données ne sont pas valides !");
          }
        })
        .catch((error) => {
          console.log(error.response);
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
          );
          this.setState({
            isError: true,
            isLoading: false,
          });

          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 5000);
        });
    }
  };

  render() {
    return (
      <div className="card-dashboard no-shadow">
        <form>
          <div className="row m-b-50">
            <div className="col-lg-9">
              <p className="titre-card-create-content">Slide</p>
            </div>
          </div>
          <div className="form-group m-b-40">
            <input
              type="text"
              className="form-control input-dashboard-backend"
              placeholder="Titre du slide"
              name="name"
              onChange={(e) => this.handleChange(e)}
            />
            {this.state.is_empty_name && (
              <div className="alert alert-danger keysjob-alert-danger">
                {" "}
                {this.state.errors.name}{" "}
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12 mb-5">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="inputVideoPdf"
                  onChange={this.urlChange}
                />
                <label
                  className="custom-file-label custom-file-label-create-content"
                  htmlFor="inputVideoPdf"
                >
                  {this.state.fields.url
                    ? this.state.fields.url.name
                    : "Ajouter un document"}
                </label>
                <FeatherIcon
                  className="icon-import-custom-file-label"
                  icon="upload"
                />
              </div>
            </div>
          </div>
          <ContentEditor
            value={this.state.fields.content}
            onChange={(value) => {
              this.setState({
                fields: { ...this.state.fields, content: value },
              });
            }}
            name="create_slide_description"
            placeholder="Résumé du slide"
            height={300}
          />

          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 m-b-20">
              {this.state.isLoading === false ? (
                <button
                  type="submit"
                  onClick={(e) => this.addForm(e)}
                  className="btn-theme text-up w-full"
                >
                  Terminer
                </button>
              ) : (
                <button className="btn-theme text-up w-full in-progress-btn">
                  Ajout en cours... &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
      </div>
    );
  }
}
