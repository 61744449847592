/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import baseUrl from '../../http/backend-base-url'
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { toast } from "react-toastify";
import api from "../../http/global-vars";
import { formatDate } from "../../utils";

export default class ResponseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      userType: window.localStorage.getItem("userType"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      showForm: false,
      showResponse: false,
      sendMessageInProgress: false,
      isSuccessSendingMessage: false,
      isErrorSendingMessage: false,
      is_empty_message: false,
      fields: {
        response: '',
        creator_id: parseInt(window.localStorage.getItem("userID")),
        message_id: this.props.id
      },
      errors: {},
      limit: 3,
      isSend: false
    };
  }

  onLoadMore = () => {
    this.setState({
      limit: this.state.limit + 3
    });
  }

  onLoadLess = () => {
    this.setState({
      limit: 3
    });
  }

  showForm = () => {
    this.setState({ showForm: !this.state.showForm })
    /* setTimeout(() => {
      this.setState({
        showForm: false
      })
    }, 5000); */
  }

  showResponse = () => {
    this.setState({ showResponse: !this.state.showResponse })
    /* setTimeout(() => {
      this.setState({
        showForm: false
      })
    }, 5000); */
  }

  handleChangeSendMessage = (e) => {
    let fields = this.state.fields;
   
      fields["creator_id"] = parseInt(this.state.userID);
      fields["message_id"] = this.props.id;
      fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
    
    console.log(fields);
  };

  onValidateSendingMessageForm = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["response"]) {
      isValidForm = false;
      this.setState({
        is_empty_message: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_message: false,
        });
      }, 5000);
      errors["response"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onSubmitResponseForm = (e) => {
    e.preventDefault();

    if (this.onValidateSendingMessageForm()) {
      this.setState({
        sendMessageInProgress: true,
      });

      var api = this.state.api;
      var config = this.state.requestConfig;
      var data = this.state.fields;
      console.log('data', data)
      axios
        .post(api + "responsemessage/", data, config)
        .then((response) => {
          this.setState({
            sendMessageInProgress: false,
          });

          console.log(response);

          if (response.status === 201) {
            //this.loadResponseByForum(this.state.forumId);
            
            //this.showForm()
            toast.success("Votre réponse a été bien envoyé!");
            this.setState({
              isSuccessSendingMessage: true,
              isSend: true,
              fields: {},
            });
            this.props.loadResponseByForum(this.props.forumId)
           
            setTimeout(() => {
              this.setState({
                isSuccessSendingMessage: false,
                showResponse: true,
              limit: this.props.response.length,
              isSend: false
              });
            }, 5000);
          } else {
          }
        })
        .catch((error) => {
          toast.error("Oupss! Une erreur est survenue lors de l'envoi de votre réponse. Veuillez réessayer plus tard.");
          console.log(error.message);
          this.setState({
            sendMessageInProgress: false,
            isErrorSendingMessage: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorSendingMessage: false,
            });
          }, 5000);
        });
    }
  };

  render() {
   console.log(this.props.response)
    return (
      <div>
        <div className="card-response">
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 auteur-reponse-position-mobile">
              <p className="auteur-reponse-card-forum-details">
                {this.props.first_name}{" "}
                {this.props.last_name}
                {/* {this.props.id} */}
              </p>
              <img
                className="avatar-reponse-card-forum-details"
                src={`${baseUrl}${this.props.avatar}`}
                alt=""
              />
            </div>
            <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8">
              <p className="date-reponse-card-forum-details">
                Posté le {this.props.created_at}
              </p>
              <p className="text-content-reponse-card-forum-details">

                {this.props.message}
              </p>
            </div>
          </div>
          <div className=" row">
            <div className="dis-flex justify-content-start col-md-6 col-sm-6 containt-mobile-center m-t-10">

              <span
                type="button"
                className="btn-list-response m-b-22"
                onClick={this.showResponse}
              >
                <FeatherIcon
                  className="icon-btn-list-response"
                  icon="message-square"
                />
                {this.props.response.length}

              </span>
            </div>
            {/* <div className="dis-flex justify-content-end col-md-6 col-sm-6 containt-mobile-center m-t-10">

              <button
                type="button"
                className="btn-add-response m-b-22"
                onClick={this.showForm}
              >
                {!this.state.showForm ? (
                  <>
                    <FeatherIcon
                      className="icon-btn-add-response"
                      icon="edit"
                    />
                Ajouter une réponse </>
                ) : (
                    <>
                      <FeatherIcon
                        className="icon-btn-add-response"
                        icon="x"
                      />
                  Annuler
                  </>)}

              </button>
            </div> */}
          </div>
          {this.state.showResponse ?
            (
              <>
              {this.props.response.slice(0, this.state.limit).map((response, i) => {
                return (
                  <div className="card-response" key={response.id}>
                    <div className="row">
                      <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 auteur-reponse-position-mobile">
                        <p className="auteur-reponse-card-forum-details">
                          {response.creator_id.first_name}{" "}
                          {response.creator_id.last_name}
                        </p>
                        <img
                          className="avatar-reponse-card-forum-details"
                          src={`${baseUrl}${response.creator_id.avatar}`}
                          alt=""
                        />
                      </div>
                      <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                        <p className="date-reponse-card-forum-details">
                          Posté le {formatDate(response.created_at)}
                        </p>
                        <p className="text-content-reponse-card-forum-details">

                          {response.response}
                        </p>
                      </div>

                    </div>
                  </div>
                )

              })}
              
              {this.props.response.length > this.state.limit ? 
              (
                <div className=" load-more m-b-10">
                  <button className="btn-theme " onClick={this.onLoadMore}>Voir Plus</button>
                </div>
              ): this.props.response.length > 3 ?(
                <div className=" load-less m-b-10">
                <button className="btn-theme " onClick={this.onLoadLess}>Voir Moins</button>
                </div>
              ): null}
              <div>
              <form>
                <div className="form-group">
                  <textarea
                    className="input-modal p-t-15"
                    rows="7"
                    placeholder="Votre réponse ..."
                    name="response"
                    value={this.state.isSend ? '' : this.state.fields.response}
                    onChange={(e) =>
                      this.handleChangeSendMessage(e)
                    }
                  ></textarea>
                  {this.state.is_empty_message && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.response}{" "}
                    </div>
                  )}
                </div>

                <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                  {this.state.sendMessageInProgress === false ? (
                      <button
                        type="submit"
                        onClick={(e) =>
                          this.onSubmitResponseForm(e)
                        }
                        className="btn-theme p-l-90 p-r-90"
                      >
                        ENVOYER
                      </button>
                    ) : (
                      <button className="btn-theme p-l-90 p-r-90">
                        Envoi en cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                </div>
              </form>
              </div>
              </>
            ) : null}
          
        </div>
      </div>
    );
  }
}
