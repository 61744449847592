import React, { Component } from 'react';
import NavbarDashboardMobileAdmin from './components/NavbarDashboardMobile';
import HeaderDashboardAdmin from './components/HeaderDashboard';
import NavbarVerticalAdmin from './components/NavbarVertical';
import api from './../http/global-vars';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import MessageAllPage from './components/MessageAllPage';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import PerformanceSkeleton from './AdminSkeletons/PerformanceSkeleton';

let order = 'desc';


export default class Performances extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            userID: window.localStorage.getItem('userID'),
            isPageLoading: true,
            performancesList: [],
            data_all: [],
            data_eligible: [],
            data_no_eligible: [],
            filterValue: 'all',
            sessions: [],
            modules: []
        }
    }

    componentDidMount() {
        this.getSession()
        this.getModule()
        this.getPerformanceList()
    }

    getSession = async () => {
        const url = api + 'session/';
        await axios.get(url, this.state.requestConfig).then((response) => {
            //console.log('response session', response.data)
            this.setState({ sessions: response.data.results })
        }).catch((e) => {
            console.log('error', e.response)
        })
    }

    getModule = async () => {
        const url = api + 'module/';
        await axios.get(url, this.state.requestConfig).then((response) => {
            //console.log('response module', response.data)
            this.setState({ modules: response.data.results })
        }).catch((e) => {
            console.log('error', e.response)
        })
    }

    getPerformanceList = async () => {
        console.log('tested', this.state.filterValue)
        const url = api + 'performance/';
        await axios.get(url, this.state.requestConfig).then((response) => {
            console.log('response performance', response)
            this.setState({
                performancesList: response.data,
                isPageLoading: false,
                data_no_eligible: response.data.data_no_eligible,
                data_eligible: response.data.data_eligible,
                data_all: response.data.data_all
            })


        }).catch((e) => {
            console.log('error', e)
        })
    }

    handleBtnClick = () => {
        if (order === 'desc') {
            this.refs.table.handleSort('asc', 'name');
            order = 'asc';
        } else {
            this.refs.table.handleSort('desc', 'name');
            order = 'desc';
        }
    }

    handleChange = (e) => {
        console.log('value', e.target.value)
        this.setState({ filterValue: e.target.value });
        this.getPerformanceList()
    }

    showFullname = (cell, row) => {
        //console.log('row cell', row, cell)
        return row.first_name + ' ' + row.last_name;
    }

    showModule = (cell, row) => {
        //console.log('row cell', row, cell)
        return this.state.modules.filter(d => d?.id === this.state.sessions.filter(d => d?.id === row.session_id)[0].module_id)[0].name
    }
    showGender = (cell, row) => {
        //console.log('row cell', row, cell)
        return row.sexe;
    }

    showEmail = (cell, row) => {
        //console.log('row cell', row, cell)
        return row.email;
    }

    showPhone = (cell, row) => {
        //console.log('row cell', row, cell)
        return row.phone;
    }

    showSession = (cell, row) => {
        //console.log('row cell', this.state.sessions.filter(d => d?.id === row.session_id)[0])
        return this.state.sessions.filter(d => d?.id === row.session_id)[0].name;
    }

    createCustomButtonGroup = props => {
        return (
            <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
                {props.exportCSVBtn}
            </ButtonGroup>
        );
    }

    render() {
        const { performancesList, filterValue, data_all, data_eligible, data_no_eligible, isPageLoading } = this.state;
        let data = [];
        if (performancesList) {
            if (filterValue === 'no_eligible') {
                data = data_no_eligible;
            } else if (filterValue === 'eligible') {
                data = data_eligible;
            } else {
                data = data_all;
            }

            console.log('data', data)
        }

        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton,
            noDataText: 'Aucune donnée disponible',
            btnGroup: this.createCustomButtonGroup,
            exportCSVText: 'Extraction'
        };
        //console.log('data', data.list_user)

        if (this.state.token === null || this.state.token === undefined || this.state.token === '') {
            window.location = 'connexion'
        } else {
            return (
                <div className="container-fluid body-background-dashboard">
                    <div className="row">
                        {/* Navbar Mobile */}
                        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
                            <NavbarDashboardMobileAdmin />
                        </div>
                    </div>

                    <HeaderDashboardAdmin />

                    <div className="row">
                        {/* Navbar Desktop  */}
                        {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
                        <div className="nav-container">
                            <div className="navbar-vertical-customisation no-view-mobile" style={{ top: '40px' }}>
                                <NavbarVerticalAdmin />
                            </div>
                        </div>
                        
                        <div className="col-xl-2 col-lg-3 col-md-12"></div>

                        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
                            <div className="container-fluid">
                                <div className="row mb-3">
                                    <div className="col-xl-4 col-lg-4 col-md-4">
                                        <h6 className="title-view-dashboard mb-2">Performances</h6>
                                        <p className="description-title-view-dashboard mb-3">Nombre de participants (pourcentage) qui ont amélioré leur score au test final par rapport au prétest</p>
                                    </div>
                                    <div className="col-xl-6 offset-xl-2 col-lg-8 col-md-8 mb-2">
                                        <div className="row px-2">
                                            <div className="col-lg-1 px-1 mb-2 col-md-1 col-sm-2 dis-flex itm-center justify-content-sm-center">
                                                <p className="title-filter-radio-dashboard-admin">Filtrer</p>
                                            </div>
                                            <div className="col-lg-4 mb-2 px-1 col-md-4 col-sm-4">
                                                <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
                                                    <input type="radio" className="custom-control-input" onChange={(e) => this.handleChange(e)} id="popul-eligible" name="filter" value="eligible" />
                                                    <label className="custom-control-label filter-radio-dashboard-admin" htmlFor="popul-eligible">Populations éligibles</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                                                <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
                                                    <input type="radio" className="custom-control-input" onChange={(e) => this.handleChange(e)} id="popul-non-eligible" name="filter" value="no_eligible" />
                                                    <label className="custom-control-label filter-radio-dashboard-admin" htmlFor="popul-non-eligible">Populations non-éligibles</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 mb-2 px-1 col-md-2 col-sm-2">
                                                <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
                                                    <input type="radio" defaultChecked="true" className="custom-control-input" onChange={(e) => this.handleChange(e)} id="total-filter" name="filter" value="all" />
                                                    <label className="custom-control-label filter-radio-dashboard-admin" htmlFor="total-filter">Total</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isPageLoading === true ?
                                    (
                                        <div className="row mb-3 px-2">
                                            <CardSkeleton />

                                        </div>
                                    ) :
                                    (
                                        <div className="row mb-3 px-2">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2 mb-3">
                                                <div className="item-card-performances mb-4">
                                                    <p className="titre-item-card-performances mb-5">
                                                        <img className="icon-item-card-performances" alt="" src="img/icons/Icon-performances-item.png" />
                                                        Module 1
                                                    </p>
                                                    <div className="row px-2">
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[0]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">
                                                                    ({data?.evolution[0]?.pourcentage}%)
                                                        </span></p>
                                                            <p className="p-item-card-performances">Session 1</p>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[1]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">
                                                                    ({data?.evolution[1]?.pourcentage}%)
                                                        </span></p>
                                                            <p className="p-item-card-performances">Session 2</p>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 p-t-6 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[2]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">
                                                                    ({data?.evolution[2]?.pourcentage}%)
                                                        </span></p>
                                                            <p className="p-item-card-performances">Session 3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2 mb-3">
                                                <div className="item-card-performances mb-4">
                                                    <p className="titre-item-card-performances mb-5">
                                                        <img className="icon-item-card-performances" alt="" src="img/icons/Icon-performances-item.png" />
                                                        Module 2
                                                    </p>
                                                    <div className="row px-2">
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[3]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">
                                                                    ({data?.evolution[3]?.pourcentage}%)
                                                    </span></p>
                                                            <p className="p-item-card-performances">Session 1</p>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[4]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">
                                                                    ({data?.evolution[4]?.pourcentage}%)
                                                    </span></p>
                                                            <p className="p-item-card-performances">Session 2</p>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 p-t-6 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[5]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">
                                                                    ({data?.evolution[5]?.pourcentage}%)
                                                    </span></p>
                                                            <p className="p-item-card-performances">Session 3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2 mb-3">
                                                <div className="item-card-performances mb-4">
                                                    <p className="titre-item-card-performances mb-5">
                                                        <img className="icon-item-card-performances" alt="" src="img/icons/Icon-performances-item.png" />
                                                        Module 3
                                                    </p>
                                                    <div className="row px-2">
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[6]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">
                                                                    ({data?.evolution[6]?.pourcentage}%)
                                                    </span>
                                                            </p>
                                                            <p className="p-item-card-performances">Session 1</p>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[7]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">({data?.evolution[7]?.pourcentage}%)</span></p>
                                                            <p className="p-item-card-performances">Session 2</p>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 p-t-6 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[8]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">({data?.evolution[8]?.pourcentage}%)</span></p>
                                                            <p className="p-item-card-performances">Session 3</p>
                                                        </div>
                                                        <div className="col-xl-6 col-md-6 col-sm-4 px-2 mb-2 p-t-6 text-center">
                                                            <p className="chiffre-item-card-performances">{data?.evolution[9]?.evolue}
                                                                <span className="child-chiffre-item-card-performances">({data?.evolution[9]?.pourcentage}%)</span></p>
                                                            <p className="p-item-card-performances">Session 4</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                <div className="bg-white b-r-5 py-5">
                                    <div className="px-4 row mb-5">
                                        <div className="col-xl-8 col-lg-5 col-md-6">
                                            <p className="titre-card-dashboard-sans-padding mb-3 ml-0">Résultats du prétest et du test final</p>
                                        </div>
                                        {/* <div className="col-xl-4 col-lg-7 col-md-6 mb-2">
                                            <div className="row px-2">
                                                <div className="col-lg-2 px-1 mb-2 col-md-2 col-sm-2 dis-flex itm-center justify-content-sm-center">
                                                    <p className="title-filter-radio-dashboard-admin">Filtrer</p>
                                                </div>
                                                <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                                                    <select class="form-select form-select-line-chart w-full border-theme" aria-label="Default select example">
                                                        <option selected>Module 1</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                                                    <select class="form-select form-select-line-chart w-full border-theme" aria-label="Default select example">
                                                        <option selected>Session 1</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="table-container">
                                        {isPageLoading === true ?
                                            <PerformanceSkeleton />
                                            :
                                            <BootstrapTable ref='table'
                                                data={data.list_user}
                                                striped={true}
                                                hover={true}
                                                options={options}
                                                condensed={true}
                                                multiColumnSort={2}
                                                exportCSV
                                                pagination
                                                version='4'
                                                bordered={false}>
                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                                                    width="70" dataField='userdocontenu_id' isKey={true}>N°</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                                                    width="300" dataAlign="center" dataField="FullName" csvFormat={this.showFullname} dataSort={true} dataFormat={this.showFullname}>Prénom & Nom</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                                                    width="200" dataAlign="center" dataField='Module' csvFormat={this.showModule} dataFormat={this.showModule} dataSort={true}>Module</TableHeaderColumn>

                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal', wordWrap: 'break-word' }}
                                                    width="200" dataAlign="center" dataField='Session' csvFormat={this.showSession} dataFormat={this.showSession} dataSort={true}>Session</TableHeaderColumn>

                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                                                    width="150" dataAlign="center" dataField='Genre' csvFormat={this.showGender} dataFormat={this.showGender} dataSort={true}>Civilité</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                                                    width="200" dataAlign="center" dataField='Phone' csvFormat={this.showPhone} dataFormat={this.showPhone}>Téléphone</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                                                    width="300" dataAlign="center" dataField='Email' csvFormat={this.showEmail} dataFormat={this.showEmail} >Adresse mail</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                                                    width="300" dataAlign="center" dataField='score_pretest'>Score au prétest</TableHeaderColumn>
                                                <TableHeaderColumn
                                                    thStyle={{ fontWeight: 600, fontSize: 18, color: '#1F497D', border: 'none' }}
                                                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                                                    width="300" dataAlign="center" dataField='score_testfinal'>Score au test final</TableHeaderColumn>
                                            </BootstrapTable>

                                        }

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <MessageAllPage />
                    </div>
                </div>
            );

        }

    }
}


const CardSkeleton = () => {
    const renderModules = Array(3)
        .fill()
        .map((item, index) => (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2" key={index}>
                <div className="card">
                    <Skeleton variant="rect" style={{ borderRadius: '10px' }} width={332} height={160} />
                </div>
            </div>

        ));
    return (
        <React.Fragment>
            {/* <Skeleton className="row mb-3 px-2" height={255} width={590} /> */}
            {renderModules}
        </React.Fragment>
    )
}