import React from 'react';
import NavbarVertical from '../components/NavbarVertical';
import HeaderDashboard from '../components/HeaderDashboard';
import NavbarDashboardMobile from '../components/NavbarDashboardMobile';
import CardSessionDownload from '../components/CardSessionDownload';
import MessageAllPage from '../../backend/components/MessageAllPage'

function Downloads() {
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobile />
          </div>
      </div>

      <HeaderDashboard />

        <div className="row">

            {/* Navbar Desktop  */}
            <div className="col-xl-2 col-lg-3 col-md-12">
              <div className="navbar-vertical-customisation no-view-mobile">
                <NavbarVertical />
              </div>
            </div>

            <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 p-t-25 content-position-after-header">
                <div className="container-fluid">
                  <h4 className="titre-page-dashboard m-b-35">Mes téléchargements</h4>
                  <div className="row">
                    <div className="col-md-4">
                      <CardSessionDownload />
                    </div>
                    <div className="col-md-4">
                      <CardSessionDownload />
                    </div>
                    
                  </div>
                </div>
            </div>
        </div>
        <div className="">
            <MessageAllPage />
        </div>
    </div>
  );
}

export default Downloads;
