import React, { Component } from 'react'
import FeatherIcon from "feather-icons-react";

export default class VraiFauxCorrection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            corrections: this.props.corrections,
            responses: this.props.responses,
        }
    }
    
    
    
    render() {
        const {corrections, responses} = this.state;
        //console.log('response', responses)
        return (
          <div>
            <p className="fs-14 fw-500 text-dark f-f-work m-b-50">
              Solution
            </p>
            {corrections.map((q, index) => {
              return (
                <div className="row m-b-20" key={q.id}>
                  <div className="col-xl-10 col-lg-10 col-md-10 m-b-10">
                    {responses[index].response !== true ? (
                      <FeatherIcon color="red" icon="x-circle" className="check" />
                    ) : (
                      <FeatherIcon
                        color="green"
                        icon="check-circle"
                        className="check"
                      />
                    )}
                    <p className="item-choice-vrai-faux">{q?.content}. </p>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-2 itm-center m-b-10">
                    {q.list_responses.map((r, index) => {
                      return (
                        <div className="col-md-6" key={r.id}>
                          <label className="label-vrai-faux">
                            {r?.content}
                          </label>

                          <label className="label-vrai-faux-mobile">
                            {r?.content}
                          </label>

                          <div
                            className="custom-control custom-radio"
                            key={r.id}
                          >
                            <input
                              type="radio"
                              className="custom-control-input"
                              id={`${r.id}`}
                              value={r.is_right_choice}
                              checked={r.is_right_choice === true}
                              name={q.id}
                              readOnly={true}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`${r.id}`}
                            ></label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        );
    }
}