import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react';
import api from './../../http/global-vars'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify' 
import $ from 'jquery'


export default class ImportApprenant extends Component{
    constructor(props){
        super(props)
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            userID: window.localStorage.getItem('userID'),
            fields: {},
            errors: {},
            importInProgress: false,
            isSuccessImport: false,
            isErrorImport: false,
            is_empty_file: false,
            isInvalidFileExtension: false,
            inputFileLabel: 'Ajouter un fichier (.csv)',
            is_empty_file_input: false,
            inputErrorClassName: '',
            is_valid_input: false,
            inputSuccessClassName: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.onSubmitImport = this.onSubmitImport.bind(this)
    }

    handleChange = (e) => {
        let fields = this.state.fields
        // fields['file'] = e.target.files[0]
        if(e.target.name === 'file'){
            if(this.CSVFileTypeValidation('csv-file')){
                fields['file'] = e.target.files[0];
                console.log(fields.file.name)
                this.setState({
                    inputFileLabel: fields.file.name,
                    is_empty_file_input: false,
                    inputErrorClassName: '',
                    is_valid_input: true,
                    inputSuccessClassName: ' input-success'
                })
            }else{
                this.setState({
                    inputFileLabel: 'Importer un fichier .csv',
                    isInvalidFileExtension: true,
                    is_empty_file_input: true,
                    inputErrorClassName: ' input-error',
                    is_valid_input: false,
                    inputSuccessClassName: ''
                })
                // setTimeout(() => {
                //     this.setState({
                //         isInvalidFileExtension: false
                //     })
                // }, 6000);
            }
        }
        this.setState({
            fields
        })
        console.log(fields)
    }

    onValidateImportForm = () => {
        let fields = this.state.fields
        let errors = this.state.errors
        var isValidForm = true

        if(!fields['file']){
            isValidForm = false
            this.setState({
                is_empty_file: true,
                is_empty_file_input: true,
                inputErrorClassName: ' input-error',
                is_valid_input: false,
                inputSuccessClassName: ''
            })
            setTimeout(() => {
                this.setState({
                    is_empty_file: false
                })
            }, 5000);
            errors['file'] = 'Vous devez charger un fichier.'
        }else{
            this.setState({
                is_empty_file: false,
                is_empty_file_input: false,
                inputErrorClassName: '',
                is_valid_input: true,
                inputSuccessClassName: ' input-success'
            })
        }

        this.setState({
            errors: errors
        })

        return isValidForm
    }

    onSubmitImport = (e) => {
        e.preventDefault()
        if(this.onValidateImportForm()){
            this.setState({
                importInProgress: true
            })

            var api = this.state.api
            var config = this.state.requestConfig
            var data = this.state.fields
            var fd = new FormData()
            fd.append('file', data.file)

            axios.post(api + `importcsv/`, fd, config)
                .then(response => {
                    this.setState({
                        importInProgress: false
                    })

                    if(response.status === 201){
                        toast.success('Fichier importé et comptes apprenants créés avec succès.')
                        $('.close').click()
                        $('#csv-file').val('')
                        this.props.onGetApprenantsInfos()
                        this.setState({
                            isSuccessImport: true,
                            fields: {},
                            errors: {}
                        })
                        setTimeout(() => {
                            this.setState({
                                isSuccessImport: false
                            })
                        }, 5000);
                    }else{
                        toast.error('Une erreur est survenue lors de l\'import.')
                        this.setState({
                            isErrorImport: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorImport: false
                            })
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error('Une erreur est survenue lors de l\'import.')
                    this.setState({
                        isErrorImport: true
                    })
                    setTimeout(() => {
                        this.setState({
                            isErrorImport: false
                        })
                    }, 5000);
                })
        }
    }

    CSVFileTypeValidation = (elementID) => { 
        var fileInput =  document.getElementById(elementID); 
        
        var filePath = fileInput.value; 
    
        // Allowing file type 
        var allowedExtension =  /(\.csv)$/i;
        
        if (!allowedExtension.exec(filePath)) { 
            fileInput.value = ''; 
            return false; 
        }else{ 
            return true
        } 
    } 

    render(){
        return(
            <div className="modal fade import-apprenant-cv" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content modal-card">
                        <div className="row m-b-35">
                            <div className="col-10 itm-center">
                                <p className="title-modal">Créer des comptes Apprenant</p>
                            </div>
                            <div className="col-2">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><FeatherIcon icon="x" /></span>
                                </button>
                            </div>
                        </div>

                        <form onSubmit={ (e) => this.onSubmitImport(e) }>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="custom-file">
                                        <input
                                            name="file"
                                            type="file"
                                            className={`custom-file-input no-focusable`}
                                            id="csv-file"
                                            onChange={this.handleChange}
                                        />
                                        {this.state.is_empty_file && (
                                            <div className="alert alert-danger gfa-alert-danger import-danger">
                                                {" "}
                                                {this.state.errors.file}{" "}
                                            </div>
                                        )}
                                        {this.state.isInvalidFileExtension && (
                                            <div className="alert alert-danger gfa-alert-danger import-danger">
                                                Ce format de fichier n'est pas accepté. Veuillez charger un fichier au format .csv
                                            </div>
                                        )}
                                        <label
                                            className={`custom-file-label custom-file-label-create-content${ this.state.isInvalidFileExtension || this.state.is_empty_file_input || this.state.is_empty_file ? this.state.inputErrorClassName : this.state.is_valid_input ? this.state.inputSuccessClassName : '' }`}
                                            htmlFor="csv-file"
                                        >
                                            {this.state.inputFileLabel}
                                        </label>
                                        <FeatherIcon
                                            className="icon-import-custom-file-label"
                                            icon="upload"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                                {this.state.importInProgress === false ?
                                    <button type="submit" className="btn-theme p-l-90 p-r-90">ENVOYER</button> :
                                    <button type="submit" className="btn-theme p-l-90 p-r-90">
                                        Envoi en cours &nbsp;
                                        <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>

                {this.state.isSuccessImport || this.state.isErrorImport ?
                    <ToastContainer /> : ''
                }
            </div>
        )
    }
}