import api from "../../http/global-vars";
import { FETCH_LIST_MODULES } from "../Type";

export const fetchListModules = () => {
  return async (dispatch) => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let list = null;
    if (
      window.localStorage.getItem("list_modules") &&
      window.localStorage.getItem("list_modules") !== undefined &&
      window.localStorage.getItem("list_modules") !== 'undefined'
    ) {
      list = window.localStorage.getItem("list_modules");
      //   console.log("list", list);
      dispatch({
        type: FETCH_LIST_MODULES,
        payload: JSON.parse(list),
        isLoading: false,
      });
    }

    const url = api + "module/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data list_modules", data.results);
        window.localStorage.setItem(
          "list_modules",
          JSON.stringify(data.results)
        );
        dispatch({
          type: FETCH_LIST_MODULES,
          payload: data.results,
          isLoading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_LIST_MODULES,
          payload: [],
          isLoading: false,
        });
      });
  };
};
