import axios from "axios";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "../../http/global-vars";
import ContentEditor from "../components/supportContentAdmin/ContentEditor";
import baseUrl from "../../http/backend-base-url";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactQuill from "react-quill";

function AddOrEditThematiqueModal({ modalId, refetch, theme }) {
  const schema = yup.object().shape({
    name: yup.string().required("Ce champ est requis."),
    description: yup.string().required("Ce champ est requis."),
    hasEdit: yup.boolean().default(false),
    logo: yup.mixed().when("hasEdit", {
      is: (val) => val === false,
      then: () => yup.mixed().required("Ce champ est requis."),
      otherwise: () => yup.mixed().nullable()
    })
  });
  const { register, handleSubmit, formState, setValue, reset, clearErrors } =
    useForm({resolver: yupResolver(schema)});
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [preview, setPreview] = useState(null);
  let userId = window.localStorage.getItem("userID");
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (theme) {
      register("hasEdit")
      register("creator_id");
      register("logo");
      register("description");
      setValue("description", theme?.description);
      setValue("name", theme?.name);
      setValue("creator_id", theme?.creator_id);
      setValue("hasEdit",true)
      if (theme?.logo) {
        setPreview(baseUrl + theme?.logo);
      }
      setDescription(theme?.description);
    } else {
      register("hasEdit")
      setValue("hasEdit",false)
      register("logo");
      register("description");
    }
  }, [theme]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleReset = () => {
    console.log("ref", ref);
    if (!theme) {
      ref?.current?.props?.onChange("");
      reset();
      setDescription(null);
      setPreview(null);
    }
    
  };

  const handleChangeImage = (e) => {
    let file = e?.target?.files[0];
    if (file) {
      setValue("logo", file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleChangeDescription = (val) => {
    setValue("description", val);
    setDescription(val);
  };

  const onSubmit = async (data) => {
    console.log(data);
    setIsLoading(true);
    const fd = new FormData();
    fd.append("name", data?.name);
    if (data?.logo) {
      fd.append("logo", data?.logo);
    }
    fd.append("description", data?.description);
    if (!theme) {
      fd.append("creator_id", userId);
    } else {
      fd.append("creator_id", data?.creator_id);
    }
    let url = null;
    let req = null;
    if (theme) {
      url = `${api}module/${theme?.id}/`;
      req = axios.put(url, fd, config);
    } else {
      url = `${api}thematique/`;
      req = axios.post(url, fd, config);
    }
    if (url && req) {
      await req
        .then((res) => {
          console.log("res", res);
          refetch();
          window.$(`#${modalId}`).modal("hide");
          handleReset();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          console.log("error", err?.response);
          toast.error(
            err?.response?.data?.message || "Une erreur est survenue."
          );
          setIsLoading(false);
        });
    }
  };
  return (
    <div
      className="modal fade sendPasswordModal"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content card-dashboard">
          <div className="row m-b-35">
            <div className="col-10 itm-center">
              <p className="title-modal">Add/Edit thématique</p>
            </div>
            <div className="col-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleReset()}
              >
                <span aria-hidden="true">
                  <FeatherIcon icon="x" />
                </span>
              </button>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12 mb-3 ">
                <div className="form-group">
                  <input
                    type="text"
                    {...register("name")}
                    className={`form-control input-dashboard-backend text-black ${
                      formState?.errors?.name ? "is-invalid" : ""
                    }`}
                    placeholder="Titre du module"
                  />
                  <span className="invalid-feedback">
                    <strong>{formState?.errors?.name?.message}</strong>
                  </span>
                </div>
              </div>
              <div className="col-md-12 m-b-40">
                <div className="form-group">
                  <label htmlFor="module-image">Image</label>
                  <div>
                    <FeatherIcon
                      className="icon-import-custom-file-label"
                      style={{ top: 50 }}
                      icon="upload"
                    />
                    <input
                      type="file"
                      id="module-image"
                      style={{ paddingTop: 10 }}
                      name="logo"
                      accept="image/*"
                      onChange={handleChangeImage}
                      className={`form-control input-dashboard-backend text-black mt-2 ${
                        formState?.errors?.logo ? "is-invalid" : ""
                      }`}
                      placeholder="Image"
                    />
                  </div>
                </div>
                {preview && (
                  <img
                    src={preview}
                    alt="logo"
                    style={{ width: 120, height: 120 }}
                  />
                )}
              </div>

              <div className="col-md-12">
                <div className="form-group m-b-40">
                  <ReactQuill
                    value={description}
                    ref={ref}
                    placeholder={"Description"}
                    name={`description`}
                    onChange={(value) => handleChangeDescription(value)}
                  />
                  <span className="invalid-feedbacks d-flex">
                    <strong className="p-2">
                      {formState?.errors?.description?.message}
                    </strong>
                  </span>
                </div>
              </div>
              <div className="col-md-6 offset-md-3 m-t-30">
                {!isLoading ? (
                  <button className="btn-submit-form">Enregistrer</button>
                ) : (
                  <button className="btn-submit-form" disabled>
                    Chargement... &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddOrEditThematiqueModal;
