import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default class DetailSessionSkeleton extends Component {

  render() {

    return (
      <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 p-t-25">
        <div className="">
          <SkeletonTheme color="#E0E4E2" highlightColor="#D1D5D3">
            <div className="card">
              <div>
                <p className="text-banner-image-cours-details">
                  <Skeleton variant="text" height={350} width={`100%`} />
                </p>
              </div>

              <div className="content-cours-details">
                <p className="text-up text-black m-b-20 fs-12 fw-600 f-f-work">
                  <Skeleton variant="text" height={28} width={`30%`} />
                </p>
                <p className="text-black m-b-40 fs-14 fw-300">
                  <Skeleton variant="text" height={40} width={`100%`} />
                </p>
                <div className="row m-b-30 dis-flex">
                  <div className="col-xl-4 col-lg-4 col-md-4 m-b-20">
                    <Skeleton height={30} width={`90%`} />
                  </div>
                  {/* <div className="col-xl-4 col-lg-5 col-md-5 ml-auto">
                                    <a className="btn-theme-light" href=""><FeatherIcon className="icon-button" icon="download" />Télécharger le cours</a>
                                </div> */}
                </div>

                <div className="ligne w-65 m-b-15"></div>

                <p className="text-up text-black m-b-20 fs-12 fw-600 f-f-work">
                  <Skeleton height={36} width={`20%`} />
                </p>
                <div className="row">
                  <div className="col-xl-2 col-lg-3 col-md-3 col-6 dis-flex flex-row">
                    <Skeleton
                      className="icon-details-infos-cours"
                      height={30}
                      width={30}
                      variant="rect"
                    />
                    <p className="text-details-infos-cours">
                      <Skeleton variant="text" height={20} width={100} />

                      <Skeleton height={20} width={100} />
                    </p>
                  </div>
                  <div className="col-xl-2 col-lg-3 col-md-3 col-6 dis-flex flex-row">
                    <Skeleton
                      className="icon-details-infos-cours"
                      height={30}
                      width={30}
                      circle={true}
                    />
                    <p className="text-details-infos-cours">
                      <Skeleton height={20} width={100} />
                      <span className="fw-600">
                        <Skeleton height={20} width={100} />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-session-details-page" >
                    <h6 className="text-up text-dark f-f-work fw-600">
                      <Skeleton className="link-details-sessions-on-title" variant="text" height={36} width={`30%`} />
                    </h6>
                   {/*  <div className="row">
                      <div className="col-xl-2 col-lg-3 col-md-3 col-6 dis-flex">
                        <Skeleton
                          className="icon-details-infos-cours"
                          height={30}
                          width={90}
                        />
                        <p className="text-details-infos-cours fw-500 fs-12">
                          <Skeleton height={30} width={90} />
                        </p>
                      </div>
                    </div> */}
                    <div
                      className="link-show-on-item-session-details-page"
                      
                    >
                      <Skeleton
                        className="icon-show-on-item-session-details-page"
                        height={30}
                        width={90}
                      />
                    </div>
                  </div>

            <p className="text-up text-black m-b-20 fs-12 fw-600 f-f-work">
              <Skeleton variant="text" height={36} width={150} />
            </p>

            {/**<LeconCard />*/}
            <div>
              
              {Array(4).fill().map((item, index) => {
                return (
                  <div className="item-session-details-page" key={index}>
                    <h6 className="text-up text-dark f-f-work fw-600">
                      <Skeleton className="link-details-sessions-on-title" variant="text" height={36} width={`30%`} />
                    </h6>
                   {/*  <div className="row">
                      <div className="col-xl-2 col-lg-3 col-md-3 col-6 dis-flex">
                        <Skeleton
                          className="icon-details-infos-cours"
                          height={30}
                          width={90}
                        />
                        <p className="text-details-infos-cours fw-500 fs-12">
                          <Skeleton height={30} width={90} />
                        </p>
                      </div>
                    </div> */}
                    <div
                      className="link-show-on-item-session-details-page"
                      
                    >
                      <Skeleton
                        className="icon-show-on-item-session-details-page"
                        height={30}
                        width={90}
                      />
                    </div>
                  </div>
                );
                
              })}
            </div>
            {/**<LeconCard /> */}

            <div>
              <div to="/cours-start">
                <Skeleton
                  className="btn-theme text-up"
                  height={30}
                  width={90}
                />
              </div>
            </div>
          </SkeletonTheme>
        </div>
      </div>
    );
  }
}
