import React, { Component } from "react";
// import { NavLink } from 'react-router-dom'
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import baseUrl from "../../http/backend-base-url";
import api from "../../http/global-vars";
import { formatDate } from "../../utils";
// import PaginationComponent from '../../modules/Pagination/Pagination'
import $ from "jquery";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";

//import 'rsuite/dist/styles/rsuite-default.css'

export default class CardAdminContactMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      countMessages: 0,
      messages: [],
      allMessages: [],
      messageItemInfos: {},
      per_page: 5,
      current_page: 1,
      total_data: "",
      currentList: [],
      fields: {},
      isloading: false,
      is_empty_text: false,
      errors: {},
      status: "",
      is_empty_status: false,
      listResponse: [],
      load: false,
      filterstatus: "",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.onGetMessagesInfos();
    }, 1000);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.message !== nextProps.message) {
      nextState.messages = nextProps.messages;
    }
  }

  onGetMessagesInfos = () => {
    var { countMessages, messages } = this.props;
    this.setState({
      countMessages: countMessages,
      messages: messages,
      allMessages: messages,
      total_data: messages.length,
    });
    this.formatData();
    // console.log(this.state.messages)
    // console.log("called")
  };

  onGetMessageItemInfos = (message) => {
    // console.log("message", message)
    this.setState(
      {
        messageItemInfos: message,
      },
      async () => await this._getResponseContact(message.id)
    );
  };

  _getResponseContact = async (id_contact) => {
    let { requestConfig } = this.state;
    const url = api + "responsebycontact/" + id_contact;
    try {
      const req = await axios.get(url, requestConfig);
      // console.log('reponse message=>', req)
      this.setState({ listResponse: req["data"] });
    } catch (error) {
      console.log("erreur=>", error);
    }
  };

  formatData() {
    const indexOfLastPost = this.state.current_page * this.state.per_page;
    const indexOfFirstPage = indexOfLastPost - this.state.per_page;
    console.log("filter", this.state.messages[0]);
    const currentList = this.state.messages.slice(
      indexOfFirstPage,
      indexOfLastPost
    );

    this.setState({ currentList });
  }

  handleClick = (number) => {
    this.setState(
      {
        current_page: number,
      },
      () => {
        this.formatData();
      }
    );
  };

  handleChange = (e, message) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["user"] = message.user?.id;
    fields["email"] = message?.email;
    fields["message"] = message.id;
    fields["admin"] = parseInt(this.state.userID);
    console.log("reponse", fields);

    this.setState({ fields });
  };

  handleStatusChange = (e, message) => {
    // console.log('state', e.target.value, message)
    this.setState({ status: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let errors = this.state.errors;
    if (!this.state.fields["response"]) {
      this.setState({
        is_empty_text: true,
      });

      setTimeout(() => {
        this.setState({
          is_empty_text: false,
        });
      }, 5000);

      errors["response"] = "Ce champ est obligatoire";

      this.setState({ errors });
    } else {
      this.setState({ isloading: true });
      const url = api + "responsecontact/";
      console.log("url", this.state.fields);
      await axios
        .post(url, this.state.fields, this.state.requestConfig)
        .then((response) => {
          console.log("response", response);
          // this.setState({ isloading: false })
          toast.success("réponse envoyée !");
          $(".close").click();
          this.setState({ fields: {}, isloading: false });
          //this.props.onUpdateContactStatus(response.data.message)
        })
        .catch((e) => {
          console.log("error request", e);
          toast.error(
            "Oupsss! Une erreur est survenue lors de l'envoi. Veuillez réessayer plus tard."
          );
          this.setState({ isloading: false });
        });
    }
  };

  onUpdateStatusDone = async (e, message) => {
    e.preventDefault();
    this.setState({ load: true });
    console.log("test", message);
    try {
      if (this.state.status) {
        await this.props.onUpdateContactStatusDone(
          message.id,
          this.state.status
        );
        setTimeout(async () => {
          await this.onGetMessagesInfos();
          this.setState({ load: false });
          toast.success("Statut modifié avec succès", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          $(".close").click();
          this.setState({ status: "" });
        }, 2000);
      } else {
        let errors = this.state.errors;
        this.setState({
          is_empty_status: true,
          load: false,
        });

        setTimeout(() => {
          this.setState({
            is_empty_status: false,
          });
        }, 5000);

        errors["status"] = "Veuillez selectionner un état d'abord";
        this.setState({ errors });
      }
    } catch (error) {
      console.log("error=>", error);
      this.setState({ load: false });
    }
  };

  handleFilterStatus = async (e) => {
    // let { messages } = this.state
    let messages = [];
    if (e.target.value !== "all") {
      messages = await this.state.allMessages.filter(
        (message) => message?.etat === e.target.value
      );
    } else {
      messages = await this.state.allMessages;
    }
    // console.log("mess", messages)
    await this.setState({
      countMessages: messages?.length,
      messages: messages,
      total_data: messages.length,
    });
    // await this.setState({messages: messages})
    await this.formatData();
  };

  // sortArray = type => {
  //   const types = {
  //     etat: 'etat',
  //   };
  //   const sortProperty = types[type];
  //   const sorted = this.state.messages.sort((a, b) => b[sortProperty] - a[sortProperty]);
  //   console.log(sorted);
  // };

  render() {
    var { countMessages, per_page, total_data, current_page, currentList } =
      this.state;

    // function sortBy(key) {
    //   return function (a, b) {
    //     return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
    //   };
    // }

    // console.log("test=>", this.state.messages.sort(sortBy('etat')))

    return (
      <div className="card-admin-contact-message-component">
        {countMessages > 0 ? (
          <select
            name="etat"
            style={{
              width: "auto",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
            id="etat"
            // value={this.state.filterstatus}
            className="form-control"
            onChange={(e) => this.handleFilterStatus(e)}
          >
            <option value="all">Filtrer par statut</option>
            <option value="nouveau">Nouveau</option>
            <option value="en_cours">En cours</option>
            <option value="résolu">Résolu</option>
          </select>
        ) : null}

        {countMessages === 0 ? (
          <div className="dis-flex flexy-container p-t-150">
            <div className="no-access-certificat-row row">
              <div className="col-md-12 no-access-certificat-col">
                <div className="alert alert-warning" role="alert">
                  <div className="row vertical-align">
                    <div className="col-md-2 text-center">
                      <i className="fa fa-exclamation-triangle fa-3x"></i>
                    </div>
                    <div className="col-md-10">
                      <strong className="strongify">Alerte :</strong> Vous
                      n'avez reçu aucun message pour le moment.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {currentList.map((item, index) => (
              <div
                className="card-dashboard m-b-30 "
                key={`cm-list-item${index}`}
              >
                <div className="row">
                  <div
                    className="col-xl-5 col-lg-5 col-md-6 linkable"
                    onClick={() => this.onGetMessageItemInfos(item)}
                    data-toggle="modal"
                    data-target=".messageContentItemModal"
                  >
                    <span className="titre-forum-card">{item.objet}</span>
                    <p className="publish-date-forum-card m-b-20">
                      Le {formatDate(item.created_at)}
                    </p>
                  </div>
                  <div className=" col-xl-4 col-lg-3 col-md-2 col-sm-6 message-status-container">
                    <span
                      style={{
                        width: "auto",
                        height: "auto",
                        color: "#ffffff",
                        background: `${
                          item.etat === "nouveau"
                            ? "red"
                            : item.etat === "en_cours"
                            ? "orange"
                            : "#0080009c"
                        }`,
                      }}
                      className={
                        item.etat === "nouveau"
                          ? "badge badge-danger linkable gfa-badge-danger"
                          : item.etat === "en_cours"
                          ? "badge badge-warning linkable gfa-badge-warning"
                          : "badge badge-success linkable gfa-badge-success"
                      }
                      data-toggle="modal"
                      data-target={`#exampleModalCenter${item.id}`}
                    >
                      {item.etat === "en_cours" ? "en cours" : item.etat}
                    </span>
                  </div>
                  {item.user !== null ? (
                    <div className=" col-xl-3 col-lg-4 col-md-4 col-sm-6 dis-flex m-b-10">
                      <img
                        className="avatar-auteur-forum-card"
                        src={`${baseUrl}${item.user?.avatar}`}
                        alt="Avatar"
                      />
                      <p className="text-forum-card fs-12 fw-300">
                        Par&nbsp;
                        <strong>
                          {item.user?.first_name + " " + item.user?.last_name}
                        </strong>
                      </p>
                    </div>
                  ) : (
                    <div className=" col-xl-3 col-lg-4 col-md-4 col-sm-6 dis-flex m-b-10">
                      <img
                        className="avatar-auteur-forum-card"
                        src={`${baseUrl}/uploads/avatars/default.png`}
                        alt="Avatar"
                      />
                      <p className="text-forum-card fs-12 fw-300">
                        Par&nbsp;
                        <strong>Utilisateur non authentifié</strong>
                      </p>
                    </div>
                  )}
                </div>

                <div
                  className="modal fade"
                  id={`exampleModalCenter${item.id}`}
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                          Modification du statut
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => this.setState({ status: "" })}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="etat"
                            id="etat"
                            value={this.state.status}
                            onChange={(e) => this.handleStatusChange(e, item)}
                          >
                            <option value="">
                              --Modifier le status du message---
                            </option>
                            <option value="nouveau">Nouveau</option>
                            <option value="en_cours">En cours</option>
                            <option value="résolu">Terminé</option>
                          </select>
                          {this.state.is_empty_status && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {" "}
                              {this.state.errors.status}{" "}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="modal-footer"
                        style={{ justifyContent: "space-between" }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={(e) => this.setState({ status: "" })}
                          style={{
                            backgroundColor: "red",
                            borderRadius: "12px",
                            borderColor: "red",
                          }}
                        >
                          Annuler
                        </button>
                        {!this.state.load ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "green",
                              borderRadius: "12px",
                              borderColor: "green",
                            }}
                            onClick={(e) => this.onUpdateStatusDone(e, item)}
                          >
                            Modifier
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "green",
                              borderRadius: "12px",
                              borderColor: "green",
                            }}
                          >
                            Modification en cours &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <Pagination
              itemClass="page-item" // add it for bootstrap 4
              linkClass="page-link" // add it for bootstrap 4
              activePage={current_page}
              itemsCountPerPage={per_page}
              totalItemsCount={total_data}
              pageRangeDisplayed={per_page}
              onChange={this.handleClick}
            />
          </>
        )}

        <div
          className="modal fade messageContentItemModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel23"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content modal-card">
              <div className="row m-b-35">
                <div className="col-10 itm-center">
                  {/* <p className="title-modal">Message de {this.state.messageItemInfos.length !== 0 ? this.state.messageItemInfos.user.first_name : '' }</p> */}
                  {Object.keys(this.state.messageItemInfos).length !== 0 &&
                  this.state.messageItemInfos.user !== null ? (
                    <>
                      <img
                        className="avatar-auteur-forum-card"
                        src={`${baseUrl}${this.state.messageItemInfos?.user?.avatar}`}
                        alt="Avatar"
                      />
                      <p className="text-forum-card fs-12 fw-300 sender-name">
                        Par&nbsp;
                        <strong>
                          {this.state.messageItemInfos?.user?.first_name +
                            " " +
                            this.state.messageItemInfos?.user?.last_name}
                        </strong>{" "}
                        <br />
                        <span className="cm-sender-email">
                          {" "}
                          {this.state.messageItemInfos?.user?.email}{" "}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <img
                        className="avatar-auteur-forum-card"
                        src={`${baseUrl}/uploads/avatars/default.png`}
                        alt="Avatar"
                      />
                      <p className="text-forum-card fs-12 fw-300 sender-name">
                        Par&nbsp;
                        <strong>Utilisateur non authentifié</strong> <br />
                      </p>
                    </>
                  )}
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => this.setState({ fields: {}, status: "" })}
                  >
                    <span aria-hidden="true">
                      <FeatherIcon icon="x" />
                    </span>
                  </button>
                </div>
              </div>

              <div className="row m-b-35 message-item-row">
                {/* <div className="row"> */}
                <div className="col-md-5 message-item-objet">
                  <h3 className="message-title">
                    {" "}
                    {this.state.messageItemInfos?.objet}{" "}
                  </h3>
                  <span className="message-created-at-label">
                    {" "}
                    {`reçu le ${formatDate(
                      this.state.messageItemInfos?.created_at
                    )}`}{" "}
                  </span>
                </div>
                <div className="col-md-7" style={style.select_style}>
                  {this.state.status.length > 0 ? (
                    !this.state.load ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "green",
                          borderRadius: "12px",
                          borderColor: "green",
                          marginLeft: "4px",
                          marginRight: "-6px",
                        }}
                        onClick={(e) =>
                          this.onUpdateStatusDone(
                            e,
                            this.state.messageItemInfos
                          )
                        }
                      >
                        Modifier
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "green",
                          borderRadius: "12px",
                          borderColor: "green",
                          marginLeft: "4px",
                          marginRight: "-6px",
                        }}
                      >
                        &nbsp;<i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )
                  ) : null}
                  <select
                    name="etat"
                    style={{ width: "auto" }}
                    id="etat"
                    value={this.state.status}
                    className="form-control"
                    onChange={(e) => {
                      this.handleStatusChange(e, this.state.messageItemInfos);
                      // console.log("infos statut=>", this.state.messageItemInfos)
                    }}
                  >
                    <option value="">--Modifier le status du message---</option>
                    <option value="nouveau">Nouveau</option>
                    <option value="en_cours">En cours</option>
                    <option value="résolu">Terminé</option>
                  </select>
                </div>
                {/* </div> */}
                <div className="col-md-12 message-item-content">
                  <p>{this.state.messageItemInfos?.message}</p>
                </div>
              </div>
              <div>
                <textarea
                  className="col-md-12"
                  name="response"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                  placeholder="Message"
                  value={
                    this.state.fields?.response
                      ? this.state.fields?.response
                      : " "
                  }
                  rows="5"
                  onChange={(e) =>
                    this.handleChange(e, this.state?.messageItemInfos)
                  }
                ></textarea>
                {this.state.is_empty_text && (
                  <div className="alert alert-danger gfa-alert-danger">
                    {" "}
                    {this.state.errors.reponse}{" "}
                  </div>
                )}
              </div>
              <div style={{ textAlign: "end" }}>
                {this.state.isloading ? (
                  <button className="btn-message in-progress-btn" disabled>
                    Réponse en cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                ) : (
                  <button className="btn-message" onClick={this.handleSubmit}>
                    Envoyer
                  </button>
                )}
              </div>
              {this.state.listResponse.length > 0 && (
                <React.Fragment>
                  {this.state.listResponse
                    .sort(
                      (a, b) =>
                        formatDate(a.created_at) < formatDate(b.created_at)
                    )
                    .map((r, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className="card-dashboard"
                            style={{ marginTop: "12px" }}
                          >
                            <div className="col-12 itm-center">
                              <img
                                className="avatar-auteur-forum-card"
                                src={`${baseUrl}${r.admin?.avatar}`}
                                alt="Avatar"
                              />
                              <p className="text-forum-card fs-12 fw-300 sender-name">
                                Par&nbsp;
                                <strong>
                                  {`${
                                    r.admin?.first_name +
                                    " " +
                                    r.admin?.last_name
                                  }`}
                                </strong>
                                <br />
                                <span className="cm-sender-email">
                                  {`${r.admin?.email}`}{" "}
                                </span>
                              </p>
                            </div>
                            <div className="col-12 itm-center">
                              <p
                                style={style.resp_style}
                                className="text-forum-card fs-12 fw-300"
                              >
                                {r.response}
                              </p>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const style = {
  resp_style: {
    margin: "1em 0px 0em 0em",
    fontSize: "20px",
    fontWeight: "500",
  },
  select_style: {
    display: "flex",
    alignItems: "self-start",
    flexDirection: "row-reverse",
  },
};
