import Axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../http/global-vars";

function ModalEditScale({ scale, onReload }) {
  const [isEmptyQuestion, setIsEmptyQuestion] = useState(false);
  const [fields, setFields] = useState({
    question: scale?.question,
    response: "___",
  });
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const handleChange = (e) => {
    fields[e.target.name] = e.target.value;
    setFields(fields);
  };
  const onValidateFormData = () => {
    let errorsCopy = errors;
    var isValidForm = true;

    if (!fields?.question) {
      isValidForm = false;
      setIsEmptyQuestion(true);
      errorsCopy["question"] = "Ce champ est obligatoire";
      setTimeout(() => {
        setIsEmptyQuestion(false);
      }, 5000);
    }
    setErrors(errorsCopy);

    return isValidForm;
  };

  const notify = () => {
    toast.success("Element modifiée avec succès", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  const renderErrorFor = (field) => {
    return (
      <span className="invalid-feedback">
        <strong>{errors[field]}</strong>
      </span>
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let url = api + "scale/" + scale?.id + "/";
    let data = fields;

    if (onValidateFormData()) {
      setLoad(true);
      setErrors({});
      await Axios.put(url, data, {
        headers: { Authorization: `Bearer ${localStorage.userToken}` },
      })
        .then(async (response) => {
          console.log("response", response);
          await onReload();
          await setLoad(false);
          await notify();
          await window.$(`#editScaleModal${scale?.id}`).modal("hide");
        })
        .catch((err) => {
          setLoad(false);
          console.log("err", err?.response);
          toast.error(
            err?.response?.data?.message ||
              `Une erreur de statut ${err?.response?.data?.status} est survenue !`
          );
        });
    }
  };
  return (
    <div>
      <h5 className="text-black m-b-50">Modifier un scale</h5>
      <form onSubmit={onSubmit} id="fieldreset">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="question"
                onChange={handleChange}
                defaultValue={fields?.question}
                className={`form-control input-dashboard-backend text-black ${
                  isEmptyQuestion ? "is-invalid" : ""
                }`}
                placeholder="Scale "
              />
              {isEmptyQuestion && renderErrorFor("question")}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row dis-flex justify-content-end">
              <div className="col-lg-6 col-md-12">
                {!load ? (
                  <button className="btn-submit-form">Modifier</button>
                ) : (
                  <button className="btn-submit-form">
                    Chargement... &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ModalEditScale;
