import React from "react";
export default class ContentEditor extends React.Component {
  state = {
    name: Math.random(),
  };
  editor = null;
  componentDidMount() {
    window.ClassicEditor.create(document.getElementById(this.state.name), {
      initialData: this.props?.value || "",
      placeholder: this.props.placeholder,
      value: this.props.value,
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "indent",
          "outdent",
          "|",
          //  "insertTable",
          "imageUpload",
          "blockQuote",
          "mediaEmbed",
          "undo",
          "redo",
        ],
      },
      language: "en",
      image: {
        toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
      },
      licenseKey: "",
    })
      .then((ed) => {
        const modelDocument = ed.model.document;
        modelDocument.on("change:data", (event) => {
          const data = ed.getData();
          if (this.props.onChange) {
            this.props.onChange(data);
          }
          // console.log("data event",data,event)
        });
        // console.log("modelDocument",modelDocument)
        this.editor = ed;
      })
      .catch((err) => {
        console.log("error editor", err.stack);
      });
  }
  // UNSAFE_componentWillReceiveProps({ value: data }) {
  //   if (this.editor) {
  //     const ed = this.editor.getData();
  //     if (!ed) {
  //       if (data) {
  //         this.editor.setData(data);
  //       } else {
  //         this.editor.setData("");
  //       }
  //       console.log("ed", ed,"&data", data);
  //     }
      
  //   } else {
  //     console.log("data", data);
  //   }
    
  // }
  render() {
    return (
      <div style={{ marginBottom: 20, margintop: 20 }}>
        <div id={this.state.name} />
      </div>
    );
  }
}
