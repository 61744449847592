import axios from "axios";
import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast, ToastContainer } from "react-toastify";
import api from "../../http/global-vars";
import ContentEditor from "./supportContentAdmin/ContentEditor";

export default class CreateVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      fields: {
        name: "",
        url_vimeo: "",
        description: "",
      },
      url: null,
      content_type: "video",
      created_by: parseInt(window.localStorage.getItem("userID")),
      isSuccess: false,
      isError: false,
      isLoading: false,
      is_empty_name: false,
      is_empty_session: false,
      is_empty_module: false,
      is_empty_url: false,
      contentId: this.props.contentId,
      errors: {},
    };
  }

  handleChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields,
    });
  };

  onValidateForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
        });
      }, 5000);

      errors["name"] = "Ce champ est obligatoire";
    }

    if (!fields["url_vimeo"]) {
      isValidForm = false;
      this.setState({
        is_empty_url: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_url: false,
        });
      }, 5000);

      errors["url_vimeo"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  addForm = (e) => {
    e.preventDefault();

    if (this.onValidateForm()) {
      this.setState({
        isLoading: true,
      });

      var api = this.state.api;
      var data = {
        name: this.state.fields.name,
        url_vimeo: this.state.fields.url_vimeo,
        description: this.state.fields.description,
        contenu: this.state.contentId,
        content_type: this.state.content_type,
        created_by: this.state.created_by,
      };

      axios
        .post(api + "support/", data, this.state.requestConfig)
        .then((response) => {
          // console.log(response)
          if (response.status === 201) {
            this.props.notify();
            this.setState({
              isSuccess: true,
              isLoading: false,
              fields: {
                name: "",
                url_vimeo: "",
              },
            });
            setTimeout(() => {
              this.setState({
                isSuccess: false,
                message: {},
              });
            }, 5000);
          } else {
            toast.error("Vos données ne sont pas valides !");
          }
        })
        .catch((error) => {
          console.log(error.response);
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
          );
          this.setState({
            isError: true,
            isLoading: false,
          });

          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 5000);
        });
    }
  };
  render() {
    return (
      <div className="card-dashboard no-shadow">
        <form>
          <div className="row m-b-50">
            <div className="col-lg-9">
              <p className="titre-card-create-content">Video</p>
            </div>

            <div className="col-lg-3">
              <span className="text-inside-select-bg-theme">Ordre</span>
              <select className="custom-select select-bg-theme" id="">
                <option value="">0</option>
              </select>
            </div>
          </div>

          <div className="form-group m-b-40">
            <input
              type="text"
              className="form-control input-dashboard-backend"
              placeholder="Titre de la vidéo"
              name="name"
              onChange={(e) => this.handleChange(e)}
            />
            {this.state.is_empty_name && (
              <div className="alert alert-danger keysjob-alert-danger">
                {" "}
                {this.state.errors.name}{" "}
              </div>
            )}
          </div>
          <div className="form-group m-b-40">
            <input
              type="url"
              className="form-control input-dashboard-backend"
              placeholder="Lien de la vidéo"
              name="url_vimeo"
              onChange={(e) => this.handleChange(e)}
            />
            {this.state.is_empty_url && (
              <div className="alert alert-danger keysjob-alert-danger">
                {" "}
                {this.state.errors.name}{" "}
              </div>
            )}
          </div>

          <ContentEditor
            placeholder="Résumé de la vidéo"
            value={this.state.fields.description}
            name="video_description"
            onChange={(val) =>
              this.setState({
                fields: { ...this.state.fields, description: val },
              })
            }
          />

          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 m-b-20">
              {this.state.isLoading === false ? (
                <button
                  type="submit"
                  onClick={(e) => this.addForm(e)}
                  className="btn-theme text-up w-full"
                >
                  Terminer
                </button>
              ) : (
                <button className="btn-theme text-up w-full in-progress-btn">
                  Ajout en cours... &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
      </div>
    );
  }
}
