import axios from "axios";
import { EditorState } from "draft-js";
import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import api from "../../http/global-vars";

export default class CreateUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      fields: {},
      questions: [""],
      reponses: [""],
      editorState: EditorState.createEmpty(),
      created_by: parseInt(window.localStorage.getItem("userID")),
      isSuccess: false,
      isError: false,
      isLoading: false,
      is_empty_name: false,
      is_empty_text: false,
      is_empty_texte: false,
      content_type: "upload",
      contentId: this.props.contentId,
      errors: {},
      global: [""],
    };
  }

  addResponseForm = (e) => {
    //this method adds label and input field to the state array
    e.preventDefault();
    this.setState({
      reponses: {
        length: this.state.reponses.length + 1,
      },
    });
    /**A ne pas commenter svp */
    // eslint-disable-next-line
    this.state.reponses.length = this.state.reponses.length + 1;
    const editedArray = [...this.state.reponses];
    this.setState({ reponses: editedArray });
  };

  handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...this.state.questions];
    list.splice(index, 1);
    this.setState({ questions: list });
  };

  handleRemoveClic = (e, index) => {
    e.preventDefault();
    const list = [...this.state.reponses];
    list.splice(index, 1);
    this.setState({ reponses: list });
  };

  addQuestionForm = (e) => {
    //this method adds label and input field to the state array
    e.preventDefault();
    this.setState({
      questions: {
        length: this.state.questions.length + 1,
      },
    });

    /**A ne pas commenter */
    // eslint-disable-next-line
    this.state.questions.length = this.state.questions.length + 1;
    const updatedArray = [...this.state.questions];
    this.setState({ questions: updatedArray });
    // this.addResponseForm(e);
  };

  onChange = (editorState) => {
    this.setState({ editorState });
  };

  handleChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    console.log(fields);
    this.setState({
      fields: fields,
    });
  };

  onValidateForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
        });
      }, 5000);

      errors["name"] = "Ce champ est obligatoire";
    }

    if (!this.state.questions[0]) {
      isValidForm = false;
      this.setState({
        is_empty_text: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_text: false,
        });
      }, 5000);

      errors["texte"] = "Ce champ est obligatoire";
    }

    if (!this.state.reponses[0]) {
      isValidForm = false;
      this.setState({
        is_empty_texte: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_texte: false,
        });
      }, 5000);

      errors["work"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  addForm = (e) => {
    e.preventDefault();
    console.log("valid", this.state.contentId);
    if (this.onValidateForm()) {
      this.setState({
        isLoading: true,
      });

      var api = this.state.api;
      var data = {
        title: this.state.fields.name,
        contenu: this.state.contentId,
        content_type: this.state.content_type,
        //list_mots_enlever: this.state.list_mots_enlever,
        created_by: this.state.created_by,
      };

      axios
        .post(api + "quizfree/", data, this.state.requestConfig)
        .then((response) => {
          // console.log(response)
          if (response.status === 201) {
            console.log("sucess");

            for (let i = 0; i < this.state.questions.length; i++) {
              for (let j = 0; j < this.state.reponses.length; j++) {
                // const el = this.state.reponses[j];
                if (i === j) {
                  console.log("cool", i);
                  const element = this.state.questions[i];
                  const colonnes = {
                    content: element,
                    quiz: response.data.id,
                  };
                  console.log("data", colonnes);
                  axios
                    .post(
                      api + "questionfree/",
                      colonnes,
                      this.state.requestConfig
                    )
                    .then((resp) => {
                      console.log("colonnes", resp.data);
                      if (resp.data) {
                        const el = this.state.reponses[j];
                        const reponses = {
                          content: el,
                          question: resp.data.id,
                        };
                        axios
                          .post(
                            api + "responsequestionfree/",
                            reponses,
                            this.state.requestConfig
                          )
                          .then((item) => {
                            console.log("mots", item.data);

                            this.setState({
                              isSuccess: true,
                              isLoading: false,
                              fields: {},
                              questions: [""],
                              reponses: [""],
                            });
                          })
                          .catch((e) => {
                            console.log("response error", e.response);
                          });
                      }
                    })
                    .catch((e) => {
                      console.log("error question", e.response);
                    });
                }
              }
            }
            this.props.notify();
            setTimeout(() => {
              this.setState({
                isSuccess: false,
                message: {},
              });
            }, 5000);
          } else {
            toast.error("Vos données ne sont pas valides !");
          }
        })
        .catch((error) => {
          console.log(error.response);
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
          );
          this.setState({
            isError: true,
            isLoading: false,
          });

          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 5000);
        });
    }
  };

  checkQuestionInput(index, e) {
    console.log("ind", index);
    this.setState({
      questions: this.state.questions.map((item, i) =>
        i === index ? e.target.value : item
      ),
    });
    console.log("questions", this.state.questions);
  }

  checkResponseInput(index, e) {
    console.log("ind", index);
    this.setState({
      reponses: this.state.reponses.map((title, i) =>
        i === index ? e.target.value : title
      ),
    });
    console.log("responses", this.state.reponses);
  }

  render() {
    const inputQ = this.state.questions.map((item, index) => (
      <div className="row m-b-5" key={index}>
        <div className="col-lg-3 col-md-4 col-sm-4 m-b-15 itm-center">
          <span className="fs-15 fw-400">Question {index + 1}</span>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8">
          <div className="form-group">
            <input
              type="text"
              className="form-control input-dashboard-backend border-theme text-theme"
              placeholder="Ajouter une question "
              value={item}
              onChange={(e) => this.checkQuestionInput(index, e)}
            />
            {this.state.questions.length !== 1 && (
              <button
                className="btn-placeholder-textarea"
                onClick={(e) => this.handleRemoveClick(e, index)}
              >
                <FeatherIcon className="close" icon="x" />
              </button>
            )}
            {this.state.is_empty_text && (
              <div className="alert alert-danger keysjob-alert-danger">
                {" "}
                {this.state.errors.texte}{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    ));

    const inputsR = this.state.reponses.map((title, index) => (
      <div className="row m-b-40" key={index}>
        <div className="col-lg-3 col-md-4 col-sm-4 m-b-15 itm-center">
          <span className="fs-15 fw-400">Champ de réponse {index + 1}</span>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-8">
          <div className="form-group" key={index}>
            <input
              type="text"
              className="form-control input-dashboard-backend bg-theme-dark text-theme"
              placeholder=""
              value={title}
              onChange={(e) => this.checkResponseInput(index, e)}
            />
            {this.state.reponses.length !== 1 && (
              <button
                className="btn-placeholder-textarea"
                onClick={(e) => this.handleRemoveClic(e, index)}
              >
                <FeatherIcon className="close" icon="x" />
              </button>
            )}

            {this.state.is_empty_texte && (
              <div className="alert alert-danger keysjob-alert-danger">
                {" "}
                {this.state.errors.work}{" "}
              </div>
            )}

            {/* <button onClick={() => this.handleRemoveClick(index)}>
              remove
            </button> */}
          </div>
        </div>
      </div>
    ));

    const inputsG = this.state.global.map((item, index) => (
      <div className="ddd" key={index}>
        {inputQ}
        {inputsR}
      </div>
    ));
    return (
      <div className="card-dashboard no-shadow">
        <form>
          <div className="row m-b-50">
            <div className="col-lg-9">
              <p className="titre-card-create-content">Upload</p>
            </div>

            <div className="col-lg-3">
              <span className="text-inside-select-bg-theme">Ordre</span>
              <select className="custom-select select-bg-theme" id="">
                <option value="">0</option>
              </select>
            </div>
          </div>

          <div className="form-group m-b-60">
            <input
              type="text"
              className="form-control input-dashboard-backend"
              placeholder="Consigne"
              name="name"
              onChange={(e) => this.handleChange(e)}
            />
            {this.state.is_empty_name && (
              <div className="alert alert-danger keysjob-alert-danger">
                {" "}
                {this.state.errors.name}{" "}
              </div>
            )}
          </div>

          {inputsG}

          <div className="row m-b-100">
            <div className="col-lg-3 col-md-12"></div>
            <div className="col-lg-8 col-md-12">
              <div className="row">
                <div className="col-lg-6">
                  <button
                    type="button"
                    className="btn-light-add-content fs-12"
                    data-toggle="modal"
                    data-target=".modalCreateContent"
                    onClick={(e) => this.addResponseForm(e)}
                  >
                    <FeatherIcon
                      className="icon-btn-add-dashboard"
                      icon="plus"
                    />
                    Ajouter un champ réponse
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    type="button"
                    className="btn-light-add-content fs-12"
                    data-toggle="modal"
                    data-target=".modalCreateContent"
                    onClick={(e) => this.addQuestionForm(e)}
                  >
                    <FeatherIcon
                      className="icon-btn-add-dashboard"
                      icon="plus"
                    />
                    Ajouter une question
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 m-b-20">
              {/* <button className="btn-theme text-up w-full">Terminer</button> */}
              {this.state.isLoading === false ? (
                <button
                  type="submit"
                  onClick={(e) => this.addForm(e)}
                  className="btn-theme text-up w-full"
                >
                  Terminer
                </button>
              ) : (
                <button className="btn-theme text-up w-full in-progress-btn">
                  Ajout en cours... &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
      </div>
    );
  }
}
