import Axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../http/global-vars";

function ModalEditSession({ session, getSessions }) {
  const [isEmptyName, setIsEmptyName] = useState(false);
  const [isEmptyDescription, setIsEmptyDescription] = useState(false);
  const [fields, setFields] = useState({
    name: session?.name,
    description: session?.description,
    module_id: session?.module_id?.id,
  });
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const handleChange = (e) => {
    fields[e.target.name] = e.target.value;
    setFields(fields);
  };
  const onValidateFormData = () => {
    let errorsCopy = errors;
    var isValidForm = true;

    if (!fields?.name) {
      isValidForm = false;
      setIsEmptyName(true);
      errorsCopy["name"] = "Ce champ est obligatoire";
      setTimeout(() => {
        setIsEmptyName(false);
      }, 5000);
    }
    if (!fields?.description) {
      isValidForm = false;
      setIsEmptyDescription(true);
      errorsCopy["description"] = "Ce champ est obligatoire";
      setTimeout(() => {
        setIsEmptyDescription(false);
      }, 5000);
    }

    setErrors(errorsCopy);

    return isValidForm;
  };

  const notify = () => {
    toast.success("Session modifiée avec succès", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });
  };

  const renderErrorFor = (field) => {
    return (
      <span className="invalid-feedback">
        <strong>{errors[field]}</strong>
      </span>
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let url = api + "session/" + session?.id + "/";
    let data = fields;

    if (onValidateFormData()) {
      setLoad(true);
      setErrors({});
      await Axios.put(url, data, {
        headers: { Authorization: `Bearer ${localStorage.userToken}` },
      })
        .then(async (response) => {
          console.log("response", response);
          await getSessions();
          await setLoad(false);
          await notify();
          await window.$(`#editSessionModal${session?.id}`).modal("hide");
        })
        .catch((err) => {
          setLoad(false);
          console.log("err", err?.response);
          toast.error(
            err?.response?.data?.message ||
              `Une erreur de statut ${err?.response?.data?.status} est survenue !`
          );
        });
    }
  };
  return (
    <div>
      <h5 className="text-black m-b-50">Modifier une session</h5>
      <form onSubmit={onSubmit} id="fieldreset">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="name"
                onChange={handleChange}
                defaultValue={fields?.name}
                className={`form-control input-dashboard-backend text-black ${
                  isEmptyName ? "is-invalid" : ""
                }`}
                placeholder="Titre de la session"
              />
              {isEmptyName && renderErrorFor("name")}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-group m-b-40">
              <textarea
                type="text"
                name="description"
                defaultValue={fields?.description}
                onChange={handleChange}
                className={`form-control input-dashboard-backend text-black b-r-15 p-t-15 p-b-15 ${
                  isEmptyDescription ? "is-invalid" : ""
                }`}
                placeholder="Description"
                rows="4"
              />
              {isEmptyDescription && renderErrorFor("description")}
            </div>
            <div className="row dis-flex justify-content-end">
              <div className="col-lg-6 col-md-12">
                {!load ? (
                  <button className="btn-submit-form">Modifier</button>
                ) : (
                  <button className="btn-submit-form">
                    Chargement... &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ModalEditSession;
