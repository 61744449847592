import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import ReactPlayer from "react-player";
import api from "../../http/global-vars";
import axios from 'axios'
import baseUrl from "../../http/backend-base-url";

//const BaseUrl = "https://www.andu-gfa-backend.withvolkeno.com";
export default class TacheVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      BaseUrl: baseUrl,
      isDone: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getSessionByModule();
    }, 3000);
  }

  _redirect = () => {
    console.log("last", this.props.last[0]?.id, this.props.video[0].id);
    if (this.props.last[0]?.id === this.props.video[0].id) {
      // console.log("END");
      var elementPos = this.props.sessions
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.props.sessionId);
      var objectFound = this.props.sessions[elementPos + 1];
      //this.setState({ endSession: true, selectedId: objectFound?.id });
      const Data = {
        user: parseInt(this.state.userID),
        session: this.props.sessionId,
      };
      //console.log("qst", this.state.selectedId);
      axios
        .post(api + "userdosession/", Data, this.state.requestConfig)
        .then((data) => {
          console.log("data", data.data);
          // window.location = `/session-start/session/${this.state.selectedId}`;
        })
        .catch((e) => {
          console.log("e", e.response);
        });

      window.location = `/session-start/session/${objectFound?.id}`;
    }
    //return (window.location = `/session-start/session/${this.state.selectedId}`);
  };

  updateProgres = async () => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id
    );
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;

    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };

    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  UserDoContenu = async () => {
    let checker = (arr) =>
      arr.every(
        (v) => parseInt(v?.contenu?.id) !== this.props.video[0]?.contenu
      );
   // console.log("check contenu", checker(this.props.usercontenus));
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.props.video[0]?.contenu,
    };
    if (checker(this.props.usercontenus) !== false) {
      const url = api + "userdocontenu/";
      await axios
        .post(url, sendData, this.state.requestConfig)
        .then((res) => {
          console.log("data post", res);
          this.setState({ isDone: true });
          this.updateProgres();
        })
        .catch((e) => {
          console.log("e post", e.response.data);
        });
    }
    this.props.onGetUserDoModules();
    window.scrollTo(0, 0);
  };

  ScrollTop = () => {
    this.props.onGetUserDoModules();
    window.scrollTo(0, 0);
  };

  getSessionByModule = (e) => {
    if (this.props.sessionId) {
      const url = api + "session/" + this.props.sessionId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) => d.module_id === response.data.module_id
          );
          let data = response["data"];
          this.setState({ session: last, module_id: data.module_id });
          //console.log("session", last);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  };

  render() {
    //console.log(this.props);
    let checker = (arr) =>
      arr.every(
        (v) => parseInt(v?.contenu?.id) !== this.props.video[0]?.contenu
      );
    return (
      <div>
        {this.props.video.map((item, i) => {
          return (
            <div key={item?.id}>
              <p className="titre-lecon-start-page">{item.name}</p>
              <div className="content-video-viemo">
                
                <ReactPlayer
                  config={{
                    vimeo: {
                      playerOptions: { title: false, byline: false, portrait: false}
                    }
                  }}
                  url={`${item.url_vimeo}?`}
                  onStart={(e) => this.UserDoContenu(e)}
                  controls={true}
                  className="video-viemo"
                  playing={false}
                  width="100%"
                  height="100%"
                />
                
              </div>

              <div className="lh-3">
                <FeatherIcon
                  className="icon-collapse-video"
                  icon="align-left"
                />
                <a
                  data-toggle="collapse"
                  href="#collapseExample"
                  id="collapseExample1"
                  className="video-collapse-video"
                >
                  VOIR LA DESCRIPTION
                </a>
              </div>
              <div className="collapse" id="collapseExample">
                <div className="video-black">
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              role="button"
              data-slide="prev"
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            {checker(this.props.usercontenus) === false ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.ScrollTop}
              >
                Suivant
              </button>
            ) : (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.UserDoContenu}
                
              
              >
                Suivant
              </button>
            ) }
          </div>
        </div>
      </div>
    );
  }
}
