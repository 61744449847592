import React, { useState } from 'react'

import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import api from '../../http/global-vars'
import ContentEditor from './supportContentAdmin/ContentEditor'

function EditSlideModal({ support, onReload }) {
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      'Content-Type': 'multipart/form-data',
    },
  }
  const [is_empty_name, setIs_empty_name] = useState(false)
  const [is_empty_description, setIs_empty_description] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [fields, setFields] = useState({
    name: support?.name,
    description: support?.description,
    contenu: support?.contenu,
    url: null,
  })
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    fields[e.target.name] = e.target.value

    setFields(fields)
  }

  const urlChange = (e) => {
    setFields({ ...fields, url: e.target.files[0] })
    console.log('fields',{ ...fields, url: e.target.files[0] })
  }
  const onValidateForm = () => {
    var isValidForm = true

    if (!fields['name']) {
      isValidForm = false
      setIs_empty_name(true)

      setTimeout(() => {
        setIs_empty_name(false)
      }, 5000)

      errors['name'] = 'Ce champ est obligatoire'
    }

    if (!fields['description']) {
      isValidForm = false
      setIs_empty_description(true)
      setTimeout(() => {
        setIs_empty_description(false)
      }, 5000)

      errors['description'] = 'Ce champ est obligatoire'
    }

    setErrors(errors)

    return isValidForm
  }

  const addForm = (e) => {
    e.preventDefault()
    if (onValidateForm()) {
      setIsLoading(true)

      var formData = new FormData()
      formData.append('name', fields.name)
      formData.append('description', fields.description)
      if (fields.url) {
        formData.append('url', fields.url)
      }
      
      formData.append('content_type', support?.content_type)
      formData.append('created_by', support?.created_by)
      formData.append('contenu', support?.contenu)
      axios
        .put(api + 'support/' + support?.id + '/', formData, requestConfig)
        .then(async (response) => {
          // console.log(response)
          if (response.status === 201 || response.status === 200) {
            await onReload()
            await Swal.fire({
              icon: 'success',
              title: 'Données modifiées avec succès',
              iconColor: 'green',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.$(`#editSupportModal${support?.id}`).modal('hide')
            })
            setIsLoading(false)
          } else {
            toast.error('Vos données ne sont pas valides !')
          }
        })
        .catch((error) => {
          console.log(error.response)
          setIsLoading(false)
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard.",
          )
        })
    }
  }
  return (
    <div className="card-dashboard no-shadow">
      <form>
        <div className="row m-b-50">
          <div className="col-lg-9">
            <p className="titre-card-create-content">Modification Slide</p>
          </div>
        </div>

        <div className="form-group m-b-40">
          <input
            type="text"
            className="form-control input-dashboard-backend"
            placeholder="Titre du slide"
            name="name"
            defaultValue={fields?.name}
            onChange={(e) => handleChange(e)}
          />
          {is_empty_name && (
            <div className="alert alert-danger keysjob-alert-danger">
              {' '}
              {errors.name}{' '}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 mb-5">
            <div className="custom-file text-start">
              <input
                type="file"
                className="custom-file-input"
                id="inputVideoPdf"
                onChange={(e) =>urlChange(e)}
              />
              <label
                className="custom-file-label custom-file-label-create-content"
                htmlFor="inputVideoPdf"
              >
                {fields.url ? fields.url.name : 'Ajouter un document'}
              </label>
              <FeatherIcon
                className="icon-import-custom-file-label"
                icon="upload"
              />
            </div>
          </div>
        </div>
        <ContentEditor
          height={300}
          value={fields?.description}
          placeholder="Résumé du slide"
          name={`update_description_${support?.id}`}
          onChange={(description) =>
            setFields((old) => ({ ...old, description }))
          }
        />
        {is_empty_description && (
          <div className="alert alert-danger keysjob-alert-danger">
            {' '}
            {errors.description}{' '}
          </div>
        )}

        <div className="row dis-flex justify-content-end m-t-60">
          <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
            <button
              type="button"
              className="btn-cancel-theme"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">Annuler</span>
            </button>
          </div>
          <div className="col-xl-4 col-lg-4 m-b-20">
            {isLoading === false ? (
              <button
                type="submit"
                onClick={(e) => addForm(e)}
                className="btn-theme text-up w-full"
              >
                Terminer
              </button>
            ) : (
              <button className="btn-theme text-up w-full in-progress-btn">
                En cours... &nbsp;
                <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditSlideModal
