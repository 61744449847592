import React from "react";
import ScormContentAdmin from "../../backend/components/ScormContentAdmin";
import MessageAllPage from "../../backend/components/MessageAllPage";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import { NavLink ,useHistory} from "react-router-dom";
import FeatherIcon from "feather-icons-react";


function SliderScormContainer({ module,theme }) {

  const history = useHistory();

  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>

        <div className="col-xl-10 col-lg-9 col-md-12 content-position-after-header p-b-200">
          <div className="test">
            <div className="row content-routing-url m-b-20">
              <div className="lh-0">
                <NavLink className="routing-view-url" to="/tous-les-modules">
                  Tous les modules
                </NavLink>
                {module?.thematique && (
                <>
                <FeatherIcon
                  className="icon-routing-view-url"
                  icon="chevron-right"
                />
                <NavLink className="routing-view-url"
                  to={{
                    pathname: `/sous-modules/${theme?.id}`,
                    state: theme
                  }}
                  >
                  {theme?.name}
                </NavLink> 
                </>
                )}
                <FeatherIcon
                  className="icon-routing-view-url"
                  icon="chevron-right"
                />
                <NavLink className="routing-view-url" to="#">
                  {module?.name}
                </NavLink>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="content-slider-details-session">
                  <div className="body-content-slider-details-sessions">
                    <ScormContentAdmin module={module} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
}

export default SliderScormContainer;
