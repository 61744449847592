import React from "react";
import Skeleton from "react-loading-skeleton";

const ResponseCardSkeleton = () => {
  return (
    <>
      {Array(5)
        .fill()
        .map((item, index) => (
          <div className="card-response" key={index}>
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 auteur-reponse-position-mobile">
                <p className="auteur-reponse-card-forum-details">
                  <Skeleton height={36} width={`90%`} />
                </p>
                <div className="avatar-reponse-card-forum-details">
                  <Skeleton circle={true} height={50} width={50} />
                </div>
              </div>
              <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                <p className="date-reponse-card-forum-details">
                  <Skeleton width={`80%`} />
                </p>
                <p className="text-content-reponse-card-forum-details">
                  
                  <Skeleton width={`100%`} />
                </p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default ResponseCardSkeleton;
