/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import $ from "jquery";
import axios from "axios";
import baseUrl from "../../http/backend-base-url";
// import { Redirect } from "react-router-dom";

export default class TacheQuestionReponse extends Component {
  constructor(props) {
    super(props);
    const task = this.props.text[0] || { title: "", list_question: [] };
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      texts: this.props.text,
      selectedtxtIndex: 0,
      selectedIndex: 0,
      exporting_data: [],
      responses: [
        {
          question: "",
          reponses: [],
        },
      ],
      fields: {},
      images: [],
      isLoading: false,
      isFile: false,
      isSend: false,
      module_id: "",
      data: task.list_question.map((q) => {
        return {
          id: q.id,
          label: q.content,
          responses: [
            ...q.list_responses.map((r) => {
              return {
                id: r.id,
                content: "",
                image: null,
              };
            }),
          ],
        };
      }),
    };
  }

  componentDidMount() {
    // console.log('props contenu', this.props.contenu)
    setTimeout(() => {
      this._getModuleBySession();
    }, 3000);
  }

  onScroll = () => {
    window.scrollTo(0, 0);
  };

  onStoreData = () => {
    console.log("click");
    var export_ = window.localStorage.getItem("resp_gfa");
    for (let d of this.state.data) {
      for (let r of d.responses) {
        let send = {};
        send.id = r.id;
        send.id_contenu = this.props?.id_contenu;
        send.id_affiche = this.props?.a_afficher_sur;
        send.text = r.content;
        console.log("affiche ", this.props?.a_afficher_sur);
        if (this.props?.a_afficher_sur !== null) {
          export_ = JSON.parse(window.localStorage.getItem("resp_gfa"));
          // if (send.text?.length !== 0) {
          if (export_ === null) {
            if (!(export_ instanceof Array)) export_ = [];
            // console.log(send);
            export_.push(send);
            localStorage.setItem("resp_gfa", JSON.stringify(export_));
          } else {
            let index = export_.findIndex((f) => f.id === send.id);
            if (index === -1) {
              if (!(export_ instanceof Array)) export_ = [];
              // console.log(send);
              export_.push(send);
              localStorage.setItem("resp_gfa", JSON.stringify(export_));

              //test callback
              // let store=localStorage.setItem("resp_gfa", JSON.stringify(export_));
              // this.props.callBackFromParent(export_,send.text);
              this.props.callBackFromParent(
                export_,
                this.props?.id_contenu,
                send.text
              );
              //end test callback

              // console.log("after setting resp_gfa=>", JSON.parse(localStorage.getItem("resp_gfa")));
            } else {
              console.log(send);
              export_[index] = send;
              localStorage.setItem("resp_gfa", JSON.stringify(export_));
              // console.log("after setting=>", JSON.stringify(localStorage.getItem("resp_gfa")))

              //test callback
              // let store=localStorage.setItem("resp_gfa", JSON.stringify(export_));
              // this.props.callBackFromParent(export_,send.text);
              this.props.callBackFromParent(
                export_,
                this.props?.id_contenu,
                send.text
              );
              //end test callback
            }
          }
          // }
        }
      }
    }
  };

  updateProgres = async () => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id
    );
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;

    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };
    console.log("send", send);
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log("continu");
      }
    } catch (error) {
      console.log(error?.response);
    }
  };

  _getModuleBySession = async () => {
    const { api } = this.state;
    if (this.props.sessionId) {
      const url = api + `session/${this.props.sessionId}/`;
      try {
        const res = await axios.get(url, this.state.requestConfig);
        if (res.status === 201 || 200) {
          let data = res["data"];
          this.setState({ module_id: data.module_id });
        }
      } catch (error) {
        console.log(error?.message);
      }
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  };

  thisFileUpload = (e, index) => {
    // console.log(index);
    e.preventDefault();
    document.getElementById(`file${index}`).click();
  };

  onChange = (q, r, name, value) => {
    let newData = [...this.state.data];
    const indexQ = newData.findIndex((q2) => q2.id === q.id);
    const newQuestion = { ...newData[indexQ] };
    const index = newQuestion.responses.findIndex((r2) => r2.id === r.id);
    if (name === "image") {
      newQuestion.responses[index][name] = value.target.files[0];
    } else {
      newQuestion.responses[index][name] = value.target.value;
    }

    newData[indexQ] = newQuestion;
    //console.log(newData);
    this.setState({ data: newData });
  };

  done = async () => {
    var api = this.state.api;
    console.log("click");
    const url = api + "userdoresponsetextview/";
    // setLoading(true);
    if (this.props?.a_afficher_sur) {
      this.onStoreData();
    }

    if (
      this.props?.usercontenus.every(
        (v) =>
          parseInt(v?.contenu?.id) !==
          this.state.texts[this.state.selectedtxtIndex]?.contenu
      ) !== false
    ) {
      for (let d of this.state.data) {
        for (let r of d.responses) {
          const sendData = {
            user: parseInt(this.state.userID),
            text: r.content,
            reponse: r.id,
          };
          axios
            .post(url, sendData, this.state.requestConfig)
            .then((response) => {
              console.log(response);
              if (response.data) {
                this.setState({
                  fields: {},
                  responses: [],
                  isLoading: false,
                  isSend: true,
                });
                //this.updateProgres();
                //$(".close").click();
              } else {
                toast.error("Vos données ne sont pas valides !");
              }
            })
            .catch((error) => {
              // console.log(error.response);
              // console.log(error.config);
              toast.error(
                "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
              );

              setTimeout(() => {
                this.setState({
                  isLoading: false,
                });
              }, 2000);
            });
        }
      }
      this.UserDoContenu();
    }
  };

  UserDoContenu = async () => {
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.state.texts[this.state.selectedtxtIndex]?.contenu,
    };
    //console.log('data', sendData)
    const url = api + "userdocontenu/";
    await axios
      .post(url, sendData, this.state.requestConfig)
      .then((res) => {
        //console.log("data post", res);
        this.updateProgres();
      })
      .catch((e) => {
        console.log("e post", e?.response?.data);
      });
    this.props.onGetUserDoModules();
    window.scrollTo(0, 0);
  };

  _redirect = () => {
    console.log(
      "last",
      this.props.last[0]?.id,
      this.props.this.props.text[0].id
    );
    if (this.props.last[0]?.id === this.props.this.props.text[0].id) {
      // console.log("END");
      var elementPos = this.props.sessions
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.props.sessionId);
      var objectFound = this.props.sessions[elementPos + 1];
      //this.setState({ endSession: true, selectedId: objectFound?.id });
      const Data = {
        user: parseInt(this.state.userID),
        session: this.props.sessionId,
      };
      //console.log("qst", this.state.selectedId);
      axios
        .post(api + "userdosession/", Data, this.state.requestConfig)
        .then((data) => {
          console.log("data", data.data);
          // window.location = `/session-start/session/${this.state.selectedId}`;
        })
        .catch((e) => {
          console.log("e", e?.response);
        });

      window.location = `/session-start/session/${objectFound?.id}`;
    }
    //return (window.location = `/session-start/session/${this.state.selectedId}`);
  };

  render() {
    // console.log(this.props.isForumTask)
    // if (this.props?.id_contenu !== undefined) {
    //   console.log("id contenu question-response =>", this.props?.id_contenu)
    // }
    // console.log("affiche ", this.props?.a_afficher_sur)

    const { texts, selectedtxtIndex, data } = this.state;

    let isValid = true;
    for (let d of data) {
      let r = d.responses[0] || {};
      if (r.content.trim().length === 0) {
        isValid = false;
        break;
      }
    }

    if (texts && texts[selectedtxtIndex]?.list_question.length) {
      //console.log(texts)
      return (
        <>
          <h4 className="content-title">{texts[selectedtxtIndex].title}</h4>
          {texts[selectedtxtIndex]?.list_question.map((item, index) => {
            return (
              <div key={item.id}>
                <div className="image-fields">
                  {item.image ? (
                    <img
                      className="img-text-fields"
                      src={baseUrl + item?.image}
                      alt=""
                    />
                  ) : null}
                </div>
                <h5 className="text-dark m-b-20">
                  <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                </h5>
                {item.list_responses.map((rsp, i) => {
                  return (
                    <div className="form-group" key={rsp.id}>
                      <textarea
                        className="form-control form-reponse-tache"
                        rows="3"
                        placeholder={
                          this.state.isFile && this.state.images.length
                            ? this.state.images[i]
                            : "Votre réponse"
                        }
                        name={rsp.id}
                        id={item.id}
                        onChange={(value) =>
                          this.onChange(item, rsp, "content", value)
                        }
                      ></textarea>
                    </div>
                  );
                })}
              </div>
            );
          })}
          {/* Goto Forum Button Condition */}
          {this.props.isForumTask !== 0 && (
            <div className="row goto-forum-item-row">
              <div className="col-md-4 goto-forum-item-col">
                <a
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Participez au forum"
                  className="goto-forum-item-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/details-forum/${this.props.isForumTask}`}
                >
                  <FeatherIcon
                    icon="message-square"
                    className="justify-content-end align-content-end goto-forum-icon"
                  />
                  &nbsp;
                  <span className="goto-forum-link-label">Allez au forum</span>
                </a>
              </div>
            </div>
          )}
          <div className="row dis-flex justify-content-end m-b-30 m-t-65 slider-actions-btn">
            <div className="col-md-3 col-sm-6 m-b-10">
              {this.props?.usercontenus.every(
                (v) =>
                  parseInt(v?.contenu?.id) !== texts[selectedtxtIndex]?.contenu
              ) !== false ? (
                isValid ? (
                  <button
                    className="btn-theme dis-flex justify-content-center fw-600 text-up"
                    onClick={this.done}
                    data-toggle="modal"
                    data-target={`#textSuccess${texts[selectedtxtIndex]?.id}`}
                  >
                    Envoyer
                  </button>
                ) : (
                  <button
                    className="btn-theme disabled dis-flex justify-content-center fw-600 text-up"
                    disabled
                  >
                    Envoyer
                  </button>
                )
              ) : (
                <a
                  className="btn-theme dis-flex justify-content-center fw-600 text-up next"
                  href="#carouselSliderContentDetailsSession"
                  role="button"
                  data-slide="next"
                  onClick={this.onScroll}
                >
                  Continuer
                </a>
              )}
            </div>
          </div>
          <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
            <div className="col-md-3 col-sm-6 m-b-10">
              <a
                className="btn-theme-light dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                role="button"
                data-slide="prev"
              >
                Précédent
              </a>
            </div>
            <div className="col-md-3 col-sm-6 m-b-10">
              {this.props?.usercontenus.every(
                (v) =>
                  parseInt(v?.contenu?.id) !== texts[selectedtxtIndex]?.contenu
              ) === false ? (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  onClick={this.onScroll}
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme disabled dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  disabled
                >
                  Suivant
                </button>
              )}
            </div>
          </div>
          <div
            className="modal fade"
            id={`textSuccess${texts[selectedtxtIndex]?.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-notify modal-success"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                    <FeatherIcon
                      color="green"
                      icon="check-circle"
                      className="mb-3 animat rotateIn"
                    />
                    <h4 className="pop-title fw-600">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.props.contenu?.message || "Tes réponses ont été soumises ! Bravo pour avoir terminé cet exercice !",
                        }}
                      />
                      {" "}
                    </h4>
                  </div>
                </div>
                <div className="modal-footer justify-content-center slider-actions-btn">
                  {/* <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-theme fw-400 fs-15 justify-content-start"
                    onClick={this._ToggleNext}
                  >
                    VOIR LE RESULTAT{" "}
                  </button> */}
                  <a
                    type="button"
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    data-dismiss="modal"
                    href="#carouselSliderContentDetailsSession"
                    role="button"
                    data-slide="next"
                    onClick={this.onScroll}
                  >
                    CONTINUER
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p>Pas de question</p>
          <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
            <div className="col-md-3 col-sm-6 m-b-10">
              <a
                className="btn-theme-light dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                role="button"
                data-slide="prev"
              >
                Précédent
              </a>
            </div>
            <div className="col-md-3 col-sm-6 m-b-10">
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
              >
                Suivant
              </button>
            </div>
          </div>
        </>
      );
    }
  }
}
