/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import { Container, Draggable } from 'react-smooth-dnd'
import { applyDrag } from './util'
import FeatherIcon from 'feather-icons-react'
import api from '../../http/global-vars'
import axios from 'axios'
import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui'

const groupStyle = {
  overflowX: 'auto',
  // border: '1px solid #ccc'
}
const maxChar = 100
export default class TacheGlisserDeposer extends Component {
  constructor(props) {
    super(props)
    const task = this.props.drag_drop[0] || { list_colonne: [] }
    let words = []
    const colonnes = []

    for (let c of task.list_colonne) {
      /* for (let w of c.list_words) {
        words.push({
          id: w.id,
          text: w.content,
          colonne: w.colonne.id,
          order: w.order,
        });
      } */
      let word = c.list_words.map((w) => ({
        id: w.id,
        text: w.content,
        colonne: w.colonne.id,
        order: w.order,
      }))
      let tab = c.list_words.map((w) => ({
        id: w.id,
        text: w.content,
        colonne: w.colonne.id,
        order: w.order,
      }))
      tab.sort((a, b) => a.order - b.order)
      const data = {
        text: c.name,
        id: c.id,
        words: [...tab],
        list_words: tab,
        itemsLength: c.list_words.length,
      }
      const val = {
        text: c.name,
        id: c.id,
        words: this.shuffleArray(word),
        list_words: word,
        itemsLength: c.list_words.length,
      }
      colonnes.push(data)
      words.push(val)
    }

    this.state = {
      list_words: [],
      drag_drop: this.props.drag_drop[0],
      token: window.localStorage.getItem('userToken'),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem('userID'),
      module_id: '',
      scene: {
        type: 'container',
        props: {
          orientation: 'horizontal',
        },
        child: colonnes.map((item, i) => {
          return {
            id: item.id,
            text: item.text,
            child: [],
          }
        }),
        colonne: this.shuffleArray(words).map((item, i) => {
          return {
            id: item.id,
            text: item.text,
            words: this.shuffleArray(item.words).map((w, j) => {
              return {
                id: w.id,
                text: w.text,
                colonne: w.colonne,
                order: w.order,
              }
            }),
          }
        }),
        correction: colonnes,
      },
      isDone: false,
      isEmpty: false,
      score: 0,
      totalScore: 0,
      isDoing: false,
      module,
      session: [],
      endModule: false,
      load: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this._getModuleBySession()
      this.getSessionByModule()
      this.checkContenu()
    }, 3000)
    window.scrollTo({
      top: 60,
      left: 0,
    })
  }

  checkContenu = () => {
    const contenususer = this.props?.usercontenus.filter(
      (v) => v.contenu.id === this.props.drag_drop[0]?.contenu,
    )
    let checker = this.props?.usercontenus.every(
      (v) => v?.contenu?.id !== contenususer[0]?.contenu?.id,
    )
    //console.log(checker)
    if (contenususer.length) {
      return this.setState({ isDoing: true })
    }
  }

  _getModuleBySession = async () => {
    const { api } = this.state
    if (this.props.sessionId) {
      const url = api + `session/${this.props.sessionId}/`
      try {
        const res = await axios.get(url, this.state.requestConfig)
        if (res.status === 201 || 200) {
          let data = res['data']
          this.setState({ module_id: data.module_id })
        }
      } catch (error) {
        console.log(error.message)
      }
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId })
    }
  }

  updateProgres = async () => {
    const { api } = this.state
    let progression
    if (this.props.progression) {
      progression = this.props.progression
    }
    let send = {
      progression: progression,
    }
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id,
    )
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig)
        console.log(res.data)
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  getCardPayload = (columnId, index) => {
    //console.log('tok')
    // console.log('teste',this.state.scene.colonne.filter((p) => p.id === columnId), index)
    return this.state.scene.child.filter((p) => p.id === columnId)[0].child[
      index
    ]
  }

  getWordPayload = (e, columnId, index) => {
    //$('.colorable').css('background', '#f39200')
    console.log(
      'test',
      this.state.scene.colonne.filter((p) => p.id === columnId),
      index,
    )
    window.scrollTo({
      top: 80,
      left: 0,
    })
    return this.state.scene.colonne.filter((p) => p.id === columnId)[0].words[
      index
    ]
  }

  onCardDrop = (columnId, dropResult, index) => {
    $('.colorable').css('background', '')
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const scene = Object.assign({}, this.state.scene)
      const column = scene.child.filter((p) => p.id === columnId)[0]
      //console.log("new", newColumn);
      const columnIndex = scene.child.indexOf(column)
      const newColumn = Object.assign({}, column)
      // console.log('new', newColumn, dropResult)
      newColumn.child = applyDrag(newColumn.child, dropResult, index)
      scene.child.splice(columnIndex, 1, newColumn)

      this.setState({
        scene,
      })
    }
  }

  onWordDrop = (columnId, dropResult, index) => {
    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      // $('.colorable').css('background', 'red')
      const scene = Object.assign({}, this.state.scene)
      const column = scene.colonne.filter((p) => p.id === columnId)[0]

      const columnIndex = scene.colonne.indexOf(column)
      const newColumn = Object.assign({}, column)
      newColumn.words = applyDrag(newColumn.words, dropResult, index)
      scene.colonne.splice(columnIndex, 1, newColumn)
      console.log('new word', newColumn)
      this.checker()
      this.setState({
        scene,
      })
      setTimeout(() => {
        $('.all').scrollTop(0)
      }, 1000)
    }
  }

  ScrollTop = () => {
    window.scrollTo(0, 0)
  }

  getSessionByModule = async () => {
    if (this.props.sessionId) {
      const url = api + 'session/' + this.props.sessionId
      await axios
        .get(url, this.state.requestConfig)
        .then(async (response) => {
          const last = this.props.sessions.filter(
            (d) =>
              d.module_id?.id === response.data?.module_id ||
              d?.module_id === response.data?.module_id,
          )
          console.log('session', last, response.data)
          let data = response['data']
          this.setState({ session: last, module_id: data?.module_id })
          this.getModuleItem(data?.module_id)
        })
        .catch((e) => {
          console.log('e', e)
        })
    }

    if (this.props.moduleId) {
      this.getModuleItem(this.props.moduleId)
      const url = api + 'sessionByModule/' + this.props.moduleId
      axios
        .get(url, this.state.requestConfig)
        .then(async (response) => {
          let data = await response['data']
          // console.log("session by module", data);
          this.setState({ session: data, module_id: this.props.moduleId })
        })
        .catch((e) => {
          console.log('e', e)
        })
      // this.setState({ module_id: this.props.moduleId });
    }
  }

  getModuleItem = async (id) => {
    // console.log('module id', id)
    const url = api + 'module/' + id
    await axios
      .get(url, this.state.requestConfig)
      .then(async (response) => {
        console.log('module item', response)
        this.setState({ module: response?.data })
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  _redirect = async () => {
    console.log('last', this.props.last[0]?.id, this.state.drag_drop?.id)
    let lastcontenu = []
    lastcontenu = this.props.contenuBySession.filter(
      (d) =>
        d?.id ===
        this.props.contenuBySession[this.props.contenuBySession.length - 1]?.id,
    )[0]?.list_task

    if (
      this.props.last[0]?.id === this.state.drag_drop?.id ||
      lastcontenu[0]?.id === this.state.drag_drop?.id
    ) {
      console.log('END Session', this.props.sessionsModule)
      var objectFound = null
      if (this.props.sessionId) {
        const Data = {
          user: parseInt(this.state.userID),
          session: this.props.sessionId,
        }

        var last = this.props.sessionsModule.filter(
          (d) =>
            d?.id ===
            this.props.sessionsModule[this.props.sessionsModule.length - 1]?.id,
        )[0]

        var elementPos = this.props.sessionsModule
          .map(function (x) {
            return x?.id
          })
          .indexOf(this.props.sessionId)

        let checkerSession = (arr) =>
          arr.every((v) => parseInt(v?.session?.id) !== this.props.sessionId)
        if (this.props.sessionsModule.length && last !== undefined) {
          console.log('tested')
          if (this.props.sessionsModule[elementPos]?.id === last?.id) {
            /* let checker = (arr) =>
              arr.every((v) => parseInt(v?.module?.id) !== last?.module_id);
            console.log(this.props.usermodules);
            console.log("check", checkerSession(this.props.usersessions)); */
            this.setState({ endModule: true, load: true })
            console.log('test')

            if (checkerSession(this.props.usersessions) !== false) {
              axios
                .post(api + 'userdosession/', Data, this.state.requestConfig)
                .then((data) => {
                  console.log('data', data.data)
                })
                .catch((e) => {
                  console.log('e', e.response)
                })
            }

            // this.updateUser()
            console.log('end module')
          } else {
            if (checkerSession(this.props.usersessions) === false) {
              axios
                .post(api + 'userdosession/', Data, this.state.requestConfig)
                .then((data) => {
                  console.log('data', data.data)
                })
                .catch((e) => {
                  console.log('e', e.response)
                })
            }
            if (elementPos >= 0) {
              objectFound = this.props.sessionsModule.sort(
                (a, b) => a?.id - b?.id,
              )[elementPos + 1]
              console.log('end end', elementPos, objectFound)
            }

            console.log('elementPos 1', objectFound)
            this.setState({ endSession: true, selectedId: objectFound?.id })
            window.location = `/session-start/session/${objectFound?.id}`
          }
        } else {
          if (checkerSession(this.props.usersessions) === false) {
            axios
              .post(api + 'userdosession/', Data, this.state.requestConfig)
              .then((data) => {
                console.log('data', data.data)
              })
              .catch((e) => {
                console.log('e', e.response)
              })
          }
          // objectFound = this.props.sessions[elementPos + 1];
          if (elementPos >= 0) {
            objectFound = this.props.sessionsModule.sort(
              (a, b) => a?.id - b?.id,
            )[elementPos + 1]
            console.log('end 2', elementPos, objectFound)
          }
          console.log('end 3', elementPos, objectFound)
          this.setState({ endSession: true, selectedId: objectFound?.id })
          window.location = `/session-start/session/${objectFound?.id}`
        }
      } else {
        console.log(
          'test 4',
          this.props.sessionsModule.sort((a, b) => a?.id - b?.id),
        )
        objectFound = this.props.sessionsModule.sort((a, b) => a?.id - b?.id)[0]
        if (objectFound !== undefined) {
          window.location = `/session-start/session/${objectFound?.id}`
        }
      }
    }
    // return (window.location = `/session-start/session/${this.state.selectedId}`);
  }

  sendData = (score) => {
    const sendData = {
      score: score,
      user: parseInt(this.state.userID),
      contenu: this.props.drag_drop[0]?.contenu,
    }
    console.log(sendData)
    const url = api + 'userdocontenu/'
    axios
      .post(url, sendData, this.state.requestConfig)
      .then((response) => {
        console.log('data post', response)
        this.updateProgres()
      })
      .catch((e) => {
        console.log('e post', e.response)
      })
    this.props.onGetUserDoModules()
  }

  loadResult = () => {
    this.setState({ isDone: true })
    window.scrollTo(0, 0)
  }

  loadScore = () => {
    let score = 0
    let totalScore = 0
    console.log(this.state.scene.correction.length)
    if (this.state.scene.correction.length === 1) {
      let clonne = this.state.scene.correction[0]
      let colonne = this.state.scene.child[0]
      totalScore = clonne.list_words.length
      for (let i = 0; i < clonne.list_words.length; i++) {
        let w = clonne.list_words[i]
        let r = colonne.child[i]
        if (w.order === r?.order) {
          score++
        }
      }
    } else {
      for (let i = 0; i < this.state.scene.correction.length; i++) {
        const element = this.state.scene.correction[i]
        const words = element.list_words
        //const responses = this.state.scene.child[i].child;
        for (let j = 0; j < words.length; j++) {
          let responses = this.state.scene.child[i].child[j]
          let r = words[j]
          console.log(r, responses)
          if (responses?.colonne === r?.colonne) {
            score++
          }
          totalScore++
        }
      }

      /*  for (let p of this.state.scene.correction) {
         const words = p.list_words;
         const responses = this.state.scene.child;
         for (let w of words) {
           if (responses.findIndex((r) => r.id === w.id) !== -1) {
             score++;
           }
           totalScore++;
         }
       } */
    }
    //console.log(score, totalScore)
    this.setState({ score, totalScore })
    this.sendData(score)
  }

  checker = () => {
    for (let i = 0; i < this.state.scene.colonne.length; i++) {
      const element = this.state.scene.colonne[i]
      if (element.words.length > 0) {
        this.setState({
          isEmpty: false,
        })
        break
      }
      this.setState({
        isEmpty: true,
      })
    }
  }

  shuffleArray = (array) => {
    let i = array.length - 1
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    // console.log(array)
    return array
  }

  render() {
    const contenususer = this.props?.usercontenus.filter(
      (v) => v.contenu.id === this.props.drag_drop[0]?.contenu,
    )
    let last = false
    if (this.props.last?.length) {
      if (this.state.drag_drop?.id === this.props.last[0]?.id) {
        last = true
      }
    }

    // console.log('contenu', this.props.drag_drop[0].list_colonne)
    return (
      <div>
        {!this.state.isDone ? (
          <>
            <div className="all">
              <h4 className="m-b-35 fw-600 f-f-work h-theme-dark fs-20">
                {this.props.drag_drop[0]?.name}.
              </h4>
              <p className="m-b-25 fw-600 f-f-work text-dark fs-16">
                Mots proposés :{' '}
              </p>

              <div className="m-t-10">
                <div className="row gfa-dnd-container">
                  {this.state.scene.colonne ? (
                    <>
                      {this.state.scene?.colonne.map((c) => {
                        return (
                          <Fragment key={c?.id}>
                            {c.words
                              .filter(
                                (v, i, a) =>
                                  a.findIndex((t) => t.id === v.id) === i,
                              )
                              .map((p, i) => {
                                return (
                                  <Container
                                    key={p?.id}
                                    groupName="1"
                                    onDrop={(e) => this.onWordDrop(c?.id, e, i)}
                                    getChildPayload={(e) =>
                                      this.getWordPayload(e, c?.id, i)
                                    }
                                    dragClass="card-ghost"
                                    dropClass="card-ghost-drop"
                                    autoScrollEnabled={true}
                                  >
                                    <Draggable key={p?.id}>
                                      <div className="dnd-custom animated">
                                        {p?.text}
                                      </div>
                                    </Draggable>
                                  </Container>
                                )
                              })}
                          </Fragment>
                        )
                      })}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="dnd-colonnes">
                <div className="row ">
                  {this.state.scene.child ? (
                    <>
                      {this.state.scene?.child.map((column, i) => {
                        return (
                          <div
                            className={
                              this.state.scene.child.length <= 2
                                ? 'col-md-6 m-b-10'
                                : 'dnd-cln m-b-10'
                            }
                            key={column?.id}
                          >
                            <p className="dnd-response-left-head">
                              {column?.text}
                            </p>
                            <div className="colorable dnd-colonne">
                              <Container
                                groupName="1"
                                autoScrollEnabled={true}
                                onDragStart={(e) =>
                                  console.log('drag started', e)
                                }
                                onDragEnd={(e) => console.log('drag end', e)}
                                onDrop={(e) => this.onCardDrop(column?.id, e)}
                                getChildPayload={(i) =>
                                  this.getCardPayload(column?.id, i)
                                }
                                dropPlaceholder={{
                                  animationDuration: 150,
                                  showOnTop: true,
                                  className: 'drop-preview',
                                }}
                                dropPlaceholderAnimationDuration={200}
                              >
                                {column.child.map((card, i) => {
                                  return (
                                    <Draggable
                                      className="dnd-custom-response"
                                      key={card?.id}
                                    >
                                      <div
                                        className="draggable-item"
                                        key={card?.id}
                                      >
                                        <p className="item-text">
                                          {card?.text.length > maxChar
                                            ? card?.text.substring(0, maxChar) +
                                              ' . . .'
                                            : card?.text}
                                        </p>
                                      </div>
                                    </Draggable>
                                  )
                                })}
                              </Container>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row dis-flex justify-content-end m-b-20 m-t-20">
              {this.state.isEmpty === true && this.state.isDone === false ? (
                <div className="col-md-3 col-sm-6 m-b-10">
                  <button
                    className="btn-theme dis-flex justify-content-center fw-600"
                    onClick={this.loadScore}
                    data-toggle="modal"
                    data-target={`#dragdropSuccess${this.props.drag_drop[0]?.id}`}
                  >
                    Valider
                  </button>
                </div>
              ) : (
                <div className="col-md-3 col-sm-6 m-b-10">
                  <button
                    className="btn-theme  disabled dis-flex justify-content-center fw-600"
                    disabled
                  >
                    Valider
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <h4 className="m-b-35 f-f-work text-dark reponse-dnd-title">
              Ta réponse
            </h4>
            <div className="row m-t-50">
              {this.state.scene.child ? (
                <>
                  {this.state.scene?.child.map((column, index) => {
                    return (
                      <div className="col-md-6 m-b-30" key={column?.id}>
                        <p className="dnd-response-left-head">{column?.text}</p>
                        <div>
                          <div>
                            {column.child.map((card, i) => {
                              return (
                                <div
                                  className="dnd-custom-response"
                                  key={card?.id}
                                >
                                  <div
                                    className="draggable-item"
                                    key={card?.id}
                                  >
                                    {this.props.drag_drop[0]?.list_colonne
                                      .length === 1 ? (
                                      this.state.scene.correction[index]
                                        .list_words[i]?.order !==
                                      card?.order ? (
                                        <>
                                          {console.log(
                                            column.order,
                                            card.colonne.order,
                                          )}
                                          <FeatherIcon
                                            color="red"
                                            icon="x-circle"
                                            className="check"
                                          />
                                        </>
                                      ) : (
                                        <FeatherIcon
                                          color="green"
                                          icon="check-circle"
                                          className="check"
                                        />
                                      )
                                    ) : column?.id !== card?.colonne ? (
                                      <FeatherIcon
                                        color="red"
                                        icon="x-circle"
                                        className="check"
                                      />
                                    ) : (
                                      <FeatherIcon
                                        color="green"
                                        icon="check-circle"
                                        className="check"
                                      />
                                    )}

                                    <p>{card?.text}</p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : null}
            </div>
            <h4 className="m-b-35 f-f-work text-dark reponse-dnd-title">
              La solution
            </h4>
            <div className="row m-t-50">
              {this.state.scene.correction.map((item, i) => {
                return (
                  <div className="col-md-6 m-b-30" key={item?.id}>
                    <p className="dnd-response-left-head">{item?.text}</p>
                    <div>
                      <div>
                        {item.list_words.map((p, index) => {
                          return (
                            <div key={p?.id} className="dnd-custom-response">
                              <div className="draggable-item">{p?.text}</div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
        <div
          className="modal fade"
          id={`dragdropSuccess${this.props.drag_drop[0]?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="mb-3 animat rotateIn"
                  />
                  <h4 className="parag fs-20 fw-600">
                    Vous avez obtenu{' '}
                    <span className="scores">
                      {this.state.score} / {this.state.totalScore} points
                    </span>
                  </h4>
                  <p className="pop-title fw-600">
                    {(this.state.score * 100) / this.state.totalScore <= 49
                      ? 'Oups ! Peut-être que tu dois répéter certains contenus ? Mais ne t’en fais pas, tu as encore le temps de t’améliorer !'
                      : (this.state.score * 100) / this.state.totalScore >=
                          50 &&
                        (this.state.score * 100) / this.state.totalScore <= 59
                      ? 'Bravo ! Tu peux encore t’améliorer, mais c’est un bon début ! '
                      : (this.state.score * 100) / this.state.totalScore >=
                          60 &&
                        (this.state.score * 100) / this.state.totalScore <= 90
                      ? 'Félicitations ! Tu as déjà beaucoup appris. Continue comme ça !'
                      : 'Wow, génial ! Tu as atteint le meilleur score. Félicitations !'}
                  </p>
                </div>
              </div>
              <div className="modal-footer justify-content-center slider-actions-btn">
                <button
                  data-dismiss="modal"
                  className="btn btn-theme fw-400 fs-15 justify-content-start"
                  onClick={this.loadResult}
                >
                  VOIR LE RESULTAT{' '}
                </button>
                {last === false ? (
                  <a
                    type="button"
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    data-dismiss="modal"
                    href="#carouselSliderContentDetailsSession"
                    role="button"
                    data-slide="next"
                  >
                    CONTINUER
                  </a>
                ) : (
                  <a
                    type="button"
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    // data-dismiss="modal"
                    href="#carouselSliderContentDetailsSession"
                    role="button"
                    data-slide="next"
                    onClick={() => {
                      window
                        .$(`#dragdropSuccess${this.props.drag_drop[0]?.id}`)
                        .modal('hide')
                      window
                        .$(`#endSessionSuccess${this.state.drag_drop?.id}`)
                        .modal('show')
                    }}
                  >
                    CONTINUER
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              role="button"
              data-slide="prev"
              onClick={this.ScrollTop}
            >
              Précédent
            </a>
          </div>
          {/* <div className="col-md-3 col-sm-6 m-b-10">
            {this.props.drag_drop ? 
            (
              this.state.isDoing === true || this.state.isDone === true ? (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  onClick={this.ScrollTop}
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme disabled dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  disabled
                >
                  Suivant
                </button>
              )
            ) : 
            (
              null
            )}
          </div> */}
          <div className="col-md-3 col-sm-6 m-b-10">
            {this.state.isDoing === true || this.state.isDone === true ? (
              last === false ? (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  data-slide="next"
                  data-toggle="modal"
                  data-target={`#endSessionSuccess${this.state.drag_drop?.id}`}
                >
                  Suivant
                </button>
              )
            ) : (
              <button
                className="btn-theme disabled dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                disabled
              >
                Suivant
              </button>
            )}
          </div>
          {/* Session End Modal */}
          <div
            className="modal fade"
            id={`endSessionSuccess${this.state.drag_drop?.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-notify modal-success"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                    <FeatherIcon
                      color="green"
                      icon="check-circle"
                      className="mb-3 animat rotateIn"
                    />
                    <h4 className="pop-title fw-600">
                      FIN DE{' '}
                      {this.props.moduleId === undefined
                        ? 'LA SESSION'
                        : "L'INTRODUCTION"}{' '}
                      !!!
                    </h4>
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    data-dismiss="modal"
                    className="btn btn-theme fw-400 fs-15 justify-content-start"
                    onClick={() => this._redirect()}
                  >
                    CONTINUER{' '}
                  </button>
                  {/* <a
                      type="button"
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      role="button"
                      data-slide="next"
                    >
                      CONTINUER
                    </a> */}
                </div>
              </div>
            </div>
          </div>
          {/* END Modal */}
        </div>
      </div>
    )
  }
}
