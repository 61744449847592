/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import api from "../../http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

export default class TacheEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      responses: [],
      isloading: false,
      arrIndex: {},
      isSended: false,
      module_id: 0,
      totalScore: 0,
      pretestScore: 0
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this._getModuleBySession();
    }, 3000);
  }

  updateProgres = async () => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id
    );
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;

    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };
    //console.log("send", send);
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  _getModuleBySession = async () => {
    const { api } = this.state;
    if (this.props.sessionId) {
      const url = api + `session/${this.props.sessionId}/`;
      try {
        const res = await axios.get(url, this.state.requestConfig);
        if (res.status === 201 || 200) {
          let data = res["data"];
          this.setState({ module_id: data.module_id });
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  };

  onStoreData = () => {
    var export_ = JSON.parse(window.localStorage.getItem("resp_gfa"));
    let tab = this.props.evaluation
    if (Array.isArray(tab) && tab.length) {
      for (let i = 0; i < tab.length; i++) {
        const element = tab[i];        
        let send = {
          id: element.id,
          id_contenu: this.props?.id_contenu,
          id_affiche: this.props?.a_afficher_sur,
          text: element.question
        }
        // console.log("affiche ", this.props?.a_afficher_sur)
        if (this.props?.a_afficher_sur !== null) {
          export_ = JSON.parse(window.localStorage.getItem("resp_gfa"));
          if (export_ === null) {
            if (!(export_ instanceof Array))
              export_ = [];
            // console.log(send);
            export_.push(send);
            localStorage.setItem("resp_gfa", JSON.stringify(export_));
          } else {            
            let index = export_.findIndex((f) => f.id === send.id);
            if (index === -1) {
              if (send.text?.length !== 0) {
                // console.log(send);
                export_.push(send)
                localStorage.setItem("resp_gfa", JSON.stringify(export_));
                // console.log("after setting resp_gfa=>", JSON.parse(localStorage.getItem("resp_gfa")));

                 //test callback
                //  let store=localStorage.setItem("resp_gfa", JSON.stringify(export_));
                 this.props.callBackFromParent(export_,this.props?.id_contenu,send.text);
                 //end test callback

              }
            } else {
              // console.log(send);
              export_[index] = send;
              localStorage.setItem("resp_gfa", JSON.stringify(export_));
              // console.log("after setting resp_gfa=>", JSON.parse(localStorage.getItem("resp_gfa")));

               //test callback
              //  let store=localStorage.setItem("resp_gfa", JSON.stringify(export_));
              //  this.props.callBackFromParent(export_,send.text);
              this.props.callBackFromParent(export_,this.props?.id_contenu,send.text);
               //end test callback
               
            }
          }
        }
      }
    }
  }

  handleChange = (e, id) => {
    const data = {
      response: e.target.value,
      user: this.state.userID,
      question: id,
    };
    this.addOrReplace(data);
    //let responses = [...this.state.responses, data];
    //console.log("data", data);
    this.setState({ responses: this.state.responses });
  };

  addOrReplace = (object) => {
    const { responses, arrIndex } = this.state;
    var index = arrIndex[object.question];
    if (index === undefined) {
      index = responses.length;
    }
    arrIndex[object.question] = index;
    responses[index] = object;
    // console.log("responses", responses);
  };

  UserDoContenu = async () => {
    let checker = (arr) =>
      arr.every(
        (v) => parseInt(v?.contenu?.id) !== this.props.evaluation[0]?.contenu
      );
    console.log("check contenu", checker(this.props.usercontenus));
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.props.evaluation[0]?.contenu,
    };
    if (checker(this.props.usercontenus) !== false) {
      const url = api + "userdocontenu/";
      await axios
        .post(url, sendData, this.state.requestConfig)
        .then((res) => {
          console.log("data post", res);
          this.updateProgres();
        })
        .catch((e) => {
          console.log("e post", e.response);
        });
    }
    this.props.onGetUserDoModules();
    window.scrollTo(0, 0);
  };


  addResponses = async () => {
    const url = api + "userdoscale/";
    let totalScore = 0;
    if (this.props?.a_afficher_sur) {
      this.onStoreData();
    }
    this.setState({ isloading: true });
    const { responses, requestConfig } = this.state;
    for (let i = 0; i < responses.length; i++) {
      const element = responses[i];
      totalScore += parseInt(element.response)
    }
    if (this.props.evaluation[0]?.is_pretest === true) {
      window.localStorage.setItem('pretestScore', totalScore)
    }
    if (this.props.evaluation[0]?.is_testfinal === true) {
      let pretestScore = parseInt(window.localStorage.getItem('pretestScore'))
      this.setState({ pretestScore });
    }
    for (let i = 0; i < responses.length; i++) {
      const element = responses[i];
      await axios
        .post(url, element, requestConfig)
        .then((response) => {
          console.log(response.data);
          if (response.data) {
            this.setState({ isloading: false, isSended: true });
            this.UserDoContenu()
          }
        })
        .catch((e) => {
          console.log("e", e.response);
          toast.error(
            "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
          );
        });
    }
    this.UserDoContenu()

  };

  _redirect = () => {
    //console.log("last", this.props.last[0]?.id, this.props.evaluation[0].id);
    if (this.props.last[0]?.id === this.props.evaluation[0].id) {
      // console.log("END");
      var elementPos = this.props.sessions
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.props.sessionId);
      var objectFound = this.props.sessions[elementPos + 1];
      this.setState({ endSession: true, selectedId: objectFound?.id });
      const Data = {
        user: parseInt(this.state.userID),
        session: this.props.sessionId,
      };
      //console.log("qst", this.state.selectedId);
      axios
        .post(api + "userdosession/", Data, this.state.requestConfig)
        .then((data) => {
          //console.log("data", data.data);
          // window.location = `/session-start/session/${this.state.selectedId}`;
        })
        .catch((e) => {
          console.log("e", e.response);
        });

      window.location = `/session-start/session/${objectFound?.id}`;
    }
    //return (window.location = `/session-start/session/${this.state.selectedId}`);
  };

  ScrollTop = () => {
    window.scrollTo(0, 0);
  };

  render() {
    let totalscore = 0;
    // console.log("evaluation", this.props.evaluation);
    // if (this.props?.id_contenu !== undefined) {
    //   console.log("id contenu evaluation =>", this.props?.id_contenu);            
    // }
    // if(this.props?.a_afficher_sur!==null){
    //   console.log("a afficher sur =>", this.props?.a_afficher_sur);
    // }
    if (this.props.evaluation[0]?.is_testfinal === true && this.state.responses.length) {
      for (let i = 0; i < this.state.responses.length; i++) {
        const element = this.state.responses[i];
        totalscore += parseInt(element.response)
      }

    }


    /*  if (this.props.evaluation[0]?.is_pretest === true) {
       window.localStorage.setItem('pretestScore', this.state.score)
     } */
    return (
      <div>
        <h5 className="m-b-20 fw-300 f-f-work">
          Évalue les déclarations suivantes selon une échelle de 1 à 5.
        </h5>
        <p className="fw-600 f-f-work text-dark">1 = Pas du tout.</p>
        <p className="fw-600 f-f-work text-dark m-b-15">5 = Oui, absolument.</p>
        <p className="fw-600 f-f-work text-dark m-b-20">
          Il s'agit ici de ton auto-évaluation. Il n’y a pas de bonne ou de
          mauvaise réponse.
        </p>

        <div className="content-choices-response-quiz p-t-30 ">
          <form>
            {this.props.evaluation.map((item, index) => {
              return (
                <div className="m-b-40" key={item.id}>
                  <p className="question-evaluation">{item.question}</p>
                  <div className="row">
                    <div className="col-md-2 col-sm-2 col-6">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={`Q${index}1X${item.id}`}
                          name={item.id}
                          value={1}
                          onChange={(e) => this.handleChange(e, item.id)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`Q${index}1X${item.id}`}
                        >
                          1
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={`Q${index}2X${item.id}`}
                          name={item.id}
                          value={2}
                          onChange={(e) => this.handleChange(e, item.id)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`Q${index}2X${item.id}`}
                        >
                          2
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={`Q${index}3X${item.id}`}
                          name={item.id}
                          value={3}
                          onChange={(e) => this.handleChange(e, item.id)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`Q${index}3X${item.id}`}
                        >
                          3
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={`Q${index}4X${item.id}`}
                          value={4}
                          name={item.id}
                          onChange={(e) => this.handleChange(e, item.id)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`Q${index}4X${item.id}`}
                        >
                          4
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-6">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={`Q${index}5X${item.id}`}
                          value={5}
                          name={item.id}
                          onChange={(e) => this.handleChange(e, item.id)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`Q${index}5X${item.id}`}
                        >
                          5
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </form>
        </div>

        <div className="row dis-flex justify-content-end m-b-80 slider-actions-btn">
          {this.state.responses.length === this.props.evaluation.length &&
            this.state.isSended !== true ? (
              <div className="col-md-3 col-sm-6 m-b-10">
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  onClick={this.addResponses}
                  data-toggle="modal"
                  data-target={`#evalSuccess${this.props.evaluation[0]?.id}`}
                >
                  envoyer
              </button>
              </div>
            ) : null}
        </div>
        <div
          className="modal fade"
          id={`evalSuccess${this.props.evaluation[0]?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="mb-3 animat rotateIn"
                  />
                  <h5 className="pop-title fw-600">
                    {!this.props.evaluation[0]?.is_pretest && !this.props.evaluation[0]?.is_testfinal ?
                      ('Tes réponses ont été soumises ! Bravo pour avoir terminé cet exercice ! ')
                      : this.props.evaluation[0]?.is_pretest ?
                        ('Merci pour ton auto-évaluation. A la fin de la session, tu repasseras l’auto-évaluation pour vérifier si tu as progressé sur certains aspects')
                        : this.props.evaluation[0]?.is_testfinal ?
                          (
                            totalscore < this.state.pretestScore ?
                              ('Ton auto-évaluation a donné un moins bon résultat qu’au pré-test. Oups ! Que s\'est-il passé ? Peut-être souhaites-tu réviser quelques parties de la session pour approfondir tes connaissances ?')
                              : totalscore === this.state.pretestScore ?
                                ('Ton auto-évaluation a donné le même résultat qu’au pré-test Peut-être souhaites-tu réviser quelques parties de la session pour approfondir tes connaissances ?')
                                : ('Ton auto-évaluation a donné un meilleur résultat qu’au-prétest. Bonne nouvelle ! Cela signifie que tu penses avoir amélioré tes connaissances. Continue comme ça !')
                          ) : ('')}

                  </h5>

                </div>
              </div>
              <div className="modal-footer justify-content-center slider-actions-btn">
                {/* <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-theme fw-400 fs-15 justify-content-start"
                    onClick={this._ToggleNext}
                  >
                    VOIR LE RESULTAT{" "}
                  </button> */}
                <a
                  type="button"
                  className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                  data-dismiss="modal"
                  href="#carouselSliderContentDetailsSession"
                  role="button"
                  data-slide="next"
                  onClick={this.ScrollTop}
                >
                  CONTINUER
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              data-slide="prev"
              onClick={this.ScrollTop}
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            {this.props?.usercontenus.every(
              (v) => parseInt(v?.contenu?.id) !== this.props.evaluation?.contenu
            ) !== false ? (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  onClick={this.ScrollTop}
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme disabled dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  disabled
                >
                  Suivant
                </button>
              )}
          </div>
        </div>
      </div>
    );
  }
}
