import axios from "axios";
import { EditorState } from "draft-js";
import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast, ToastContainer } from "react-toastify";
import api from "../../http/global-vars";
import ContentEditor from "./supportContentAdmin/ContentEditor";
export default class CreateClickAndReveal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          "Content-Type": "multipart/form-data",
        },
      },
      response_image: null,
      es1: EditorState.createEmpty(),
      es2: EditorState.createEmpty(),
      question_image: null,
      api: api,
      userID: window.localStorage.getItem("userID"),
      fields: {},

      content_type: "click_reveal",
      created_by: parseInt(window.localStorage.getItem("userID")),
      isSuccess: false,
      isError: false,
      isLoading: false,
      contentId: this.props.contentId,
      errors: {},
    };
  }

  onChange = (editorState) => {
    this.setState({ editorState });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields,
    });
  };

  urlChange = (e) => {
    const files = e.target.files;
    if (files?.length) {
      this.setState({ [e.target.name]: files[0] });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  getText(es) {
    return es?.getCurrentContent()?.getPlainText();
  }
  onValidateForm() {
    const question_text = this.state.question_text;
    const response_text = this.state.response_text;
    let fields = this.state.fields;
    let isValidForm = true;
    this.setState({
      is_empty_name: false,
      is_empty_question: false,
      is_empty_response: false,
    });
    if (!fields["name"]) {
      this.setState({
        is_empty_name: true,
      });
    }
    if (!this.state.question_image && !question_text) {
      isValidForm = false;
      this.setState({
        is_empty_question: true,
      });
    }
    if (!this.state.response_image && !response_text) {
      isValidForm = false;
      this.setState({
        is_empty_response: true,
      });
    }

    return isValidForm;
  }

  renderEditor(name) {
    return (
      <ContentEditor
        name={name}
        placeholder="Texte"
        value={this.state[name]}
        onChange={(val) => this.setState({ [name]: val })}
      />
    );
  }

  addForm = (e) => {
    e.preventDefault();
    if (this.onValidateForm()) {
      this.setState({
        isLoading: true,
      });
      const question_text = this.state.question_text;
      const response_text = this.state.response_text;
      const api = this.state.api;
      const fd = new FormData();
      fd.append("title", this.state.fields.name);
      fd.append("content_type", this.state.content_type);
      fd.append("created_by", this.state.created_by);
      fd.append("contenu", this.state.contentId);
      axios
        .post(api + "quizfree/", fd, this.state.requestConfig)
        .then((response) => {
          if (response.status === 201) {
            const fdata = new FormData();
            fdata.append("content", question_text || "");
            if (this.state.question_image) {
              fdata.append("image", this.state.question_image);
            }
            fdata.append("quiz", response.data.id);
            axios
              .post(api + "questionfree/", fdata, this.state.requestConfig)
              .then((resp) => {
                const form = new FormData();
                form.append("content", response_text || "");
                form.append("question", resp.data.id);
                if (this.state.response_image) {
                  form.append("image", this.state.response_image || "");
                }
                axios
                  .post(
                    api + "responsequestionfree/",
                    form,
                    this.state.requestConfig
                  )
                  .then((resp) => {
                    this.props.notify();
                    this.setState({
                      isSuccess: true,
                      isLoading: false,
                      fields: {},
                      url: null,
                      response_image: null,
                      response_text: null,
                      question_image: null,
                      question_text: null,
                    });
                  })
                  .catch((ex) => {
                    this.setState({
                      isSuccess: false,
                    });
                  });
              });
          } else {
            toast.error("Vos données ne sont pas valides !");
          }
        })
        .catch((error) => {
          console.log(error.response);
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
          );
          this.setState({
            isError: true,
            isLoading: false,
          });
          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 5000);
        });
    }
  };

  render() {
    return (
      <div className="card-dashboard no-shadow">
        <form>
          <div className="row m-b-50">
            <div className="col-lg-9">
              <p className="titre-card-create-content">Clik and reveal</p>
            </div>
          </div>
          <div className="form-group m-b-60">
            <input
              type="text"
              className="form-control input-dashboard-backend"
              placeholder="Consigne"
              name="name"
              onChange={(e) => this.handleChange(e)}
            />
            {this.state.is_empty_name && (
              <div className="alert alert-danger keysjob-alert-danger">
                Ce champ est obligatoire
              </div>
            )}
          </div>

          <div className="row mb-3">
            <div className="col-md-12 itm-center">
              <span className="fs-15 fw-400">Question (Image ou texte)</span>
            </div>
            <div className="col-md-12  mb-5">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  name="question_image"
                  id="question_image"
                  onChange={this.urlChange}
                />
                <label
                  className="custom-file-label custom-file-label-create-content border-theme p-l-25 p-r-25 fs-12 fw-500"
                  htmlFor="question_image"
                >
                  {this.state.question_image
                    ? this.state.question_image.name
                    : "Upload une image"}
                </label>
                <FeatherIcon className="icon-import-file-label" icon="upload" />
              </div>
            </div>
            <div className="col-md-12">
              {this.renderEditor("question_text")}
            </div>

            <div className="col-md-12">
              {this.state.is_empty_question && (
                <div className="alert alert-danger keysjob-alert-danger">
                  Ce champ est obligatoire
                </div>
              )}
            </div>
          </div>

          <div className="row mb-3 mt-5">
            <div className="col-md-12  itm-center">
              <span className="fs-15 fw-400">Réponse (Image ou texte)</span>
            </div>
            <div className="col-md-12 mb-5">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  name="response_image"
                  id="response_image"
                  onChange={this.urlChange}
                />
                <label
                  className="custom-file-label custom-file-label-create-content border-theme p-l-25 p-r-25 fs-12 fw-500"
                  htmlFor="response_image"
                >
                  {this.state.response_image
                    ? this.state.response_image.name
                    : "Upload une image"}
                </label>
                <FeatherIcon className="icon-import-file-label" icon="upload" />
              </div>
            </div>
            <div className="col-md-12">
              {this.renderEditor("response_text")}
            </div>
            <div className="col-md-12">
              {this.state.is_empty_response && (
                <div className="alert alert-danger keysjob-alert-danger">
                  Ce champ est obligatoire
                </div>
              )}
            </div>
          </div>

          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 m-b-20">
              {/* <button className="btn-theme text-up w-full">Terminer</button> */}
              {this.state.isLoading === false ? (
                <button
                  type="submit"
                  onClick={(e) => this.addForm(e)}
                  className="btn-theme text-up w-full"
                >
                  Terminer
                </button>
              ) : (
                <button className="btn-theme text-up w-full in-progress-btn">
                  Ajout en cours... &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
      </div>
    );
  }
}
