// import { data } from "jquery";
// import { join } from "lodash";
import React, { Component, Fragment, ChangeEvent } from "react";
import api from "../../http/global-vars";
import axios from 'axios';
import FeatherIcon from "feather-icons-react";
// import { render } from "react-dom";

export default class TacheTextATroux extends Component {
  constructor(props) {
    super(props);

    // console.log('final', finalData, currentIndex)

    this.state = {
      data: this.props.texte_trous[0] || {
        list_mots_enlever: [],
        complete_content: "",
        list_mots_supplementaires: [],
      },
      userID: window.localStorage.getItem("userID"),
      token: window.localStorage.getItem("userToken"),
      module_id: "",
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      // mots: this.props.texte_trous[0].list_mots_enlever,
      show: false,
      isDoing: false,
      selectValue: ''
      // data:this.props.texte_trous,
    };
  }


  componentDidMount() {
    setTimeout(() => {
      this.getSessionByModule()
    }, 3000);
  }

  _onShowSolution = () => {
    this.setState({
      show: true,
    });
  };

  partString(string) {
    var parts = string.split("");
    for (let i = 0; i < parts.length; i += 2) {
      parts[i] = <span key={i}>{parts[i]}</span>;
    }
    return <div>{parts}</div>;
  }

  handleChange = (e) => {
    console.log('event', e)

  }

  renderSelect(texte_trous) {
    var items = "";
    var str = [];
    // eslint-disable-next-line
    texte_trous.map((text) => {
      str = text.list_mots_enlever.map((word) => word.content);
      // console.log(str);
      var tab_mots_enlever = str;
      items += `<option value="placeholder">------------------------</option>`;
      for (let index = 0; index < tab_mots_enlever.length; index++) {
        const element = tab_mots_enlever[index];
        items += `<option key="${index}"  value="${element.split(
          " "
        )}" >${element.split(" ")}</option>`;
      }
      // console.log(items);
    });

    //console.log(`${items}`)
    return `<select class="select_tat" style="border:0;" >${items}</select>`;
  }

  renderStr(texte_trous) {
    var texty = "";
    var deletd_words = [];
    var mapObj = {};

    texte_trous.map((text) =>
      // eslint-disable-next-line
      text?.list_mots_enlever.map((word, i) => {
        deletd_words[i] = word.content.toLowerCase();
        var indx = deletd_words[i];
        mapObj[indx] = this.renderSelect(texte_trous);
        // console.log(mapObj)
      })
    );

    // eslint-disable-next-line
    texte_trous.map((text) => {
      texty = text.complete_content;
    });

    // console.log(texty)
    return this.replaceAll(texty, mapObj, texte_trous);
  }

  replaceAll(str, mapObj, full_text) {
    var regexy = new RegExp(Object.keys(mapObj).join("|"), "gi");
    var final_text = str.replace(regexy, this.renderSelect(full_text));
    return final_text;
  }

  updateProgres = async () => {
    const { api } = this.state;
    // const url = api + `userdomodule/${this.state.module_id}/`;
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id
    );
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;

    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };
   // console.log(send)
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  UserDoContenu = async () => {
    let checker = (arr) =>
      arr.every(
        (v) => parseInt(v?.contenu?.id) !== this.props.texte_trous[0]?.contenu
      );
    console.log("check contenu", checker(this.props.usercontenus));
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.props.texte_trous[0]?.contenu,
    };
    //this.updateProgres();
    if (checker(this.props.usercontenus) !== false) {
      const url = api + "userdocontenu/";
      await axios
        .post(url, sendData, this.state.requestConfig)
        .then((res) => {
          console.log("data post", res);
          this.setState({ isDoing: true });
          this.updateProgres();
        })
        .catch((e) => {
          console.log("e post", e.response.data);
        });
    }
    this.props.onGetUserDoModules();
    window.scrollTo(0, 0);
  };

  getSessionByModule = () => {
    if (this.props.sessionId) {
      const url = api + "session/" + this.props.sessionId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) => d.module_id === response.data.module_id
          );
          let data = response["data"];
          this.setState({ session: last, module_id: data.module_id });
          //console.log("session", last);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  }

  renderSolution = (texte_trous) => {
    var items = "";
    var str = [];
    texte_trous.map((text) => {
      str = text.list_mots_enlever.map((word) => word.content);
      // console.log(str);
      var tab_mots_enlever = str;
      //items += `<option value="placeholder">------------------------</option>`;
      for (let index = 0; index < tab_mots_enlever.length; index++) {
        const element = tab_mots_enlever[index];
        items += `<span key="${index}" style="color: red">${element.split(" ")}</span>`;
      }
      return items;
    });
  }

  renderCorrect(texte_trous) {
    var texty = "";
    var deletd_words = [];
    var mapObj = {};

    texte_trous.map((text) =>
      // eslint-disable-next-line
      text?.list_mots_enlever.map((word, i) => {
        deletd_words[i] = word.content.toLowerCase();
        var indx = deletd_words[i];
        mapObj[indx] = this.renderSolution(texte_trous);
        // console.log(mapObj)
      })
    );

    // eslint-disable-next-line
    texte_trous.map((text) => {
      texty = text.complete_content;
    });

    // console.log(texty)
    return this.replaceAll(texty, mapObj, texte_trous);
  }


  render() {
    let { texte_trous } = this.props;
    let checker = (arr) =>
      arr.every(
        (v) => parseInt(v?.contenu?.id) !== texte_trous[0]?.contenu
      );
    /*  let solution = this.props.texte_trous.map((text, index) => {
       return (
         <div className="content-choices-response-quiz p-t-30 p-b-30 m-b-35">
           <p className="fs-14 f-f-work fw-400 text-dark">
             {text.complete_content}
           </p>
         </div>
       );
     }); */
    return (
      <div>
        {this.props.texte_trous.map((text, index) => {
          return (
            <React.Fragment key={index}>

              <>
                <h6 className="m-b-35 fw-600 f-f-work h-theme-dark fs-20">
                  {text?.name}
                </h6>
                <p className="fw-600 text-dark f-f-work">Mots : </p>

                <p className="fs-14 f-f-work fw-400 m-b-50 text-dark">
                  {text?.list_mots_enlever.map((word, i) => {
                    return (
                      <Fragment key={word.id}>{word.content}, </Fragment>
                    );
                  })}
                </p>
                <div className="content-choices-response-quiz p-t-30 p-b-30 m-b-35">
                  <div
                    className="fs-14 f-f-work fw-400 text-dark"
                    dangerouslySetInnerHTML={{
                      __html: this.renderStr(texte_trous),
                    }}
                  />
                </div>

                {this.state.show ? (null) : (
                  <div className="row send-response-row">
                    <div className="col-md-4 offset-md-8 col-sm-6 m-b-10">
                      <button
                        className="btn-theme dis-flex justify-content-center fw-600 resp-btn"
                        onClick={this.UserDoContenu}
                        data-toggle="modal"
                        data-target={`#texteSuccess${texte_trous[0]?.id}`}
                      >
                        Soumettre votre réponse
                    </button>
                    </div>
                  </div>
                )}
              </>
              {!this.state.show ? (null) : (
                <h4 className="m-b-30 fw-300 f-f-work">CORRECTION</h4>
              )}
              {this.state.show ? (
                <div className="content-choices-response-quiz p-t-30 p-b-30 m-b-35">
                  {/* <p className="fs-14 f-f-work fw-400 text-dark">
                    {text.complete_content}
                  </p> */}
                  {/*        {text?.list_mots_enlever.map(w => {
                    return <div
                    className="fs-14 f-f-work fw-400 text-dark"
                    dangerouslySetInnerHTML={{ __html: text?.complete_content.replace(new RegExp(w?.content, 'g'), 
                    '<a href="/' + w?.id + '">$&</a> (<Icon name="external" />)') }}

                  />
                  })} */}
                  <div
                    className="fs-14 f-f-work fw-400 text-dark"
                    dangerouslySetInnerHTML={{ __html: text?.complete_content }}

                  />
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600 slide-precedent"
              href="#carouselSliderContentDetailsSession"
              data-slide="prev"
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            {checker(this.props.usercontenus) === false || this.state.isDoing ?
              (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600 slide-suivant"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme disabled dis-flex justify-content-center fw-600 slide-suivant"
                  disabled
                  data-slide="next"
                >
                  Suivant
                </button>
              )}

          </div>
        </div>
        <div
          className="modal fade"
          id={`texteSuccess${texte_trous[0]?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="mb-3 animat rotateIn"
                  />
                  <h4 className="pop-title fw-600"> Tes réponses ont été soumises ! Bravo pour avoir terminé cet exercice ! </h4>
                  {/*  <p className="parag fs-20 fw-600">
                    Réponses envoyées avec succés{" "}
                    {/* <span className="scores">
                        {this.state.score} / {totalScore} points
                      </span> 
                  </p> */}
                </div>
              </div>
              <div className="modal-footer justify-content-center slider-actions-btn">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-theme fw-400 fs-15 justify-content-start"
                  onClick={this._onShowSolution}
                >
                  VOIR LA CORRECTION{" "}
                </button>
                <a
                  type="button"
                  className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                  data-dismiss="modal"
                  href="#carouselSliderContentDetailsSession"
                  role="button"
                  data-slide="next"
                >
                  CONTINUER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
