import React, { Component } from 'react';

export default class QuizItem extends Component {
    constructor(props){
        super(props)
        this.state = {
            
        }
    }
  
    render(){
        return (
            <div className="quiz-item">
                <div className="row">
                    <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12 itm-center containt-mobile-center">
                        <p className="content-quiz-item m-b-15 h-content-quiz-item">Quizz 1 : Lorem ipsum</p>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 text-center m-b-15">
                        <p className="content-quiz-item fw-300">Apprenants</p>
                        <p className="content-quiz-item h-theme-light">1454</p>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 text-center m-b-15">
                        <p className="content-quiz-item fw-300">Taux de reussite</p>
                        <p className="content-quiz-item h-theme-light">57 %</p>
                    </div>
                </div>
            </div>
        );
    }
}
