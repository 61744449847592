import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

export default class HeaderAccueil extends Component {
  constructor(props){
      super(props)
      this.state = {
          
      }
  }

  render(){
    return (
      <nav className="navbar navbar-light bg-white box-shadow nav-accueil p-t-15 p-b-15">
          <div className="container">
              <NavLink className="navbar-brand" to="/">
                  <img src="img/defaru300_100px.png" className="logo-accueil-header" alt="" loading="lazy" />
              </NavLink>
          </div>
      </nav>
    );
  }
}