import React, { Component } from "react";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import MessageAllPage from "./components/MessageAllPage";
import ModuleItem from "./components/ModuleItem";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import NavbarVerticalAdmin from "./components/NavbarVertical";

export default class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobileAdmin />
          </div>
        </div>

        <HeaderDashboardAdmin />

        <div className="row">
          {/* Navbar Desktop  */}
          {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
          <div className="nav-container">
            <div
              className="navbar-vertical-customisation no-view-mobile"
              style={{ top: "40px" }}
            >
              <NavbarVerticalAdmin />
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-12"></div>

          <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
            <div className="container-fluid">
              <h5 className="text-black f-f-work fw-500 m-b-15">Module</h5>

              <div className="card-add">
                <button
                  type="button"
                  className="link-add-card-add"
                  data-toggle="modal"
                  data-target="#AddModuleModal"
                >
                  <div>
                    <img
                      className="img-link-add-card-add"
                      src="img/icons/icon-add.png"
                      alt=""
                    />
                  </div>
                  <span>Ajouter un module</span>
                </button>
              </div>

              <h6 className="text-black f-f-work fw-500 m-b-15">
                Liste des modules
              </h6>

              <ModuleItem />
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>
      </div>
    );
  }
}
