import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import ItemIntroModule from "./components/ItemIntroModule";
import ItemSessionModule from "./components/ItemSessionModule";
import MessageAllPage from "./components/MessageAllPage";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import NavbarVerticalAdmin from "./components/NavbarVertical";
import { useLocation, useParams, useHistory } from "react-router-dom";
import Axios from "axios";
import api from "../http/global-vars";
import ScormContentAdmin from "./components/ScormContentAdmin";
import ScormProvider from "react-scorm-provider";
import { withRouter } from "react-router-dom";

// export default class ModuleDetailsV2 extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       module_id: this.props.match.params.id,
//       module: this.props.location?.state,
//     };
//   }

//   componentDidMount() {
//     // console.log("id module=>", this.props);
//   }

//   render() {
//     // console.log(this.props);

//   }
// }

function ModuleDetailsV2() {
  const [module, setModule] = useState({});
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const getModule = async (id) => {
    await Axios.get(`${api}module/${id}`, config)
      .then((res) => {
        if (res?.data?.scorm_link) {
          // window.open(res?.data?.scorm_link)
          // this.props.history.goBack()
        }
        console.log(res)
        setModule(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
        history.goBack();
      });
  };

  useEffect(() => {
    if (id) {
      getModule(id);
    }
  }, [id]);

  useEffect(() => {
    if (location?.state) {
      setModule(location?.state);
    }
  }, [location]);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobileAdmin />
        </div>
      </div>

      <HeaderDashboardAdmin />

      <div className="row">
        {/* Navbar Desktop  */}
        {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
        <div className="nav-container">
          <div
            className="navbar-vertical-customisation no-view-mobile"
            style={{ top: "40px" }}
          >
            <NavbarVerticalAdmin />
          </div>
        </div>

        <div className="col-xl-2 col-lg-3 col-md-12"></div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <p className="m-b-30">
              <NavLink className="routing-view-url" to="/module">
                Module
              </NavLink>
              <FeatherIcon
                className="icon-routing-view-url"
                icon="chevron-right"
              />
              <NavLink className="routing-view-url" to="#">
                {module?.name}
              </NavLink>
            </p>
            {(module && module?.scorm_link) && <ScormContentAdmin module={module} />}
            {(module && !module?.scorm_link && !module?.thematique) && (
              <>
                <ItemIntroModule module_id={id} module={module} />
                <ItemSessionModule module_id={id} module={module} />
                <button
                  className="btn-light-extra-large"
                  data-toggle="modal"
                  data-target=".modalAddSession"
                >
                  <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
                  Ajouter une session
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
}

export default withRouter(ModuleDetailsV2);
