import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
// import { NavLink } from 'react-router-dom';
import ReactAudioPlayer from "react-audio-player";
import baseUrl from '../../http/backend-base-url';
import axios from 'axios'
import api from '../../http/global-vars';

export default class LayoutCours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      podcast: this.props.podcast,
      module_id: "",
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getSessionByModule();
    }, 3000);
  }

  updateProgres = async (e) => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter((v) => v?.module?.id === this.state.module_id)
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;

    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };
    // console.log(send)
    try {
      const res = await axios.put(url, send, this.state.requestConfig);
      console.log(res);
    } catch (error) {
      console.log(error.message);
    }
  };

  UserDoContenu = (e) => {
    let checker = (arr) =>
      arr.every(
        (v) => parseInt(v?.contenu?.id) !== this.state.podcast[0]?.contenu
      );
    console.log("check contenu", checker(this.props.usercontenus));
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.state.podcast[0]?.contenu,
    };
    if (checker(this.props.usercontenus) !== false) {
      const url = api + "userdocontenu/";
      axios
        .post(url, sendData, this.state.requestConfig)
        .then((res) => {
          console.log("data post", res);
          this.updateProgres();
        })
        .catch((e) => {
          console.log("e post", e.response.data);
        });
    }
    window.scrollTo(0, 0);
  };

  getSessionByModule = () => {
    if (this.props.sessionId) {
      const url = api + "session/" + this.props.sessionId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) => d.module_id === response.data.module_id
          );
          let data = response["data"];
          this.setState({ session: last, module_id: data.module_id });
          //console.log("session", last);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  };

  render() {
    //console.log('podcast', this.state.podcast)
    return (
      <div>
        {this.state.podcast.map((item, i) => {
          return (
            <div key={item.id}>
              <p className="titre-lecon-start-page">{item?.name}</p>
              <div className="audio">
                <ReactAudioPlayer
                  src={`${baseUrl}${item.url}`}
                  autoPlay={false}
                  onPlay={(e) => this.UserDoContenu(e)}
                  controls
                />
              </div>

              <div className="lh-3">
                <FeatherIcon
                  className="icon-collapse-video"
                  icon="align-left"
                />
                <a
                  data-toggle="collapse"
                  href="#collapseExamples"
                  id="collapseExample"
                  className="text-collapse-video"
                >
                  VOIR LA DESCRIPTION
                </a>
              </div>
              <div className="collapse" id="collapseExamples">
                <div className="text-black">
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                </div>
              </div>

              {/* <div className="collapse" id="collapseExample">
                  <p className="text-black">{item.description}</p>
                </div> */}
            </div>
          );
        })}
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              role="button"
              data-slide="prev"
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            <button
              className="btn-theme dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              data-slide="next"
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    );
  }
}
