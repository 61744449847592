import axios from "axios";
import FeatherIcon from "feather-icons-react";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import Swal from "sweetalert2";
import baseUrl from "../../../http/backend-base-url";
import api from "../../../http/global-vars";
import EditPodcast from "../EditPodcast";
import { hideModal } from "../ItemSessionModule";

function SupportPodcast({ data, onReload }) {
  const onDelete = () => {
    Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer ce support ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: false,
      iconColor: "#f39200",
      preConfirm: () => {
        return axios
          .delete(`${api}support/${data.id}`, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          })
          .then(async (response) => {
            // await getContent()
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Erreur: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((results) => {
      if (results?.isConfirmed) {
        Swal.fire({
          title: "Support supprimé avec succès !",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          focusCancel: false,
          iconColor: "#f39200",
          timer: 3000,
        });

        onReload();
      }
    });
  };

  return (
    <div className="row">
      <div className="col-md-8"> {data?.name}</div>
      <div className="col-md-4">
        <button
          className="btn-action-modul-session"
          data-toggle="modal"
          data-target={`#EditUpload${data?.id}`}
        >
          <FeatherIcon icon="edit" />
        </button>
        <button
          className="btn-action-modul-session"
          title="Supprimer"
          onClick={() => onDelete()}
        >
          <FeatherIcon icon="trash-2" />
        </button>
      </div>
      <div className="col-md-6">
        <div className="audio">
          <ReactAudioPlayer
            src={`${baseUrl}${data.url}`}
            autoPlay={false}
            controls
          />
        </div>
      </div>
      <div className="btn-action-container-modul-session">
        <div
          className="modal fade"
          id={`EditUpload${data?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content card-dashboard">
              <EditPodcast
                onDone={() => {
                  onReload();
                  hideModal(`#EditUpload${data?.id}`);
                }}
                data={data}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportPodcast;
