import baseUrl from "./backend-base-url";

// RECETTE API BASE URL
var api = `${baseUrl}/api/`;

export default api;

/*
   module [
      sessions [
         supports [
            contenus[
               type
               
            ]
         ]
      ]
   ]

*/
