import React, { Component } from "react";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarVertical from "../components/NavbarVertical";
import MessageAllPage from "../../backend/components/MessageAllPage";
// import SessionStartInfos from '../components/SessionStartInfos';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react'
import api from './../../http/global-vars'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";


export default class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem('userToken'),
      requestConfig: {
        headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
      },
      api: api,
      userID: window.localStorage.getItem('userID'),
      userInfos: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        sexe: '',
        adresse: ''
      },
      isPageLoading: false,
      updateUserInfosInProgress: false,
      isSuccessUpdating: false,
      isErrorUpdating: false,
      is_empty_email: false,
      is_empty_first_name: false,
      is_empty_last_name: false,
      is_empty_phone: false,
      is_invalid_email: false,
      is_empty_sexe: false,
      is_empty_adresse: false,
      errors: {}
    };

    this.handleChangeUpdate = this.handleChangeUpdate.bind(this)
    this.onSubmitUpdateUserInfos = this.onSubmitUpdateUserInfos.bind(this)
  }

  componentDidMount(){
    var config = this.state.requestConfig
    this.onGetConnectedUserInfos(config)
  }

  onGetConnectedUserInfos = (config) => {
    this.setState({
      isPageLoading: true
    })
    var api = this.state.api
    axios.get(api + 'me/', config)
        .then(response => {
            //console.log(response)
            this.setState({
              isPageLoading: false
            })
            this.setState({
                isLoginInProgress: false
            })
            if(response.status === 200){
                this.setState({
                    userInfos: response.data.data
                })
                //console.log(response.data.data)
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({
              isPageLoading: false
            })
        })
  }

  handleChangeUpdate = (e) => {
    e.persist();

    this.setState(prevState => ({
      userInfos: { ...prevState.userInfos,  [e.target.name]: e.target.value }
    }))
  }

  onValidateUpdateForm = () => {
    let fields = this.state.userInfos
    let errors = this.state.errors
    var isValidForm = true

    if(!fields['first_name']){
      isValidForm = false
      this.setState({
        is_empty_first_name: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_first_name: false
        })
      }, 5000);
      errors['first_name'] = 'Ce champ ne doit pas être vide'
    }
    if(!fields['last_name']){
      isValidForm = false
      this.setState({
        is_empty_last_name: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_last_name: false
        })
      }, 5000);
      errors['last_name'] = 'Ce champ ne doit pas être vide'
    }
    if(!fields['email']){
      isValidForm = false
      this.setState({
        is_empty_email: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_email: false
        })
      }, 5000);
      errors['email'] = 'Ce champ ne doit pas être vide'
    }
    if(!fields['phone']){
      isValidForm = false
      this.setState({
        is_empty_phone: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_phone: false
        })
      }, 5000);
      errors['phone'] = 'Ce champ ne doit pas être vide'
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
          isValidForm = false
          this.setState({
              is_invalid_email: true
          })
          setTimeout(() => {
              this.setState({
                  is_invalid_email: false
              })
          }, 5000);

          errors['invalid_email'] = 'Votre adresse email n\'est pas valide'
      }
    }
    if(!fields['sexe']){
      isValidForm = false
      this.setState({
        is_empty_sexe: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_sexe: false
        })
      }, 5000);
      errors['sexe'] = 'Ce champ ne doit pas être vide'
    }
    if(!fields['adresse']){
      isValidForm = false
      this.setState({
        is_empty_adresse: true
      })
      setTimeout(() => {
        this.setState({
          is_empty_adresse: false
        })
      }, 5000);
      errors['adresse'] = 'Ce champ ne doit pas être vide'
    }

    this.setState({
      errors
    })

    return isValidForm
  }

  onSubmitUpdateUserInfos = (e) => {
    e.preventDefault()

    if(this.onValidateUpdateForm()){
      this.setState({
        updateUserInfosInProgress: true
      })

      var api = this.state.api
      var config = this.state.requestConfig
      var data = new FormData()
      var userID = this.state.userID

      data.append('first_name', this.state.userInfos.first_name)
      data.append('last_name', this.state.userInfos.last_name)
      data.append('email', this.state.userInfos.email)
      data.append('phone', this.state.userInfos.phone)
      data.append('sexe', this.state.userInfos.sexe)
      data.append('adresse', this.state.userInfos.adresse)

      axios.put(`${api}user/${userID}/`, data, config)
        .then(response => {
          this.setState({
            updateUserInfosInProgress: false
          })

          if(response.status === 200){
            window.localStorage.setItem('userFirstName', data.first_name)
            window.localStorage.setItem('userLastName', data.last_name)
            window.localStorage.setItem('userFullname', data.first_name + ' ' + data.last_name)
            toast.success('Votre profil a été bien modifié!')
            this.setState({
              isSuccessUpdating: true
            })
            setTimeout(() => {
              this.setState({
                isSuccessUpdating: false
              })
              window.location.reload()
            }, 5000);
          }else{
            console.log(response)
            toast.error('Oupsss! Une erreur est survenue lors de la modification de votre profil')
            this.setState({
              isErrorUpdating: true
            })
            setTimeout(() => {
              this.setState({
                isErrorUpdating: false
              })
            }, 5000);
          }
        })
        .catch(error => {
          console.log(error)
          toast.error('Oupsss! Une erreur est survenue lors de la modification de votre profil')
          this.setState({
            updateUserInfosInProgress: false,
            isErrorUpdating: true
          })
          setTimeout(() => {
            this.setState({
              isErrorUpdating: false
            })
          }, 5000);
        })
    }
  }

  render() {
    var { userInfos } = this.state
    console.log(userInfos)
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobile />
          </div>
        </div>

        <HeaderDashboard />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="col-xl-2 col-lg-3 col-md-12">
            <div className="navbar-vertical-customisation no-view-mobile">
              <NavbarVertical />
            </div>
          </div>

          <div className="col-xl-10 col-lg-9 col-md-12 content-position-after-header p-b-200">
            <div className="">
              <div className="row content-routing-url m-b-20">
                <p className="lh-0">
                  <NavLink className="routing-view-url" to="">
                    Mon profil
                  </NavLink>
                </p>
              </div>

              <div className="row">
                <div className="container-fluid">
                  <div className="item-profile-badge-container">
                    <p className="text-header-quiz-card p-l-40 m-b-50">
                      Mes badges
                    </p>
                    <div className="row p-l-40">
                      <div className="col-md-12 dis-flex badges text-center">
                        {userInfos.badge === 0 ?
                          <div className="alert alert-info gfa-alert-info empty-badge-alert">
                            <div className="row empty-alert-row">
                              <div className="col-md-2 empty-icon-col">
                                <FeatherIcon
                                  icon="alert-circle"
                                  className="m-b-3 empty-alert-icon rotateIn"
                                />
                              </div>
                              <div className="col-md-10 empty-textual-col">
                                <p>Vous n'avez acquis aucun badge pour le moment.</p>
                                <p className="info-label">Pour rappel chaque module de formation terminé vous donne droit à un badge.</p>
                              </div>
                            </div>
                          </div> :
                          userInfos.badge === 1 ?
                            <div className="row badge-row">
                              <div className="col-md-12 badge-col">
                                <FeatherIcon
                                  icon="award"
                                  className="m-b-3 badge rotateIn"
                                />
                                <div className="badge-motivation">
                                  <span className="badge-emoticon" role="img" aria-label="force">&#128170;</span>&nbsp;
                                  <span className="badge-motivation-label">Du courage! Il ne vous reste plus que 2 modules à terminer.</span>
                                </div>
                              </div>
                            </div> :
                          userInfos.badge === 2 ?
                            <div className="row badge-row">
                              <div className="col-md-6 badge-col">
                                <FeatherIcon
                                  icon="award"
                                  className="m-b-3 badge rotateIn"
                                />
                              </div>
                              <div className="col-md-6 badge-col">
                                <FeatherIcon
                                  icon="award"
                                  className="m-b-3 badge rotateIn"
                                />
                              </div>
                              <div className="badge-motivation">
                                <span className="badge-emoticon" role="img" aria-label="reverse">&#128579;</span>&nbsp;
                                <span className="badge-motivation-label">C'est renversant, quelle performance! Plus qu'un seul module à boucler pour disposer de vos 3 badges.</span>
                              </div>
                            </div>  :
                          userInfos.badge === 3 && 
                            <div className="row badge-row">
                              <div className="col-md-4 badge-col">
                                <FeatherIcon
                                  icon="award"
                                  className="m-b-3 badge rotateIn"
                                />
                              </div>
                              <div className="col-md-4 badge-col">
                                <FeatherIcon
                                  icon="award"
                                  className="m-b-3 badge rotateIn"
                                />
                              </div>
                              <div className="col-md-4 badge-col">
                                <FeatherIcon
                                  icon="award"
                                  className="m-b-3 badge rotateIn"
                                />
                              </div>
                              <div className="badge-motivation">
                                <span className="badge-emoticon" role="img" aria-label="clap-hand">&#128079;</span>&nbsp;
                                <span className="badge-emoticon" role="img" aria-label="clap-hand">&#128079;</span>&nbsp;
                                <span className="badge-emoticon" role="img" aria-label="clap-hand">&#128079;</span>&nbsp;
                                <span className="badge-motivation-label">Bravo! Vous l'avez bien mérité vos 3 badges.</span>
                              </div>
                            </div>
                        }
                        
                        {/* <FeatherIcon
                          icon="award"
                          className="m-b-3 badge rotateIn"
                        />
                        <FeatherIcon
                          icon="award"
                          className="m-b-3 badge rotateIn"
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="card-dashboard">
                    {/* <a className="icon-return-text-header-quiz-card" href="/">
                      <FeatherIcon icon="arrow-left" />
                    </a> */}
                    <p className="text-header-quiz-card p-l-40 m-b-50">
                      Mon profil
                    </p>
                    <form onSubmit={(e) => this.onSubmitUpdateUserInfos(e)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group m-b-40">
                            <label
                              htmlFor="inputMyProfilePrenom"
                              className="label-input-my-profile"
                            >
                              Prénom
                            </label>
                            <div className="pos-relative">
                              <input
                                name="first_name"
                                value={this.state.userInfos.first_name}
                                onChange={(e) => this.handleChangeUpdate(e)}
                                type="text"
                                className="form-control input-my-profile"
                                id="inputMyProfilePrenom"
                                placeholder="Prénom"
                              />
                              <FeatherIcon
                                className="icon-input-my-profile"
                                icon="edit-3"
                              />
                            </div>
                            {this.state.is_empty_first_name && (
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.first_name}
                              </div>
                            )}
                          </div>
                          <div className="form-group m-b-40">
                            <label
                              htmlFor="inputMyProfileNom"
                              className="label-input-my-profile"
                            >
                              Nom
                            </label>
                            <div className="pos-relative">
                              <input
                                name="last_name"
                                type="text"
                                value={userInfos.last_name}
                                onChange={(e) => this.handleChangeUpdate(e)}
                                className="form-control input-my-profile"
                                id="inputMyProfileNom"
                                placeholder="Nom"
                              />
                              <FeatherIcon
                                className="icon-input-my-profile"
                                icon="edit-3"
                              />
                            </div>
                            {this.state.is_empty_last_name && (
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.last_name}
                              </div>
                            )}
                          </div>
                          <div className="form-group m-b-40">
                            <label
                              htmlFor="inputMyProfileCivilite"
                              className="label-input-my-profile"
                            >
                              Civilité
                            </label>
                            <select
                              name="sexe"
                              value={this.state.userInfos.sexe}
                              onChange={(e) => this.handleChangeUpdate(e)}
                              className="form-control input-my-profile"
                              id="inputMyProfileCivilite"
                            >
                              <option value="femme">Femme</option>
                              <option value="homme">Homme</option>
                            </select>
                            {this.state.is_empty_sexe && (
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.sexe}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group m-b-40">
                            <label
                              htmlFor="inputMyProfileEmail"
                              className="label-input-my-profile"
                            >
                              Adresse mail
                            </label>
                            <div className="pos-relative">
                              <input
                                name="email"
                                value={this.state.userInfos.email}
                                onChange={(e) => this.handleChangeUpdate(e)}
                                type="email"
                                className="form-control input-my-profile"
                                id="inputMyProfileEmail"
                                placeholder="Adresse mail"
                              />
                              <FeatherIcon
                                className="icon-input-my-profile"
                                icon="edit-3"
                              />
                            </div>
                            {this.state.is_empty_email && (
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-group m-b-40">
                            <label
                              htmlFor="inputMyProfileTelephone"
                              className="label-input-my-profile"
                            >
                              N° téléphone
                            </label>
                            <div className="pos-relative">
                              <input
                                name="phone"
                                value={this.state.userInfos.phone}
                                onChange={(e) => this.handleChangeUpdate(e)}
                                type="text"
                                className="form-control input-my-profile"
                                id="inputMyProfileTelephone"
                                placeholder="77 444 55 66"
                              />
                              <FeatherIcon
                                className="icon-input-my-profile"
                                icon="edit-3"
                              />
                            </div>
                            {this.state.is_empty_phone && (
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.phone}
                              </div>
                            )}
                          </div>
                          <div className="form-group m-b-40">
                            <label
                              htmlFor="inputMyProfilePassword"
                              className="label-input-my-profile"
                            >
                              Adresse
                            </label>
                            <div className="pos-relative">
                              <input
                                name="adresse"
                                value={this.state.userInfos.adresse}
                                onChange={(e) => this.handleChangeUpdate(e)}
                                type="text"
                                className="form-control input-my-profile"
                                id="inputMyProfilePassword"
                                placeholder="Adresse"
                              />
                              <FeatherIcon
                                className="icon-input-my-profile"
                                icon="edit-3"
                              />
                            </div>
                            {this.state.is_empty_adresse && (
                              <div className="alert alert-danger gfa-danger">
                                {this.state.errors.adresse}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 offset-md-6">
                          {!this.state.updateUserInfosInProgress ? (
                            <button className="btn-theme fw-500 w-full m-b-200">
                              ENREGISTRER LES MODIFICATIONS
                            </button>
                          ) : (
                            <button className="btn-theme fw-500 w-full m-b-200 in-progress">
                              MODIFICATIONS EN COURS &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <MessageAllPage />
        </div>

        {this.state.isSuccessUpdating || this.state.isErrorUpdating ? (
          <ToastContainer />
        ) : (
          ""
        )}
      </div>
    );
  }
}
