import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";

import Swal from "sweetalert2";
import CreateClickAndReveal from "./components/CreateClickAndReveal";
import CreateGlisserDeposer from "./components/CreateGlisserDeposer";
import CreateImages from "./components/CreateImages";

import CreatePodcast from "./components/CreatePodcasts";
import CreateQcm from "./components/CreateQcm";
import CreateQuestionReponse from "./components/CreateQuestionReponse";
import CreateScale from "./components/CreateScale";
import CreateSlide from "./components/CreateSlide";
import CreateTexteATrous from "./components/CreateTexteATrous";
import CreateUpload from "./components/CreateUpload";
import CreateVideo from "./components/CreateVideo";

import CreateVraiFaux from "./components/CreateVraiFaux";
import { hideModal } from "./components/ItemSessionModule";

export default class TypeContentCreating extends Component {
  getModalId() {
    return `TypeContentCreating${this.props.contenu.id}`;
  }

  notify(end = true) {
    try {
      if (end !== false) {
        hideModal(`#${this.getModalId()}`);
      }
    } catch (ex) {}
    Swal.fire({
      icon: "success",
      title: "Données ajoutées avec succès",
      iconColor: "green",
      showConfirmButton: false,
      timer: 1500,
    });
    this.props.onReload();
  }

  render() {
    const { contenu, visible } = this.props;

    const MODALS = [
      { type: "slide", component: CreateSlide },
      { type: "doc", component: CreateSlide },
      { type: "video", component: CreateVideo },
      { type: "click_reveal", component: CreateClickAndReveal },
      { type: "drag_drop", component: CreateGlisserDeposer },
      // TODO CreateAutoEvaluation: call the notify function after done adding
      { type: "scale", component: CreateScale },
      { type: "text_field", component: CreateQuestionReponse },
      { type: "multiple_choice", component: CreateQcm },
      { type: "podcast", component: CreatePodcast },
      { type: "texte_trous", component: CreateTexteATrous },
      { type: "yes_no", component: CreateVraiFaux },
      { type: "image", component: CreateImages },
      { type: "upload", component: CreateUpload },
    ];
    const { component: Component } = MODALS.find(
      (m) => m.type === contenu.content_type
    );
    const modalId = this.getModalId();
    return (
      <>
        <div className="mt-2" style={{ display: visible ? "block" : "none" }}>
          <button
            className="btn-light-add-content m-b-0"
            data-toggle="modal"
            type="button"
            data-target={`#${modalId}`}
          >
            <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
            Ajouter un contenu
          </button>
        </div>

        <div
          className="modal fade"
          id={modalId}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content card-dashboard">
              <div className="col-2">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
              </div>
              <Component contentId={contenu.id} notify={() => this.notify()} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
