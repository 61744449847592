import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import api from './../../http/global-vars'
import axios from 'axios'
import { Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Search from './Search'
import baseUrl from '../../http/backend-base-url';


export default class HeaderDashboard extends Component {
    constructor(props){
        super(props)
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            userID: window.localStorage.getItem('userID'),
            userInfos: [],
            isExpiredToken: false,
            baseUrl: baseUrl,

            countBadge: 0,
            moduleName: []
        }
    }

    componentDidMount(){
        var config = this.state.requestConfig
        this.onGetConnectedUserInfos(config)
        // this.onGetUserDoModules()
    }



    onGetConnectedUserInfos = (config) => {
        var api = this.state.api
        axios.get(api + 'me/', config)
            .then(response => {
                //console.log(response)
                this.setState({
                    isLoginInProgress: false
                })
                if(response.status === 200){
                    this.setState({
                        userInfos: response.data.data
                    })
                    //console.log(response.data.data)
                }
            })
            .catch(error => {
                console.log(error.message)
                if(error.message === 'Request failed with status code 401'){
                    this.setState({
                        isExpiredToken: true
                    })
                }
            })
    }

    onGetUserDoModules = () => {
    
        var userID = this.state.userID;
        var api = this.state.api;
        var config = this.state.requestConfig;
    
        axios
          .get(`${api}usermodules/${userID}/`, config)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
                var infos = response.data.data
                this.setState({
                    moduleInfos: infos
                })
                // console.log(infos)
                var countBadge = 0
                var moduleName = []
                infos.forEach((item) => {
                    if(item.progression === 100){
                        countBadge += 1
                        moduleName.push(item.module.name)
                    }
                })
                this.setState({
                    countBadge: countBadge,
                    moduleName: moduleName
                })
                // console.log('count badge ',countBadge)
                // console.log('modules', moduleName)
            } else {
              console.log(response)
            }
          })
          .catch((error) => {
            console.log(error);
          });
    };

    onLogoutUser = () => {
        window.localStorage.removeItem('userToken')
        localStorage.removeItem('resp_gfa')
        setTimeout(() => {
            window.location = '/connexion'
        }, 1000);
    }
  
    render(){
        if(this.state.isExpiredToken === true){
            return <Redirect to="/token-expire" />
        }else{
            var {userInfos} = this.state
            var countBadge = userInfos.badge
            return (
                <div className="bg-white p-t-20 p-b-15 row b-b-1 fixed-top-web flex-end">               
                    <div className="col-xl-4 col-lg-4 col-md-9 col-sm-8 col-9 dis-flex justify-content-end no-view-mobile">
                        <img className="logo-dashboard" src={`/img/defaru300_100px.png`} alt="" />
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-9 col-sm-8 col-9 dis-flex flex-col">
                        <div className="logo-gfa-container">
                            <img src="/img/logo-frame.png" className="logo-gfa-top" alt="Logos Sponsors" />
                        </div>
                        <Search />
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-3 header-dropdown-item-container p-lg-0 user-info-place-for-mobile">
                        <div className="dropdown dis-flex">
                            <button className="user-acount-dashboard-header" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {this.state.userInfos.avatar === null ?
                                    <img className="image-user-acount-dashboard-header" src="#" alt="Avatar" /> :
                                    <img className="image-user-acount-dashboard-header" src={ baseUrl+ this.state.userInfos.avatar} alt="Avatar" />
                                }
                                
                            <div className="user-acount-dashboard-header-display">
                                {this.state.userInfos.first_name + ' ' + this.state.userInfos.last_name}
                                <FeatherIcon className="icon-user-acount-dashboard" icon="chevron-down" />
                                {/* <div className="header-badge-container">
                                    {countBadge === 1 ?
                                        <span className="header-badge-item badge-blue">
                                            <FeatherIcon className="icon-user-badge-header" icon="award" />
                                        </span> :
                                        countBadge === 2 ?
                                        <span>
                                            <span className="header-badge-item badge-blue">
                                                <FeatherIcon className="icon-user-badge-header" icon="award" />
                                            </span>
                                            <span className="header-badge-item badge-orange">
                                                <FeatherIcon className="icon-user-badge-header" icon="award" />
                                            </span>
                                        </span> :
                                        countBadge === 3 ?
                                        <span>
                                            <span className="header-badge-item badge-blue">
                                                <FeatherIcon className="icon-user-badge-header" icon="award" />
                                            </span>
                                            <span className="header-badge-item badge-orange">
                                                <FeatherIcon className="icon-user-badge-header" icon="award" />
                                            </span> 
                                            <span className="header-badge-item badge-green">
                                                <FeatherIcon className="icon-user-badge-header" icon="award" />
                                            </span>
                                        </span> : ''
                                    }
                                </div> */}
                            </div>
                            </button>
                            
                            <div className="dropdown-menu dropdown-menu-custom" aria-labelledby="dropdownMenuButton">
                                <p className="user-fullname-mobile">{this.state.userInfos.first_name + ' ' + this.state.userInfos.last_name}</p>
                                <NavLink className="dropdown-item item-dropdown-custom my-profile-link" to="/mon-profile">
                                    <span className="">Mon profil</span >
                                </NavLink>
                                <span className="dropdown-item item-dropdown-custom logout-link linkable" onClick={ (e) => this.onLogoutUser(e) }>Deconnexion</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
    }
}