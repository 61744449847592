import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import $ from "jquery";
import axios from "axios";
//import baseUrl from "../../http/backend-base-url";
// import { Redirect } from "react-router-dom";

export default class TacheUpload extends Component {
  constructor(props) {
    super(props);
    const task = this.props.upload[0] || { title: "", list_question: [] };
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          "Content-Type": "multipart/form-data",
        },
      },
      config: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      texts: this.props.upload,
      selectedtxtIndex: 0,
      selectedIndex: 0,
      responses: [
        {
          question: "",
          reponses: [],
        },
      ],
      fields: {},
      images: [],
      isLoading: false,
      isFile: false,
      file: '',
      isSend: false,
      module_id: "",
      data: task.list_question.map((q) => {
        return {
          id: q.id,
          label: q?.content,
          responses: [
            ...q.list_responses.map((r) => {
              return {
                id: r.id,
                image: r?.image,
              };
            }),
          ],
        };
      }),
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this._getModuleBySession();
    }, 3000);
  }

  onScroll = () => {
    this.props.onGetUserDoModules()
    window.scrollTo(0, 0);
  };

  updateProgres = async () => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id
    );
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`;

    let progression;
    if (this.props.progression) {
      progression = this.props.progression;
    }
    let send = {
      progression: progression,
    };
    console.log("send", send);
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  _getModuleBySession = async () => {
    const { api } = this.state;
    if (this.props.sessionId) {
      const url = api + `session/${this.props.sessionId}/`;
      try {
        const res = await axios.get(url, this.state.config);
        if (res.status === 201 || 200) {
          let data = res["data"];
          this.setState({ module_id: data.module_id });
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  };

  thisFileUpload = (e, index) => {
    console.log(index);
    e.preventDefault();
    document.getElementById(`file${index}`).click();
  };

  onChange = (q, r, name, value) => {
    let newData = [...this.state.data];
    const indexQ = newData.findIndex((q2) => q2.id === q.id);
    const newQuestion = { ...newData[indexQ] };
    const index = newQuestion.responses.findIndex((r2) => r2.id === r.id);
    if (name === "image") {
      newQuestion.responses[index][name] = value.target.files[0];
      this.setState({
        file: URL.createObjectURL(value.target.files[0])
      })
    } else {
      newQuestion.responses[index][name] = value.target.value;
    }

    newData[indexQ] = newQuestion;
    // console.log(newData);
    this.setState({ data: newData });
  };

  done = async () => {
    var api = this.state.api;
    const url = api + "userdoresponseupload/";
    // setLoading(true);
    if (
      this.props?.usercontenus.every(
        (v) =>
          parseInt(v?.contenu?.id) !==
          this.state.texts[this.state.selectedtxtIndex]?.contenu
      ) !== false
    ) {
      for (let d of this.state.data) {
        for (let r of d.responses) {
        //   const sendData = {
        //     user: parseInt(this.state.userID),
        //     contenu: this.props.upload[0]?.contenu,
        //     file: r.image,
        //   };
        //   console.log('data', sendData)
          var formData = new FormData()
          formData.append('user', parseInt(this.state.userID))
          formData.append("contenu", this.props.upload[0]?.contenu);
          formData.append('file', r.image)
          axios
            .post(url, formData, this.state.config)
            .then((response) => {
              console.log(response);
              if (response.data) {
                this.setState({
                  fields: {},
                  responses: [],
                  isLoading: false,
                  isSend: true,
                });
                //this.updateProgres();
                //$(".close").click();
              } else {
                toast.error("Vos données ne sont pas valides !");
              }
            })
            .catch((error) => {
              console.log(error.response);
              console.log(error.config);
              toast.error(
                "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
              );

              setTimeout(() => {
                this.setState({
                  isLoading: false,
                });
              }, 2000);
            });
        }
      }
      this.UserDoContenu();
    }
  };

  UserDoContenu = async () => {
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.state.texts[this.state.selectedtxtIndex]?.contenu,
    };
    //console.log('data', sendData)
    const url = api + "userdocontenu/";
    await axios
      .post(url, sendData, this.state.config)
      .then((res) => {
        //console.log("data post", res);
        this.updateProgres();
      })
      .catch((e) => {
        console.log("e post", e.response.data);
      });
      this.props.onGetUserDoModules();
    window.scrollTo(0, 0);
  };

  _redirect = () => {
   /*  console.log(
      "last",
      this.props.last[0]?.id,
      this.props.upload[0].id
    ); */
    if (this.props.last[0]?.id === this.props.upload[0].id) {
      // console.log("END");
      var elementPos = this.props.sessions
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.props.sessionId);
      var objectFound = this.props.sessions[elementPos + 1];
      //this.setState({ endSession: true, selectedId: objectFound?.id });
      const Data = {
        user: parseInt(this.state.userID),
        session: this.props.sessionId,
      };
      //console.log("qst", this.state.selectedId);
      axios
        .post(api + "userdosession/", Data, this.state.requestConfig)
        .then((data) => {
          console.log("data", data.data);
          // window.location = `/session-start/session/${this.state.selectedId}`;
        })
        .catch((e) => {
          console.log("e", e.response);
        });

      window.location = `/session-start/session/${objectFound?.id}`;
    }
    //return (window.location = `/session-start/session/${this.state.selectedId}`);
  };

  render() {
    const { texts, selectedtxtIndex, data } = this.state;
    // console.log(
    //   "new",
    //   this.props?.usercontenus.every(
    //     (v) => parseInt(v?.contenu?.id) !== texts[selectedtxtIndex]?.contenu
    //   )
    // );
    //console.log('upload', texts)
    let isValid = true;
    for (let d of data) {
      for (let r of d.responses) {
        if (!r.image) {
          isValid = false;
          break;
        }
      }
      if (!isValid) break;
    }

    if (data.length) {
      // console.log(data)
      return (
        <>
          <h6 className="content-title">{texts[selectedtxtIndex].title}</h6>
          {data.map((item, index) => {
            return (
              <div key={item?.id}>
                <h5 className="text-dark m-b-20">
                <div
                    dangerouslySetInnerHTML={{ __html: item?.label }}
                  />
                </h5>
                {item.responses.map((rsp, i) => {
                  return (
                    <div className="form-group" key={rsp.id}>
                      <input
                        className="form-control form-reponse-tache"
                        placeholder={rsp.image
                            ? rsp.image.name
                            : "Votre réponse"
                        }
                        name={rsp.id}
                        id={item.id}
                        disabled
                      />
                      {rsp?.image && (rsp?.image?.type === 'image/jpg' || rsp?.image?.type === 'image/jpeg' || rsp?.image?.type === 'image/png') ? 
                      <img src={this.state.file} alt="" width="200" height="200" />
                      : null
                      }
                      
                      <div className="row dis-flex justify-content-end containt-mobile-left">
                        <div className="col-lg-3 col-md-3 dis-flex itm-center pos-relative">
                          <FeatherIcon
                            className="icon-import-file-reponse-tache"
                            icon="link-2"
                            onClick={(e) => this.thisFileUpload(e, index)}
                          />
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              name={item.id}
                              id={`file${index}`}
                              onChange={(url) => this.onChange(item, rsp, "image", url)}
                            />
                            <label
                              className="custom-file-label import-file-reponse-tache"
                              htmlFor={`file${index}`}
                            >
                              Joindre un fichier
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="row dis-flex justify-content-end m-b-30 m-t-65 slider-actions-btn">
            <div className="col-md-3 col-sm-6 m-b-10">
              {this.props?.usercontenus.every(
                (v) =>
                  parseInt(v?.contenu?.id) !== texts[selectedtxtIndex]?.contenu
              ) !== false ? (
                isValid ? (
                  <button
                    className="btn-theme dis-flex justify-content-center fw-600 text-up"
                    onClick={this.done}
                    data-toggle="modal"
                    data-target={`#textSuccess${texts[selectedtxtIndex]?.id}`}
                  >
                    envoyer
                  </button>
                ) : (
                  <button
                    className="btn-theme disabled dis-flex justify-content-center fw-600 text-up"
                    disabled
                  >
                    envoyer
                  </button>
                )
              ) : (
                <a
                  className="btn-theme dis-flex justify-content-center fw-600 text-up next"
                  href="#carouselSliderContentDetailsSession"
                  role="button"
                  data-slide="next"
                  onClick={this.onScroll}
                >
                  Continuer
                </a>
              )}
            </div>
          </div>
          <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
            <div className="col-md-3 col-sm-6 m-b-10">
              <a
                className="btn-theme-light dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                role="button"
                data-slide="prev"
              >
                Précédent
              </a>
            </div>
            <div className="col-md-3 col-sm-6 m-b-10">
              {this.props?.usercontenus.every(
                (v) =>
                  parseInt(v?.contenu?.id) !== texts[selectedtxtIndex]?.contenu
              ) === false ? (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  onClick={this.onScroll}
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme disabled dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  disabled
                >
                  Suivant
                </button>
              )}
            </div>
          </div>
          <div
            className="modal fade"
            id={`textSuccess${texts[selectedtxtIndex]?.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-notify modal-success"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                    <FeatherIcon
                      color="green"
                      icon="check-circle"
                      className="mb-3 animat rotateIn"
                    />
                    <h4 className="pop-title fw-600"> Tes réponses ont été soumises ! Bravo pour avoir terminé cet exercice ! </h4>
                    {/* <p className="parag fs-20 fw-600">
                      Réponses envoyées avec succés{" "}
                    
                    </p> */}
                  </div>
                </div>
                <div className="modal-footer justify-content-center slider-actions-btn">
                  {/* <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-theme fw-400 fs-15 justify-content-start"
                    onClick={this._ToggleNext}
                  >
                    VOIR LE RESULTAT{" "}
                  </button> */}
                  <a
                    type="button"
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    data-dismiss="modal"
                    href="#carouselSliderContentDetailsSession"
                    role="button"
                    data-slide="next"
                    onClick={this.onScroll}
                  >
                    CONTINUER
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p>Pas de question</p>
          <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
            <div className="col-md-3 col-sm-6 m-b-10">
              <a
                className="btn-theme-light dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                role="button"
                data-slide="prev"
              >
                Précédent
              </a>
            </div>
            <div className="col-md-3 col-sm-6 m-b-10">
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
              >
                Suivant
              </button>
            </div>
          </div>
        </>
      );
    }
  }
}
