import React from "react";
import { NavLink } from "react-router-dom";
import MessageAllPage from "../backend/components/MessageAllPage";
import ContactSupport from "./components/ContactSupport";
import HeaderAccueil from "./components/HeaderAccueil";

function WelcomePage() {
  return (
    <div>
      <HeaderAccueil />
      <div className="bg-white p-t-50 p-b-40">
        <div className="container">
          <div className="row m-b-20">
            <div className="col-md-12 welcome-page-introduction">
              <div className="text-dark fw-700 f-f-poppins lh-1-5 welcome-textual-bloc">
                <h2 className="content-text-welcome text-color fw-700 text-center">
                  Bonjour et bienvenu(e) sur DEFARU
                </h2>
                <p className="content-text-welcome-v4" style={{ fontSize: 15}}>
                  Ta plateforme sénégalaise pour les formations en ligne sur les
                  Soft Skills, l’Entrepreneuriat et le Numérique !
                </p>
              </div>
            </div>
            <div class="col-md-12 mb-5">
              <div className="home-illustration-container row">
                <div className="col-md-12 dis-flex justify-content-center itm-center home-illustration-container-col">
                  <img
                    className="w-full home-illustration welcome-page-img-v4"
                    src="img/unnamed.webp"
                    alt=""
                    style={{ height: 400, objectFit: "contain" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="learning-programme-content-container mb-5">
            <div className="learning-programme-content-row">
              <p className="content-text-welcome-v4" style={{ fontSize: 15}}>
                Nous sommes ravis de te voir parmi nous ! Voici des informations
                sur
                <span className="h-theme fw-700 pl-2">le contenu</span> des
                modules à découvrir sur la plateforme :
              </p>
              <div className="container-presentation-modules-v4">
                <h4 className="content-text-welcome text-color fw-700">
                  Soft Skills
                </h4>
                <ul>
                  <li
                    className="content-text-welcome-v4"
                    style={{ textAlign: "justify" }}
                  >
                    Dans le module{" "}
                    <span className="h-theme fw-700">Communication</span>, tu
                    découvriras les différents types de communication et tu
                    apprendras pourquoi des bonnes capacités en matière de
                    communication sont importantes pour ton avenir
                    professionnel.
                  </li>
                  <li
                    className="content-text-welcome-v4"
                    style={{ textAlign: "justify" }}
                  >
                    Dans le module{" "}
                    <span className="h-theme fw-700">L’éthique de travail</span>
                    , tu apprendras la signification du terme « éthique de
                    travail », son application dans la vie professionnelle et
                    les règles qui en découlent.
                  </li>
                  <li
                    className="content-text-welcome-v4"
                    style={{ textAlign: "justify" }}
                  >
                    Dans le module{" "}
                    <span className="h-theme fw-700 pl-2">
                      La gestion de carrière
                    </span>
                    , tu seras amené(e) à te focaliser sur les aspects pratiques
                    liés à la gestion de ta carrière. D’abord tu identifieras
                    tes besoins, tes intérêts, et tes points forts pour ensuite
                    approfondir les aspects relatifs à tes démarches de
                    recherche d’emploi.
                  </li>
                </ul>
              </div>
              <div className="container-presentation-modules-v4">
                <h4 className="content-text-welcome text-color fw-700">
                  Entrepreneuriat
                </h4>
                <ul>
                  <li
                    className="content-text-welcome-v4"
                    style={{ textAlign: "justify" }}
                  >
                    Dans le module{" "}
                    <span className="h-theme fw-700">
                      Mon modèle d’affaires
                    </span>
                    , tu découvriras le concept de la vision d’entreprise et les
                    différents types d’entreprises et tu apprendras comment
                    l’approche simplifiée de démarrage peut t’aider à lancer tes
                    activités entrepreneuriales.
                  </li>
                  <li
                    className="content-text-welcome-v4"
                    style={{ textAlign: "justify" }}
                  >
                    Le module{" "}
                    <span className="h-theme fw-700">Gestion des finances</span>
                    , aborde la question de la viabilité des entreprises et de
                    la gestion financière de ton entreprise et donne un aperçu
                    simplifié des indicateurs économiques les plus pertinents.
                  </li>
                </ul>
              </div>
              <div className="container-presentation-modules-v4">
                <h4 className="content-text-welcome text-color fw-700">
                  Numérique
                </h4>
                <ul>
                  <li
                    className="content-text-welcome-v4"
                    style={{ textAlign: "justify" }}
                  >
                    Dans le module{" "}
                    <span className="h-theme fw-700">
                      Les bases du numérique
                    </span>
                    , tu découvriras les différentes méthodes liées à la bonne
                    utilisation de l’ordinateur, les notions de bases concernant
                    l’utilité de l’Internet ainsi que de l’Intelligence
                    Artificielle.
                  </li>
                  <li
                    className="content-text-welcome-v4"
                    style={{ textAlign: "justify" }}
                  >
                    Dans le module{" "}
                    <span className="h-theme fw-700">
                      La communication numérique
                    </span>
                    , tu développeras ton professionnalisme lors de la rédaction
                    d’emails et tu plongeras dans le monde des réseaux sociaux
                    et aussi la protection des données personnelles.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="autres-infos-section-container">
            <div className="demarches-section-container mb-5">
              <h3
                className="content-text-welcome-v4"
                style={{ textAlign: "justify", fontWeight: "bold" }}
              >
                Démarche
              </h3>
              <p
                className="content-text-welcome-v4"
                style={{ textAlign: "justify" }}
              >
                A l’issue de <span className="pl-2 fw-700">chaque module</span>{" "}
                de formation complété, tu recevras un certificat. Mais pour
                obtenir le <span className="pl-2 fw-700">certificat</span>, il
                faudra d’abord parcourir l’intégralité du module et réaliser
                tous les exercices qui te sont proposés. Il n’y a pas de limite
                de temps, cependant nous t’encourageons à terminer chaque module
                dans un <span className="pl-2 fw-700">délai de 3 semaines</span>{" "}
                pour un apprentissage optimal.
                <br />
                Les modules de formations peuvent bien entendu être réalisés
                individuellement. Le certificat de participation sera disponible
                au format PDF téléchargeable directement sur la plateforme
                DEFARU et via un lien qui te sera envoyé par email.
              </p>
            </div>
            <div className="commencer-section-container">
              <h3
                className="content-text-welcome-v4"
                style={{ textAlign: "justify", fontWeight: "bold" }}
              >
                Commençons
              </h3>
              <p
                className="content-text-welcome-v4"
                style={{ textAlign: "justify" }}
              >
                Nous te souhaitons une bonne formation et nous espérons que tu
                profiteras pleinement de cette expérience en ligne.
                <br />
                Si tu as des difficultés de connexion ou des difficultés à
                visionner le contenu, surtout n’hésite pas à contacter le
                support en envoyant un mail à l'adresse suivante :
                <a
                  href="email:defaru.support@volkeno.sn"
                  className="text-defaru-link-mail pl-2"
                >
                  defaru.support@volkeno.sn
                </a>
              </p>
            </div>
          </div>
          <div className="row dis-flex justify-content-center m-t-60">
            <div className="col-md-4 start-btn-container">
              <NavLink
                className="btn-theme fw-600 f-f-poppins dis-flex justify-content-center"
                to="/fiche-identification"
              >
                Commencer
              </NavLink>
            </div>
          </div>
          <div className="new-modules-law-required">
            <quote>
              <span className="law-asterix">*</span> Les modules Mon modèle
              d’affaires et Gestion des finances ont été développés dans le
              cadre de Synergie Genre et Entrepreneuriat (SyGEn), une formation
              en ligne en auto-apprentissage destinée aux entrepreneurs, qui
              leur permet de mieux comprendre le monde des affaires et d'y
              intégrer la dimension de genre afin de prospérer avec un nouvel
              état d'esprit. Le cours en 5 modules a été développé par le projet
              L'enseignement et la formation techniques et professionnelles
              agricoles pour les femmes (ATVET4W), mis en œuvre par l'agence de
              développement de l'Union africaine (AUDA-NEPAD) avec le soutien
              technique et financier de Deutsche Gesellschaft für Internationale
              Zusammenarbeit (GIZ) GmbH et de GFA Consulting.
            </quote>
          </div>
        </div>
      </div>

      <div className="">
        <MessageAllPage fullWidth={true} />
      </div>
    </div>
  );
}

export default WelcomePage;
