import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import { NavLink } from "react-router-dom";
import api from "../../http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import AllCourseSkeleton from "./AllCourseSkeleton";
import baseUrl from "../../http/backend-base-url";

export default class CardSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      data: [],
      showEthik: false,
      showGestion: false,
      loading: false,
      baseUrl: baseUrl,
      selectedPersonnage: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getUserPersonnage();
    const timer = setTimeout(() => {
      this._getModules();
    }, 2000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

  _getModules = async () => {
    let { api } = this.state;
    let url = api + `module/`;
    try {
      let res = await axios.get(url, this.state.requestConfig);
      // console.log(res);
      let data = res.data["results"];
      // console.log(data);
      this.setState({ data: data, loading: false });
    } catch (error) {
      console.log(error.message);
      console.log(error.response);
      toast.error(
        "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
        { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
      );
    }
  };

  getUserPersonnage = async () => {
    let { api, userID } = this.state;
    let url = api + `user/${parseInt(userID)}/personnage/`;
    try {
      let res = await axios.get(url, this.state.requestConfig);
      console.log("ress", res);
      if (res?.data?.id) {
        this.setState({ selectedPersonnage: true });
      }
    } catch (error) {
      this.setState({ selectedPersonnage: false });
    }
  };

  render() {
    const { data, loading, baseUrl, selectedPersonnage } = this.state;
    if (loading) {
      return <AllCourseSkeleton />;
    } else {
      return data.map((item) => {
        return (
          !item?.thematique && (
            <div className="col-md-4 px-md-2 d-xl-flex" key={item.id}>
              <div className="card card-all-modules">
                {(item?.is_thematique) && (
                  <div className="badge-new-container">
                    Nouveau
                  </div>
                )}
                <img
                  className="img-course-on-card-cours"
                  src={baseUrl + item.logo}
                  alt={item.name}
                />
                <div className="content-course-on-card-cours" title={item?.name}>
                  <NavLink
                    className="titre-course-on-card-cours"
                    to={{
                      pathname: item?.is_thematique
                        ? !selectedPersonnage
                          ? `/presentation-module/${item?.id}`
                          : `/sous-modules/${item?.id}`
                        : `/cours-details/${item.id}`,
                      state: item,
                    }}
                  >
                    {item.name}
                  </NavLink>
                  <div className="row m-t-15">
                    {/* <div className="col-lg-12 col-md-12 col-sm-3 col-xs-6 col-12 flex-row info-content-course-on-card-cours itm-center">
                      <FeatherIcon
                        className="icon-content-course-on-card-cours"
                        icon="youtube"
                      />
                      {item?.is_thematique
                        ? `${item.modules_count || 0} modules`
                        : `${item.number_session} sessions`}
                    </div> */}
                    <div className="col">
                      <NavLink
                        to={{
                          pathname: item?.is_thematique
                            ? !selectedPersonnage
                              ? `/presentation-module/${item?.id}`
                              : `/sous-modules/${item?.id}`
                            : `/cours-details/${item.id}`,
                          state: item,
                        }}
                      >
                        <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        );
      });
    }
  }
}
