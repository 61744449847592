import React, {Component} from 'react';
import HeaderAccueilV2 from './components/HeaderAccueilV2';
import MessageAllPage from '../backend/components/MessageAllPage';
import { NavLink } from 'react-router-dom';
import isAuthenticate from './../modules/Secure/Secure'
import HeaderDashboard from '../frontend/components/HeaderDashboard';
import HeaderDashboardAdmin from '../backend/components/HeaderDashboard'

export default class LegalNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: window.localStorage.getItem("userType")
        }
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render(){
        var isLogInExpired = isAuthenticate()
        return (
            <div>
                {isLogInExpired === false ?
                    this.state.userType === 'admin' ?
                        <HeaderDashboardAdmin /> :
                        <HeaderDashboard /> :
                    <HeaderAccueilV2 />
                }
                <div className="bg-white p-t-150 p-b-100">
                    <div className="container">
                        <h2 className="name-landing-page-gfa m-b-50">Mentions légales </h2>
        
                        <p className="p-landing-page-gfa">
                        La plateforme Defaru est une plateforme d’apprentissage en ligne. <br/>
                        Elle est édité par GFA Consulting Group GmbH Thiès <br/>
                        Adresse : <br/>
                        Grand Standing en face station SGF <br/>
                        Route de Dakar <br/>
                        Thiès Azur, Sénégal <br/><br/>

                        Le Site est hébergé par :  <br/>
                        VOLKENO SARL  <br/>
                        (+221) 33 827 67 91 <br/>
                        <a className="link-theme-landing-page-gfa" href="mailto:contact@volkeno-tank.com">contact@volkeno-tank.com</a> <br/>
                        Hlm Grand Yoff Villa n°263 Près du terminus des bus Dakar Dem Dikk qui vont à l'AIBD <br/>
                        Dakar 11500 <br/><br/>

                        Ces mentions légales s’appliquent également à toutes les apparitions spécifiques de Defaru dans les médias sociaux, 
                        tels que les profils sur Facebook des partenaires. <br/><br/>


                        <span className="fw-600">Responsable au regard du droit de la presse</span><br/>
                        Günter Diewald, Chef d ’Equipe GFA <br/>

                        <span className="fw-600">Responsable du contenu</span><br/>
                        Clarisse Goffard, Consultante GFA <a className="link-theme-landing-page-gfa" href="mailto:clarisse.goffard@gfa-group.de">clarisse.goffard@gfa-group.de</a><br/><br/>

                        <span className="fw-600">Accessibilité du site</span><br/>
                        Defaru est accessible via l’adresse <a className="link-theme-landing-page-gfa" href="https://defaru.sn/">www.defaru.sn </a> 
                        ou en contactant le support <a className="link-theme-landing-page-gfa" href="mailto:defaru.support@volkeno.sn">defaru.support@volkeno.sn</a> <br/><br/>

                        <span className="fw-600">Liens hypertextes</span><br/>
                        Notre site contient des liens vers des sites web externes de tiers, sur le contenu desquels nous n’avons aucune influence. 
                        Par conséquent, nous ne pouvons assumer aucune responsabilité quant à ces contenus externes. Le fournisseur ou l’exploitant 
                        des pages externes est toujours responsable du contenu des pages liées. Les pages liées ont été vérifiées au moment de la mise
                         en place du lien afin de détecter d’éventuelles infractions à la loi. Aucun contenu illégal n’a été détecté au moment de 
                         la mise en place du lien. Un contrôle permanent du contenu des pages liées n’est pas réaliste sans preuve concrète d’une 
                         infraction. Dès que nous aurons connaissance d’une infraction à la loi, nous supprimerons immédiatement ces liens.<br/><br/>

                        <span className="fw-600">Droit d’auteur</span><br/>
                        Les contenus et les œuvres de ces pages créés par les opérateurs du site sont soumis à la loi allemande sur le droit d’auteur (UrhG). 
                        La reproduction, le traitement, la distribution et toute forme d’utilisation en dehors des limites de la loi sur le droit d’auteur 
                        nécessitent l’accord écrit de l’auteur ou du créateur concerné. Dans la mesure où le contenu de ce site n’a pas été créé par 
                        l’opérateur, les droits d’auteur de tiers sont respectés. Si vous avez néanmoins connaissance d’une violation des droits d’auteur, 
                        veuillez nous en informer. Dès que nous aurons connaissance d’une infraction, nous retirerons immédiatement ces contenus.<br/><br/>

                        <span className="fw-600">Crédits photos</span><br/>
                        Toutes les images utilisées sur la plateforme proviennent des bases de données librement accessibles unsplash.com et pexels.com. 
                        Toutes les autres images sont acquises directement sur le site shutterstock.com. 
                        Nous déclinons toute responsabilité pour les conséquences découlant de la violation des données personnelles liées aux images 
                        de notre contenu. En cas de problème, veuillez contacter directement les sites web respectifs.<br/><br/>

                        <span className="fw-600">Médiation des litiges de la consommation</span><br/>
                        La Commission Européenne met à la disposition des consommateurs, qui peuvent l’utiliser pour le règlement de leurs litiges, 
                        une plateforme en ligne de règlement des litiges disponible à cette adresse : 
                        <a className="link-theme-landing-page-gfa" href="http://ec.europa.eu/consumers/odr/"> http://ec.europa.eu/consumers/odr/</a>. 
                        En cas de litige, et sous réserve du respect des conditions légales, nous participerons à une procédure de médiation de la 
                        consommation devant l’entité compétente : Universal Schlichtungsstelle des Bundes am Zentrum für Schlichtung e.V.<br/><br/>

                        <span className="fw-600">Loi applicable et Attribution de Compétence</span><br/>
                        Sont soumis au droit allemand et à la compétence des tribunaux allemands la non satisfaction d’un litige découlant de la procédure de médiation.<br/><br/>

                        <span className="fw-600">Prestataire</span><br/>
                        VOLKENO SARL  <br/>
                        (+221) 33 827 67 91 <br/>
                        <a className="link-theme-landing-page-gfa" href="mailto:contact@volkeno-tank.com">contact@volkeno-tank.com</a> <br/>
                        Hlm Grand Yoff Villa n°263 Près du terminus des bus Dakar Dem Dikk qui vont à l'AIBD <br/>
                        Dakar 11500 

                        </p>
                    </div>
                </div>
                <MessageAllPage fullWidth={true} />
            </div>
          );
    }
}

