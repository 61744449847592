import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import api from './../../http/global-vars';
import axios from 'axios';
import baseUrl from '../../http/backend-base-url'
import { NavLink } from 'react-router-dom'
export default class HeaderDashboardAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            userID: window.localStorage.getItem('userID'),
            userInfos: [],

        }
    }

    componentDidMount() {
        var config = this.state.requestConfig
        this.onGetConnectedUserInfos(config)
    }



    onGetConnectedUserInfos = (config) => {
        var api = this.state.api
        axios.get(api + 'me/', config)
            .then(response => {
                //console.log(response)
                this.setState({
                    isLoginInProgress: false
                })
                if (response.status === 200) {
                    this.setState({
                        userInfos: response.data.data
                    })
                    //console.log(response.data.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    onLogoutUser = () => {
        window.localStorage.removeItem('userToken')
        setTimeout(() => {
            window.location = '/connexion'
        }, 1000);
    }

    render() {
        return (
            <div className="bg-white p-t-20 p-b-20 row b-b-1 fixed-top-web itm-center">
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-8 col-9 dis-flex justify-content-end no-view-mobile">
                    <img className="logo-dashboard" src={`/img/defaru300_100px.png`} alt="" />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-3 ml-auto no-view-mobile">
                    <div className="dropdown dis-flex">
                        <button className="user-acount-dashboard-header" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {this.state.userInfos.avatar === null ?
                                <img className="image-user-acount-dashboard-header" src={`${baseUrl}/uploads/avatars/default.png`} alt="Avatar" /> :
                                <img className="image-user-acount-dashboard-header" src={baseUrl + this.state.userInfos.avatar} alt="Avatar" />
                            }

                            <div className="user-acount-dashboard-header-display">
                                {this.state.userInfos.first_name + ' ' + this.state.userInfos.last_name}
                                <FeatherIcon className="icon-user-acount-dashboard" icon="chevron-down" />
                            </div>
                        </button>
                        <div className="dropdown-menu dropdown-menu-custom" aria-labelledby="dropdownMenuButton">
                            <NavLink className="dropdown-item item-dropdown-custom not-active" to={`/parametres`}>Paramètres</NavLink>
                            <span className="dropdown-item item-dropdown-custom" style={{ cursor: 'pointer' }} onClick={(e) => this.onLogoutUser(e)}> Deconnexion</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}