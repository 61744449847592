import React, {Component} from 'react';
import HeaderAccueilV2 from './components/HeaderAccueilV2';
import MessageAllPage from '../backend/components/MessageAllPage';
import isAuthenticate from './../modules/Secure/Secure'
import HeaderDashboard from '../frontend/components/HeaderDashboard';
import HeaderDashboardAdmin from '../backend/components/HeaderDashboard'

export default class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: window.localStorage.getItem("userType")
        }
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render(){
        var isLogInExpired = isAuthenticate()
        return (
            <div>
                {isLogInExpired === false ?
                    this.state.userType === 'admin' ?
                        <HeaderDashboardAdmin /> :
                        <HeaderDashboard /> :
                    <HeaderAccueilV2 />
                }
                <div className="bg-white p-t-150 p-b-100">
                    <div className="container">
                        <h2 className="name-landing-page-gfa m-b-50">Politique de confidentialité </h2>
        
                        <p className="p-landing-page-gfa">
                            GFA Consulting Group GmbH Thiès est responsable du traitement de vos données personnelles 
                            collectées par l'utilisation de la plateforme www.defaru.sn <br/><br/>
                            La plateforme Defaru collecte, stocke et utilise vos données pour offrir ses services. 
                            Toutes les données qui peuvent être directement ou indirectement liées à votre personne sont traitées comme des données personnelles. 
                            La présente politique de confidentialité des données décrit la manière dont Defaru utilise vos données personnelles.<br/><br/>
        
                            Le traitement des données relève de la responsabilité de la GFA Consulting Group GmbH Thiès<br/><br/>
        
                            Adresse :<br/>
                            Grand Standing en face station SGF<br/>
                            Route de Dakar<br/>
                            Thiès Azur, Sénégal<br/><br/>
        
                            Téléphone: + 221 78 145 76 75<br/>
                            E-mail: gfa-senegal@gfa-group.de <br/><br/>
        
                            <span className="h-theme-light fw-600 fs-22">Introduction et définition</span><br/>
                            Les données à caractère personnel sont toutes les informations qui se rapportent à une personne vivante identifiée ou identifiable. 
                            Différents éléments d'information, qui, rassemblés ensemble, peuvent conduire à l'identification d'une personne particulière, 
                            constituent également des données à caractère personnel.<br/><br/>
        
                            Les données à caractère personnel sont une préoccupation majeure et exigent une protection particulière. 
                            C'est pourquoi nous divulguons le type de données que nous recueillons et la manière dont nous les utilisons.<br/><br/>
        
                            <span className="h-theme-light fw-600">Finalité du traitement des données et base législative</span><br/>
                            <span className="fw-600">Inscription et Données d'utilisation</span><br/>
                            Nous commençons à collecter et à traiter vos données personnelles dès que vous vous inscrivez sur la plate-forme Defaru. 
                            Nous avons besoin d'un ensemble de données personnelles pour offrir les services Defaru. Cet ensemble comprend votre nom, 
                            votre sexe, votre date de naissance, votre adresse électronique, votre numéro de téléphone, votre pays et région de résidence. 
                            Afin d'optimiser notre offre de formation, nous vous demandons également votre niveau d'études le plus élevé, 
                            votre statut professionnel ainsi que des informations d'ordre économique et financier (revenus, situation financière, situation fiscale...), 
                            des données de connexion (adresses IP, journaux d'événements...), et des données de localisation (déplacements, données GPS, GSM...)<br/><br/>
        
                            Cet ensemble de données personnelles est nécessaire pour vous offrir les services d'apprentissage de Dafaru.<br/><br/>
        
                            En outre, vous pouvez télécharger une photo de profil. Nous ne publierons votre photo que si vous nous en donnez l'autorisation. 
                            Vous pouvez retirer votre consentement à tout moment sans avoir à fournir de raison.<br/><br/>
        
                            <span className="fw-600">Apprendre avec Defaru</span><br/>
                            Lors de l'utilisation de Defaru, nous collecterons et analyserons des données sur vos progrès d'apprentissage, l'historique de vos cours, 
                            vos évaluations et vos certificats et badges. Nous ne pouvons pas offrir les services Defaru sans cet ensemble de données.<br/><br/>
        
                            Vous pouvez poster des messages dans un forum et donner votre avis et vos évaluations sur les cours. De plus, vous pouvez envoyer et recevoir des messages d'autres apprenants. 
                            Pour tous ces services, nous conserverons vos données.<br/><br/>
        
                            Si vous avez besoin d'aide, nous stockerons également vos demandes d'aide si vous choisissez de nous contacter.<br/><br/>
        
                            <span className="fw-600">Cookies</span><br/>
                            Les cookies sont de petits fichiers texte. Les cookies sont stockés sur l'appareil d'un utilisateur lorsqu'une certaine page web est visitée. 
                            Les cookies permettent l'identification unique d'un navigateur lors de visites répétées d'une page web.<br/><br/>
        
                            Nous utilisons des cookies pour la connexion à la plateforme Defaru. Certains éléments de notre site web requièrent l'identification unique d'un navigateur sur différentes pages web. 
                            Si les cookies sont désactivés, la connexion à la plateforme peut ne pas fonctionner. 
                            D'autres fonctions de la plateforme peuvent également ne pas fonctionner pleinement. En outre, nous utilisons des cookies pour optimiser 
                            la convivialité de la plateforme. Nous utilisons Google Analytics comme solution logicielle de suivi des sites web. 
                            Google Analytics utilise des cookies pour suivre les données suivantes pour chaque session<br/>
                            •	dispositif,<br/>
                            •	système d'exploitation,<br/>
                            •	les paramètres techniques du navigateur,<br/>
                            •	adresse IP anonyme,<br/>
                            •	Lieu de connexion à la plateforme, <br/>
                            •	la date et l'heure,<br/>
                            •	la page consultée ou le nom du fichier.<br/>
                            Vous pouvez retirer votre consentement au traitement de vos données personnelles à l'adresse suivante à tout moment. 
                            Si vous souhaitez revenir sur votre consentement, il vous suffit de nous envoyer un courriel à defaru.support@volkeno.sn avec votre nom , 
                            votre email et votre date de naissance. Vous n'avez pas à indiquer de raison pour votre rétractation.<br/><br/><br/>
        
        
        
        
                            <span className="fw-600">Transfert de données et garantie</span><br/>
                            Les administrateurs de l'équipe ont un accès restreint à vos données personnelles. 
                            Ils peuvent accéder aux données qui sont nécessaires à la mise en œuvre de la formation qu'ils gèrent.<br/><br/>
        
                            Si nous traitons des données dans un pays tiers (c'est-à-dire en dehors de l'Union européenne (UE) ou de l'Espace économique européen (EEE)) 
                            ou si cela se produit dans le cadre de l'utilisation de services tiers ou de la divulgation ou du transfert de données à des tiers, 
                            cela ne se produira que si cela est fait afin de remplir nos obligations (pré)contractuelles, sur la base de votre consentement, 
                            sur la base d'une obligation légale ou sur la base de nos intérêts légitimes. Sous réserve d'autorisations légales ou contractuelles, 
                            nous ne traiterons ou n'autoriserons le traitement de données dans un pays tiers que si les exigences particulières des articles 44 ff du RGPD. 
                            Cela signifie que le traitement est effectué, par exemple, sur la base de garanties spéciales, telles que la détermination officiellement reconnue d'un niveau de protection 
                            des données correspondant à l'UE (par exemple pour les États-Unis par le "Privacy Shield") ou le respect d'obligations contractuelles spéciales officiellement 
                            reconnues (dites "clauses contractuelles types"). <br/>
                            <span className="fw-600">Durée de conservation</span><br/>
                            Nous conservons vos données uniquement pendant la période nécessaire pour vous offrir les services de la plateforme de Defaru. Si vous annulez votre accès à Defaru, 
                            nous conservons vos données jusqu’au 31.12.2021, sauf en cas de législation divergente. À titre exceptionnel, les certificats acquis par 
                            l'intermédiaire de Defaru seront conservés pendant des années. Après cette période, vos données personnelles seront automatiquement purgées de nos systèmes, 
                            à moins que la législation ne nous oblige à les conserver pendant une période différente.<br/><br/>
        
                            <span className="fw-600">Analyse du site web</span><br/>
                            Pour l’analyse du site web, ce site utilise Google (Universal) Analytics, un service d’analyse du web fourni par Google LLC (www.google.com). 
                            Ceci sert à la sauvegarde de nos intérêts légitimes, qui prédominent dans le cadre d’une mise en balance des intérêts, 
                            afin d’optimiser la représentation de notre offre conformément à l’article 6 (1) Clause 1 lit. f RGPD. Google (Universal) Analytics utilise des méthodes qui 
                            vous permettent d’analyser l’utilisation du site web, telles que les cookies. Les informations recueillies automatiquement sur votre utilisation de ce site web 
                            sont généralement transmises à un serveur de Google aux États-Unis et y sont stockées. En activant l’anonymisation IP sur ce site web, 
                            l’adresse IP sera raccourcie avant la transmission dans les États membres de l’Union européenne ou dans d’autres États contractants de l’accord sur 
                            l’Espace économique européen. Ce n’est que dans des cas exceptionnels que l’adresse IP complète sera envoyée à un serveur de Google aux États-Unis et y sera 
                            raccourcie.<br/><br/>
        
                            L’adresse IP anonymisée fournie par Google Analytics dans le cadre de Google Analytics ne sera généralement pas fusionnée avec d’autres données fournies par Google. 
                            Après l’utilisation de Google Analytics, les données collectées dans ce cadre seront supprimées.<br/><br/>
        
                            Google LLC a son siège social aux États-Unis et est certifié par le Privacy Shield UE-États-Unis. 
                            Un certificat actuel peut être trouvé ici. En vertu de l’accord entre les États-Unis et la Commission européenne, cette dernière a établi un niveau approprié de protection des données 
                            pour les entreprises certifiées au titre du Privacy Shield.<br/><br/>
        
                            Vous pouvez empêcher la collecte des données (y compris votre adresse IP) générées par le cookie et liées à votre utilisation du site web de Google ainsi 
                            que le traitement de ces données par Google en téléchargeant et en installant le plug-in du navigateur disponible sous le 
                            lien suivant : https://tools.google.com/dlpage/gaoptout <br/><br/>
        
                            Vous pouvez également cliquer ici pour empêcher à l’avenir le suivi de ce site par Google Analytics. 
                            Un cookie de désactivation est stocké sur votre appareil. Si vous supprimez vos cookies, vous devez cliquer à nouveau sur le lien.<br/><br/>
        
                            <span className="fw-600">Plugins vidéo Vimeo</span><br/>
                            Aux fins d’une conception interactive de nos contenus sur Vimeo, des contenus tiers sont intégrés à ce site web. 
                            Cela permet de sauvegarder nos intérêts légitimes prédominants dans une présentation multimédia de nos services et de nos activités conformément à l’article. 
                            6 (1) Clause 1 lit. f RGDP. YouTube est exploité par Google LLC, 1600 Amphitheater Parkway, Mountain View, CA 94043, USA (“Google”). Vimeo est exploité par Vimeo LLC, 555 West 18th Street, NY, New York 10011, États-Unis.<br/><br/>
        
                            Afin d’accroître la protection de vos données lors de la visite de notre site web, les plugins sont intégrés au site de sorte qu’ils ne peuvent
                             être activés que par un clic supplémentaire. Cette intégration garantit que lorsque vous visitez une page de notre site web qui contient de tels
                              plugins, aucune connexion n’est établie avec les serveurs du réseau social concerné. Ce n’est que lorsque vous activez les plugins que votre 
                              navigateur établit une connexion directe avec les serveurs du réseau social concerné.<br/><br/>
        
                            Le contenu du plugin correspondant est alors transmis directement à votre navigateur par le fournisseur associé et intégré dans la page. 
                            En intégrant les plugins, les fournisseurs reçoivent l’information que votre navigateur a accédé à la page correspondante de notre site web, 
                            même si vous n’avez pas de profil avec le fournisseur correspondant ou si vous n’êtes pas connecté actuellement. Ces informations (y compris votre adresse IP) sont 
                            transmises par votre navigateur directement à un serveur du fournisseur concerné (éventuellement aux États-Unis) et y sont stockées.<br/><br/>
        
                            Si vous interagissez avec les plugins, par exemple en cliquant sur le bouton “J’aime”, 
                            les informations correspondantes sont également transmises directement à un serveur du fournisseur et y sont stockées.<br/><br/>
        
                            Pour les vidéos de Vimeo qui sont intégrées sur notre site, l’outil de suivi Google Analytics est automatiquement intégré. 
                            Nous n’avons aucune influence sur les résultats d’analyse qui en découlent et nous ne pouvons pas non plus les consulter. 
                            En outre, l’intégration des vidéos de Vimeo permet de placer des balises web sur les visiteurs du site lors de l’activation desdites vidéos. Pour empêcher 
                            l’installation de cookies de suivi de Google Analytics, vous pouvez prendre les précautions habituelles pour désactiver Google Analytics. 
                            Pour plus d’informations, voir la section sur l’analyse web.<br/><br/>
        
                            Pour plus d’informations sur l’objectif et l’étendue de la collecte de données et sur le traitement et l’utilisation ultérieurs 
                            des données par le fournisseur, ainsi que sur vos droits connexes et les options de paramétrage pour protéger votre vie privée, 
                            veuillez-vous reporter à la politique de confidentialité du fournisseur :<br/>
                            Vimeo : https://vimeo.com/privacy <br/><br/>
        
                            <span className="fw-600">Droits affectés</span><br/>
                            Vous pouvez à tout moment demander un aperçu de toutes les données que nous avons recueillies auprès de vous. 
                            Vous pouvez également nous demander comment nous utilisons vos données. Si vos données sont incorrectes, 
                            vous pouvez demander une correction de vos données. Si vous résiliez votre compte chez Defaru, vous pouvez également demander la suppression immédiate de vos 
                            données personnelles dans notre système. Vous pouvez également vous opposer au traitement de vos données personnelles si vous ne souhaitez plus que 
                            nous utilisions vos données.<br/><br/>
        
                            Pendant le traitement de votre demande de transparence, de révocation ou de suppression, 
                            vous pouvez demander la restriction du traitement de vos données. Dans ce cas, nous enregistrons vos données dans le but de traiter votre demande.<br/><br/>
        
                            Vous pouvez contacter notre responsable de la protection des données si vous avez des questions concernant vos données ou la politique de protection des 
                            données de la plateforme Defaru. Vous pouvez joindre le délégué à la protection des données à l'adresse suivante : defaru.support@volkeno.sn<br/><br/>
        
                            <span className="fw-600">Droit de recours</span><br/>
                            Dans la mesure où nous traitons des données à caractère personnel comme expliqué ci-dessus afin de sauvegarder nos intérêts légitimes, 
                            qui sont prédominants dans le cadre d’une mise en balance des intérêts, vous pouvez vous opposer à ce traitement avec effet pour l’avenir. 
                            Si le traitement est effectué à des fins de marketing direct, vous pouvez exercer ce droit à tout moment comme décrit ci-dessus. Dans la mesure où le traitement 
                            a lieu à d’autres fins, vous n’avez le droit de vous opposer à ce traitement que si des raisons découlant de votre situation particulière le justifient.<br/><br/>
        
                            Après avoir exercé votre droit d’opposition, nous ne traiterons plus vos données à caractère personnel à ces fins, sauf si nous pouvons démontrer 
                            l’existence de motifs légitimes impérieux de traitement qui l’emportent sur vos intérêts, droits et libertés, ou si le traitement sert à faire valoir, 
                            à exercer ou à défendre des droits légaux.<br/><br/>
        
                            Cela ne s’applique pas si le traitement est effectué à des fins de marketing direct. Dans ce cas, nous ne traiterons pas vos données personnelles à cette fin.
        
                        </p>
                    </div>
                </div>
                <MessageAllPage fullWidth={true} />
            </div>
          );
    }
}

