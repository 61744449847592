import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { apprenantsReducer } from "./apprenantList/apprenantListReducer";
import { moduleReducer } from "./modules/moduleReducer";

const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(
  combineReducers({
    list_apprenants: apprenantsReducer,
    list_modules: moduleReducer
  }),
  composedEnhancers
);

export default store;
