import axios from 'axios'
import { EditorState } from 'draft-js'
import FeatherIcon from 'feather-icons-react'
import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import api from '../../http/global-vars'

export default class CreateGlisserDeposer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: window.localStorage.getItem('userToken'),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem('userID'),
      fields: {},
      colones: ['', '', '', ''],
      works: ['', '', '', ''],
      data: [{ name: '', words: [{ content: '' }] }],
      editorState: EditorState.createEmpty(),
      created_by: parseInt(window.localStorage.getItem('userID')),
      isSuccess: false,
      isError: false,
      isLoading: false,
      is_empty_column: false,
      is_empty_word: false,
      is_empty_name: false,
      is_empty_text: false,
      is_empty_texte: false,
      content_type: 'drag_drop',
      contentId: this.props.contentId,
      errors: {},
      attributeForm: [],
    }
  }

  addAttributeForm = (e) => {
    //this method adds label and input field to the state array
    e.preventDefault()
    let newData = this.state.data
    newData.push({ name: '', words: [{ content: '' }] })

    this.setState({
      data: newData,
    })
  }

  addWordAttributeForm = (e, i) => {
    //this method adds label and input field to the state array
    e.preventDefault()
    let newData = this.state.data
    newData[i].words.push({ content: '' })

    this.setState({
      data: newData,
    })
  }

  handleRemoveWordClick = (e, i, index) => {
    e.preventDefault()
    const list = [...this.state.data]
    list[i].words.splice(index, 1)
    this.setState({ data: list })
  }

  handleRemoveClick = (e, index) => {
    e.preventDefault()
    const list = [...this.state.data]
    list.splice(index, 1)
    this.setState({ data: list })
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  }

  handleChange = (e) => {
    let fields = this.state.fields

    fields[e.target.name] = e.target.value

    console.log(fields)
    this.setState({
      fields: fields,
    })
  }

  handleChangeColumn = (e, i) => {
    let newData = this.state.data
    newData[i].name = e.target.value
    // console.log('name',newData,i)
    this.setState({ data: newData })
  }

  onValidateForm() {
    let fields = this.state.fields
    let errors = this.state.errors
    var isValidForm = true

    let copyData = this.state.data

    for (let i = 0; i < copyData.length; i++) {
      const element = copyData[i]
      if (!element['name']) {
        isValidForm = false
        this.setState({
          is_empty_column: true,
        })
        errors[`name${i}`] = 'Ce champ est obligatoire'
        setTimeout(() => {
          errors[`name${i}`] = ''
          this.setState({
            is_empty_column: false,
            errors: {},
          })
        }, 5000)
      }
      for (let j = 0; j < element.words.length; j++) {
        const ele = element.words[j]
        if (!ele['content']) {
          isValidForm = false
          this.setState({
            is_empty_word: true,
          })
          errors[`content${j}`] = 'Ce champ est obligatoire'
          setTimeout(() => {
            errors[`content${j}`] = ''
            this.setState({
              is_empty_word: false,
              errors: {},
            })
          }, 5000)
        }
      }
    }

    if (!fields['name']) {
      isValidForm = false
      this.setState({
        is_empty_name: true,
      })
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
        })
      }, 5000)

      errors['name'] = 'Ce champ est obligatoire'
    }

    this.setState({
      errors: errors,
    })

    return isValidForm
  }

  addForm = async (e) => {
    e.preventDefault()
    console.log('valid', this.state.contentId)
    if (this.onValidateForm()) {
      this.setState({
        isLoading: true,
      })

      var api = this.state.api
      var data = {
        name: this.state.fields.name,
        contenu: this.state.contentId,
        content_type: this.state.content_type,
        //list_mots_enlever: this.state.list_mots_enlever,
        created_by: this.state.created_by,
      }

      await axios
        .post(api + 'dragdroptask/', data, this.state.requestConfig)
        .then(async (response) => {
          // console.log(response)
          if (response.status === 201 || response.status === 200) {
            console.log('sucess')
            for (let i = 0; i < this.state.data.length; i++) {
              const element = this.state.data[i]
              const colonnes = {
                name: element?.name,
                drag_drop: response.data.id,
              }
              await axios
                .post(api + 'colonnedrag/', colonnes, this.state.requestConfig)
                .then(async (resp) => {
                  console.log('colonnes', resp.data)
                  if (resp.data) {
                    for (let j = 0; j < element?.words.length; j++) {
                      const ele = element?.words[j]
                      const works = {
                        content: ele?.content,
                        colonne: resp.data.id,
                      }
                      await axios
                        .post(
                          api + 'wordcolonne/',
                          works,
                          this.state.requestConfig,
                        )
                        .then((item) => {
                          console.log('mots', item.data)

                          this.setState({
                            isSuccess: true,

                            fields: {},
                            colones: ['', '', '', ''],
                            works: ['', '', '', ''],
                          })
                        })
                        .catch((e) => {
                          console.log('work error', e.response)
                        })
                    }
                  }
                })
                .catch((e) => {
                  console.log('error colonne', e.response)
                })
            }

            this.props.notify()
            setTimeout(() => {
              this.setState({
                isLoading: false,
                isSuccess: false,
                message: {},
                data: [{ name: '', words: [{ content: '' }] }],
              })
            }, 5000)
          } else {
            this.setState({
            isError: true,
            isLoading: false,
          })
            toast.error('Vos données ne sont pas valides !')
          }
        })
        .catch((error) => {
          console.log(error.response)
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard.",
          )
          this.setState({
            isError: true,
            isLoading: false,
          })

          setTimeout(() => {
            this.setState({
              isError: false,
            })
          }, 5000)
        })
    }
  }

  checkColonneInput(index, e) {
    this.setState({
      colones: this.state.colones.map((title, i) =>
        i === index ? e.target.value : title,
      ),
    })
    console.log('array', this.state.colones)
  }

  checkWordInput(e, i, index) {
    let newData = this.state.data
    newData[i].words[index].content = e.target.value
    // console.log('name',newData,i)
    this.setState({ data: newData })

    console.log('array', newData)
  }

  render() {
    let { data } = this.state

    return (
      <div className="card-dashboard no-shadow">
        <form>
          <div className="row m-b-50">
            <div className="col-lg-9">
              <p className="titre-card-create-content">GlisserDeposer</p>
            </div>

            <div className="col-lg-3">
              <span className="text-inside-select-bg-theme">Ordre</span>
              <select className="custom-select select-bg-theme" id="">
                <option value="">0</option>
              </select>
            </div>
          </div>

          <div className="form-group m-b-40">
            <input
              type="text"
              className="form-control input-dashboard-backend fw-700"
              placeholder="Consigne"
              name="name"
              onChange={(e) => this.handleChange(e)}
            />
            {this.state.is_empty_name && (
              <div className="alert alert-danger keysjob-alert-danger">
                {' '}
                {this.state.errors.name}{' '}
              </div>
            )}
          </div>

          <div className="row">
            {data?.map((item, i) => (
              <div className="col-lg-6 mb-3">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control head-term-input input-dashboard-backend"
                    placeholder="Nom de la colonne"
                    onChange={(e) => this.handleChangeColumn(e, i)}
                    name={`name${i}`}
                    id={`name${i}`}
                  />
                  {i > 0 ? (
                    <button
                      className="btn-placeholder-rmv"
                      onClick={(e) => this.handleRemoveClick(e, i)}
                    >
                      <FeatherIcon className="close" icon="trash-2" />
                    </button>
                  ) : null}
                  {this.state.is_empty_column &&
                    this.state.errors[`name${i}`] && (
                      <div className="alert alert-danger keysjob-alert-danger">
                        {' '}
                        {this.state.errors[`name${i}`]}{' '}
                      </div>
                    )}
                </div>

                {/* <div className="head-term-left">
                  <span>Terme {i + 1} </span>
                </div> */}
                {item?.words?.map((el, index) => (
                  <>
                    <div className="form-group d-flex" key={index}>
                      <input
                        type="text"
                        className="form-control input-dashboard-backend"
                        placeholder="Ajouter un mot"
                        name={`content${index}`}
                        onChange={(e) => this.checkWordInput(e, i, index)}
                      />
                      {index > 0 ? (
                        <button
                          className="btn-placeholder-rmv-word"
                          onClick={(e) =>
                            this.handleRemoveWordClick(e, i, index)
                          }
                        >
                          <FeatherIcon
                            className="close"
                            style={{ width: '15px' }}
                            icon="trash-2"
                          />
                        </button>
                      ) : null}
                    </div>
                    {this.state.is_empty_word &&
                      this.state.errors[`content${index}`] && (
                        <div className="alert alert-danger keysjob-alert-danger">
                          {' '}
                          {this.state.errors[`content${index}`]}{' '}
                        </div>
                      )}
                  </>
                ))}
                <div className="col-12 ">
                  <button
                    type="button"
                    className="btn-light-add-content"
                    data-toggle="modal"
                    data-target=".modalCreateContent"
                    onClick={(e) => this.addWordAttributeForm(e, i)}
                  >
                    <FeatherIcon
                      className="icon-btn-add-dashboard"
                      icon="plus"
                    />
                  </button>
                </div>
              </div>
            ))}

            {/* <div className="col-lg-6">
              <div className="head-term-right">
                <span>Terme B</span>
              </div>
              {inputsB}
            </div> */}
            <div className="col-lg-6 m-t-10">
              <button
                type="button"
                className="btn-light-add-content"
                data-toggle="modal"
                data-target=".modalCreateContent"
                onClick={(e) => this.addAttributeForm(e)}
              >
                <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
                Ajouter une colonne
              </button>
            </div>
          </div>

          <div className="row dis-flex justify-content-end m-t-60">
            <div className="col-xl-3 col-lg-3 m-b-20 itm-center">
              <button
                type="button"
                className="btn-cancel-theme"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Annuler</span>
              </button>
            </div>
            <div className="col-xl-4 col-lg-4 m-b-20">
              {/* <button className="btn-theme text-up w-full">Terminer</button> */}
              {this.state.isLoading === false ? (
                <button
                  type="submit"
                  onClick={(e) => this.addForm(e)}
                  className="btn-theme text-up w-full"
                >
                  Terminer
                </button>
              ) : (
                <button className="btn-theme text-up w-full in-progress-btn">
                  Ajout en cours... &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ''}
      </div>
    )
  }
}
