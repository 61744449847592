import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react'
import { NavLink } from 'react-router-dom'
import $ from 'jquery'
import ContactSupport from './ContactSupport'
import PackageJson from '../../../package.json'

export default class NavbarVerticalAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: PackageJson.version,
    }
  }

  onClickable = () => {
    $('.contactSupportModal').appendTo($('body'))
  }

  render() {
    return (
      <div className="hoverable">
        <ul className="nav nav-items-custom flex-column">
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/tableau-de-bord"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="grid" />
              <span className="hiddenable-navlink-label">Tableau de bord</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/thematiques"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="book-open" />
              <span className="hiddenable-navlink-label">Thématiques</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/module"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="book" />
              <span className="hiddenable-navlink-label">Module</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/personnages"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="users" />
              <span className="hiddenable-navlink-label">Personnages</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/apprenants"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="user" />
              <span className="hiddenable-navlink-label">Apprenant</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/liste-des-identifications"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="user" />
              <span className="hiddenable-navlink-label">
                Apprenants Identifiés
              </span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/certificats"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="award" />
              <span className="hiddenable-navlink-label">Certificats</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/performances"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="activity" />
              <span className="hiddenable-navlink-label">Performances</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom "
              to="/parametres"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="settings" />
              <span className="hiddenable-navlink-label">Paramétres</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/extraction"
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon="download" />
              <span className="hiddenable-navlink-label">Extraction</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/gestion-messages-contact"
              activeClassName="actived"
            >
              <FeatherIcon
                className="icon-navbar-vertical"
                icon="message-square"
              />
              <span className="hiddenable-navlink-label">Messages</span>
            </NavLink>
          </li>
        </ul>

        <div className="container-fluid m-l-15">
          <p className="text-white f-f-inter fw-600 fs-14 m-b-20">
            {"v" + this.state.version}
          </p>
          

         
        </div>
      </div>
    )
  }
}
