import React from "react";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarVertical from "../components/NavbarVertical";
import MessageAllPage from "../../backend/components/MessageAllPage";
import { ImagesModules } from "../components/WelcomeV4";
import { NavLink } from "react-router-dom";

const AboutDefaru = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid bg-white pt-4">
            <h4 className="titre-page-dashboard m-b-35">A propos</h4>
            <div className="bg-white p-b-40">
              <div className="container-about-page">
                <div className="row m-b-20">
                  <div className="col-md-12 welcome-page-introduction">
                    <div className="text-dark fw-700 f-f-poppins lh-1-5 welcome-textual-bloc">
                      <h2 className="content-text-welcome text-color fw-700 text-center">
                        Bonjour et bienvenu(e) sur DEFARU
                      </h2>
                      <p className="content-text-welcome-v4" style={{ fontSize: 15}}>
                        Ta plateforme sénégalaise pour les formations en ligne sur les
                        Soft Skills, l’Entrepreneuriat et le Numérique !
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12 mb-5">
                    <div className="home-illustration-container row">
                      <div className="col-md-12 dis-flex justify-content-center itm-center home-illustration-container-col">
                        <img
                          className="w-full home-illustration welcome-page-img-v4"
                          src="img/unnamed.webp"
                          alt=""
                          style={{ height: 400, objectFit: "contain" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="learning-programme-content-container mb-5">
                  <div className="learning-programme-content-row">
                    <p className="content-text-welcome-v4" style={{ fontSize: 15}}>
                      Nous sommes ravis de te voir parmi nous ! Voici des informations
                      sur
                      <span className="h-theme fw-700 pl-2">le contenu</span> des
                      modules à découvrir sur la plateforme :
                    </p>
                    <div className="container-presentation-modules-v4">
                      <h4 className="content-text-welcome text-color fw-700">
                        Soft Skills
                      </h4>
                      <ul>
                        <li
                          className="content-text-welcome-v4"
                          style={{ textAlign: "justify" }}
                        >
                          Dans le module{" "}
                          <span className="h-theme fw-700">Communication</span>, tu
                          découvriras les différents types de communication et tu
                          apprendras pourquoi des bonnes capacités en matière de
                          communication sont importantes pour ton avenir
                          professionnel.
                        </li>
                        <li
                          className="content-text-welcome-v4"
                          style={{ textAlign: "justify" }}
                        >
                          Dans le module{" "}
                          <span className="h-theme fw-700">L’éthique de travail</span>
                          , tu apprendras la signification du terme « éthique de
                          travail », son application dans la vie professionnelle et
                          les règles qui en découlent.
                        </li>
                        <li
                          className="content-text-welcome-v4"
                          style={{ textAlign: "justify" }}
                        >
                          Dans le module{" "}
                          <span className="h-theme fw-700 pl-2">
                            La gestion de carrière
                          </span>
                          , tu seras amené(e) à te focaliser sur les aspects pratiques
                          liés à la gestion de ta carrière. D’abord tu identifieras
                          tes besoins, tes intérêts, et tes points forts pour ensuite
                          approfondir les aspects relatifs à tes démarches de
                          recherche d’emploi.
                        </li>
                      </ul>
                    </div>
                    <div className="container-presentation-modules-v4">
                      <h4 className="content-text-welcome text-color fw-700">
                        Entrepreneuriat
                      </h4>
                      <ul>
                        <li
                          className="content-text-welcome-v4"
                          style={{ textAlign: "justify" }}
                        >
                          Dans le module{" "}
                          <span className="h-theme fw-700">
                            Mon modèle d’affaires
                          </span>
                          , tu découvriras le concept de la vision d’entreprise et les
                          différents types d’entreprises et tu apprendras comment
                          l’approche simplifiée de démarrage peut t’aider à lancer tes
                          activités entrepreneuriales.
                        </li>
                        <li
                          className="content-text-welcome-v4"
                          style={{ textAlign: "justify" }}
                        >
                          Le module{" "}
                          <span className="h-theme fw-700">Gestion des finances</span>
                          , aborde la question de la viabilité des entreprises et de
                          la gestion financière de ton entreprise et donne un aperçu
                          simplifié des indicateurs économiques les plus pertinents.
                        </li>
                      </ul>
                    </div>
                    <div className="container-presentation-modules-v4">
                      <h4 className="content-text-welcome text-color fw-700">
                        Numérique
                      </h4>
                      <ul>
                        <li
                          className="content-text-welcome-v4"
                          style={{ textAlign: "justify" }}
                        >
                          Dans le module{" "}
                          <span className="h-theme fw-700">
                            Les bases du numérique
                          </span>
                          , tu découvriras les différentes méthodes liées à la bonne
                          utilisation de l’ordinateur, les notions de bases concernant
                          l’utilité de l’Internet ainsi que de l’Intelligence
                          Artificielle.
                        </li>
                        <li
                          className="content-text-welcome-v4"
                          style={{ textAlign: "justify" }}
                        >
                          Dans le module{" "}
                          <span className="h-theme fw-700">
                            La communication numérique
                          </span>
                          , tu développeras ton professionnalisme lors de la rédaction
                          d’emails et tu plongeras dans le monde des réseaux sociaux
                          et aussi la protection des données personnelles.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};
export default AboutDefaru;
