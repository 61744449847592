import Axios from "axios";
import FeatherIcon from "feather-icons-react";
import React from "react";
import Swal from "sweetalert2";
import api from "../../../http/global-vars";
import CreateQcmTest from "../CreateQcm";
import { hideModal } from "../ItemSessionModule";
function SupportQcm({ data, contentId, onReload }) {
  const dq = useDeleteQuiz(onReload);
  const DATA = [
    { title: "Prétest", value: data.is_pretest ? "OUI" : "NON" },
    { title: "Test final", value: data.is_testfinal ? "OUI" : "NON" },
  ];
 
  return (
    <div>
      <div className="row mt-1 mb-2">
        <div className="col-md-6">
          <div
            className="link-collapse-session-item"
            style={{ position: "relative" }}
          >
            {data.title}
          </div>
        </div>
        <div className="col-md-6 text-right">
          <button
            onClick={() => dq(data.id)}
            className="btn-action-modul-session"
          >
            <FeatherIcon icon="trash-2" />
          </button>
        </div>
        {DATA.map((row) => {
          return (
            <div className="col-md-4 text-center mb-2" key={row.title}>
              <div>{row.title}</div>
              <div style={{ fontWeight: "bold" }}>{row.value}</div>
            </div>
          );
        })}
      </div>
      {data.list_question.map((q) => {
        return (
          <QcmItem
            contentId={contentId}
            onReload={onReload}
            quiz={data.id}
            item={q}
            key={q.id}
          />
        );
      })}
      <AddButton
        content={contentId}
        onReload={onReload}
        quiz={data.id}
        title="Ajouter une question"
      />
    </div>
  );
}

export default SupportQcm;

function AddButton({ content, question, quiz, onReload, title }) {
  const modalId = `ModalAddQcm${question ? question : ""}${quiz}`;
  return (
    <>
      <div className="mt-2">
        <button
          data-toggle="modal"
          data-target={`#${modalId}`}
          className="btn-light-add-content m-b-0"
        >
          <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
          {title}
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="ModalCreateQcmLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header b-b-0">
              <h6
                className="modal-title fw-500 text-black"
                id="ModalCreateQcmLabel"
              >
                Créer un contenu
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <CreateQcmTest
              notify={(end) => {
                onReload();
                if (end !== false) {
                  hideModal(`#${modalId}`);
                }
                Swal.fire({
                  icon: "success",
                  title: "Données ajoutées avec succès",
                  iconColor: "green",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }}
              contentId={content}
              question={question}
              quiz={quiz}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function QcmItem({ item, contentId, quiz, onReload }) {
  const collapseId = `QcmItem${item.id}`;
  const deteleResponse = useDeleteResponse(onReload);
  const DATA = [{ title: "Score", value: item.score }];
  return (
    <div className="session-content-container">
      <div className="card-item-sessions-module contenu-shadow">
        <a
          className="link-collapse-session-item collapsed"
          data-toggle="collapse"
          href={`#${collapseId}`}
          role="button"
          style={{ marginRight: 50 }}
          aria-expanded="false"
          aria-controls="collapseItemSessionModule"
        >
          <i
            className="fa chevron-collapse-session-item"
            aria-expanded="false"
          ></i>
          {item.content}
        </a>
        <div className="collapse" id={collapseId}>
          <div className="card card-body p-t-20 m-b-0">
            <div
              className="row dis-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-md-10 offset-md-1">
                <div className="session-content-container">
                  <div className="row">
                    {DATA.map((row) => {
                      return (
                        <div
                          className="col-md-4 text-center mb-2"
                          key={row.title}
                        >
                          <div>{row.title}</div>
                          <div style={{ fontWeight: "bold" }}>{row.value}</div>
                        </div>
                      );
                    })}
                  </div>

                  <ul className="list-group">
                    {item.list_responses.map((r) => {
                      const className = r.is_right_choice
                        ? "list-group-item text-success"
                        : "list-group-item";
                      return (
                        <li key={r.id} className={className}>
                          {r.content}
                          <div
                            style={{ top: 0, right: 0 }}
                            className="btn-action-container-modul-session"
                          >
                            <button
                              onClick={() => deteleResponse(r.id)}
                              className="btn-action-modul-session"
                            >
                              <FeatherIcon icon="trash-2" />
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <AddButton
            content={contentId}
            onReload={onReload}
            question={item.id}
            quiz={quiz}
            title="Ajouter une réponse"
          />
        </div>
        <DeleteQuestionButton onReload={onReload} questionId={item.id} />
      </div>
    </div>
  );
}

export function DeleteQuestionButton({ questionId, onReload }) {
  const onDelete = useDeleteQuestion(onReload);
  return (
    <div className="btn-action-container-modul-session">
      <button
        onClick={() => onDelete(questionId)}
        className="btn-action-modul-session"
      >
        <FeatherIcon icon="trash-2" />
      </button>
    </div>
  );
}

export function useDeleteQuestion(onReload) {
  return useDeleteItem(
    onReload,
    "question",
    "Question supprimée avec succés",
    "Question non supprimée"
  );
}

export function useDeleteQuiz(onReload) {
  return useDeleteItem(
    onReload,
    "quiz",
    "Quiz supprimée avec succés",
    "Quiz non supprimée"
  );
}
export function useDeleteQuizFree(onReload) {
  return useDeleteItem(
    onReload,
    "quizfree",
    "Quiz supprimée avec succés",
    "Quiz non supprimée"
  );
}
export function useDeleteResponse(onReload) {
  return useDeleteItem(
    onReload,
    "response",
    "Réponse supprimée avec succés",
    "Réponse non supprimée"
  );
}
export function useDeleteWord(onReload) {
  return useDeleteItem(
    onReload,
    "wordtextatrous",
    "Mot supprimé avec succés",
    "Mot non supprimé"
  );
}

export function useDeleteTextTrous(onReload) {
  return useDeleteItem(
    onReload,
    "textatroustask",
    "Données supprimées avec succés",
    "Données non supprimées"
  );
}

export function useDeleteItem(onReload, route, successMessage, errorMessage) {
  const exec = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    return Axios.delete(api + `${route}/${id}/`, config);
  };
  const onDelete = async (id) => {
    Swal.fire({
      title: `Êtes-vous sûr de vouloir effectuer cette action ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#F00",
      confirmButtonColor: "#F00",
      preConfirm: async () => {
        try {
          await exec(id);
          Swal.fire({
            icon: "success",
            title: successMessage,
            showConfirmButton: false,
            timer: 3000,
          });
          onReload();
        } catch (ex) {
          Swal.fire({
            icon: "error",
            title: errorMessage,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      },
    });
  };
  return onDelete;
}
