/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import NavbarVerticalAdmin from "./components/NavbarVertical";
import MessageAllPage from "./components/MessageAllPage";
import api from "../http/global-vars";
import CardModuleSkeleton from "./components/CardModuleSkeleton";
import AddOrEditThematiqueModal from "./modal/AddOrEditThematiqueModal";
import Swal from "sweetalert2";

export const EmptyDataAlert = ({ text }) => {
  return (
    <div className="col-md-12 dis-flex badges text-center">
      <div className="alert alert-info gfa-alert-info empty-badge-alert">
        <div className="row empty-alert-row">
          <div className="col-md-2 empty-icon-col">
            <FeatherIcon
              icon="alert-circle"
              className="m-b-3 empty-alert-icon rotateIn"
            />
          </div>
          <div className="col-md-10 empty-textual-col">
            <p>{text}</p>
            {/* <p className="info-label">
              Pour rappel chaque module de formation terminé vous donne droit à
              un certificat.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const ThematiqueItem = ({ theme, refetch }) => {
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce thématique ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "red",
      confirmButtonColor: "red",
      preConfirm: async () => {
        // return deleteData(slug);
        try {
          const url = `${api}module/${theme?.id}/`;
          const response = await fetch(url, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
          });

          console.log("response", response);
          return response;
        } catch (error) {
          Swal.showValidationMessage(`
        Request failed: ${error}
      `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          refetch();
          Swal.fire({
            icon: "success",
            title: `Thématique supprimé avec succès!`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };

  return (
    <>
      <div className="module-item" key={theme?.id}>
        <div className="row align-items-center">
          <div className="col-md-8 col-sm-7">
            <NavLink
              to={{
                pathname: `/thematique-details/${theme?.id}`,
                state: theme,
              }}
              className="no-link"
            >
              <p
                className="titre-module-item"
                id={`module-name${theme?.id}`}
                data-module={theme?.name}
              >
                {theme?.name}
              </p>
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 dis-flex itm-center m-b-15">
                  <img
                    className="icon-module-item"
                    src="img/icons/module-icon-card1.png"
                    alt=""
                  />
                  <div className="align-right-in-small-device">
                    <p className="chiffre-module-item">
                      {theme?.modules_count}
                    </p>
                    <p className="text-content-module-item">modules</p>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4 col-sm-5 text-center ">
            <div className="row">
              <div className="col-md-6">
                <button
                  data-toggle="modal"
                  data-target={`#editThematiqueModal${theme?.id}`}
                  className="btn-details-module-item d-block "
                >
                  {/* <FeatherIcon icon="edit" /> */}
                  Modifier
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn-details-module-item d-block "
                  onClick={() => onDelete()}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddOrEditThematiqueModal
        modalId={`editThematiqueModal${theme?.id}`}
        refetch={refetch}
        theme={theme}
      />
    </>
  );
};
function Thematiques() {
  const [thematiques, setThematiques] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const getThematiques = async () => {
    let url = `${api}thematique/`;
    await axios
      .get(url, config)
      .then((res) => {
        console.log("res", res);
        setThematiques(res.data?.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getThematiques();
  }, []);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobileAdmin />
        </div>
      </div>

      <HeaderDashboardAdmin />

      <div className="row">
        {/* Navbar Desktop  */}
        {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
        <div className="nav-container">
          <div
            className="navbar-vertical-customisation no-view-mobile"
            style={{ top: "40px" }}
          >
            <NavbarVerticalAdmin />
          </div>
        </div>

        <div className="col-xl-2 col-lg-3 col-md-12"></div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="row m-b-10">
              <div className="col-xl-6">
                <h5 className="text-black f-f-work fw-500 m-b-15">
                  Thématiques
                </h5>
              </div>
              <div className="col-xl-6 dis-flex justify-content-end justify-left-tablette">
                <button
                  className="btn-add-dashboard"
                  data-toggle="modal"
                  data-target="#addThematiqueModal"
                >
                  <FeatherIcon
                    className="icon-btn-add-dashboard"
                    icon="plus-circle"
                  />
                  Ajouter un thématique
                </button>
              </div>
            </div>

            <div className="card-dashboard p-l-0 p-r-0">
              <div className="col">
                <div className="row m-b-50">
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                    <p className="titre-card-dashboard-sans-padding m-b-15">
                      Liste des thématiques
                    </p>
                  </div>
                </div>
              </div>
              <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                {!!isLoading && <CardModuleSkeleton />}
                {!isLoading &&
                  (thematiques?.length ? (
                    thematiques?.map((theme) => (
                      <ThematiqueItem
                        key={theme?.id}
                        theme={theme}
                        refetch={getThematiques}
                      />
                    ))
                  ) : (
                    <EmptyDataAlert text={"Aucune donnée trouvé"} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddOrEditThematiqueModal
        modalId={"addThematiqueModal"}
        refetch={getThematiques}
      />
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
}

export default Thematiques;
