import React from "react";
import Skeleton from "react-loading-skeleton";

const CardModuleSkeleton = () => {
  return (
    <div className="module-item">
        {Array(9)
          .fill()
          .map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className="col-md-10 col-sm-9">
                <p className="titre-module-item">
                  <Skeleton height={36} width={`90%`} />
                </p>
                <div className="row">
                  <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 dis-flex itm-center m-b-15">
                    <div className="icon-module-item">
                      <Skeleton width={`80%`} />
                    </div>

                    <div className="align-right-in-small-device">
                      <p className="chiffre-module-item">
                        <Skeleton width={`80%`} />
                      </p>
                      <p className="text-content-module-item">
                        <Skeleton width={`80%`} />
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 dis-flex itm-center m-b-15">
                    <div className="icon-module-item">
                      <Skeleton width={`80%`} />
                    </div>
                    <div className="align-right-in-small-device">
                      <p className="chiffre-module-item">
                        <Skeleton width={`80%`} />
                      </p>
                      <p className="text-content-module-item">
                        <Skeleton width={`80%`} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-3 itm-center">
                <div className="icon-module-item">
                  <Skeleton width={`80%`} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
  );
};

export default CardModuleSkeleton;
