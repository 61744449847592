import React from 'react'

const Suggestions = (props) => {
console.log(props)
  const options = props.results.filter(item => 
        Object.keys(item).some(k => item.name.toLowerCase().includes(props.query.toLowerCase()))).map(r => (
    <a href={`/cours-details/${r.id}`} key={`search${r.id}`} className="search-result-item">
      {r.name}
    </a>
  ))
  if(options.length !== 0){
    return <div className="search-result-container">{options}</div>
  }else{
    return <div className="search-result-container">
        <span className="empty-suggestion">Aucun module trouvé</span>
    </div>
  }
  
}

export default Suggestions