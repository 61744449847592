/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import NavbarDashboardMobile from "../../components/NavbarDashboardMobile";
import HeaderDashboard from "../../components/HeaderDashboard";
import NavbarVertical from "../../components/NavbarVertical";
import MessageAllPage from "../../../backend/components/MessageAllPage";
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import api from "../../../http/global-vars";
import Axios from "axios";
import { EmptyDataAlert } from "../../../backend/Thematiques";
import baseUrl from "../../../http/backend-base-url";
import FeatherIcon from "feather-icons-react";


const PersonnageItem = ({
  personne,
  selectedIndex,
  setSelectedIndex,
  index,
  count,
  item,
}) => {
  return (
    <div
      className={`tab-pane fade show ${
        selectedIndex === index ? "active" : ""
      }`}
      id={`p${index}`}
      role="tabpanel"
      aria-labelledby={`personnage${index}-tab`}
    >
      <div className="container-presentation-page mb-4">
        <div className="container-presentaion-personnage">
          <div className="container-img-personnage">
            <img
              src={baseUrl + personne?.image}
              alt={personne?.name}
              className="img-personnage"
            />
          </div>
          <div className="container-text-presentation-personnage">
            <h3 className="nom-personnage mb-4">{personne?.name}</h3>
            <p
              className="text-description-presentation-personnage"
              dangerouslySetInnerHTML={{
                __html: personne?.description,
              }}
            />
            {/* <p className="text-description-presentation-personnage">
              Bonjour, je m'appelle Ibrahima Diop, j'ai 26 ans et je suis
              électricien à Mbour, à environ 80 km de Dakar. Je travaille à mon
              compte. Je suis là pour régler tous les problèmes d'électricité !
              Normalement, je m'occupe des maisons, mais la semaine dernière, on
              m'a demandé de faire une offre pour faire l'installation
              électrique d'une école ! C'est une grosse mission ! Ils ont
              également demandé à mon concurrent de faire une offre, il est donc
              important que ma proposition soit bonne et que mon offre soit
              claire et complète. Je pense qu'il est temps pour moi d'acheter un
              ordinateur personnel, afin de pouvoir faire une bonne proposition,
              envoyer des factures professionnelles et développer mon entreprise
              !
            </p> */}
          </div>
        </div>
      </div>
      <div
        className="container-btn-theme-presenttion-page"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 15,
        }}
      >
        {index > 0 && (
          <a
            id={`personnage${index - 1}-tab`}
            data-toggle="tab"
            href={`#p${index - 1}`}
            role="tab"
            aria-controls={`personnage${index - 1}`}
            aria-selected="false"
            className="btn btn-theme-outline"
            onClick={() => setSelectedIndex((prev) => prev - 1)}
          >
            Précédent
          </a>
        )}
        {index + 1 < count ? (
          <a
            id={`personnage${index + 1}-tab`}
            data-toggle="tab"
            href={`#p${index + 1}`}
            role="tab"
            aria-controls={`personnage${index + 1}`}
            aria-selected="false"
            className="btn btn-theme"
            onClick={() => setSelectedIndex((prev) => prev + 1)}
          >
            Suivant
          </a>
        ) : (
          <NavLink
            to={{
              pathname: `/choisir-personnage/${item?.id}`,
              state: item,
            }}
            className="btn btn-theme"
          >
            Suivant
          </NavLink>
        )}
      </div>
    </div>
  );
};

const AllPersonnages = () => {
  const [personnages, setPersonnages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const { state } = useLocation();
  const [item, setItem] = useState(state.item);
  const history = useHistory();
  const { id } = useParams();


  const getModule = async (id) => {
    await Axios.get(`${api}module/${id}`, config)
      .then((res) => {
        setItem(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
        history.goBack();
      });
  };
  const getPersonnages = async () => {
    let url = `${api}personnage/`;
    await Axios.get(url, config)
      .then((res) => {
        console.log("res", res);
        setPersonnages(res.data?.results);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getPersonnages();
  }, []);
  useEffect(() => {
    if (id) {
      getModule(id);
    } else {
      history.goBack();
    }
  }, [id]);

  useEffect(() => {
    if (state) {
      setItem(state);
    }
  }, [state]);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="row content-routing-url m-b-20">
            <div className="lh-0">
              <NavLink className="routing-view-url" 
              to={
                state.parcours ?
                "/mon-parcours"
                :
                "/tous-les-modules"}
              >
                {
                state.parcours ?
                "Mon parcours"
                :
                "Tous les modules"}
              
              </NavLink>
              <FeatherIcon
                className="icon-routing-view-url"
                icon="chevron-right"
              />
              <NavLink className="routing-view-url" 
              to={{
                pathname: state.path,
                state: item,
              }}
              >
                {item?.name}
              </NavLink>
              <FeatherIcon
                className="icon-routing-view-url"
                icon="chevron-right"
              />
              <NavLink className="routing-view-url" to="#">
                Les personnages se présentent
              </NavLink>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container-title-page-presentation-personnage">
              <h4 className="titre-page-dashboard m-b-35">
                Les personnages se présentent
              </h4>
              <ul className="nav nav-tabs b-b-0" id="myTab" role="tablist">
                {personnages?.map((p, i) => (
                  <li className="nav-item" role="presentation" key={p?.id}>
                    <a
                      className={`nav-link nav-link-presentation-personnage ${
                        selectedIndex === i ? "active" : ""
                      }`}
                      id={`personnage${i}-tab`}
                      data-toggle="tab"
                      href={`#p${i}`}
                      role="tab"
                      aria-controls={`personnage${i}`}
                      aria-selected="false"
                    ></a>
                  </li>
                ))}
                {/* <li className="nav-item" role="presentation">
                  <a
                    className="nav-link nav-link-presentation-personnage active"
                    id="personnage1-tab"
                    data-toggle="tab"
                    href="#p1"
                    role="tab"
                    aria-controls="personnage1"
                    aria-selected="true"
                  ></a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link nav-link-presentation-personnage"
                    id="personnage2-tab"
                    data-toggle="tab"
                    href="#p2"
                    role="tab"
                    aria-controls="personnage2"
                    aria-selected="false"
                  ></a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link nav-link-presentation-personnage"
                    id="personnage3-tab"
                    data-toggle="tab"
                    href="#p3"
                    role="tab"
                    aria-controls="personnage3"
                    aria-selected="false"
                  ></a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link nav-link-presentation-personnage"
                    id="personnage4-tab"
                    data-toggle="tab"
                    href="#p4"
                    role="tab"
                    aria-controls="personnage4"
                    aria-selected="false"
                  ></a>
                </li> */}
              </ul>
            </div>
            <div className="tab-content m-t-40" id="myTabContent">
              {!isLoading &&
                (personnages?.length ? (
                  personnages?.map((p, i) => (
                    <PersonnageItem
                      key={p?.id}
                      personne={p}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      index={i}
                      count={personnages?.length}
                      item={item}
                    />
                  ))
                ) : (
                  <EmptyDataAlert text={"Aucune donnée trouvée"} />
                ))}
              {/* <div
                className="tab-pane fade show active"
                id="p1"
                role="tabpanel"
                aria-labelledby="personnage1-tab"
              >
                <div className="container-presentation-page mb-4">
                  <div className="container-presentaion-personnage">
                    <div className="container-img-personnage">
                      <img
                        src="/img/personnages/ibrahima.svg"
                        alt="Personnage 1"
                        className="img-personnage"
                      />
                    </div>
                    <div className="container-text-presentation-personnage">
                      <h3 className="nom-personnage mb-4">IBRAHIMA</h3>
                      <p className="text-description-presentation-personnage">
                        Bonjour, je m'appelle Ibrahima Diop, j'ai 26 ans et je
                        suis électricien à Mbour, à environ 80 km de Dakar. Je
                        travaille à mon compte. Je suis là pour régler tous les
                        problèmes d'électricité ! Normalement, je m'occupe des
                        maisons, mais la semaine dernière, on m'a demandé de
                        faire une offre pour faire l'installation électrique
                        d'une école ! C'est une grosse mission ! Ils ont
                        également demandé à mon concurrent de faire une offre,
                        il est donc important que ma proposition soit bonne et
                        que mon offre soit claire et complète. Je pense qu'il
                        est temps pour moi d'acheter un ordinateur personnel,
                        afin de pouvoir faire une bonne proposition, envoyer des
                        factures professionnelles et développer mon entreprise !
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="container-btn-theme-presenttion-page"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 15,
                  }}
                >
                  <a
                    id="personnage2-tab"
                    data-toggle="tab"
                    href="#p2"
                    role="tab"
                    aria-controls="personnage2"
                    aria-selected="false"
                    className="btn btn-theme"
                  >
                    Suivant
                  </a>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="p2"
                role="tabpanel"
                aria-labelledby="personnage2-tab"
              >
                <div className="container-presentation-page mb-4">
                  <div className="container-presentaion-personnage">
                    <div className="container-img-personnage">
                      <img
                        src="/img/personnages/fatou.svg"
                        alt="Personnage 1"
                        className="img-personnage"
                      />
                    </div>
                    <div className="container-text-presentation-personnage">
                      <h3 className="nom-personnage mb-4">FATOU</h3>
                      <p className="text-description-presentation-personnage">
                        Bonjour, je m'appelle Fatou Sow, j'ai 23 ans et j'habite
                        à Saint Louis. Depuis l'âge de 9 ans, je m'intéresse à
                        ce que les gens portent. J'adore la mode. Mon rêve est
                        de devenir une célèbre créatrice de mode. J'ai presque
                        terminé ma formation de couture et de stylisme au Centre
                        de Formation Professionnelle, mais j'ai déjà lancé mon
                        activité d'indépendante à côté. J'ai déjà pas mal de
                        clients ! Pendant mon temps libre, je suis des ateliers
                        sur la mode et les affaires et je travaille actuellement
                        à la mise en place d'une boutique en ligne, où les gens
                        pourront commander mes créations uniques.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="container-btn-theme-presenttion-page"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 15,
                  }}
                >
                  <a
                    id="personnage1-tab"
                    data-toggle="tab"
                    href="#p1"
                    role="tab"
                    aria-controls="personnage1"
                    aria-selected="false"
                    className="btn btn-theme-outline"
                  >
                    Précédent
                  </a>
                  <a
                    id="personnage3-tab"
                    data-toggle="tab"
                    href="#p3"
                    role="tab"
                    aria-controls="personnage3"
                    aria-selected="false"
                    className="btn btn-theme"
                  >
                    Suivant
                  </a>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="p3"
                role="tabpanel"
                aria-labelledby="personnage3-tab"
              >
                <div className="container-presentation-page mb-4">
                  <div className="container-presentaion-personnage">
                    <div className="container-img-personnage">
                      <img
                        src="/img/personnages/moussa.svg"
                        alt="Personnage 1"
                        className="img-personnage"
                      />
                    </div>
                    <div className="container-text-presentation-personnage">
                      <h3 className="nom-personnage mb-4">MOUSSA</h3>
                      <p className="text-description-presentation-personnage">
                        Bonjour, je m'appelle Moussa Diatta, j'ai 20 ans et
                        j'habite dans le village de Koubanaw, près de
                        Ziguinchor. Je viens de commencer mes études de droit à
                        l'Université Numérique Cheikh Amidou Kane (UNCHK). Je
                        veux devenir avocat ! Ma formation se fait entièrement
                        en ligne et à distance. Plusieurs fois par semaine, nous
                        recevons un cours en ligne et ensuite des travaux à
                        rendre. Pas seulement des travaux individuels, mais
                        aussi des travaux de groupe. Je travaille avec un groupe
                        d'étudiants en ligne sur un projet de recherche sur
                        l'évolution des droits humain. Nous avons des réunions
                        en ligne et nous discutons beaucoup sur WhatsApp, mais
                        la connexion Internet dans mon village est un véritable
                        défi !
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="container-btn-theme-presenttion-page"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 15,
                  }}
                >
                  <a
                    id="personnage2-tab"
                    data-toggle="tab"
                    href="#p2"
                    role="tab"
                    aria-controls="personnage2"
                    aria-selected="false"
                    className="btn btn-theme-outline"
                  >
                    Précédent
                  </a>
                  <a
                    id="personnage4-tab"
                    data-toggle="tab"
                    href="#p4"
                    role="tab"
                    aria-controls="personnage4"
                    aria-selected="false"
                    className="btn btn-theme"
                  >
                    Suivant
                  </a>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="p4"
                role="tabpanel"
                aria-labelledby="personnage4-tab"
              >
                <div className="container-presentation-page mb-4">
                  <div className="container-presentaion-personnage">
                    <div className="container-img-personnage">
                      <img
                        src="/img/personnages/aicha.svg"
                        alt="Personnage 1"
                        className="img-personnage"
                      />
                    </div>
                    <div className="container-text-presentation-personnage">
                      <h3 className="nom-personnage mb-4">AICHA</h3>
                      <p className="text-description-presentation-personnage">
                        Bonjour, je m'appelle Aicha Ndiaye, j'ai 24 ans et
                        j'habite à Thiès. Je viens d'obtenir ma licence en
                        économie à l'Université Cheikh Anta Diop de Dakar (UCAD)
                        et je suis maintenant à la recherche d'un emploi. J'ai
                        fait un gros projet sur le commerce international
                        pendant mes études, mais je ne sais pas encore si je
                        veux continuer dans cette voie car trouver un emploi
                        salarié est très compliqué ! J'envisage de me
                        reconvertir carrément. Je pense que je devrais prendre
                        mon temps pour regarder un peu le marché du travail,
                        commencer à créer un réseau et voir quelles opportunités
                        s'offrent à moi. Je suis assez active sur LinkedIn et je
                        publie parfois des petits articles sur des sujets qui
                        m'intéressent.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="container-btn-theme-presenttion-page"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 15,
                  }}
                >
                  <a
                    id="personnage3-tab"
                    data-toggle="tab"
                    href="#p3"
                    role="tab"
                    aria-controls="personnage3"
                    aria-selected="false"
                    className="btn btn-theme-outline"
                  >
                    Précédent
                  </a>
                  <NavLink to="/choisir-personnage" className="btn btn-theme">
                    Suivant
                  </NavLink>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};

export default AllPersonnages;
