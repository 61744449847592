import FeatherIcon from "feather-icons-react";
import React from "react";
import baseUrl from "../../../http/backend-base-url";
import { useDeleteQuizFree } from "./SupportQcm";
function SupportClickReaveal({ data, contentId, onReload }) {
  const dq = useDeleteQuizFree(onReload);

  return (
    <div>
      <div className="row mt-1 mb-2">
        <div className="col-md-6">
          <div
            className="link-collapse-session-item"
            style={{ position: "relative" }}
          >
            {data.title}
          </div>
        </div>
        <div className="col-md-6 text-right">
          <button
            onClick={() => dq(data.id)}
            className="btn-action-modul-session"
          >
            <FeatherIcon icon="trash-2" />
          </button>
        </div>
      </div>
      {data.list_question.map((q) => {
        return <RevealItem item={q} key={q.id} />;
      })}
    </div>
  );
}

export default SupportClickReaveal;

function RevealItem({ item }) {
  const res = item.list_responses[0];
  return (
    <div className="session-content-container">
      <div className="card-item-sessions-module contenu-shadow">
        <div>
          <div className="card card-body p-t-20 m-b-0">
            <div className="row">
              <div className="col-md-6">
                <p>Question</p>
                {!!!item.image && (
                  <span dangerouslySetInnerHTML={{ __html: item.content }} />
                )}
                {!!item.image && (
                  <img
                    src={baseUrl + item.image}
                    alt=""
                    style={{ maxWidth: 200, minWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
              <div className="col-md-6">
                <p>Réponse</p>
                {!!!res?.image && (
                  <span dangerouslySetInnerHTML={{ __html: res?.content }} />
                )}
                {!!res?.image && (
                  <img
                    src={baseUrl + res?.image}
                    alt=""
                    style={{ maxWidth: 200, minWidth: "100%", height: "auto" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
