import axios from "axios";
import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import api from "../../http/global-vars";
import CardModuleSkeleton from "./CardModuleSkeleton";
import ModalAddModule from "./ModalAddModule";
import { useDeleteItem } from "./supportContentAdmin/SupportQcm";

export default class QuizItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      loading: false,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const timer = setTimeout(() => {
      this.getModules();
    }, 2000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

  getModules = async () => {
    const url = `${this.state.api}modules`;
    //console.log('url', url)
    // this.setState({ loading: true });
    try {
      axios.get(url, this.state.requestConfig).then((response) => {
        const data = response.data.results;
        // console.log("data module", data);
        if (data) {
          this.setState({ modules: data, loading: false });
        }
      });
    } catch (error) {
      console.log("e", error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { modules, loading } = this.state;
    return (
      <>
        {loading ? (
          <CardModuleSkeleton />
        ) : (
          <>
            {modules.map((module) => {
              return (
                <ModuleDisplayItem
                  onReload={this.getModules}
                  module={module}
                  key={module.id}
                />
              );
            })}
          </>
        )}
        <AddModuleModal modalId="AddModuleModal" onReload={this.getModules} />
      </>
    );
  }
}

function AddModuleModal({ modalId, module, onReload }) {
  return (
    <div
      className="modal fade modalAddModule"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content card-dashboard">
          <ModalAddModule
            modalId={modalId}
            module={module}
            getModules={onReload}
          />
        </div>
      </div>
    </div>
  );
}

function ModuleDisplayItem({ module, onReload }) {
  const modalId = "UpdateModule" + module.id;
  const onDelete = useDeleteItem(
    onReload,
    "module",
    "Module supprimé avec succès",
    "Module non supprimé"
  );
  return (
    <>
      <div className="module-item" key={module.id}>
        <div className="row align-items-center">
          <div className="col-md-8 col-sm-7">
            <NavLink
              to={{
                pathname: `/module-details/${module?.id}`,
                state: module,
              }}
              className="no-link"
            >
              <p
                className="titre-module-item"
                id={`module-name${module.id}`}
                data-module={module.name}
              >
                {module.name}
              </p>
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 dis-flex itm-center m-b-15">
                  <img
                    className="icon-module-item"
                    src="img/icons/module-icon-card1.png"
                    alt=""
                  />
                  <div className="align-right-in-small-device">
                    <p className="chiffre-module-item">
                      {module.number_session}
                    </p>
                    <p className="text-content-module-item">sessions</p>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4 col-sm-5 text-center ">
            <div className="row">
              {/* <div className="col-md-6">
                <NavLink
                  className="btn-details-module-item d-block "
                  to={{
                    pathname: `/module-details/${module.id}`,
                    state: module,
                  }}
                >
                  Détails
                </NavLink>
              </div> */}
              <div className="col-md-6">
                <button
                  data-toggle="modal"
                  data-target={`#${modalId}`}
                  className="btn-details-module-item d-block "
                >
                  {/* <FeatherIcon icon="edit" /> */}
                  Modifier
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn-details-module-item d-block "
                  onClick={() => onDelete(module?.id)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddModuleModal modalId={modalId} module={module} onReload={onReload} />
    </>
  );
}
